import { useCallback, useContext, useEffect, useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { InspectionAreaDownSampleStatus } from 'project-dashboard-library/dist/interfaces/inspectionArea'
import { useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { RootState, useAppDispatch } from 'store/app'
import {
  fetchInspectionArea,
  reset,
  setInspectionArea,
  setInspectionAreas,
  setIsInvited,
  setIsLoading,
  setIsOwner,
  setProject,
} from 'store/page'

import { GlobalModalContext } from 'contexts/GlobalModal'

import { JOBS_WATCHING_INTERVAL } from 'config/constants'

import { InspectionArea } from 'interfaces/inspection'
import { Project } from 'interfaces/project'

import { getInspectionAreas, setInspectionAreasOrder } from 'services/InspectionArea'
import { getProject } from 'services/Projects'
import { isInvited, isOwner } from 'services/Validation'

interface PageHookProps {
  onLoaded?: ({
    project,
    inspectionArea,
    inspectionAreas,
  }: {
    project: Project
    inspectionArea: InspectionArea | null
    inspectionAreas: InspectionArea[] | null
  }) => Promise<void>
}

export const usePage = ({ onLoaded }: PageHookProps = {}) => {
  const { project_id } = useParams<{ project_id: string }>()
  const location = useLocation()
  const queries = new URLSearchParams(location.search)
  const inspection_area_id = queries.get('area')

  // States
  const [initComplete, setInitComplete] = useState(false)

  // Context
  const { getAccessTokenSilently } = useAuth0()
  const { showErrorModal } = useContext(GlobalModalContext)

  // Store
  const dispatch = useAppDispatch()
  const project = useSelector((state: RootState) => state.page.project)
  const inspectionArea = useSelector((state: RootState) => state.page.inspectionArea)
  const inspectionAreas = useSelector((state: RootState) => state.page.inspectionAreas)
  const userLoaded = useSelector((state: RootState) => state.user.userLoaded)
  const userProfile = useSelector((state: RootState) => state.user.userProfile)

  /**
   * Check the status of the inspection area
   */
  const checkInspectionAreaStatus = useCallback(async () => {
    if (!project_id || !inspection_area_id) return

    const token = await getAccessTokenSilently()
    if (!token) {
      return
    }

    await dispatch(
      fetchInspectionArea({
        access_token: token,
        project_id,
        inspection_area_id,
      }),
    )
  }, [project_id, inspection_area_id, getAccessTokenSilently, dispatch])

  useEffect(() => {
    if (inspectionArea && inspectionArea.downsample_status?.status === InspectionAreaDownSampleStatus.RUNNING) {
      setTimeout(() => {
        void checkInspectionAreaStatus()
      }, JOBS_WATCHING_INTERVAL)
    }
  }, [inspectionArea, checkInspectionAreaStatus, dispatch])

  /**
   * If area was switched after initial page load.
   */
  useEffect(() => {
    if (!initComplete || !inspection_area_id || !inspectionAreas.length) return

    const area = inspectionAreas?.find((ia) => ia.inspection_area_id === inspection_area_id)

    if (inspection_area_id && !area) {
      showErrorModal('エリアが見つかりませんでした。')
      dispatch(setIsLoading(false))
      return
    }

    dispatch(setIsLoading(false))
    dispatch(setInspectionArea(area))
  }, [inspection_area_id, inspectionAreas, initComplete, showErrorModal, dispatch])

  /**
   * Fetch project and inspection area on page load.
   */
  useEffect(() => {
    if (!userLoaded || !project_id || initComplete) {
      return () => null
    }

    dispatch(setIsLoading(true))

    const controller = new AbortController()

    void (async () => {
      const token = await getAccessTokenSilently()
      if (!token) {
        dispatch(setIsLoading(false))
        return
      }

      // fetch project and all inspection areas of that project
      const result = await Promise.all([
        getProject(token, project_id, showErrorModal, controller.signal),
        getInspectionAreas(token, project_id, showErrorModal, controller.signal),
      ])

      // false refers to aborted requests, ignore them
      if (result.some((r) => r === false)) {
        return
      }

      if (result.some((r) => r === null)) {
        dispatch(setIsLoading(false))
        return
      }

      // Derive inspection area from areas list, if inspection_area_id is provided
      const data = {
        project: result[0] as Project,
        inspectionAreas: setInspectionAreasOrder(result[1] as InspectionArea[], []).sort((a, b) => a.order! - b.order!),
        inspectionArea:
          (result[1] as InspectionArea[])?.find((ia) => ia.inspection_area_id === inspection_area_id) || null,
      }

      dispatch(setProject(data.project))
      dispatch(setInspectionAreas(data.inspectionAreas))
      dispatch(setIsLoading(false))
      setInitComplete(true)

      if (onLoaded) await onLoaded(data)
    })()

    return () => {
      controller.abort()
    }
  }, [
    userLoaded,
    project_id,
    inspection_area_id,
    initComplete,
    getAccessTokenSilently,
    dispatch,
    onLoaded,
    showErrorModal,
  ])

  /**
   * Set owner and invited permission.
   */
  useEffect(() => {
    if (!project || !userProfile) return

    dispatch(setIsOwner(isOwner(project, userProfile)))
    dispatch(setIsInvited(isInvited(project, userProfile)))
  }, [project, userProfile, dispatch])

  /**
   * Cleanup on unmount.
   */
  useEffect(
    () => () => {
      dispatch(reset())
    },
    [dispatch],
  )

  return {
    project,
    inspectionArea,
  }
}
