import axios, { CanceledError } from 'axios'

import { API_GATEWAY_URL } from 'config/environments'

import {
  InspectionAreaRebarSurface,
  InspectionAreaVolume,
  InspectionItem,
  InspectionSheet,
  Project,
  Shapes,
} from 'interfaces/interfaces'

import { ERROR_PROCESS, processErrorHandler } from './ErrorHandler'

export const PROJECTS_API_URL = `${API_GATEWAY_URL}/projects`
const INVITE_USER_API_URL = `${API_GATEWAY_URL}/invite-user`
export const GET_RPOJECTS_API_URL = (...args: string[]) => `${PROJECTS_API_URL}/${args.join('/')}`

/* --- 工事操作 --- */

/**
 * Get all projects
 * @param {string} access_token
 * @return {{projects: Project[]}}
 */
export const getProjects = async (
  access_token: string,
  userId: string | undefined,
  showErrorModal: (message: string) => void,
): Promise<{ projects: Project[] } | null> => {
  const projects = await axios
    .get<{ projects: Project[] }>(`${PROJECTS_API_URL}${userId ? `?user_id=${userId}` : ''}`, {
      responseType: 'json',
      headers: { 'X-Authorization': `Bearer ${access_token}` },
    })
    .then((response) => ({ projects: response.data.projects }))
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.GET_PROJECTS, showErrorModal)
      return null
    })

  return projects
}

/**
 * Get a project by ID
 * @param {string} access_token Access token provided by Auth0
 * @param {string} projectId Project ID
 */
export const getProject = async (
  access_token: string,
  projectId: string,
  showErrorModal?: (message: string) => void,
  abortSignal?: AbortSignal,
): Promise<Project | null | false> =>
  axios
    .get<{ project: Project | null }>(`${PROJECTS_API_URL}/${projectId}`, {
      responseType: 'json',
      headers: { 'X-Authorization': `Bearer ${access_token}` },
      signal: abortSignal,
    })
    .then((response) => response.data.project)
    .catch((err) => {
      // istanbul ignore next can't test DOMEException throws so ignore it from coverage. Test would have tested with CanceledError.
      // if signal is provided and the request is aborted, ignore it. It would have been intentional.
      if (abortSignal && ((err instanceof DOMException && err.name === 'AbortError') || err instanceof CanceledError)) {
        return false
      }

      if (showErrorModal) {
        processErrorHandler(err, ERROR_PROCESS.GET_PROJECTS, showErrorModal)
        return null
      }

      throw err
    })

/**
 * 共有した工事群の取得
 * @param {string} access_token アクセストークン
 * @return {{projects: Project[]}}
 */
export const getInvitedProjects = async (
  access_token: string,
  userId: string | undefined,
  showErrorModal: (message: string) => void,
): Promise<{ projects: Project[] } | null> => {
  const projects = await axios
    .get<{ projects: Project[] }>(`${PROJECTS_API_URL}?shared=true${userId ? `&user_id=${userId}` : ''}`, {
      responseType: 'json',
      headers: { 'X-Authorization': `Bearer ${access_token}` },
    })
    .then((response) => ({ projects: response.data.projects }))
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.GET_PROJECTS, showErrorModal)
      return null
    })

  return projects
}

/**
 * Get all inspection sheets for a project.
 * @param access_token
 * @param projectId
 * @param inspectionAreaId
 * @param showErrorModal
 * @returns
 */
export const getProjectInspectionSheets = async (
  access_token: string,
  projectId: string,
  showErrorModal: (message: string) => void,
  abortSignal?: AbortSignal,
): Promise<InspectionSheet[] | false> => {
  const sheets = await axios
    .get<{ results: InspectionSheet[] }>(`${PROJECTS_API_URL}/${projectId}/inspection-sheets`, {
      responseType: 'json',
      headers: { 'X-Authorization': `Bearer ${access_token}` },
      signal: abortSignal,
    })
    .then((response) => response.data.results)
    .catch((err) => {
      // istanbul ignore next can't test DOMEException throws so ignore it from coverage. Test would have tested with CanceledError.
      // if signal is provided and the request is aborted, ignore it. It would have been intentional.
      if (abortSignal && ((err instanceof DOMException && err.name === 'AbortError') || err instanceof CanceledError)) {
        return false
      }

      processErrorHandler(err, ERROR_PROCESS.GET_INSPECTION_SHEET, showErrorModal)
      return []
    })

  return sheets
}

/**
 * Get all inspection items for all inspection areas within a project.
 * @param access_token API Access token
 * @param project_id Project ID
 * @param showErrorModal Function to show error modal in case of failure.
 */
export const getProjectInspectionItems = (
  access_token: string,
  project_id: string,
  showErrorModal: (message: string) => void,
  abortSignal?: AbortSignal,
) =>
  axios
    .get<{ results: InspectionItem[] }>(`${PROJECTS_API_URL}/${project_id}/inspection-items`, {
      responseType: 'json',
      headers: { 'X-Authorization': `Bearer ${access_token}` },
      signal: abortSignal,
    })
    .then((response) => response.data.results)
    .catch((err) => {
      // istanbul ignore next can't test DOMEException throws so ignore it from coverage. Test would have tested with CanceledError.
      // if signal is provided and the request is aborted, ignore it. It would have been intentional.
      if (abortSignal && ((err instanceof DOMException && err.name === 'AbortError') || err instanceof CanceledError)) {
        return false
      }

      processErrorHandler(err, ERROR_PROCESS.GET_INSPECTION_ITEMS, showErrorModal)
      return null
    })

/**
 * Get all shapes for all inspection areas within a project.
 * @param access_token API Access token
 * @param project_id Project ID
 * @param showErrorModal Function to show error modal in case of failure.
 */
export const getProjectShapes = (
  access_token: string,
  project_id: string,
  showErrorModal: (message: string) => void,
  abortSignal?: AbortSignal,
) =>
  axios
    .get<{ results: Shapes }>(`${PROJECTS_API_URL}/${project_id}/shapes`, {
      responseType: 'json',
      headers: { 'X-Authorization': `Bearer ${access_token}` },
      signal: abortSignal,
    })
    .then((response) => response.data.results)
    .catch((err) => {
      // istanbul ignore next can't test DOMEException throws so ignore it from coverage. Test would have tested with CanceledError.
      // if signal is provided and the request is aborted, ignore it. It would have been intentional.
      if (abortSignal && ((err instanceof DOMException && err.name === 'AbortError') || err instanceof CanceledError)) {
        return false
      }

      processErrorHandler(err, ERROR_PROCESS.GET_INSPECTION_ITEMS, showErrorModal)
      return null
    })

/**
 * 工事データの保存
 * @param {string} access_token アクセストークン
 * @param {string} project_name 工事名
 * @return {Project} Projectオブジェクト
 */
export const postProject = async (
  access_token: string,
  project: Project,
  showErrorModal: (message: string) => void,
): Promise<Project | null> =>
  axios
    .post<{ message: string; project: Project }>(
      `${PROJECTS_API_URL}`,
      {
        project_name: project.project_name,
        default_construction_properties: project.default_construction_properties,
      },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      },
    )
    .then((response) => response.data.project)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.CREATE_PROJECT, showErrorModal)
      return null
    })

/**
 * 工事名の変更
 * @param {string} access_token アクセストークン
 * @param {string} project_id ファイル名
 * @param {string} project_name 工事名
 * @return {Project} Projectオブジェクト
 * @deprecated Use updateProject instead
 */
export const updateProjectName = async (
  access_token: string,
  project_id: string,
  project_name: string,
  showErrorModal: (message: string) => void,
): Promise<Project | null> => {
  const project = await axios
    .patch<Project>(
      `${PROJECTS_API_URL}/${project_id}`,
      {
        project_name,
      },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      },
    )
    .then((response) => response.data)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.UPDATE_PROJECT, showErrorModal)
      return null
    })

  return project
}

/**
 * Update project.
 *
 * @param access_token Auth0 access token
 * @param project Project object to update
 * @param showErrorModal Function to show error modal in case of failure
 * @returns Updated project object
 */
export const updateProject = async (
  access_token: string,
  {
    project_id,
    project_name,
    default_construction_properties,
    sheet_rows_visibility,
    sheet_cols_visibility,
    sheet_diagram_visibility,
    sheet_models_visibility,
  }: Project,
  showErrorModal?: (message: string) => void,
) => {
  const request = axios
    .patch<Project>(
      `${PROJECTS_API_URL}/${project_id}`,
      {
        project_name,
        default_construction_properties,
        sheet_rows_visibility,
        sheet_cols_visibility,
        sheet_diagram_visibility,
        sheet_models_visibility,
      },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      },
    )
    .then((response) => response.data)

  if (showErrorModal) {
    return request.catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.UPDATE_PROJECT, showErrorModal)
      return null
    })
  }

  return request
}

/**
 * 閲覧ユーザーを招待
 * @param {string} access_token アクセストークン
 * @param {string} project_id 工事ID
 * @param {string} email_address 閲覧ユーザーのメール
 * @return {Project} Projectオブジェクト
 */
export const inviteUserToProject = async (
  access_token: string,
  project_id: string,
  email_address: string,
  showErrorModal: (message: string) => void,
): Promise<Project | null> => {
  const project = await axios
    .post<Project>(
      `${INVITE_USER_API_URL}`,
      {
        project_id,
        email_address,
      },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      },
    )
    .then((response) => response.data)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.INVITE_USER_TO_PROJECT, showErrorModal)
      return null
    })

  return project
}

/**
 * 閲覧ユーザーを削除
 * @param {string} access_token アクセストークン
 * @param {string} project_id 工事ID
 * @param {string} user_id 閲覧ユーザーのID
 * @return {boolean}
 */
export const deleteInvitedUserFromProject = async (
  access_token: string,
  project_id: string,
  user_id: string,
  showErrorModal: (message: string) => void,
): Promise<boolean> => {
  const result = await axios
    .delete<Project>(`${PROJECTS_API_URL}/${project_id}/users/${user_id}`, {
      responseType: 'json',
      headers: { 'X-Authorization': `Bearer ${access_token}` },
    })
    .then(() => true)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.DELETE_INVITED_USER_FROM_PROJECT, showErrorModal)
      return false
    })

  return result
}

/**
 * 工事群の取得
 * @param {string} access_token アクセストークン
 * @return {{projects: Project[], total_size: number}} {projects: Project[], total_size: number}
 */
export const deleteProject = async (
  access_token: string,
  projectId: string,
  showErrorModal: (message: string) => void,
): Promise<boolean> => {
  const result = await axios
    .delete(`${PROJECTS_API_URL}/${projectId}`, { headers: { 'X-Authorization': `Bearer ${access_token}` } })
    .then(() => true)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.DELETE_PROJECT, showErrorModal)
      return false
    })
  return result
}

/**
 * Calculate total volume for a project.
 * This will limit decimal places to 4 digits to prevent too long of a number
 * and as part of safety for floating point error.
 *
 * @param inspectionAreasVolumes Inspection areas volumes (result from calculateInspectionAreasTotalVolume)
 * @returns
 */
export const calculateProjectTotalVolume = (inspectionAreasVolumes: InspectionAreaVolume[]): number =>
  parseFloat(inspectionAreasVolumes.reduce((previous, current) => (current.volume || 0) + previous, 0).toFixed(4))

/**
 * Calculate total rebar surface for a project.
 * This will limit decimal places to 4 digits to prevent too long of a number
 * and as part of safety for floating point error.
 *
 * @param inspectionAreasSurfaces Inspection areas rebar surfaces (result from calculateInspectionAreasTotalRebarSurfaceArea)
 * @returns
 */
export const calculateProjectTotalRebarSurface = (inspectionAreasRebarSurfaces: InspectionAreaRebarSurface[]): number =>
  parseFloat(
    inspectionAreasRebarSurfaces.reduce((previous, current) => (current.surface || 0) + previous, 0).toFixed(4),
  )
