import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { PointArray } from 'interfaces/attribute'
import { Cuboid } from 'interfaces/shape'

export interface MaskPCDState {
  /**
   * Mask cuboid is being saved.
   */
  isLoading: boolean

  /**
   * List of all cuboids loaded.
   */
  regions: Cuboid[]

  /**
   * Position of current mouse position.
   */
  mouseAnchor?: PointArray
}

const initialState: MaskPCDState = {
  isLoading: false,
  regions: [],
}

export const slice = createSlice({
  name: 'editor/tools/maskPCD',
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setMaskRegions: (state, action: PayloadAction<Cuboid[]>) => {
      state.regions = action.payload
    },
    addMaskRegion: (state, action: PayloadAction<Cuboid>) => {
      state.regions.push(action.payload)
    },
    removeMaskRegion: (state, action: PayloadAction<string>) => {
      state.regions = state.regions.filter((cuboid) => cuboid.region_id !== action.payload)
    },
    updateMaskRegion: (state, action: PayloadAction<Cuboid>) => {
      state.regions = state.regions.map((cuboid) =>
        cuboid.region_id === action.payload.region_id ? action.payload : cuboid,
      )
    },
    setMouseAnchor: (state, action: PayloadAction<PointArray | undefined>) => {
      state.mouseAnchor = action.payload
    },
  },
})

export const { setIsLoading, setMaskRegions, addMaskRegion, removeMaskRegion, updateMaskRegion, setMouseAnchor } =
  slice.actions

export default slice.reducer
