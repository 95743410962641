import { FC, useCallback, useContext, useMemo } from 'react'

import { Box, Button, Flex, HStack, Text } from '@chakra-ui/react'
import CollapsePanel from 'pages/projects/editor/infoPanels/components/CollapsePanel'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'

import { EditorContext } from 'contexts/Editor'

import { EDITOR_COLLAPSE_TYPES, EDITOR_TOOLS } from 'config/constants'
import { INFO_PANEL_PADDING } from 'config/styles'

import { PlaneSide } from 'interfaces/shape'

import { DrawingStage, setPlaneCreationOrder } from '../store'

const PlaneSideSelectionPanel: FC = () => {
  // Context
  const { selectedTool } = useContext(EditorContext)

  // Store
  const dispatch = useAppDispatch()
  const drawingStage = useSelector((root: RootState) => root.toolVolumeEstimationPolygon.drawingStage)
  const planeCreationOrder = useSelector((state: RootState) => state.toolVolumeEstimationPolygon.planeCreationOrder)

  // Flags
  const isToolSelected = useMemo(() => selectedTool === EDITOR_TOOLS.VOLUME_POLYGON, [selectedTool])
  const currentPlaneSide = planeCreationOrder[drawingStage === DrawingStage.Draw ? 0 : 1]
  const isDoneDrawing = drawingStage === DrawingStage.Complete

  /**
   * Depending on the drawing stage, the target plane to be working on will change.
   * - DrawingStage.Draw -> first element of the planeCreationOrder array.
   * - DrawingStage.HeightSelection -> second element of the planeCreationOrder array.
   */
  const getNewOrder = useCallback(
    (target: PlaneSide): PlaneSide[] => {
      // Get the opposite of target
      const opposite = target === PlaneSide.UPPER ? PlaneSide.LOWER : PlaneSide.UPPER

      if (drawingStage === DrawingStage.Draw) {
        return [target, opposite]
      }

      return [opposite, target]
    },
    [drawingStage],
  )

  // Only for volume polygon tool
  if (!isToolSelected) {
    return null
  }

  return (
    <Flex
      backgroundColor="gray.800"
      borderBottomLeftRadius="md"
      borderTopLeftRadius="md"
      w="100%"
      flex={1}
      data-testid="plane-side-selection-panel"
    >
      <HStack w="100%" spacing={0} pb={INFO_PANEL_PADDING - 1} overflowY="auto">
        <CollapsePanel title="検出中の設定" type={EDITOR_COLLAPSE_TYPES.planeSide} onChange={() => null}>
          <Box px={INFO_PANEL_PADDING} py={INFO_PANEL_PADDING}>
            <Text mb={2} fontSize="sm" color="gray.200">
              作成する平面
            </Text>
            <HStack>
              <Button
                key="plane-side-selection-button-upper"
                data-testid="plane-side-selection-button-upper"
                variant={currentPlaneSide === PlaneSide.UPPER && !isDoneDrawing ? 'toolbar' : 'editorGhost'}
                size="sm"
                fontSize="xs"
                onClick={
                  currentPlaneSide !== PlaneSide.UPPER
                    ? () => dispatch(setPlaneCreationOrder(getNewOrder(PlaneSide.UPPER)))
                    : undefined
                }
                isDisabled={isDoneDrawing}
                spinnerPlacement="end"
                justifyContent="space-between"
              >
                仕上がり面
              </Button>
              <Button
                key="plane-side-selection-button-lower"
                data-testid="plane-side-selection-button-lower"
                variant={currentPlaneSide === PlaneSide.LOWER && !isDoneDrawing ? 'toolbar' : 'editorGhost'}
                size="sm"
                fontSize="xs"
                onClick={
                  currentPlaneSide !== PlaneSide.LOWER
                    ? () => dispatch(setPlaneCreationOrder(getNewOrder(PlaneSide.LOWER)))
                    : undefined
                }
                isDisabled={isDoneDrawing}
                spinnerPlacement="end"
                justifyContent="space-between"
              >
                斫り面
              </Button>
            </HStack>
          </Box>
        </CollapsePanel>
      </HStack>
    </Flex>
  )
}

export default PlaneSideSelectionPanel
