/**
 * Hook to use blueprint store
 */
import { useCallback, useContext, useEffect, useRef, useState } from 'react'

import mixpanel from 'mixpanel-browser'
import { useSelector } from 'react-redux'
import { useMatches } from 'react-router-dom'
import { RootState, useAppDispatch } from 'store/app'

import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import { decideActionPermission } from 'services/Validation'

import { fetchAndAutoSelectBlueprintsByProject, reset, setIsAllowedModify } from '../store/blueprint'

export const useBlueprint = () => {
  // Route matching to check current page
  const matches = useMatches()
  const isInspectionAreaBlueprintPage = matches.some((row) => row.id === 'inspection-area-blueprint')

  // Contexts
  const { getAccessToken } = useContext(UserContext)
  const { showErrorModal } = useContext(GlobalModalContext)

  // Store
  const dispatch = useAppDispatch()
  const selectedBlueprint = useSelector((state: RootState) => state.blueprint.selectedBlueprint)
  const comments = useSelector((state: RootState) => state.comments.comments)
  const project = useSelector((state: RootState) => state.page.project)
  const isOwner = useSelector((state: RootState) => state.page.isOwner)
  const isInvited = useSelector((state: RootState) => state.page.isInvited)
  const inspectionArea = useSelector((state: RootState) => state.page.inspectionArea)
  const userLoaded = useSelector((state: RootState) => state.user.userLoaded)
  const userType = useSelector((state: RootState) => state.user.userType)
  const isPageLoading = useSelector((state: RootState) => state.page.isLoading)

  // States
  const [initComplete, setInitComplete] = useState(false)

  /**
   * Get comments specifically for the selected blueprint.
   * Will also include comments that are not yet assigned to a blueprint.
   */
  const getCommentsByBlueprint = useCallback(
    () => comments.filter((c) => c.blueprint_id === selectedBlueprint?.blueprint_id || !c.blueprint_id),
    [comments, selectedBlueprint],
  )

  /**
   * Mixpanel
   */
  // Super properties to register
  const mixpanelSuperPropsRef = useRef({
    Page: 'Blueprint',
    ...(project ? { 'Project ID': project.project_id } : {}),
    ...(inspectionArea ? { 'Inspection Area ID': inspectionArea.inspection_area_id } : {}),
  })

  // Cleanup super properties
  useEffect(() => () => Object.keys(mixpanelSuperPropsRef.current).forEach((prop) => mixpanel.unregister(prop)), [])

  /**
   * On page load, fetch project, inspection area and blueprints
   */
  useEffect(() => {
    void (async () => {
      if (!project || (isInspectionAreaBlueprintPage && !inspectionArea) || isPageLoading) {
        return
      }

      const access_token = await getAccessToken()
      if (!access_token) {
        return
      }

      await dispatch(
        fetchAndAutoSelectBlueprintsByProject({
          access_token,
          project_id: project.project_id,
          showErrorModal,
        }),
      )

      setInitComplete(true)

      // analytics
      mixpanel.register(mixpanelSuperPropsRef.current)
      mixpanel.track('Page View')
    })()
  }, [isPageLoading, project, inspectionArea, isInspectionAreaBlueprintPage, getAccessToken, showErrorModal, dispatch])

  /**
   * Set modify permission.
   */
  useEffect(() => {
    dispatch(setIsAllowedModify(decideActionPermission(isOwner, isInvited).BLUEPRINT_VIEWER.MODIFY.includes(userType)))
  }, [dispatch, isOwner, isInvited, userType])

  /**
   * We're only interested in unmouting of the component.
   * Since we use redux, state remains after unmount so need to clear it manually.
   */
  useEffect(
    () => () => {
      dispatch(reset())
    },
    [dispatch],
  )

  return {
    // store
    comments: getCommentsByBlueprint(),
    selectedBlueprint,

    // Flags
    isLoading: !userLoaded || !initComplete,
  }
}
