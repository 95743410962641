import { useContext } from 'react'

import dayjs from 'dayjs'
import { useSelector } from 'react-redux'
import { RootState } from 'store/app'
import { Cache } from 'three'

import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import {
  UPLOAD_LIMIT_BYTE,
  UPLOAD_LIMIT_FILE_BYTE,
  UPLOAD_LIMIT_FILE_GIGABYTE,
  UPLOAD_LIMIT_FILE_LAZ_BYTE,
  UPLOAD_LIMIT_FILE_LAZ_MEGABYTE,
  UPLOAD_LIMIT_GIGABYTE,
} from 'config/constants'

import { InspectionArea } from 'interfaces/inspection'
import { Project } from 'interfaces/project'

import { uploadFile } from 'services/AWS'
import { getSignedUrlForUploadFile, updateInspectionAreaFileName } from 'services/InspectionArea'
import { returnContentType } from 'services/Util'
import { checkSize } from 'services/Validation'

/**
 * File definition for uploading a PCD file.
 */
export interface UploadPCDFile {
  file: File
  name: string
  content_type: string
}

export function useUploadPcd() {
  // Contexts
  const { getAccessToken } = useContext(UserContext)
  const { showErrorModal } = useContext(GlobalModalContext)

  // Store
  const userProfile = useSelector((root: RootState) => root.user.userProfile)

  /**
   * Process the selected file and return the file information.
   *
   * @param e - The event containing the selected file
   */
  const processSelectedFile = (e: React.ChangeEvent<HTMLInputElement>): UploadPCDFile | false => {
    e.preventDefault()
    if (!e || !e.target || !e.target.files || !e.target.files.length) return false
    const file = e.target.files[0]
    const extension: string | undefined = file.name.split('.').pop()

    //* ファイルサイズチェック
    if (!checkSize(file.size, extension === 'laz' ? UPLOAD_LIMIT_FILE_LAZ_BYTE : UPLOAD_LIMIT_FILE_BYTE)) {
      showErrorModal(
        `${UPLOAD_LIMIT_FILE_GIGABYTE}GB以下（LAZの場合${UPLOAD_LIMIT_FILE_LAZ_MEGABYTE}MB以下）のファイルのみアップロード可能です。`,
      )
      return false
    }

    if (!checkSize(file.size + (userProfile?.stats?.total_size || 0), UPLOAD_LIMIT_BYTE)) {
      showErrorModal(`アップロードファイルの合計が${UPLOAD_LIMIT_GIGABYTE}GBを超えています。`)
      return false
    }

    const filename = dayjs().format('YYYYMMDDHHmmss_') + file.name
    const content_type = returnContentType(filename)

    if (!content_type) {
      showErrorModal('ファイル形式に誤りがあります。')
      return false
    }

    if (!checkSize(file.size, extension === 'laz' ? UPLOAD_LIMIT_FILE_LAZ_BYTE : UPLOAD_LIMIT_FILE_BYTE)) {
      showErrorModal(
        `${UPLOAD_LIMIT_FILE_GIGABYTE}GB以下（LAZの場合${UPLOAD_LIMIT_FILE_LAZ_MEGABYTE}MB以下）のファイルのみアップロード可能です。`,
      )
      return false
    }

    return {
      file,
      name: file.name,
      content_type,
    }
  }

  /**
   * Upload a PCD file.
   * No need to translate any error messages here, they will not be shown to the user.
   *
   * @param project - The project to which the inspection area will be added
   * @param inspectionArea - The inspection area to be edited. If this is provided, the modal will be in edit mode
   * @param file - The file to upload
   * @param onProgress - A function to call with the upload progress
   * @returns The updated inspection area
   * @throws If any error occurs during the upload process
   */
  const uploadPcd = async (
    project: Project,
    inspectionArea: InspectionArea,
    file: UploadPCDFile,
    onProgress: (value: number) => void,
  ): Promise<InspectionArea> => {
    const token = await getAccessToken()
    if (!token) {
      throw new Error('failed to get access token')
    }

    const presignedUrl = await getSignedUrlForUploadFile(token, file.name, file.content_type, showErrorModal)
    if (!presignedUrl) {
      throw new Error('failed to get presigned url')
    }

    //* ファイルアップロード
    const uploadResult = await uploadFile(presignedUrl, file.content_type, file.file, onProgress, showErrorModal)
    if (!uploadResult) {
      throw new Error('failed to upload file')
    }

    if (inspectionArea?.project_id && inspectionArea.down_sampled_file?.name) {
      Cache.remove(inspectionArea.inspection_area_id)
    }

    //* Add file name to InspectionArea
    const updateResult = await updateInspectionAreaFileName(
      token,
      project.project_id,
      inspectionArea.inspection_area_id,
      file.name,
      showErrorModal,
    )
    if (!updateResult) {
      throw new Error('failed to update inspection area file name')
    }

    return updateResult
  }

  return { processSelectedFile, uploadPcd }
}
