import { FC, useContext, useEffect, useRef, useState } from 'react'

import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Container,
  Flex,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Spacer,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import Navbar from 'components/Navbar/Navbar'
import PageHeading from 'components/PageHeading'
import mixpanel from 'mixpanel-browser'
import PageFooter from 'pages/common/PageFooter'
import { useSelector } from 'react-redux'
import { Link as RouterLink, useParams } from 'react-router-dom'
import { RootState, useAppDispatch } from 'store/app'

import { AddIcon, HDotsIcon } from 'assets/icons'

import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import { MODAL_TYPES } from 'config/constants'
import { CONTAINER_MAX_WIDTH } from 'config/styles'

import { deleteInvitedUserFromProject } from 'services/Projects'

import InviteUserModal from './components/InviteUserModal'
import { useDashboard } from './hooks/dashboard'
import { fetchProjects } from './store/dashboard'

const InvitedUsersTable: FC = () => {
  useDashboard()

  const { project_id } = useParams<{ project_id: string }>()

  // Store
  const dispatch = useAppDispatch()
  const permissionSet = useSelector((state: RootState) => state.dashboard.permissionSet)
  const projects = useSelector((state: RootState) => state.dashboard.projects)
  const invitedProjects = useSelector((state: RootState) => state.dashboard.invitedProjects)
  const userType = useSelector((state: RootState) => state.user.userType)

  // Context
  const { showModal, showErrorModal } = useContext(GlobalModalContext)
  const { getAccessToken } = useContext(UserContext)

  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const ownProject = projects.find((proj) => proj.project_id === project_id)
  const invitedProject = invitedProjects.find((proj) => proj.project_id === project_id)
  const project = invitedProject || ownProject
  const invitedUsers = { ...(ownProject?.shared_user || {}), ...(invitedProject?.shared_user || {}) }

  // Permission check
  const isAllowedToInvite = permissionSet.INVITE_USER_TO_PROJECT.includes(userType)

  const confirmInvitationDelete = (userId: string) => {
    if (!project_id) {
      return false
    }

    showModal({
      body: (
        <>
          <Text>一度削除してしまうと、元に戻せません。</Text>
          <Text mt="1" fontWeight="semibold">
            {invitedUsers[userId]}
          </Text>
        </>
      ),
      confirmText: '削除',
      modalType: MODAL_TYPES.CONFIRMATION_CRITICAL,
      onConfirm: () => {
        const deleteInvitationApi = async () => {
          setIsLoading(true)
          const access_token = await getAccessToken()
          if (!access_token) {
            setIsLoading(false)
            return false
          }

          const deleteResult = await deleteInvitedUserFromProject(access_token, project_id, userId, showErrorModal)
          if (!deleteResult) {
            setIsLoading(false)
            return false
          }

          mixpanel.track('Delete Invited User', {
            'Project ID': project_id,
            'Invited User ID': userId,
          })

          await dispatch(fetchProjects({ access_token, showErrorModal }))
          setIsLoading(false)
          return true
        }

        void deleteInvitationApi()
        return true
      },
      title: '閲覧ユーザーを工事から削除しますか？',
    })
    return true
  }

  /**
   * Mixpanel
   */
  // Super properties to register
  const mixpanelSuperPropsRef = useRef({
    Page: 'Invited User',
    'Project ID': project_id,
  })

  // Cleanup super properties
  useEffect(() => () => Object.keys(mixpanelSuperPropsRef.current).forEach((prop) => mixpanel.unregister(prop)), [])

  useEffect(() => {
    // Do analytics here to guarantee only ran once
    mixpanel.register(mixpanelSuperPropsRef.current)
    mixpanel.track('Page View')
  }, [project_id])

  if (!project_id || !project) return null

  return (
    <Flex flex={1} bg="gray.50" flexDirection="column">
      <Navbar />

      <Container maxW={CONTAINER_MAX_WIDTH} id="inner-container" flex={1}>
        <Breadcrumb pt={8}>
          <BreadcrumbItem>
            <Link as={RouterLink} to="/dashboard" variant="neutral">
              工事一覧
            </Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Text>{invitedProject?.project_name || ownProject?.project_name}</Text>
          </BreadcrumbItem>
        </Breadcrumb>
        <PageHeading>閲覧ユーザー一覧</PageHeading>
        <Box>
          {isAllowedToInvite && (
            <Button
              variant="outlinePrimary"
              size="md"
              mb={7}
              onClick={() => setIsInviteModalOpen(true)}
              rightIcon={<AddIcon />}
              aria-label="invite"
            >
              閲覧ユーザーを招待
            </Button>
          )}
        </Box>
        <TableContainer mb={10}>
          <Table w="100%" variant="simple" size="sm">
            <Thead fontSize="sm">
              <Tr>
                <Th>メールアドレス</Th>
                <Th w="1%" />
              </Tr>
            </Thead>
            <Tbody>
              {Object.keys(invitedUsers).map((userId: string) => (
                <Tr key={userId}>
                  <Td>{invitedUsers[userId]}</Td>
                  <Td>
                    <Menu>
                      <MenuButton
                        isDisabled={!isAllowedToInvite || isLoading}
                        variant="ghost"
                        as={IconButton}
                        aria-label="Actions"
                        fontSize="lg"
                        icon={<HDotsIcon />}
                      />
                      <Portal>
                        <MenuList>
                          {!!ownProject && (
                            <MenuItem aria-label={`delete.${userId}`} onClick={() => confirmInvitationDelete(userId)}>
                              削除
                            </MenuItem>
                          )}
                        </MenuList>
                      </Portal>
                    </Menu>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
        <InviteUserModal
          isOpen={isInviteModalOpen}
          invitingProject={project}
          onConfirm={() => setIsInviteModalOpen(false)}
        />
        <Spacer />
        <PageFooter />
      </Container>
    </Flex>
  )
}

export default InvitedUsersTable
