import './CustomizableField.css'

import { FC, ReactElement, useState } from 'react'

import { Box, Flex, Switch, Text, VStack } from '@chakra-ui/react'

import ToggleField, { ToggleFieldProps } from '../ToggleField'

const CustomizableField: FC<{
  icon?: ReactElement
  title: string
  enabled: boolean
  expanded?: boolean
  expandable?: boolean
  additionalFields?: ToggleFieldProps[]
  onToggle: (enabled: boolean) => void
}> = ({ icon, title, enabled, additionalFields = [], expanded = false, expandable = true, onToggle }) => {
  const [isExpanded, setIsExpanded] = useState(expanded)

  return (
    <Box
      className={[
        'customizable-field',
        enabled ? 'enabled' : 'disabled',
        expandable ? 'expandable' : null,
        isExpanded ? 'opened' : null,
      ].join(' ')}
    >
      <Flex p={2} className="header" onClick={expandable ? () => setIsExpanded(!isExpanded) : undefined} gap={2}>
        {icon}
        <Text flex={1} fontSize="xl">
          {title}
        </Text>
        <Switch isChecked={enabled} onChange={() => onToggle(!enabled)} />
      </Flex>
      <Box bg="gray.50" p={4} borderBottomRadius={6}>
        <Text mb={2}>追加のデータポイント</Text>
        <VStack bg="white" py={3} px={5} borderColor="gray.300" borderWidth={1} borderRadius={6}>
          {additionalFields.map((field) => (
            <ToggleField {...field} disabled={!enabled} />
          ))}
        </VStack>
      </Box>
    </Box>
  )
}

export default CustomizableField
