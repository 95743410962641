import { FC, useCallback } from 'react'

import { Center, Flex, Square, Tooltip, useDisclosure } from '@chakra-ui/react'
import TutorialVideoModal from 'components/TutorialVideoModal'
import mixpanel from 'mixpanel-browser'
import { useSelector } from 'react-redux'
import { RootState } from 'store/app'

import { HelpCircleIcon } from 'assets/icons'

import { EDITOR_TOOL_BUTTON_SIZE } from 'config/constants'

const HelpButton: FC = () => {
  const useDisclosureProps = useDisclosure()
  const { onOpen } = useDisclosureProps

  // store
  const videoConfig = useSelector((root: RootState) => root.videos.selectedVideoConfig)

  // tracking
  const openModal = useCallback(() => {
    if (videoConfig) {
      mixpanel.track('Show Help Modal', {
        'Selected Tool': videoConfig.toolType,
        'Video URL': videoConfig.videoUrl,
        'External URL': videoConfig.helpCenterUrl,
        'Triggered By': 'Help Button',
        'Help Config Version': videoConfig.version,
      })
    }

    // Open modal
    onOpen()
  }, [onOpen, videoConfig])

  return (
    <>
      {videoConfig && <TutorialVideoModal useDisclosureProps={useDisclosureProps} {...videoConfig} />}
      <Square
        position="relative"
        backgroundColor="gray.800"
        color="white"
        fontSize="xl"
        size={EDITOR_TOOL_BUTTON_SIZE}
        borderRadius="md"
        onClick={openModal}
      >
        <Tooltip hasArrow placement="right" label="ユーザーマニュアル" p={2} fontSize="xs" fontWeight="normal">
          <Flex>
            <Center width="100%" height="100%">
              <HelpCircleIcon size="60%" />
            </Center>
          </Flex>
        </Tooltip>
      </Square>
    </>
  )
}

export default HelpButton
