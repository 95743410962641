import { ToolbarGridIcon } from 'assets/icons'

import { EDITOR_TOOLS, EDITOR_TOOLS_LABELS, USER_TYPES } from 'config/constants'

import { EditorTool, ToolbarCategory } from 'interfaces/editor'
import { ShapeKeyType } from 'interfaces/interfaces'
import { PermissionSets } from 'interfaces/validation'

import GridInfoPanel from './components/infoPanels/GridInfoPanel'
import GridIntervalPanel from './components/infoPanels/GridIntervalPanel'
import WorkingGridInfoPanel from './components/infoPanels/WorkingGridInfoPanel'
import useEditor from './hooks/useEditor'
import useMainCanvas from './hooks/useMainCanvas'

/**
 * Grid depth tool - generates grid points on the selected volume.
 * --------------------------------------------------------------
 * Code-wise flow for manipulating grid points:
 * 1. useEditor - listen to `selectedShapeIds` to if any volume is selected and generates `intervals` stored in Redux store.
 *             - In the case the volume already has grid points, `intervals` will be marked as locked
 *               and generate `workingGridPoints` directly.
 * 2. useMainCanvas - listen to `intervals` to generate grid points from `intervals` that are not `locked`.
 * 3. useMainCanvas - listen to `workingGridPoints` to generate distance labels.
 *
 * Code-wise flow for loading grid points from API:
 * 1. useEditor - listen to `inspectionItems` to get items with grids and set `grids` in Redux store.
 * 2. useMainCanvas - listen to `grids` to generate distance labels.
 *
 * Extra notes:
 * - When abitrarily moving a grid point, it will set the `interval` config to `locked`.
 * - When returning distance labels for display, it will merge result of working and existing distance labels.
 *   - For working grid points that are locked and newly generated, it will re-use the existing values of `workingGridPoints`.
 */

const GridTool: EditorTool = {
  key: EDITOR_TOOLS.GRID,
  authCheck: (permissionSets: PermissionSets, userType: keyof typeof USER_TYPES) =>
    permissionSets.MAIN_CANVAS.DETECT.includes(userType),
  toolbar: {
    icon: <ToolbarGridIcon size="50%" />,
    label: EDITOR_TOOLS_LABELS.GRID,
    category: ToolbarCategory.Manipulate,
  },
  hooks: {
    useEditor,
    useMainCanvas,
  },
  components: {
    editor: [],
    panel: [GridIntervalPanel, WorkingGridInfoPanel, GridInfoPanel],
  },
  config: {
    volume: {
      required: true,
      selectable: true,
      onlyOneSelectable: true,
    },
    selectableShapes: [ShapeKeyType.POLYGON],
  },
}

export default GridTool
