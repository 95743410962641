import './index.css'

import React from 'react'

import { AppState, Auth0Provider } from '@auth0/auth0-react'
import { ChakraProvider } from '@chakra-ui/react'
import mixpanel from 'mixpanel-browser'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { store } from 'store/app'

import { AUTH0_AUDIENCE, AUTH0_CLIENT_ID, AUTH0_DOMAIN } from 'config/environments'
import theme from 'config/theme'

import { router } from 'services/Router'

import App from './App'

const onRedirectCallback = (appState: AppState | undefined) => {
  mixpanel.track('Login')
  void router.navigate(appState?.returnTo || window.location.pathname)
}

const root = createRoot(document.getElementById('root')!)

root.render(
  <Auth0Provider
    domain={AUTH0_DOMAIN}
    clientId={AUTH0_CLIENT_ID}
    authorizationParams={{
      audience: AUTH0_AUDIENCE,
      redirect_uri: window.location.origin,
    }}
    onRedirectCallback={onRedirectCallback}
  >
    <React.StrictMode>
      <ChakraProvider theme={theme}>
        <Provider store={store}>
          <App />
        </Provider>
      </ChakraProvider>
    </React.StrictMode>
  </Auth0Provider>,
)
