import { useCallback, useContext, useMemo, useState } from 'react'

import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'

import { GlobalModalContext } from 'contexts/GlobalModal'
import { RouterContext } from 'contexts/Router'
import { UserContext } from 'contexts/Users'

import { TEMP_COMMENT_ID, TEMP_REPLY_ID } from 'config/constants'

import { Comment } from 'interfaces/interfaces'

import { fetchCommentReplies, setEditingComment, setEditingReply, setFormImages } from '../store/comments'

export interface CommentPopupProps {
  /**
   * Comment to be shown in the popup.
   */
  comment: Comment

  /**
   * Triggered when user closes the popup.
   */
  onClose: () => void

  /**
   * Triggered when user initiates moving of comment.
   */
  onMove?: () => void
}

export const useCommentPopup = ({ comment, onClose }: CommentPopupProps) => {
  // Store
  const dispatch = useAppDispatch()
  const project = useSelector((state: RootState) => state.page.project)
  const editingComment = useSelector((state: RootState) => state.comments.editingComment)
  const editingReply = useSelector((state: RootState) => state.comments.editingReply)
  const inspectionArea = useSelector((state: RootState) => state.page.inspectionArea)
  const inspectionAreas = useSelector((state: RootState) => state.page.inspectionAreas)
  const blueprints = useSelector((state: RootState) => state.blueprint.blueprints)
  const user = useSelector((state: RootState) => state.user.user)

  // Context
  const { getAccessToken } = useContext(UserContext)
  const { showErrorModal } = useContext(GlobalModalContext)
  const { router } = useContext(RouterContext)

  // temp vars
  const isDisabled = false

  // Vars
  const isNewComment = comment.thread_id === TEMP_COMMENT_ID

  const isBlueprintPage = !!(
    router?.state?.matches?.find((match) =>
      ['project-blueprint', 'inspection-area-blueprint'].includes(match.route.id),
    ) || false
  )

  // State
  const [isLoading, setIsLoading] = useState(false)

  /**
   * Reset the form.
   */
  const handleResetForm = useCallback(() => {
    dispatch(setEditingReply(null))
    dispatch(setEditingComment(null))
    dispatch(setFormImages([]))
  }, [dispatch])

  /**
   * Cancel input of new comment/reply.
   */
  const onCancelInput = () => {
    onClose()

    // remove temp comment from store
    if (isNewComment) {
      handleResetForm()
    }
  }

  /**
   * Callback after saving of comment/reply.
   */
  const onSaved = () => {
    handleResetForm()

    if (comment.thread_id !== TEMP_COMMENT_ID) {
      void fetchReplies()
    }
  }

  /**
   * Set a dummy reply to show the reply form.
   */
  const showReplyForm = () => {
    if (!comment.thread_id || !user) return
    dispatch(setFormImages([]))
    dispatch(setEditingComment(null))
    dispatch(
      setEditingReply({
        reply_id: TEMP_REPLY_ID,
        author_name: user.nickname || '',
        reply_body: '',
        images: [],
        inspection_area_id: comment.inspection_area_id,
      }),
    )
  }

  /**
   * Fetch replies for a comment.
   */
  const fetchReplies = async () => {
    if (!project || !comment.inspection_area_id || !comment?.thread_id) {
      return false
    }

    setIsLoading(true)

    const token = await getAccessToken()
    if (!token) {
      setIsLoading(false)
      return false
    }

    await dispatch(
      fetchCommentReplies({
        token,
        project_id: project.project_id,
        inspection_area_id: comment.inspection_area_id,
        thread_id: comment.thread_id,
        showErrorModal,
      }),
    )

    setIsLoading(false)
    return true
  }

  /**
   * Title of the comment popup. Only used for linking to PCD/Blueprint.
   */
  const title = useMemo(() => {
    if (!blueprints.length || (!isBlueprintPage && !comment.blueprint_id)) {
      return {
        text: null,
        link: null,
      }
    }

    const editorLink = `/projects/${project?.project_id || ''}/editor?area=${comment.inspection_area_id}#comments-${
      comment.thread_id || ''
    }`
    // TODO: 3DGUI -> Blueprint is disabled for now. Can't get it to work reliably.
    const blueprintLink = undefined
    //   `/projects/${project?.project_id || ''}/blueprints?area=${
    //   comment.inspection_area_id
    // }#comments-${comment.thread_id || ''}#blueprints-${comment.blueprint_id || ''}`

    if (comment.thread_id !== TEMP_COMMENT_ID && inspectionArea) {
      return {
        text: isBlueprintPage ? '3D画面に移動' : '設計図面に移動',
        link: isBlueprintPage ? editorLink : blueprintLink,
      }
    }

    return {
      text: inspectionAreas.find((row) => comment.inspection_area_id === row.inspection_area_id)?.inspection_area_name,
      link: editorLink,
    }
  }, [comment, inspectionArea, inspectionAreas, blueprints, isBlueprintPage, project?.project_id])

  return {
    // Flags
    isDisabled,
    isLoading,
    isNewComment,
    isInputShown: editingComment?.thread_id === TEMP_COMMENT_ID || editingReply?.reply_id === TEMP_REPLY_ID,

    // Title of the comment popup. Only used for linking to PCD/Blueprint.
    title: title.text,
    titleLinkPath: title.link,

    // States
    setIsLoading,

    // event handlers
    onCancelInput,
    onSaved,

    // functions
    handleClose: onClose,
    showReplyForm,
    fetchReplies,
  }
}
