import { ForwardRefRenderFunction, useCallback, useContext, useEffect, useImperativeHandle, useRef } from 'react'

import {
  Alert,
  AlertIcon,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  forwardRef,
  useDisclosure,
} from '@chakra-ui/react'
import axios from 'axios'
import { isEqual } from 'lodash'
import mixpanel from 'mixpanel-browser'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'
import { fetchInspectionAreas, patchInspectionAreas, setProject } from 'store/page'

import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import { MODAL_TYPES } from 'config/constants'

import { ErrorResponse } from 'interfaces/errors'

import { COMMON_MESSAGE } from 'services/ErrorHandler'
import { updateInspectionAreas } from 'services/InspectionArea'
import { updateInspectionSheets } from 'services/InspectionSheet'
import { updateProject } from 'services/Projects'

import {
  SettingDrawerTab,
  patchInspectionSheets,
  patchSettings,
  resetEditedInspectionAreas,
  resetEditedInspectionSheetIds,
  setError,
  setHasConflictedAreaName,
  setInspectionAreaIdToEdit,
  setSavingSettings as setIsSheetSaving,
  setSettingDrawerTab,
  setSettings,
} from '../../store'
import SettingsDrawerAreaList from './Tabs/AreaList'
import SettingsDrawerData from './Tabs/Data'

const AllTabs = [
  {
    title: 'データ',
    content: SettingsDrawerData,
  },
  {
    title: 'エリア一覧',
    content: SettingsDrawerAreaList,
  },
]

const SettingsDrawerFunction: ForwardRefRenderFunction<{
  openDrawer: () => void
}> = (props, ref) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  // Context
  const { getAccessToken } = useContext(UserContext)
  const { showModal, showErrorModal } = useContext(GlobalModalContext)

  // Stores
  const dispatch = useAppDispatch()
  const project = useSelector((state: RootState) => state.page.project)
  const settings = useSelector((state: RootState) => state.inspectionSheet.settings)
  const inspectionAreaIdToEdit = useSelector((state: RootState) => state.inspectionSheet.inspectionAreaIdToEdit)
  const editedInspectionSheets = useSelector((state: RootState) => state.inspectionSheet.editedInspectionSheets)
  const editedInspectionAreas = useSelector((state: RootState) => state.inspectionSheet.editedInspectionAreas)
  const errorMessage = useSelector((state: RootState) => state.inspectionSheet.errorMessage)
  const settingDrawerTab = useSelector((state: RootState) => state.inspectionSheet.settingDrawerTab)
  const hasConflictedAreaName = useSelector((state: RootState) => state.inspectionSheet.hasConflictedAreaName)
  const isSavingSettings = useSelector((state: RootState) => state.inspectionSheet.isSavingSettings)

  // Refs
  const drawerBody = useRef<HTMLDivElement>(null)

  /**
   * Merge the project settings with the default settings
   */
  useEffect(() => {
    if (project) {
      dispatch(patchSettings(project))
    }
  }, [project, dispatch])

  /**
   * Close the drawer
   */
  const handleClose = useCallback(() => {
    dispatch(setInspectionAreaIdToEdit(null))
    dispatch(setError(''))
    onClose()
  }, [dispatch, onClose])

  /**
   * Reset data.
   */
  const handleReset = useCallback(() => {
    dispatch(resetEditedInspectionAreas())
    dispatch(resetEditedInspectionSheetIds())
    dispatch(setSettingDrawerTab(SettingDrawerTab.Data))
    dispatch(setHasConflictedAreaName(false))
    if (project) {
      dispatch(setSettings(project))
    }
  }, [dispatch, project])

  /**
   * Cancel the changes
   */
  const handleCancel = useCallback(() => {
    if (editedInspectionSheets.length || editedInspectionAreas.length || !isEqual(settings, project)) {
      showModal({
        modalType: MODAL_TYPES.CONFIRMATION_CRITICAL,
        title: '変更を破棄します',
        body: '変更内容が失われますが、よろしいですか？',
        confirmText: '破棄',
        onConfirm: () => {
          handleClose()
          handleReset()
          return true
        },
      })
    } else {
      handleClose()
      handleReset()
    }
  }, [
    handleClose,
    handleReset,
    showModal,
    editedInspectionSheets.length,
    editedInspectionAreas.length,
    settings,
    project,
  ])

  /**
   * Save the settings
   */
  const handleSave = useCallback(async () => {
    if (!project) {
      return
    }

    // Get access token
    const accessToken = await getAccessToken()
    if (!accessToken) {
      dispatch(setIsSheetSaving(false))
      return
    }

    // immediately close the drawer and show the loading spinner
    dispatch(setIsSheetSaving(true))
    handleClose()

    mixpanel.track('Udpate project settings', { settings })

    // (the auto formatter makes it super hard to read instead)
    // prettier-ignore
    const results = await Promise.allSettled([
      updateProject(accessToken, { ...project, ...settings }),
      editedInspectionSheets.length ? updateInspectionSheets(accessToken, project.project_id, editedInspectionSheets) : Promise.resolve([]),
      editedInspectionAreas.length ? updateInspectionAreas(accessToken, project.project_id, editedInspectionAreas) : Promise.resolve([])
    ])

    // Save changed values
    if (results[0]?.status === 'fulfilled' && results[0].value) {
      dispatch(setSettings(results[0].value))
      dispatch(setProject(results[0].value))
    }

    if (results[1]?.status === 'fulfilled' && results[1].value) {
      dispatch(patchInspectionSheets(results[1].value))
    }

    // inspection areas has custom error handling due to possible name conflicts
    if (
      results[2]?.status === 'rejected' &&
      axios.isAxiosError(results[2].reason) &&
      (results[2].reason.response?.data as ErrorResponse)?.status_id === 40011
    ) {
      // re-fetch the inspection areas to show the user the conflicting names
      await dispatch(
        fetchInspectionAreas({ access_token: accessToken, project_id: project.project_id, showErrorModal }),
      )

      dispatch(setSettingDrawerTab(SettingDrawerTab.Edit))
    } else if (results[2]?.status === 'fulfilled' && results[2].value) {
      dispatch(patchInspectionAreas(results[2].value))
    }

    // if even one of the requests failed, show an error message, and re-open the drawer
    if (results.some((result) => result.status === 'rejected')) {
      dispatch(setError(COMMON_MESSAGE))
      onOpen()
    } else {
      handleReset()
    }

    dispatch(setIsSheetSaving(false))
  }, [
    settings,
    project,
    editedInspectionAreas,
    editedInspectionSheets,
    dispatch,
    getAccessToken,
    showErrorModal,
    handleClose,
    onOpen,
    handleReset,
  ])

  /**
   * Scroll to the edit area
   */
  const scrollToEditArea = useCallback(() => {
    const box = document.getElementById(`edit-inspection-area-${inspectionAreaIdToEdit}`)

    if (!box) {
      setTimeout(scrollToEditArea, 100)
      return
    }

    drawerBody.current?.scrollTo({ top: box?.offsetTop })
  }, [inspectionAreaIdToEdit])

  /**
   * Open the drawer
   */
  const openDrawer = useCallback(() => {
    onOpen()

    if (inspectionAreaIdToEdit) {
      scrollToEditArea()
    }
  }, [onOpen, scrollToEditArea, inspectionAreaIdToEdit])

  useImperativeHandle(ref, () => ({ openDrawer }))

  return (
    <Drawer
      isOpen={isOpen}
      placement="right"
      onClose={handleClose}
      size="sm"
      closeOnEsc={false}
      closeOnOverlayClick={false}
    >
      <DrawerOverlay bg="blackAlpha.300" />
      <DrawerContent>
        <DrawerHeader textAlign="center" borderBottomColor="gray.300" borderBottomWidth="1px">
          検査帳票の設定
        </DrawerHeader>

        {isOpen && (
          <DrawerBody pb={10} ref={drawerBody}>
            {errorMessage && (
              <Alert status="error" variant="left-accent">
                <AlertIcon />
                {errorMessage}
              </Alert>
            )}

            <Tabs defaultIndex={settingDrawerTab === SettingDrawerTab.Edit ? 1 : 0}>
              {/* Tab list */}
              <TabList my={5}>
                {AllTabs.map((tab) => (
                  <Tab key={tab.title}>{tab.title}</Tab>
                ))}
              </TabList>

              {/* Tab contents */}
              <TabPanels>
                {AllTabs.map((tab) => (
                  <TabPanel key={tab.title}>
                    <tab.content />
                  </TabPanel>
                ))}
              </TabPanels>
            </Tabs>
          </DrawerBody>
        )}

        <DrawerFooter borderTopColor="gray.300" borderTopWidth="1px">
          <Button variant="outline" mr={3} onClick={handleCancel} isDisabled={isSavingSettings}>
            キャンセル
          </Button>
          <Button
            colorScheme="blue"
            onClick={handleSave}
            isDisabled={hasConflictedAreaName || isSavingSettings}
            isLoading={isSavingSettings}
            loadingText="保存中..."
          >
            保存
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}

// Note to future me: forwardRef is from chakra-ui, not react
const SettingsDrawer = forwardRef(SettingsDrawerFunction)
export default SettingsDrawer
