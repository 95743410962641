import './styles.css'

import { FC } from 'react'

import { Divider, HStack } from '@chakra-ui/react'

const SectionTitle: FC<{ title: string }> = ({ title }) => (
  <HStack className="section-title">
    <span>{title}</span>
    <Divider />
  </HStack>
)

export default SectionTitle
