import { useCallback, useContext } from 'react'

import { Text } from '@chakra-ui/react'
import { setAttentionText } from 'pages/projects/common/AttentionText/store/attentionText'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'

import { EditorContext } from 'contexts/Editor'
import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import { MODAL_TYPES } from 'config/constants'

import { LayerStatusExtended } from 'interfaces/attribute'

import { batchDeleteInspectionItem, deleteInspectionItem } from 'services/InspectionSheet'

import {
  CustomMeasurement,
  deleteTempMeasurement,
  setCustomMeasurements,
  setTempMeasurements,
  updateCustomMeasurement,
  updateTempMeasurement,
} from '../store'

export default function useCustomMeasurementPanel() {
  // Context
  const { inspectionSheet, setIsLayerModifying, fetchInspectionItems } = useContext(EditorContext)
  const { showModal, showErrorModal } = useContext(GlobalModalContext)
  const { getAccessToken } = useContext(UserContext)

  // Store
  const dispatch = useAppDispatch()
  const measurements = useSelector((state: RootState) => state.toolCustomMeasurement.measurements)
  const tempMeasurements = useSelector((state: RootState) => state.toolCustomMeasurement.tempMeasurements)
  const project = useSelector((state: RootState) => state.page.project)
  const inspectionArea = useSelector((state: RootState) => state.page.inspectionArea)

  // Data
  const selectedMeasurements = measurements.concat(tempMeasurements).filter((measurement) => measurement.selected)

  /**
   * Update all distance anchors status
   * @param item Layer status
   */
  const updateAllMeasurementStatus = useCallback(
    (item: LayerStatusExtended) => {
      const newStatus = { ...item }
      if (newStatus.invisible === true) {
        newStatus.selected = false
      }

      dispatch(setCustomMeasurements(measurements.map((measurement) => ({ ...measurement, ...newStatus }))))
    },
    [measurements, dispatch],
  )

  /**
   * Update layer visibility
   * @param status Layer status
   * @param measurement Measurement to update
   */
  const updateMeasurement = useCallback(
    (status: LayerStatusExtended, measurement: CustomMeasurement) => {
      dispatch(updateCustomMeasurement({ ...measurement, ...status }))
    },
    [dispatch],
  )

  /**
   * Delete layer for temporary measurements
   * @param measurement Measurement to delete
   */
  const deleteMeasurement = useCallback(
    (measurement: CustomMeasurement) => {
      if (!project || !inspectionArea || !inspectionSheet || !measurement.inspectionItem) {
        return
      }

      showModal({
        title: measurement.inspectionItem.part_name || `距離`,
        body: <Text>一度削除してしまうと、元に戻せません。</Text>,
        confirmText: '削除',
        modalType: MODAL_TYPES.CONFIRMATION_CRITICAL,
        onConfirmPromised: async () => {
          if (!measurement.inspectionItem?.inspection_item_id) {
            return false
          }

          setIsLayerModifying(true)
          dispatch(setAttentionText({ message: 'データを更新中...' }))
          const token = await getAccessToken()
          if (!token) {
            return false
          }

          const result = await deleteInspectionItem(
            token,
            project.project_id,
            inspectionArea.inspection_area_id,
            inspectionSheet.inspection_sheet_id,
            measurement.inspectionItem.inspection_item_id,
            showErrorModal,
          )

          // Refresh inspection sheet
          if (result) await fetchInspectionItems()

          setIsLayerModifying(false)
          dispatch(setAttentionText({ message: '' }))

          return true
        },
      })
    },
    [
      dispatch,
      fetchInspectionItems,
      getAccessToken,
      inspectionArea,
      inspectionSheet,
      project,
      setIsLayerModifying,
      showErrorModal,
      showModal,
    ],
  )

  /**
   * Update all temporary measurements status
   * @param item Layer status
   */
  const updateAllTemporaryMeasurementStatus = useCallback(
    (item: LayerStatusExtended) => {
      const newStatus = { ...item }
      if (newStatus.invisible === true) {
        newStatus.selected = false
      }

      dispatch(setTempMeasurements(tempMeasurements.map((measurement) => ({ ...measurement, ...newStatus }))))
    },
    [tempMeasurements, dispatch],
  )

  /**
   * Update layer visibility for temporary measurements
   * @param status Layer status
   * @param measurement Measurement to update
   */
  const updateTemporaryMeasurement = useCallback(
    (status: LayerStatusExtended, measurement: CustomMeasurement) => {
      dispatch(updateTempMeasurement({ ...measurement, ...status }))
    },
    [dispatch],
  )

  /**
   * Delete temporary measurement
   *
   * @param measurement Measurement to delete
   */
  const deleteTemporaryMeasurement = useCallback(
    (measurement: CustomMeasurement) => {
      dispatch(deleteTempMeasurement(measurement.id))
    },
    [dispatch],
  )

  /**
   * Delete selected measurements
   */
  const deleteSelectedMeasurements = useCallback(() => {
    if (!project || !inspectionArea || !inspectionSheet || !selectedMeasurements.length) {
      return
    }

    // Delete measuremnts from DB
    if (measurements.filter((measurement) => measurement.selected).length > 0) {
      showModal({
        title: `${selectedMeasurements.length} 距離を削除しますか？`,
        body: <Text>一度削除してしまうと、元に戻せません。</Text>,
        confirmText: '削除',
        modalType: MODAL_TYPES.CONFIRMATION_CRITICAL,
        onConfirmPromised: async () => {
          setIsLayerModifying(true)
          dispatch(setAttentionText({ message: 'データを更新中...' }))
          const token = await getAccessToken()
          if (!token) {
            return false
          }

          const result = await batchDeleteInspectionItem(
            token,
            project.project_id,
            inspectionArea.inspection_area_id,
            inspectionSheet.inspection_sheet_id,
            selectedMeasurements
              .map((measurement) => measurement.inspectionItem?.inspection_item_id || '')
              .filter(Boolean),
            showErrorModal,
          )

          // Refresh inspection sheet
          if (result) await fetchInspectionItems()

          setIsLayerModifying(false)
          dispatch(setAttentionText({ message: '' }))

          return true
        },
      })
    }

    // Remove temporary measurements
    dispatch(setTempMeasurements(tempMeasurements.filter((measurement) => !measurement.selected)))
  }, [
    measurements,
    tempMeasurements,
    inspectionArea,
    inspectionSheet,
    project,
    selectedMeasurements,
    setIsLayerModifying,
    dispatch,
    showModal,
    showErrorModal,
    fetchInspectionItems,
    getAccessToken,
  ])

  /**
   * Update selected measurements' status
   * @param status New layer status
   */
  const updateSelectedMeasurements = useCallback(
    (status: LayerStatusExtended) => {
      dispatch(
        setCustomMeasurements(
          measurements.map((measurement) => {
            if (measurement.selected) {
              return { ...measurement, ...status }
            }
            return measurement
          }),
        ),
      )

      dispatch(
        setTempMeasurements(
          tempMeasurements.map((measurement) => {
            if (measurement.selected) {
              return { ...measurement, ...status }
            }
            return measurement
          }),
        ),
      )
    },
    [measurements, tempMeasurements, dispatch],
  )

  /**
   * Deselect all selected measurements
   */
  const deselectSelectedMeasurements = useCallback(() => {
    dispatch(setCustomMeasurements(measurements.map((measurement) => ({ ...measurement, selected: false }))))
    dispatch(setTempMeasurements(tempMeasurements.map((measurement) => ({ ...measurement, selected: false }))))
  }, [measurements, tempMeasurements, dispatch])

  return {
    // Measurements
    measurements,
    updateMeasurement,
    deleteMeasurement,
    updateAllMeasurementStatus,

    // Temporary measurements
    tempMeasurements,
    updateAllTemporaryMeasurementStatus,
    updateTemporaryMeasurement,
    deleteTemporaryMeasurement,

    // Selected measurements
    selectedMeasurements,
    deleteSelectedMeasurements,
    updateSelectedMeasurements,
    deselectSelectedMeasurements,
  }
}
