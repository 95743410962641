/* istanbul ignore file */
import { useEffect } from 'react'

import * as Sentry from '@sentry/react'
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom'

Sentry.init({
  dsn: 'https://2cb8d673a9332440a6ce196acc63f027@o4505779235454976.ingest.us.sentry.io/4507972779769856',
  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  tracePropagationTargets: [
    /^\//, // Includes all local routes.
    /hatsuly\.app\/v1$/, // Includes all requests to the API.
  ],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  // replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,

  // Set environment
  environment: process.env.REACT_APP_ENV,

  // All 'builds' (yarn build) are production builds. Only local development (yarn start) is considered development.
  // https://create-react-app.dev/docs/adding-custom-environment-variables/
  // This is to prevent reporting on local development.
  enabled: process.env.NODE_ENV === 'production',
})
