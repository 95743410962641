import { RefObject, useContext, useMemo } from 'react'

import { createStandaloneToast } from '@chakra-ui/react'
import { ThreeEvent } from '@react-three/fiber'
import { setHoveredShapeId } from 'pages/projects/editor/store/editor'
import { Tools } from 'pages/projects/editor/tools/setup'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'
import { Camera } from 'three'

import { EditorContext } from 'contexts/Editor'

import { EDITOR_TOOLS } from 'config/constants'
import { TOAST_CONFIG } from 'config/styles'
import theme from 'config/theme'

import { PointArray, ShapeKey, ShapeKeyType, ShapeTypes } from 'interfaces/interfaces'

import { createRayCaster, getClickCoords } from 'services/Points'
import { validateSelectedShape } from 'services/Util'
import { getVolumeEstimationItems } from 'services/VolumeEstimation'

export const TOAST_ID = 'shape-selection-error'

// User need to click down an up on the same mesh to make a selection.
// Store the down status on this state object for the up status checking
let isPointerDown = false

const useShape = (shape: ShapeTypes, shapeKey: ShapeKey, cameraRef: RefObject<Camera> | null) => {
  // Context
  const { changeSelectedShapeIds, shapes, isDragging, selectedTool, inspectionItems } = useContext(EditorContext)

  // Store
  const dispatch = useAppDispatch()
  const selectedShapeIds = useSelector((root: RootState) => root.editor.selectedShapeIds)
  const hoveredShapeId = useSelector((root: RootState) => root.editor.hoveredShapeId)

  // Use standalone toast as we're considered 'outside' of React component.
  const { toast } = useMemo(
    () =>
      createStandaloneToast({
        theme,
      }),
    [],
  )
  // Current Editor tool
  const currentEditorTool = Tools.find((tool) => tool.key === selectedTool)
  const isShapeSelectable = currentEditorTool?.config?.selectableShapes?.includes(shapeKey as ShapeKeyType) || false

  const getSelectedPoint = (event: ThreeEvent<PointerEvent>): PointArray | undefined => {
    if (!cameraRef) return undefined

    //* カメラ取得
    const camera = cameraRef.current as Camera

    //* 光線生成とクリック位置特定
    const rayCaster = createRayCaster()
    const targetElem = (event.nativeEvent.target as HTMLElement).getBoundingClientRect()
    const { x, y, width, height } = targetElem
    const { coords } = getClickCoords(
      {
        x: event.nativeEvent.clientX,
        y: event.nativeEvent.clientY,
      },
      {
        x,
        y,
        width,
        height,
      },
    )

    //* カメラからpointまで光線を伸ばす
    rayCaster.setFromCamera(coords, camera)
    const intersects = rayCaster.intersectObject(event.eventObject)
    if (!intersects.length || intersects[0].point === undefined) {
      return undefined
    }

    return intersects[0].point.toArray()
  }

  /**
   * Pointer over object event handler
   * @param event
   * @returns
   */
  const onPointerOver = (event: ThreeEvent<PointerEvent>) => {
    event.stopPropagation()

    if ((selectedTool !== EDITOR_TOOLS.MOVE && !isShapeSelectable) || isDragging) {
      return
    }
    dispatch(setHoveredShapeId(shape.shape_id))
  }

  /**
   * Pointer out event handler
   * @param event
   * @returns
   */
  const onPointerOut = (event: ThreeEvent<PointerEvent>) => {
    event.stopPropagation()

    if ((selectedTool !== EDITOR_TOOLS.MOVE && !isShapeSelectable) || isDragging) {
      return
    }
    dispatch(setHoveredShapeId(shape.shape_id))
    dispatch(setHoveredShapeId(''))
    isPointerDown = false
  }

  /**
   * Pointer down event handler
   */
  const onPointerDown = () => {
    isPointerDown = true
  }

  /**
   * Pointer up event handler
   * @param event
   */
  const onPointerUp = (event: ThreeEvent<PointerEvent>) => {
    event.stopPropagation()
    if (isPointerDown) {
      onClick(event)
    }
    isPointerDown = false
  }

  /**
   * Handle clicking of a shape
   *
   * @param event Click event
   */
  const onClick = (event: ThreeEvent<PointerEvent>) => {
    if (selectedTool === EDITOR_TOOLS.COMMENT) {
      const anchorPoint = getSelectedPoint(event)
      if (!anchorPoint || !cameraRef) return
    }

    if ((selectedTool !== EDITOR_TOOLS.MOVE && !isShapeSelectable) || isDragging) {
      return
    }

    try {
      validateSelectedShape(shape, shapeKey, getVolumeEstimationItems(inspectionItems), currentEditorTool)
    } catch (e) {
      if (!toast.isActive(TOAST_ID)) {
        toast({
          ...TOAST_CONFIG,
          id: TOAST_ID,
          status: 'error',
          position: 'bottom',
          title: e instanceof Error ? e.message : 'エラー',
        })
      }
      return
    }

    let newShapeIds = [...selectedShapeIds]

    // If only 1 volume can be selected, deselect all other volumes
    if (currentEditorTool && currentEditorTool.config?.volume?.onlyOneSelectable && shapeKey === ShapeKeyType.POLYGON) {
      newShapeIds = newShapeIds
        .filter((id) => !shapes.polygons.find((poly) => poly.shape_id === id))
        .filter((id) => !shapes.cylinders.find((cylinder) => cylinder.shape_id === id))
    }

    const idIndex = newShapeIds.indexOf(shape.shape_id)
    if (idIndex >= 0) {
      newShapeIds.splice(idIndex, 1)
    } else {
      newShapeIds.push(shape.shape_id)
    }

    changeSelectedShapeIds(newShapeIds)
  }

  return {
    onPointerOver,
    onPointerOut,
    onPointerDown,
    onPointerUp,
    isHovered: shape.shape_id === hoveredShapeId,
  }
}

export default useShape
