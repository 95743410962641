import { FC, useCallback, useContext } from 'react'

import { Box, Button, Menu, MenuButton, MenuItem, MenuList, Portal, Spacer } from '@chakra-ui/react'
import CollapsePanel from 'pages/projects/editor/infoPanels/components/CollapsePanel'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'

import { ChevronDownIcon } from 'assets/icons'

import { EditorContext } from 'contexts/Editor'

import { DIAMETERS, EDITOR_COLLAPSE_TYPES, EDITOR_TOOLS } from 'config/constants'
import { INFO_PANEL_PADDING, INPUT_GROUP_STYLES } from 'config/styles'

import { findDiameterKeyByValue } from 'services/Util'

import { setDiameter } from '../store'

const DiameterPanel: FC = () => {
  // Context
  const { selectedTool } = useContext(EditorContext)

  // Store
  const dispatch = useAppDispatch()
  const baseDiameter = useSelector((state: RootState) => state.toolRebarDetection.baseDiameter)

  /**
   * Update the base diameter of the rebar and any rebar that its diameter has not been set yet.
   */
  const updateDiameter = useCallback(
    (diameter: number) => {
      dispatch(setDiameter(diameter))
    },
    [dispatch],
  )

  if (selectedTool !== EDITOR_TOOLS.CYLINDER) {
    return null
  }

  return (
    <Box
      id="property-panel"
      backgroundColor="gray.800"
      borderBottomLeftRadius="md"
      borderTopLeftRadius="md"
      w="100%"
      data-testid="property-panel"
      transitionDuration="1s"
      transitionProperty="background-color"
    >
      <CollapsePanel title="鉄筋の直径指定" type={EDITOR_COLLAPSE_TYPES.diameter} onChange={() => null}>
        <Box px={INFO_PANEL_PADDING}>
          <Menu variant="toolbar">
            <MenuButton
              as={Button}
              rightIcon={<ChevronDownIcon />}
              textAlign="left"
              fontWeight="normal"
              fontSize="xs"
              paddingX={2}
              height={7}
              width="100%"
              variant="toolbar"
              {...INPUT_GROUP_STYLES}
              borderColor={!baseDiameter ? 'red' : 'whiteAlpha.200'}
            >
              {baseDiameter ? `${findDiameterKeyByValue(baseDiameter)}（${baseDiameter || ''}）` : ''}
            </MenuButton>
            <Portal>
              <MenuList>
                {Object.keys(DIAMETERS).map((d) => (
                  <MenuItem
                    key={d}
                    backgroundColor={baseDiameter === DIAMETERS[d] ? 'secondary.600' : 'transparent'}
                    onClick={() => updateDiameter(DIAMETERS[d])}
                    fontSize="xs"
                  >
                    {d}（{DIAMETERS[d]}）
                  </MenuItem>
                ))}
              </MenuList>
            </Portal>
          </Menu>
        </Box>
        <Spacer h={INFO_PANEL_PADDING} />
      </CollapsePanel>
    </Box>
  )
}

export default DiameterPanel
