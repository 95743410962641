import { FC, useContext, useState } from 'react'

import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react'
import { ERROR_MODAL_PROPS } from 'components/GlobalModal'
import InviteNonPayingUserModal from 'components/Navbar/InviteUserModal'
import mixpanel from 'mixpanel-browser'
import { useAppDispatch } from 'store/app'

import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import { TOAST_CONFIG } from 'config/styles'

import { Project } from 'interfaces/interfaces'

import { ERROR_PROCESS_MESSAGE } from 'services/ErrorHandler'
import { inviteUserToProject } from 'services/Projects'
import { validateEmail } from 'services/Util'

import { fetchProjects } from '../store/dashboard'

const InviteUserModal: FC<{
  isOpen: boolean
  invitingProject?: Project
  onConfirm: () => void
}> = ({ isOpen, invitingProject, onConfirm }) => {
  // Store
  const dispatch = useAppDispatch()

  // Contexts
  const { showModal, showErrorModal } = useContext(GlobalModalContext)
  const { getAccessToken } = useContext(UserContext)

  const toast = useToast()

  const [emailAddress, setEmailAddress] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false)

  const onInviteUser = async () => {
    if (!invitingProject) {
      return false
    }

    setIsLoading(true)

    const access_token = await getAccessToken()
    if (!access_token) {
      onConfirm()
      setIsLoading(false)
      return false
    }

    const invitedProject = await inviteUserToProject(
      access_token,
      invitingProject.project_id,
      emailAddress,
      (errorMessage: string) => {
        if (errorMessage === ERROR_PROCESS_MESSAGE.INVITE_USER_TO_PROJECT.messages[40402]) {
          showModal({
            ...ERROR_MODAL_PROPS,
            body: errorMessage,
            resolveText: 'アカウント作成招待',
            onResolve: () => {
              setIsInviteModalOpen(true)
            },
          })
        } else {
          showErrorModal(errorMessage)
        }
      },
    )

    if (invitedProject) {
      toast({
        ...TOAST_CONFIG,
        title: '閲覧ユーザーを招待し、通知メールを送信しました',
      })
      await dispatch(fetchProjects({ access_token, showErrorModal }))
    }

    mixpanel.track('Invite User', {
      'Project ID': invitingProject.project_id,
    })

    setIsLoading(false)
    onConfirm()
    return true
  }

  return (
    <>
      <Modal closeOnOverlayClick isOpen={isOpen} onClose={() => onConfirm()} trapFocus={false} isCentered size="md">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize="md">閲覧ユーザーを招待</ModalHeader>
          <ModalCloseButton hidden={isLoading} />
          <ModalBody position="relative">
            <FormControl>
              <FormLabel>
                すでにアカウントを持っているユーザーを工事{' '}
                <Text fontWeight="bold" display="inline-block">
                  {invitingProject?.project_name}
                </Text>{' '}
                に招待する
              </FormLabel>
              <Input
                placeholder="メールアドレスを入力してください"
                id="email_address"
                type="text"
                value={emailAddress}
                onChange={(e) => setEmailAddress(e.target.value)}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter mt={8} justifyContent="center">
            <Button me={3} py={2} minW="100px" onClick={() => onConfirm()}>
              キャンセル
            </Button>
            <Button
              isDisabled={isLoading || !validateEmail(emailAddress)}
              colorScheme="primary"
              me={3}
              py={2}
              minW="100px"
              onClick={() => onInviteUser()}
            >
              招待
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <InviteNonPayingUserModal
        isOpen={isInviteModalOpen}
        onConfirm={() => {
          onConfirm()
          setIsInviteModalOpen(false)
        }}
        email={emailAddress}
      />
    </>
  )
}

export default InviteUserModal
