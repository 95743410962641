import { FC, useContext, useState } from 'react'

import { Box, Flex, HStack, Text, VStack } from '@chakra-ui/react'
import CollapsePanel from 'pages/projects/editor/infoPanels/components/CollapsePanel'
import LayerItem from 'pages/projects/editor/infoPanels/components/LayerItem'
import { setHoveredShapeId } from 'pages/projects/editor/store/editor'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'

import { EditorContext } from 'contexts/Editor'

import { EDITOR_COLLAPSE_TYPES } from 'config/constants'
import { INFO_PANEL_PADDING } from 'config/styles'

import usePolylinePanel from '../../hooks/usePolylinePanel'

const PolylinePanel: FC = () => {
  const {
    polylines,
    selectedPolylines,
    updateAllPolylineStatus,
    updatePolylineStatus,
    deletePolyline,
    deleteSelectedPolylines,
    updateSelectedPolylines,
    deselectSelectedPolylines,
  } = usePolylinePanel()

  // Context
  const { isLayerModifying } = useContext(EditorContext)

  // Store
  const dispatch = useAppDispatch()
  const permissionSet = useSelector((state: RootState) => state.editor.permissionSet)
  const userType = useSelector((state: RootState) => state.user.userType)

  // States
  const [isCollapseOpen, setIsCollapseOpen] = useState(true)

  // Derived
  const isAllowedToDetect = permissionSet.DETECT.includes(userType)
  const isSomePolylinesVisible = polylines.some((anchor) => !anchor.invisible)
  const isAllPolylinesSelected = polylines.every((anchor) => anchor.selected)
  const isSomeSelectedShapesVisible = selectedPolylines.some((anchor) => !anchor.invisible)

  if (!polylines?.length) {
    return null
  }

  return (
    <Flex
      backgroundColor="gray.800"
      borderBottomLeftRadius="md"
      borderTopLeftRadius="md"
      w="100%"
      flex={1}
      minH={isCollapseOpen ? 20 : 10}
      data-testid="distance-panel"
    >
      <CollapsePanel title="測定した延長" type={EDITOR_COLLAPSE_TYPES.polyline} onChange={setIsCollapseOpen}>
        <VStack w="100%" spacing={0} pb={INFO_PANEL_PADDING - 1} overflowY="auto">
          <LayerItem
            disabled={isLayerModifying}
            invisible={!isSomePolylinesVisible}
            selected={isAllPolylinesSelected}
            label="延長"
            updateVisibility={(invisible) => updateAllPolylineStatus({ invisible })}
            onClick={() => updateAllPolylineStatus({ selected: !isAllPolylinesSelected, invisible: false })}
          />
          {polylines.map((polyline) => (
            <LayerItem
              disabled={isLayerModifying}
              selected={polyline.selected}
              deleteLayer={isAllowedToDetect ? () => deletePolyline(polyline) : undefined}
              invisible={polyline.invisible}
              key={polyline.inspection_item_id}
              label={
                <HStack fontSize={10} alignItems="baseline" spacing={1}>
                  <Text>{polyline.part_name}</Text>
                </HStack>
              }
              updateVisibility={(invisible) => updatePolylineStatus({ invisible }, polyline)}
              onClick={() => updatePolylineStatus({ selected: !polyline.selected, invisible: false }, polyline)}
              onMouseOver={() => dispatch(setHoveredShapeId(polyline.inspection_item_id!))}
              onMouseOut={() => dispatch(setHoveredShapeId(''))}
              isChild
            />
          ))}

          {/* Batch deletion */}
          {selectedPolylines.length > 0 && (
            <Box fontWeight="bold" w="100%">
              <LayerItem
                onClick={deselectSelectedPolylines}
                invisible={!isSomeSelectedShapesVisible}
                label={`選択した延長(${selectedPolylines.length})`}
                updateVisibility={(invisible) => updateSelectedPolylines({ invisible })}
                deleteLayer={deleteSelectedPolylines}
              />
            </Box>
          )}
        </VStack>
      </CollapsePanel>
    </Flex>
  )
}

export default PolylinePanel
