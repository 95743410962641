import { FC } from 'react'

import { Box, Heading, Text } from '@chakra-ui/react'

const TooltipBody: FC<{
  /**
   * Title of the tooltip
   */
  title?: string

  /**
   * Body text to be displayed in the tooltip.
   */
  body: string
}> = ({ title, body }) => (
  <Box textAlign="center">
    {title ? <Heading size="sm">{title}</Heading> : ''}
    <Text color="tomato">{body}</Text>
  </Box>
)

export default TooltipBody
