/* eslint-disable jsx-a11y/no-static-element-interactions */

/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC } from 'react'

import { Box, Center, IconButton, Tooltip } from '@chakra-ui/react'
import { Html } from '@react-three/drei'
import { Vector3 } from 'three'

import {
  AlertFillIcon,
  CommentDisplayIcon,
  CuboidIcon,
  InputEditorCancelIcon,
  InputEditorConfirmIcon,
} from 'assets/icons'

import { EDITOR_CANVAS_CONTAINER_ID } from 'config/constants'

import { PointArray, Position } from 'interfaces/interfaces'

const ICON_BUTTON_PROPS = {
  size: 'xs',
  variant: 'outline',
  fontSize: 'sm',
  borderWidth: '1px',
  borderRadius: '50%',
  borderColor: 'gray.500',
  padding: '4px',
  backgroundColor: 'white',
  boxShadow: '0 0 3px white',
}

const CommentIcon: FC<{
  cartesianPosition?: Position
  cuboidPosition?: PointArray
  disabled: boolean
  visible: boolean
  isMoving: boolean
  onClick?: () => void
  onMouseUp?: (e: React.MouseEvent) => void
  onCancelMove?: () => void
  onConfirmMove?: () => void
}> = ({
  cartesianPosition,
  cuboidPosition,
  disabled,
  visible,
  isMoving,
  onClick,
  onMouseUp,
  onCancelMove,
  onConfirmMove,
}) => {
  const content = isMoving ? (
    <Center position="relative" className="comment-icon moving">
      <CommentDisplayIcon data-testid="commentDisplayIcon" />
      <IconButton
        {...ICON_BUTTON_PROPS}
        borderColor="var(--chakra-colors-red-500)"
        color="var(--chakra-colors-red-500)"
        aria-label="Cancel Move"
        // please keep this attribute for moving comment position feature
        aria-controls={EDITOR_CANVAS_CONTAINER_ID}
        icon={<InputEditorCancelIcon pointerEvents="none" />}
        isDisabled={disabled}
        onClick={(e) => {
          e.stopPropagation()
          if (!disabled && onCancelMove) onCancelMove()
        }}
        onMouseUp={(e) => e.stopPropagation()}
        position="absolute"
        bottom={-30}
        left={-16}
      />
      <IconButton
        {...ICON_BUTTON_PROPS}
        borderColor="var(--chakra-colors-primary-500)"
        color="var(--chakra-colors-primary-500)"
        aria-label="Confirm Move"
        // please keep this attribute for moving comment position feature
        aria-controls={EDITOR_CANVAS_CONTAINER_ID}
        icon={<InputEditorConfirmIcon pointerEvents="none" />}
        isDisabled={disabled}
        onClick={(e) => {
          e.stopPropagation()
          if (!disabled && onConfirmMove) onConfirmMove()
        }}
        onMouseUp={(e) => e.stopPropagation()}
        position="absolute"
        bottom={-30}
        left={16}
      />
    </Center>
  ) : (
    <Tooltip
      hasArrow
      placement="bottom"
      label={!cartesianPosition?.x && !cuboidPosition && !isMoving ? '位置が指定されていません。' : ''}
      p="8px"
      fontWeight="normal"
      className="canvas-tooltip"
    >
      <div
        onClick={() => {
          if (!disabled && onClick) onClick()
        }}
        onMouseUp={(e) => {
          if (!disabled && onMouseUp) onMouseUp(e)
        }}
      >
        <CommentDisplayIcon data-testid="commentDisplayIcon" />
        {cuboidPosition && (
          <Box position="absolute" top={5} left={7}>
            <CuboidIcon width={11} height={11} />
          </Box>
        )}
        {!cartesianPosition?.x && !cuboidPosition && (
          <Center position="absolute" color="white" zIndex={1} top={3} left={5}>
            <AlertFillIcon data-testid="unplacedCommentDisplayIcon" />
          </Center>
        )}
      </div>
    </Tooltip>
  )

  // Unplaced comment is grouped into a single drei-Html element outside of this component
  // Here we'll just return a standard HTML element
  if (!cartesianPosition?.x) {
    return (
      <Box
        cursor={disabled ? 'inherit' : 'pointer'}
        opacity={disabled ? '0.6' : '1'}
        visibility={!visible ? 'hidden' : 'visible'}
        padding="4px"
      >
        <Box width="24px" height="24px" position="relative">
          {content}
        </Box>
      </Box>
    )
  }

  return (
    <Html
      position={
        new Vector3(
          cartesianPosition?.x || cuboidPosition?.[0] || 0,
          cartesianPosition?.y || cuboidPosition?.[1] || 0,
          cartesianPosition?.z || cuboidPosition?.[2] || 0,
        )
      }
      style={{
        transform: 'translateY(-100%)',
        width: '24px',
        height: '24px',
        cursor: disabled ? 'inherit' : 'pointer',
        opacity: disabled ? '0.6' : '1',
        visibility: visible ? 'visible' : 'hidden',
        position: 'relative',
      }}
      zIndexRange={isMoving ? [1, 1] : [0, 0]}
    >
      {content}
    </Html>
  )
}

export default CommentIcon
