import { useContext, useEffect } from 'react'

import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'

import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import { debouncedUpdateInspectionItems } from 'services/InspectionSheet'

import { resetEditedInspectionItems, setIsSaving, setLatestInspectionItems } from '../store'

/**
 * A dummny component that its only duty is to save data to BE. It does not render anything.
 * This encapsulation is done to prevent re-render when we pull data
 * from Redux store of data that was modified by the user.
 * Pulling this data on the parent component would cause re-render on every child,
 * causing severe performance issue.
 */
const DataSaver = () => {
  // Store
  const dispatch = useAppDispatch()
  const project = useSelector((state: RootState) => state.page.project)
  const editedInspectionItems = useSelector((state: RootState) => state.inspectionSheet.editedInspectionItems)
  const isAllowedToModifySheet = useSelector((state: RootState) => state.inspectionSheet.isAllowedToModifySheet)

  // Context
  const { getAccessToken } = useContext(UserContext)
  const { showErrorModal } = useContext(GlobalModalContext)

  /**
   * Listen to changes in inspection items and update them on the server.
   */
  useEffect(() => {
    if (isAllowedToModifySheet && project && editedInspectionItems.length) {
      void debouncedUpdateInspectionItems(
        getAccessToken,
        project.project_id,
        editedInspectionItems,
        showErrorModal,
        // we reset the edited sheet ids before saving to immediately recognize any new changes while saving
        () => {
          dispatch(resetEditedInspectionItems())
        },
        (result) => {
          dispatch(setLatestInspectionItems(result))
        },
        (flag) => dispatch(setIsSaving(flag)),
      )
    }
  }, [editedInspectionItems, project, isAllowedToModifySheet, dispatch, getAccessToken, showErrorModal])

  return null
}

export default DataSaver
