import { FC } from 'react'

import { Box, Image, Text, VStack } from '@chakra-ui/react'

import LoadingAnimation from 'assets/imgs/loading-animation-black.gif'

const LoadingPage: FC<{ text: string }> = ({ text }) => (
  <Box
    w="100vw"
    h="100vh"
    position="absolute"
    top={0}
    left={0}
    backgroundColor="black"
    zIndex={10}
    data-testid="loadingPage"
  >
    <VStack position="absolute" left="50%" top="50%" transform="translate(-50%, -50%)" alignItems="center">
      <Image src={LoadingAnimation} w={160} />
      <Text color="whiteAlpha.700" fontWeight="bold">
        {text}
      </Text>
    </VStack>
  </Box>
)

export default LoadingPage
