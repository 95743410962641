import './styles.css'

import { FC, useRef } from 'react'

import {
  Button,
  HStack,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Square,
  Tooltip,
} from '@chakra-ui/react'
import { isMobile } from 'react-device-detect'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'

import { AddIcon, ChevronDownIcon, DeleteIcon } from 'assets/icons'

import { useDocumentSwitcher } from '../../hooks/documentSwitcher'
import { setSelectedBlueprint } from '../../store/blueprint'

const DocumentSwitcher: FC = () => {
  // Store
  const dispatch = useAppDispatch()
  const selectedBlueprint = useSelector((state: RootState) => state.blueprint.selectedBlueprint)
  const blueprints = useSelector((state: RootState) => state.blueprint.blueprints)
  const isAllowedModify = useSelector((state: RootState) => state.blueprint.isAllowedModify)

  // Element refs
  const uploadFileRef = useRef<HTMLInputElement>(null)

  // Hook
  const { isLoading, onFileChange, onBlueprintDelete } = useDocumentSwitcher()

  return (
    <HStack position="relative" spacing={1} h={{ base: 12, xl: 10 }} w="100%" id="blueprint-document-switcher">
      <Menu variant="toolbar">
        <MenuButton
          as={Button}
          rightIcon={<ChevronDownIcon />}
          backgroundColor="gray.800"
          fontWeight="normal"
          color="white"
          fontSize="md"
          size="lg"
          _hover={{ bg: 'gray.600' }}
          _expanded={{ bg: 'gray.600' }}
          _focus={{ bg: 'gray.600', boxShadow: 'outline' }}
          _active={{ bg: 'gray.600' }}
          h="100%"
          isDisabled={isLoading}
          flex={1}
          px={4}
        >
          {selectedBlueprint?.original_filename}
        </MenuButton>
        <MenuList minW={0}>
          {blueprints.map((blueprint) => (
            <MenuItem
              isDisabled={isLoading}
              key={blueprint.blueprint_id}
              backgroundColor={
                blueprint.blueprint_id === selectedBlueprint?.blueprint_id ? 'whiteAlpha.100' : 'gray.700'
              }
              onClick={() => {
                dispatch(setSelectedBlueprint(blueprint))
              }}
            >
              {blueprint.original_filename}
              {isAllowedModify && (
                <>
                  <Spacer />
                  <Square
                    position="relative"
                    color="white"
                    fontSize={{ base: '2xl', xl: 'lg' }}
                    ml={4}
                    backgroundColor="whiteAlpha.100"
                    _hover={{ bg: 'whiteAlpha.400' }}
                    p={{ base: 2, xl: 1 }}
                    borderRadius="md"
                    overflow="hidden"
                    cursor="pointer"
                    onClick={(e) => {
                      if (!isLoading) {
                        e.stopPropagation()
                        void onBlueprintDelete(blueprint.blueprint_id)
                      }
                    }}
                  >
                    <DeleteIcon />
                  </Square>
                </>
              )}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
      {isAllowedModify && (
        <Tooltip hasArrow placement="bottom" label="設計図面（pdf）追加" p={2} fontSize="xs" fontWeight="normal">
          <IconButton
            aria-label="upload blueprint"
            position="relative"
            color="white"
            backgroundColor="gray.800"
            _hover={{ bg: 'gray.600' }}
            borderRadius="md"
            overflow="hidden"
            cursor="pointer"
            isDisabled={isLoading}
            w={{ base: 12, xl: 10 }}
            h={{ base: 12, xl: 10 }}
            onClick={() => {
              uploadFileRef.current?.click()
            }}
            icon={
              <Square fontSize={{ base: '2xl', xl: 'lg' }}>
                <AddIcon />
              </Square>
            }
            isLoading={isLoading}
          />
        </Tooltip>
      )}
      <Input
        data-testid="file-upload"
        hidden
        type="file"
        accept={isMobile ? '' : '.pdf'}
        onChange={onFileChange}
        ref={uploadFileRef}
      />
    </HStack>
  )
}

export default DocumentSwitcher
