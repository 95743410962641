import { FC, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react'

import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'

import { GlobalModalContext } from 'contexts/GlobalModal'

import { MODAL_TYPES } from 'config/constants'

import { ModalProps } from 'interfaces/interfaces'

const DEFAULT_MODAL_PROPS: ModalProps = {
  body: '',
  cancelText: 'キャンセル',
  resolveText: '',
  closable: true,
  confirmText: '確定',
  modalType: MODAL_TYPES.CONFIRMATION,
  onConfirm: () => true,
  onCancel: () => null,
  onResolve: () => null,
  isConfirmable: true,
  title: '注意',
  size: 'sm',
}
export const ERROR_MODAL_PROPS: ModalProps = {
  ...DEFAULT_MODAL_PROPS,
  confirmText: '了解',
  modalType: MODAL_TYPES.ERROR,
  title: 'エラー',
}

const GlobalModal: FC<PropsWithChildren> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [props, setProps] = useState<ModalProps>(DEFAULT_MODAL_PROPS)

  const showModal = useCallback((modalProps: ModalProps) => {
    setIsOpen(true)
    setProps({
      ...(modalProps.modalType === MODAL_TYPES.ERROR ? ERROR_MODAL_PROPS : DEFAULT_MODAL_PROPS),
      ...modalProps,
    })
  }, [])

  const showErrorModal = useCallback((message: string) => {
    setIsOpen(true)
    setProps({
      ...ERROR_MODAL_PROPS,
      body: message,
    })
  }, [])

  const updateModal = useCallback(
    (modalProps: ModalProps) => {
      setProps({
        ...props,
        ...modalProps,
      })
    },
    [props],
  )

  const hideModal = useCallback(() => {
    setIsOpen(false)
  }, [])

  const contextValue = useMemo(
    () => ({ showModal, showErrorModal, updateModal, hideModal }),
    [showModal, showErrorModal, updateModal, hideModal],
  )

  const handleModalToggle = () => {
    hideModal()
  }

  const handleModalConfirm = async () => {
    if (props.onConfirmPromised) {
      setIsLoading(true)
      if (await props.onConfirmPromised()) {
        hideModal()
      }
      setIsLoading(false)
    } else if (props.onConfirm && props.onConfirm()) {
      hideModal()
    }
  }

  const handleModalCancel = () => {
    if (props.onCancel) {
      props.onCancel()
    }
    hideModal()
  }

  const handleModalResolve = () => {
    if (props.onResolve) {
      props.onResolve()
    }
    hideModal()
  }

  const getColorScheme = (modalType: string | undefined) => {
    switch (modalType) {
      case MODAL_TYPES.CONFIRMATION_CRITICAL:
        return 'red'
      default:
        return 'primary'
    }
  }

  /**
   * If this is a loader modal, run the loader function and close the modal when it's done.
   */
  useEffect(
    () => {
      if (isOpen) {
        void (async () => {
          if (props.loader) {
            const result = await props.loader()
            if (result && props.closeOnLoaded) {
              hideModal()
            }
          }
        })()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isOpen],
  )

  const renderComponent = () => {
    if (!isOpen) {
      return null
    }

    return (
      <Modal
        closeOnEsc={props.closable}
        closeOnOverlayClick={props.closable}
        id="globalModal"
        isOpen
        onClose={handleModalToggle}
        size={props.size}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{props.title}</ModalHeader>
          {props.closable && <ModalCloseButton />}
          <ModalBody whiteSpace="pre-wrap">{props.body}</ModalBody>
          <ModalFooter>
            {props.modalType !== MODAL_TYPES.LOADING && (
              <>
                {props.modalType !== MODAL_TYPES.ALERT && props.modalType !== MODAL_TYPES.ERROR && props.closable && (
                  <Button mr={3} onClick={handleModalCancel}>
                    {props.cancelText}
                  </Button>
                )}
                {props.resolveText && props.onResolve && (
                  <Button mr={3} onClick={handleModalResolve}>
                    {props.resolveText}
                  </Button>
                )}
                <Button
                  colorScheme={getColorScheme(props.modalType)}
                  onClick={handleModalConfirm}
                  isDisabled={!props.isConfirmable || isLoading}
                  isLoading={isLoading}
                >
                  {props.confirmText}
                </Button>
              </>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    )
  }

  return (
    <GlobalModalContext.Provider value={contextValue}>
      {renderComponent()}
      {children}
    </GlobalModalContext.Provider>
  )
}

export default GlobalModal
