import axios from 'axios'

import { EDITOR_FRAME_COLORS } from 'config/styles'

import { INSPECTION_ITEM_TYPES } from 'interfaces/inspection'
import { AnchorPoints, InspectionItem, InspectionItemNumberValues, ShapesId } from 'interfaces/interfaces'

import { calculateCenterAndDistance } from './Editor'
import { ERROR_PROCESS, processErrorHandler } from './ErrorHandler'
import { GET_INSPECTION_SHEET_API_URL } from './InspectionSheet'

export type DepthEstimationResultKey =
  | 'plane_to_cylinders_distance1'
  | 'plane_to_cylinders_distance2'
  | 'plane_to_plane_distance'

/**
 * Find and return the inspection item used for depth estimation.
 *
 * Will return empty array if it couldn'filters find one.
 *
 * @param {InspectionItem[]} items Array of inspection items.
 * @returns {InspectionItem[]}
 */
export const getDepthEstimationItems = (items: InspectionItem[]): InspectionItem[] =>
  items.filter(
    (item) =>
      item.item_type &&
      [INSPECTION_ITEM_TYPES.PLANE_TO_CYLINDERS_DISTANCE].includes(item.item_type) &&
      !!getDepthEstimatedValue(item),
  )

/**
 * Get the estimated value of a depth estimation regardless if it's
 * 'plane to plane' or 'plane to cylinder'.
 *
 * @param item Inspection item
 * @returns The estimated value
 */
export const getDepthEstimatedValue = (item: InspectionItem): number => {
  if (item.plane_to_cylinders_distance?.estimated_value) {
    return item.plane_to_cylinders_distance.estimated_value
  }

  // TOOD: `plane_to_plane` has been deprecated. Remove this once usage is confirmed not to break if removed.
  if (item.plane_to_plane_distance?.estimated_value) {
    return item.plane_to_plane_distance.estimated_value
  }

  return 0
}

/**
 * Generate anchors data from depth estimation data for plane to cylinder distances.
 * The center will be calculated from the provided points. Distance value will be
 * used as-is from the source data instead of recalculated.
 *
 * @param item Value of `plane_to_cylinders_distance` from an inspection item.
 * @returns Anchor points
 */
export const getPlaneToCylinderDistanceAnchors = (item: InspectionItemNumberValues): AnchorPoints[] => {
  if (!item.individual_distances) {
    return []
  }

  return item.individual_distances.map<AnchorPoints>((row) => {
    const center = calculateCenterAndDistance({ points: row.positions_for_distance })

    return {
      points: row.positions_for_distance,
      center: center?.[0],
      distance: row.distance || center?.[1],
    }
  })
}

/**
 * Get color code specific to a depth type.
 *
 * @param type Depth Type number (either 1, 2 or 3)
 * @return Color code or black as fallback
 */
const depthTypeColorMap = [
  null, // nothing since depth type starts from 1
  EDITOR_FRAME_COLORS.detectedPlaneToCylinderDistance,
  EDITOR_FRAME_COLORS.detectedCylinderToPlaneDistance,
  EDITOR_FRAME_COLORS.detectedPlaneToCylinderDistance,
  EDITOR_FRAME_COLORS.detectedCylinderToPlaneDistance,
]
export const getDepthTypeColor = (type: number): string => depthTypeColorMap[type] || 'black'

/**
 * Estimate depth. No shapes are provided as back-end will figure
 * out what shapes to be used.
 *
 * @param access_token Access token to access API
 * @param showErrorModal Function to show error modal
 */
export const estimateDepth = async (
  access_token: string,
  projectId: string,
  inspectionAreaId: string,
  inspectionSheetId: string,
  volumeId: string,
  shapeIds: ShapesId,
  showErrorModal: (message: string) => void,
): Promise<Record<DepthEstimationResultKey, InspectionItem> | null> =>
  axios
    .post<Record<DepthEstimationResultKey, InspectionItem> | null>(
      `${GET_INSPECTION_SHEET_API_URL(projectId, inspectionAreaId, inspectionSheetId)}/evaluate-depths`,
      {
        volume_id: volumeId,
        polygons: shapeIds.polygons.length ? shapeIds.polygons : undefined,
        cylinders: shapeIds.cylinders,
      },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      },
    )
    .then((res) => res.data)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.ESTIMATE_DEPTH, showErrorModal)
      return null
    })
