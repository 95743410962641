import { FC } from 'react'

import {
  Box,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  SimpleGrid,
  Spacer,
  Spinner,
  Text,
} from '@chakra-ui/react'
import SectionTitle from 'components/SectionTitle'
import { useSelector } from 'react-redux'
import { RootState } from 'store/app'

const AccountInformation: FC = () => {
  // Store
  const user = useSelector((state: RootState) => state.user.user)
  const userLoaded = useSelector((state: RootState) => state.user.userLoaded)

  return (
    <Box className="content-container">
      {!userLoaded ? (
        <Flex gap={4}>
          <Spinner />
          <Text fontWeight="bold">読み込み中…</Text>
        </Flex>
      ) : (
        <>
          <Heading fontSize="2xl">アカウント</Heading>

          <Divider mt={4} />

          <Spacer m={4} />

          {/* User Information */}
          <SectionTitle title="ユーザー情報" />

          <Spacer m={2} />

          <SimpleGrid columns={2} spacing={4}>
            {/* <FormControl>
              <FormLabel fontWeight="normal">Last Name</FormLabel>
              <Input type="lastname" value={user.family_name} readOnly />
            </FormControl>
            <FormControl>
              <FormLabel fontWeight="normal">First Name</FormLabel>
              <Input type="firstname" value={user.given_name} readOnly />
            </FormControl> */}
            <FormControl>
              <FormLabel fontWeight="normal">メールアドレス</FormLabel>
              <Input type="email" defaultValue={user.email} readOnly />
            </FormControl>
          </SimpleGrid>

          <Spacer m={4} />

          {/* Company Informatio */}
          {/* <Box className="section-title">
            <Divider />
            <span>Company Information</span>
          </Box>

          <Spacer m={2} />

          <VStack gap={4}>
            <FormControl>
              <FormLabel fontWeight="normal">Company Name</FormLabel>
              <Input type="companyname" />
            </FormControl>
            <FormControl>
              <FormLabel fontWeight="normal">Company Logo</FormLabel>
            </FormControl>
          </VStack> */}
        </>
      )}
    </Box>
  )
}

export default AccountInformation
