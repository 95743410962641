/* istanbul ignore file */
import { MutableRefObject, ReactElement, RefObject } from 'react'

import { ThreeEvent } from '@react-three/fiber'
import { Group, Object3D, PerspectiveCamera, Vector2, Vector3 } from 'three'
import { ArcballControls } from 'three-stdlib'

import { LayerStatus, PointArray } from './attribute'
import { InspectionItem, InspectionSheet } from './inspection'
import { Cylinder, Polygon, Shape, ShapeKey, Shapes } from './shape'

export enum TransformTypes {
  TRANSLATE = 'translate',
  ROTATE = 'rotate',
  SCALE = 'scale',
}

export interface CylinderMeshProps {
  /**
   * Cylinder data.
   */
  cylinder: Cylinder

  /**
   * Label to be displayed on the cylinder.
   * Will be positioned on the center of the cylinder.
   */
  label?: string | JSX.Element | ReactElement

  /**
   * Color of the label.
   */
  labelBgColor?: string

  /**
   * Show icon on the left of the label.
   * Use this if you want to have an icon on a separate container of different bg color.
   * If bg color doesn't matter, use `label` prop.
   */
  labelLeftIcon?: ReactElement

  /**
   * Background color for the left icon.
   */
  labelLeftIconBgColor?: string
}

export interface PolygonPlaneMeshProps {
  /**
   * Polygon data.
   */
  polygon: Polygon

  /**
   * Custom origin of the plane. If not defined, polygon.center will be used.
   */
  origin?: PointArray

  /**
   * Set the plane as transparent.
   * Defaults to TRUE.
   */
  transparent?: boolean

  /**
   * Color of the plane
   */
  color?: string

  /**
   * Color of the plane when hovered.
   */
  hoverColor?: string

  /**
   * Color of the plane when selected.
   */
  selectedColor?: string

  /**
   * Opacity of the plane.
   */
  opacity?: number

  /**
   * Show guides between points.
   */
  showGuides?: boolean

  /**
   * Show guide's labels between points.
   * This is by default true if `showGuides` is true.
   */
  showGuidesLabel?: boolean

  /**
   * Show distance between points.
   */
  showGuidesDistance?: boolean

  /**
   * Color of the guides.
   */
  guideColor?: string

  /**
   * Style of the guides.
   */
  guideStyle?: LineStyle

  /**
   * Guide line thickness.
   * Value starts from 0.0015. Adjust by adding ~0.001 for each thickness.
   */
  guideThickness?: number

  /**
   * Text color of the guides' label.
   */
  guideLabelTextColor?: string

  /**
   * Background color of the guides.
   */
  guideLabelBgColor?: string

  /**
   * Outline color of the guides.
   */
  guideLabelOutlineColor?: string

  /**
   * Border width of the guides.
   */
  guideLabelBorderWidth?: number

  /**
   * Opacity of the guides.
   */
  guideOpacity?: number

  /**
   * Label to be displayed on center of the plane.
   */
  label?: string

  /**
   * Background color for the label.
   */
  labelBgColor?: string

  /**
   * Text color for the label.
   */
  labelTextColor?: string

  /**
   * Is the plane selectable.
   */
  selectable?: boolean

  /**
   * Given vertices are open loop (last point is not a duplicate of first).
   */
  isOpenLoop?: boolean
}

export interface PolygonPlaneMeshTransformableProps extends PolygonPlaneMeshProps {
  allowedTransforms: TransformTypes[]
  pivotPoint: PointArray

  /**
   * Enable or disable the transform controls.
   */
  enabled?: boolean
}

export enum CircleAnchorIcon {
  CHECK,
  MOVE,
}

export interface CircleAnchorProps {
  id: string
  groupRef?: MutableRefObject<Group | null>
  point: PointArray
  scale?: number
  hoverScale?: number

  /**
   * Use an icon as label on the anchor itself.
   */
  icon?: CircleAnchorIcon

  /**
   * Color of the icon.
   */
  iconColor?: string

  /**
   * Scale of the icon.
   */
  iconScale?: number

  /**
   * Inner circle color.
   */
  color?: string

  /**
   * Color for the outer circle.
   */
  outlineColor?: string

  /**
   * Scale for the inner circle.
   * This is relative to the size of the outer circle (`scale` prop).
   */
  innerScale?: number

  /**
   * Opacity for the outer circle.
   */
  outlineOpacity?: number

  /**
   * Opacity for the inner circle.
   * Also applies to the tooltip.
   */
  opacity?: number

  renderOrder?: number
  onDown?: (event: ThreeEvent<PointerEvent>) => void
  onUp?: (event: ThreeEvent<PointerEvent>) => void
  onEnter?: (event: ThreeEvent<PointerEvent>) => void
  onLeave?: (event: ThreeEvent<PointerEvent>) => void
  onMove?: (event: ThreeEvent<PointerEvent>) => void

  /**
   * Tooltip when hovered.
   */
  tooltipText?: string
}

export interface AnchorPoints extends LayerStatus {
  points: PointArray[]
  plane_side?: 'upper' | 'lower'
  is_virtual?: boolean
  diameter?: number
  distance?: number
  center?: PointArray
  pickedInfo?: {
    mousePosition: Vector2
    cameraDistance: number
    timestamp: number
  }[]
  error_id?: number
}

export interface CuboidAnchor {
  diameter: number
  points: PointArray[]
}

export interface ModalProps {
  body?: string | React.ReactNode
  cancelText?: string
  resolveText?: string
  closable?: boolean
  confirmText?: string
  modalType?: string
  isConfirmable?: boolean
  onConfirm?: () => boolean
  onConfirmPromised?: () => Promise<boolean>
  onCancel?: () => void
  onResolve?: () => void
  loader?: () => Promise<boolean>
  closeOnLoaded?: boolean
  title?: string
  size?: string
}

export interface GlobalModal {
  showModal: (modalProps: ModalProps) => void
  showErrorModal: (message: string) => void
  updateModal: (modalProps: ModalProps) => void
  hideModal: () => void
}

export type CuboidDirection = 'x' | 'y' | 'z'
export type Anchors = {
  cylinders: AnchorPoints[]
  tori: AnchorPoints[]
  planes: AnchorPoints[]
  cuboid: AnchorPoints[]
  polygons: AnchorPoints[]
}

export interface Editor {
  backgroundColor: string
  cuboidDirection?: CuboidDirection
  changeBackgroundColor: (color: string) => void
  changeCuboidDirection: (direction: CuboidDirection) => void
  changeIsJobRunning: (checking: boolean) => void
  changeIsDragging: (dragging: boolean) => void
  changeIsMouseDown: (isDown: boolean) => void
  changeIsToolProcessing: (processing: boolean) => void
  changePointSize: (size?: number) => void
  changeSelectedShapeIds: (shapeIds: string[]) => void
  changeTool: (tool: string, skipClearAnchorFramesChecking?: boolean) => void
  changeSubTool: (subTool: string) => void
  setPrevSelectedTool: (tool: string) => void
  deleteShapes: (forSelectedShapes: boolean, shapeId?: string, shapeKey?: ShapeKey) => void
  initCompleted: boolean
  isJobRunning: boolean
  isDragging: boolean
  isLayerModifying: boolean
  isMouseDown: boolean
  isPointCloudInvisible: boolean
  isToolProcessing: boolean
  meshRefs?: MeshRefs
  pointSize?: number
  inspectionSheet?: InspectionSheet
  setInspectionSheet: (inspectionSheet: InspectionSheet) => void
  selectedTool: string
  selectedSubTool?: string
  prevSelectedTool: string
  prevPrevSelectedTool: string
  isPreviousTool: (tool: string) => boolean
  shapes: Shapes
  setShapes: (shapes: Shapes) => void
  toggledCollapses: string[]
  updateAllSelectedShapesStatus: (props: Record<'invisible', boolean>) => void
  updateAllShapesStatus: (props: Record<'invisible', boolean>, shapeKey: ShapeKey) => void
  updatePointCloudVisibility: (invisible: boolean) => void
  updateMeshRefs: (refs: MeshRefs) => void
  updateShapeStatus: (props: LayerStatus, index: number, shapeKey: ShapeKey) => void
  updateShapeStatusById: (props: LayerStatus, id: string, shapeKey: ShapeKey) => void
  updateShapeStatusByIds: (shapeKey: ShapeKey, shps: Pick<Shape, 'shape_id' | keyof LayerStatus>[]) => void
  updateToggledCollapses: (collapses: string[]) => void
  inspectionItems: InspectionItem[]
  setInspectionItems: (items: InspectionItem[] | ((items: InspectionItem[]) => InspectionItem[])) => void
  fetchInspectionSheet: () => Promise<boolean>
  fetchInspectionItems: () => Promise<boolean>
  fetchShapes: () => Promise<boolean>
  setIsLayerModifying: (state: boolean) => void
  changeCollidingShapeIds: (shapeIds: string[]) => void
  collidingShapeIds: string[]
  selectionBoundaryBoxVectors: Vector3[][]
  meshPoints: MeshPoints
  setMeshPoints: (meshPoints: MeshPoints) => void
  cameraRef: RefObject<PerspectiveCamera>
  arcballControlsRef: RefObject<ArcballControls>
  focusCamera: (point: PointArray | undefined) => void
  focusCameraDirectional: (point: PointArray, normal: Vector3, right: Vector3, distance?: number) => void
}

export interface MeshRefs {
  [key: string]: MutableRefObject<Object3D>
}

export interface MeshPoints {
  [key: string]: Vector3[]
}

export type MeasureKey = 'distance' | 'detectedPlaneToCylinderDistance' | 'detectedCylinderToPlaneDistance'
export type CuboidKey = 'cuboid'

export interface FocusedPoint {
  anchorIndex: number
  pointIndex: number
  shapeKey: ShapeKey | MeasureKey | CuboidKey
}

export interface DepthType {
  invisible?: boolean
  inspectionItems?: InspectionItem[]
  label?: string
}

export enum LineStyle {
  Solid,
  Dashed,
}

export interface LineProps {
  /**
   * Anchor points to be connected by the line.
   */
  points: PointArray[]

  /**
   * Line color.
   */
  color?: string

  /**
   * Opacity for the line.
   */
  opacity?: number
}

export interface DistanceLabelProps {
  /**
   * Unique ID for the label. It _must_ be unique across all React component instance.
   */
  id: string

  /**
   * Anchor points to be connected by the line.
   */
  points: PointArray[]

  /**
   * Label to be displayed on the midpoint.
   */
  label?: string | JSX.Element | ReactElement

  /**
   * Line color.
   * @deprecated Use `lineColor` instead.
   */
  color?: string

  /**
   * Props for both anchor.
   * Will be overridden by `topAnchorProps` and `bottomAnchorProps` respectively.
   * Use this for common props and individual props for customizing each anchor.
   */
  anchorProps?: Partial<CircleAnchorProps>

  /**
   * Props for the top anchor.
   * Direct props will override this.
   */
  topAnchorProps?: Partial<CircleAnchorProps>

  /**
   * Props for the bottom anchor.
   */
  bottomAnchorProps?: Partial<CircleAnchorProps>

  /**
   * Hide anchors.
   */
  hideAnchors?: boolean

  /**
   * Hide top anchor.
   */
  hideTopAnchor?: boolean

  /**
   * Hide bottom anchor.
   */
  hideBottomAnchor?: boolean

  /**
   * Scale for the anchors.
   * @deprecated Use `anchorProps.scale` instead.
   */
  anchorScale?: number

  /**
   * Scale for the anchors during hover.
   * @deprecated Use `anchorProps.hoverScale` instead.
   */
  anchorHoverScale?: number

  /**
   * Color for the anchors.
   * Will apply to both top and bottom anchors.
   * @deprecated Use `anchorProps.color` instead.
   */
  anchorColor?: string

  /**
   * Outline color for the anchors.
   * Will apply to both top and bottom anchors.
   * @deprecated  Use `anchorProps.outlineColor` instead.
   */
  anchorOutlineColor?: string

  /**
   * Color for the top anchor.
   * @deprecated Use `topAnchorProps.color` instead.
   */
  topAnchorColor?: string

  /**
   * Color for the bottom anchor.
   * @deprecated Use `bottomAnchorProps.color` instead.
   */
  bottomAnchorColor?: string

  /**
   * Add outline to the line.
   */
  lineOutline?: boolean

  /**
   * Outline color.
   */
  lineOutlineColor?: string

  /**
   * Line style
   */
  lineStyle?: LineStyle

  /**
   * Line thickness.
   * Value starts from 0.0015. Adjust by adding ~0.001 to customize thickness.
   */
  lineThickness?: number

  /**
   * Color of the dashed line between the two anchos.
   * Defaults to black.
   */
  lineColor?: string

  /**
   * Background color for the label.
   */
  labelBgColor?: string

  /**
   * Text color for the label.
   */
  labelTextColor?: string

  /**
   * Outline color for the label.
   */
  labelOutlineColor?: string

  /**
   * Border width for the label.
   */
  labelBorderWidth?: number

  /**
   * Opacity for the label.
   */
  opacity?: number

  warningMessage?: string

  /**
   * Callback function when the label is held down (mouse down).
   *
   * @param anchorIndex Which anchor is held down (0 for the first anchor, 1 for the second anchor)
   */
  onDown?: (anchorIndex: number, event: ThreeEvent<PointerEvent>) => void

  /**
   * Callback function when the label's anchor is hovered.
   */
  onEnter?: (anchorIndex: number, event: ThreeEvent<PointerEvent>) => void

  /**
   * Callback function when mouse leaves the label's anchor.
   */
  onLeave?: (anchorIndex: number, event: ThreeEvent<PointerEvent>) => void
}

export interface LabelProps {
  point: PointArray
  label?: string | JSX.Element | ReactElement
  message: string
  backgroundColor?: string
  color?: string
  opacity?: number
  offset?: [number, number]

  /**
   * Adds a prefix to the label.
   * @default '⚠️ '
   * @deprecated Use `label` instead.
   */
  labelPrefix?: string | JSX.Element | ReactElement

  /**
   * Padding for the box.
   * - A single number will apply to all sides.
   * - Two numbers will apply to top/bottom and left/right.
   * - Four numbers will apply to top, right, bottom, and left respectively.
   */
  padding?: number | [number, number] | [number, number, number, number]
}
