import { useCallback, useContext, useEffect, useRef, useState } from 'react'

import { startsWith } from 'lodash'
import mixpanel from 'mixpanel-browser'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'

import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import { TEMP_COMMENT_ID, TEMP_IMAGE_ID, TEMP_REPLY_ID } from 'config/constants'

import { Comment, CommentReply, CommentUploadedImage, IMAGE_UPLOAD_STATUS } from 'interfaces/interfaces'

import { uploadFile } from 'services/AWS'
import {
  createComment,
  createReply,
  deleteImage,
  editComment,
  editReply,
  getSignedUrlForUploadImage,
  upsertImageMetaData,
} from 'services/Comments'

import {
  addComment,
  increaseReplyCounter,
  replaceComment,
  replaceFormImage,
  replaceReply,
  setEditingCommentId,
  setFormImages,
} from '../store/comments'

export interface CommentInputProps {
  /**
   * Parent comment when working on a reply.
   * Will be undefined when working on a comment.
   */
  parentComment?: Comment

  /**
   * Disable the inputs.
   */
  isDisabled: boolean

  /**
   * Callback when the user cancels the form.
   */
  onCancel: () => void

  /**
   * Callback when the user saves the comment/reply.
   */
  onSaved?: () => void
}

export const useCommentInput = ({ isDisabled, onSaved, parentComment }: CommentInputProps) => {
  // Store
  const dispatch = useAppDispatch()
  const project = useSelector((state: RootState) => state.page.project)
  const formImages = useSelector((state: RootState) => state.comments.formImages)
  const editingComment = useSelector((state: RootState) => state.comments.editingComment)
  const editingReply = useSelector((state: RootState) => state.comments.editingReply)

  // Vars
  const isNewComment = editingComment?.thread_id === TEMP_COMMENT_ID
  const isNewReply = editingReply?.reply_id === TEMP_REPLY_ID
  const thread_id = editingComment?.thread_id || parentComment?.thread_id || ''
  const inspection_area_id = editingComment?.inspection_area_id || parentComment?.inspection_area_id || ''

  // Contexts
  const { getAccessToken } = useContext(UserContext)
  const { showErrorModal } = useContext(GlobalModalContext)

  // States
  const [commentName, updateCommentName] = useState(editingReply?.author_name || editingComment?.author_name || '')
  const [commentBody, updateCommentBody] = useState(editingReply?.reply_body || editingComment?.thread_body || '')
  const [loading, setLoading] = useState(false)

  // Element refs
  const uploadMultipleFilesRef = useRef<HTMLInputElement>(null)
  const textareaRef = useRef<HTMLTextAreaElement>(null)

  /**
   * Handles when user selects files.
   * Used both for comment and reply.
   */
  const onMultipleFilesChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files) return

      e.preventDefault()
      if (!e || !e.target || !e.target.files || !e.target.files.length) return
      const files: File[] = Array.from(e.target.files)
      const addingFiles = files.filter((file) => !formImages?.some((image) => image.filename === file.name)) || []
      const images = addingFiles.map<CommentUploadedImage>((file) => ({
        image_id: `${TEMP_IMAGE_ID}-${file.name}`,
        caption: '',
        filename: file.name,
        content_type: file.type,
        url: URL.createObjectURL(file),
        status: IMAGE_UPLOAD_STATUS.PENDING,
      }))

      dispatch(setFormImages([...formImages, ...images]))

      // reset file input
      e.target.value = ''
    },
    [dispatch, formImages],
  )

  /**
   * Main function for adding images to comment / reply.
   *
   * @param images Images to be added.
   */
  const handleAddingImages = async (images: CommentUploadedImage[]) => {
    if (!images.length) return []

    return Promise.all(
      images
        .filter((image) => image.status === IMAGE_UPLOAD_STATUS.PENDING)
        .map(async (image) => handleUploadImage(image)),
    )
  }

  /**
   * Upload image to S3 and save image metadata to the database.
   *
   * @param image Images to be uploaded
   */
  const handleUploadImage = async (image: CommentUploadedImage) => {
    if (!project || !inspection_area_id || isDisabled || !image.url) {
      return image
    }

    const token = await getAccessToken()
    if (!token) {
      return image
    }

    const signedImage = await getSignedUrlForUploadImage(
      token,
      project.project_id,
      inspection_area_id,
      image.filename,
      showErrorModal,
    )

    const file = await fetch(image.url)
      .then((r) => r.blob())
      .then((blobFile) => new File([blobFile], image.filename, { type: image.content_type }))

    if (signedImage?.url) {
      const uploadResult = await uploadFile(
        signedImage.url,
        signedImage.content_type || file.type,
        file,
        null,
        showErrorModal,
      )

      if (uploadResult) {
        return {
          ...signedImage,
          url: image.url,
          caption: image.caption?.trim(),
          status: IMAGE_UPLOAD_STATUS.DONE,
        }
      }
    }

    return {
      ...image,
      status: IMAGE_UPLOAD_STATUS.ERROR,
    }
  }

  /**
   * Save updated images to the database.
   *
   * @param images Imagest to be updated
   */
  const handleUpdatingImages = async (images: CommentUploadedImage[]): Promise<CommentUploadedImage[]> => {
    if (!project || !inspection_area_id || isDisabled || !images.length) {
      return images || []
    }

    const token = await getAccessToken()
    if (!token) {
      return images
    }

    return Promise.all(
      images.map(async (image) =>
        upsertImageMetaData(
          token,
          project.project_id,
          inspection_area_id,
          thread_id,
          editingReply?.reply_id || null,
          image.image_id,
          image.caption || '',
          image.filename,
          showErrorModal,
        ).then((res) => {
          if (res) {
            const updated = {
              ...res,
              status: IMAGE_UPLOAD_STATUS.DONE,
            }

            dispatch(replaceFormImage({ image_id: image.image_id, image: updated }))
            return updated
          }

          return {
            ...image,
            status: IMAGE_UPLOAD_STATUS.ERROR,
          }
        }),
      ),
    )
  }

  /**
   * Handle image deletion for both comment and reply.
   *
   * @param images Images to delete
   */
  const handleImageDelete = async (images: CommentUploadedImage[]): Promise<CommentUploadedImage[]> => {
    if (!project || !inspection_area_id || isDisabled || !images.length) {
      return images
    }

    const token = await getAccessToken()
    if (!token) {
      return images
    }

    return Promise.all(
      images.map((image) =>
        deleteImage(
          token,
          project.project_id,
          inspection_area_id,
          thread_id,
          editingReply?.reply_id || null,
          image.image_id,
          showErrorModal,
        ).then((res) => ({
          ...image,
          status: !res ? IMAGE_UPLOAD_STATUS.ERROR : IMAGE_UPLOAD_STATUS.DELETING,
        })),
      ),
    )
  }

  /**
   * Handle all saves operation
   * - file upload / deletion
   * - add / update comment
   * - add / update reply
   * - add / delete images
   */
  const handleSave = async () => {
    if (isDisabled) {
      return false
    }

    // Handle images first
    const updatedImages: CommentUploadedImage[] = formImages.filter(
      (image) => !startsWith(image.image_id, TEMP_IMAGE_ID) && image.status !== IMAGE_UPLOAD_STATUS.DELETING,
    )
    let uploadedImages: CommentUploadedImage[] = formImages.filter(
      (image) => image.status === IMAGE_UPLOAD_STATUS.PENDING,
    )
    let deletedImages: CommentUploadedImage[] = formImages.filter(
      (image) => image.status === IMAGE_UPLOAD_STATUS.DELETING,
    )

    // Loading state
    // Only set loading state to non-deleting images
    setLoading(true)
    dispatch(
      setFormImages(
        formImages.map((image) => {
          if (image.status === IMAGE_UPLOAD_STATUS.DELETING) {
            return image
          }

          return { ...image, status: IMAGE_UPLOAD_STATUS.LOADING }
        }),
      ),
    )

    // Upload images (if any)
    if (uploadedImages.length) {
      uploadedImages = await handleAddingImages(uploadedImages)
    }

    // combine new images and existing as they're going to the same API
    let toSaveImages = updatedImages.concat(uploadedImages)

    // Upsert image metadata, only when editing
    if (!isNewComment && !isNewReply && toSaveImages.length) {
      toSaveImages = await handleUpdatingImages(toSaveImages)
    }

    // Image deletion
    if (deletedImages.length) {
      deletedImages = await handleImageDelete(deletedImages)
    }

    // Pause if there is any image upload error
    if (
      toSaveImages.some((image) => image.status === IMAGE_UPLOAD_STATUS.ERROR) ||
      deletedImages.some((image) => image.status === IMAGE_UPLOAD_STATUS.ERROR)
    ) {
      setLoading(false)
      return false
    }

    // Then create a new comment
    return (editingReply ? handleSaveReply(toSaveImages) : handleSaveComment(toSaveImages)).finally(() =>
      setLoading(false),
    )
  }

  /**
   * Handle saving a new comment or updating an existing one.
   *
   * @param status Response after saving images
   */
  const handleSaveComment = async (images: CommentUploadedImage[]): Promise<boolean> => {
    if (!project || !inspection_area_id || isDisabled || !editingComment?.thread_id) {
      return false
    }

    const token = await getAccessToken()
    if (!token) {
      return false
    }

    const updatedComment: Comment = {
      ...editingComment,
      thread_body: commentBody.trim(),
      author_name: commentName.trim(),
      images: images.map((image) => ({
        image_id: image.image_id,
        filename: image.filename,
        caption: image.caption,
      })),
    }

    // handle updating existing comment
    let savedComment: Comment | null = null
    if (isNewComment) {
      savedComment = await createComment(token, project.project_id, inspection_area_id, updatedComment, showErrorModal)
    } else {
      savedComment = await editComment(token, project.project_id, inspection_area_id, updatedComment, showErrorModal)
    }

    if (savedComment?.thread_id) {
      // If it's a new comment, set it as selected and reset editing state
      if (isNewComment) {
        dispatch(addComment({ comment: savedComment, selected: true }))
        dispatch(setEditingCommentId(''))
      } else {
        // take images from provided instance since API does not return it
        dispatch(
          replaceComment({
            thread_id,
            comment: {
              ...savedComment,
              replies: editingComment.replies,
              images: images.map((image) => ({ ...image, status: undefined })),
            },
          }),
        )
      }

      updateCommentBody('')
      if (onSaved) onSaved()

      // track event to mixpanel
      let trackSituation = ''
      if (updatedComment.cartesian_position) {
        trackSituation = '3D GUI (point)'
      } else if (updatedComment.cuboid_position) {
        trackSituation = '3D GUI (cuboid)'
      } else if (updatedComment.blueprint_position) {
        trackSituation = 'blueprint viewer'
      }

      mixpanel.track(`${isNewComment ? 'Create' : 'Update'} comment`, {
        'Inspection area ID': project.project_id,
        'Thread ID': savedComment.thread_id,
        Situation: trackSituation,
        'Number of images': updatedComment.images?.length || 0,
        'Length of text': updatedComment.thread_body.length,
      })
    }

    return true
  }

  /**
   * Handle saving a new reply or updating an existing one.
   *
   * @param status Response after saving images
   */
  const handleSaveReply = async (images: CommentUploadedImage[]): Promise<boolean> => {
    if (!project || !inspection_area_id || isDisabled || !editingReply || !parentComment) {
      return false
    }

    const token = await getAccessToken()
    if (!token) {
      return false
    }

    const updatedReply: CommentReply = {
      ...editingReply,
      reply_body: commentBody.trim(),
      author_name: commentName.trim(),
      images: images.map((image) => ({
        image_id: image.image_id,
        filename: image.filename,
        caption: image.caption,
      })),
    }

    // handle updating existing comment
    let savedReply: CommentReply | null = null
    if (isNewReply) {
      savedReply = await createReply(
        token,
        project.project_id,
        inspection_area_id,
        thread_id,
        updatedReply,
        showErrorModal,
      )
    } else {
      savedReply = await editReply(
        token,
        project.project_id,
        inspection_area_id,
        thread_id,
        updatedReply,
        showErrorModal,
      )
    }

    if (savedReply?.reply_id) {
      // Increaese reply counter on comment if it's a new reply
      if (isNewReply) {
        dispatch(increaseReplyCounter(thread_id))
      } else {
        // If user is editing a reply, replace the existing instance with the saved one
        // Take images from the provided images since API does not return it
        dispatch(
          replaceReply({
            thread_id,
            reply: { ...savedReply, images: images.map((image) => ({ ...image, status: undefined })) },
          }),
        )
      }

      updateCommentBody('')
      if (onSaved) onSaved()

      // track event to mixpanel
      let trackSituation = ''
      if (parentComment.cartesian_position) {
        trackSituation = '3D GUI (point)'
      } else if (parentComment.cuboid_position) {
        trackSituation = '3D GUI (cuboid)'
      } else if (parentComment.blueprint_position) {
        trackSituation = 'blueprint viewer'
      }

      mixpanel.track(`${isNewReply ? 'Create' : 'Update'} reply`, {
        'Inspection area ID': project.project_id,
        'Thread ID': thread_id,
        'Reply ID': editingReply.reply_id,
        Situation: trackSituation,
        'Number of images': updatedReply.images?.length || 0,
        'Length of text': updatedReply.reply_body.length,
      })
    }

    return true
  }

  /**
   * Focus on textarea after the component is mounted.
   */
  useEffect(() => {
    setTimeout(() => {
      if (textareaRef.current) textareaRef.current.focus()
    })
  }, [])

  return {
    commentName,
    commentBody,
    loading,
    isNewComment,
    isNewReply,
    updateCommentName,
    updateCommentBody,
    onMultipleFilesChange,
    handleSave,
    handleCancel: () => null,
    uploadMultipleFilesRef,
    textareaRef,
  }
}
