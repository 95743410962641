import { FC, MutableRefObject, useContext, useEffect, useRef, useState } from 'react'

import { Flex, HStack } from '@chakra-ui/react'
import { Html } from '@react-three/drei'
import { useSelector } from 'react-redux'
import { RootState } from 'store/app'
import { BackSide, CylinderGeometry, Euler, Matrix4, Mesh, MeshBasicMaterial, Object3D } from 'three'

import { EditorContext } from 'contexts/Editor'

import useMeshOutline from 'hooks/MeshOutline'
import useShape from 'hooks/Shape'

import { EDITOR_SHAPE_KEYS } from 'config/constants'

import { CylinderMeshProps } from 'interfaces/interfaces'

import { getCenterPoint } from 'services/Editor'

//* Rebar geometry
const createCylinderGeometry = (diameter: number, length: number) =>
  new CylinderGeometry(diameter / 2, diameter / 2, length, 64, 1, false, 0, Math.PI * 2)

//* Rebar material
const standardMaterial = new MeshBasicMaterial({ color: 'red' })
const hoveredMaterial = new MeshBasicMaterial({ color: 'yellow' })
const selectedMaterial = new MeshBasicMaterial({
  color: 'white',
  side: BackSide,
})

//* Defines an Eulerian angle that rotates the X-axis by 90 degrees, since the detected rebar is up in the Z-axis direction.
const cylinderEuler = new Euler(Math.PI / 2, 0, 0)

export const CylinderMesh: FC<CylinderMeshProps> = ({
  cylinder,
  label,
  labelBgColor = 'yellow',
  labelLeftIcon,
  labelLeftIconBgColor = 'black',
}) => {
  // Context
  const { updateMeshRefs, cameraRef, arcballControlsRef } = useContext(EditorContext)

  // Store
  const selectedShapeIds = useSelector((state: RootState) => state.editor.selectedShapeIds)

  // States
  const [cylinderMesh, setCylinderMesh] = useState<Mesh>(new Mesh())
  const [outlineMesh, setOutlineMesh] = useState<Mesh>(new Mesh())

  // Hooks
  const { outlineThickness } = useMeshOutline(arcballControlsRef.current)
  const { onPointerDown, onPointerUp, onPointerOver, onPointerOut, isHovered } = useShape(
    cylinder,
    EDITOR_SHAPE_KEYS.CYLINDERS,
    cameraRef,
  )

  // Refs
  const meshRef = useRef<Mesh | null>(null)

  /**
   * Update mesh refs
   */
  useEffect(() => {
    if (meshRef.current) {
      updateMeshRefs({ [cylinder.shape_id]: meshRef as MutableRefObject<Object3D> })
    }
  }, [updateMeshRefs, cylinder.shape_id, meshRef])

  /**
   * Draw cylinder mesh
   */
  useEffect(() => {
    const { diameter, length, transformation } = cylinder
    if (transformation?.length !== 16) {
      return
    }
    const matrix = new Matrix4().set(...transformation)

    //* Rotate the X-axis by 90° in the direction of the Z-axis and generate a mesh with rebar rotation applied.
    const mesh = new Mesh()
    mesh.quaternion.setFromEuler(cylinderEuler)
    mesh.applyMatrix4(matrix)

    // used for outline effect
    const outlineCylinderMesh = mesh.clone()
    setOutlineMesh(outlineCylinderMesh)

    //* Setting the shape of the cylinder
    const geo = createCylinderGeometry(diameter, length)
    geo.translate(0, cylinder.length / 2, 0)
    mesh.geometry = geo

    //* state保存
    setCylinderMesh(mesh)
  }, [cylinder])

  /**
   * Calculate cylinder geometry for outline effect
   */
  useEffect(() => {
    if (!outlineMesh || !selectedShapeIds.includes(cylinder.shape_id)) {
      return
    }

    // used for outline effect
    const { diameter, length } = cylinder
    const outlineGeo = createCylinderGeometry(diameter + outlineThickness, length + outlineThickness)
    outlineGeo.translate(0, length / 2, 0)
    outlineMesh.geometry = outlineGeo
  }, [cylinder, outlineMesh, outlineThickness, selectedShapeIds])

  if (!cylinder) return null

  return (
    <>
      <mesh
        ref={meshRef}
        onPointerUp={onPointerUp}
        onPointerDown={onPointerDown}
        onPointerOver={onPointerOver}
        onPointerOut={onPointerOut}
        quaternion={cylinderMesh.quaternion}
        position={cylinderMesh.position}
        geometry={cylinderMesh.geometry}
        material={isHovered ? hoveredMaterial : standardMaterial}
      >
        {!!(label || labelLeftIcon) && (
          <Html
            position={getCenterPoint(cylinderMesh)}
            style={{ transform: 'translateX(-50%) translateY(-50%)', pointerEvents: 'none' }}
            zIndexRange={[1, 9]}
          >
            <HStack
              spacing={0}
              backgroundColor={label ? labelBgColor : undefined}
              fontSize="80%"
              fontWeight="bold"
              color="black"
              whiteSpace="nowrap"
              px={2}
            >
              {labelLeftIcon && (
                <Flex
                  backgroundColor={labelLeftIconBgColor}
                  color="black"
                  alignItems="center"
                  justifyContent="center"
                  flex={1}
                  height="17px"
                  px={3}
                  position="relative"
                  left={label ? '-3px' : 0}
                  fontSize="120%"
                >
                  {labelLeftIcon}
                </Flex>
              )}
              {label}
            </HStack>
          </Html>
        )}
      </mesh>
      {selectedShapeIds.includes(cylinder.shape_id) && (
        <mesh
          quaternion={outlineMesh.quaternion}
          position={outlineMesh.position}
          geometry={outlineMesh.geometry}
          material={selectedMaterial}
        />
      )}
    </>
  )
}
