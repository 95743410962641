import { FC, useMemo } from 'react'

import { Flex, HStack, Spacer, Spinner, Text } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { RootState } from 'store/app'

import { getDiagramHeaders } from 'services/InspectionSheet'
import { zeroPad } from 'services/Util'

const TableHeader: FC<{
  isSaving?: boolean

  /**
   * All additional metrics' toggle state, including ones not belonging to this metric.
   */
  allAdditionalMetricsToggle?: boolean[]
}> = ({ isSaving, allAdditionalMetricsToggle = [] }) => {
  // Store
  const settings = useSelector((state: RootState) => state.inspectionSheet.settings)

  const { planeHeader, gridHeader } = useMemo(() => getDiagramHeaders(settings), [settings])

  return (
    <Flex className="inspection-sheet-header" fontSize="md">
      {/* Table header */}
      <HStack color="gray.500" transition="width ease 0.3s">
        {isSaving && (
          <>
            <Spinner />
            <Text>保存中...</Text>
          </>
        )}
      </HStack>
      <Text
        w="100px"
        className={['column', !settings.sheet_cols_visibility?.estimated_value ? 'hidden' : ''].join(' ')}
      >
        実測値
      </Text>
      <Text w="100px" className={['column', !settings.sheet_cols_visibility?.designed_value ? 'hidden' : ''].join(' ')}>
        設計値
      </Text>
      <Text
        w="100px"
        ml={6}
        className={['column', !settings.sheet_cols_visibility?.tolerance ? 'hidden' : ''].join(' ')}
      >
        規格値
      </Text>
      <Text w="100px" className={['column', !settings.sheet_cols_visibility?.designed_value ? 'hidden' : ''].join(' ')}>
        差
      </Text>

      {allAdditionalMetricsToggle.map((hidden, index) => (
        <Text
          key={`metrics-additional-column-${allAdditionalMetricsToggle.length}-${zeroPad(index, 2)}`}
          w="100px"
          className={['column', hidden ? 'hidden' : ''].filter(Boolean).join(' ')}
        >
          &nbsp;
        </Text>
      ))}

      <Spacer flex={0} mr={5} />

      <Text w="550px" className={['column', !planeHeader ? 'hidden' : ''].join(' ')}>
        {planeHeader}
      </Text>
      <Text w="550px" className={['column', !gridHeader ? 'hidden' : ''].join(' ')}>
        {gridHeader}
      </Text>
    </Flex>
  )
}

export default TableHeader
