import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { ServiceStatus } from 'interfaces/servicestatus'

export interface SystemState {
  serviceStatus: ServiceStatus | null
}

const initialState: SystemState = {
  serviceStatus: null,
}

export const systemSlice = createSlice({
  name: 'system',
  initialState,
  reducers: {
    setServiceStatus: (state, action: PayloadAction<ServiceStatus>) => {
      state.serviceStatus = action.payload
    },
  },
})

export const { setServiceStatus } = systemSlice.actions

export default systemSlice.reducer
