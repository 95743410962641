import { useCallback, useContext, useMemo, useState } from 'react'

import { CursorState, setCursor } from 'pages/projects/editor/store/editor'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'
import { Plane, Vector3 } from 'three'

import { EditorContext } from 'contexts/Editor'

import { EDITOR_TOOLS } from 'config/constants'

import { PointArray } from 'interfaces/attribute'
import { CircleAnchorIcon, CircleAnchorProps } from 'interfaces/canvas'

import { setIsClosing, updatePlaneAnchor } from '../store'

export function useAnchors(threePlanes: Plane[], lastTouchClient: { x: number; y: number } | undefined) {
  // Context
  const { selectedTool, changeIsDragging } = useContext(EditorContext)

  // Store
  const dispatch = useAppDispatch()
  const workingPoints = useSelector((root: RootState) => root.toolPlaneDetection.workingPoints)
  const planes = useSelector((root: RootState) => root.toolPlaneDetection.planes)
  const isClosing = useSelector((root: RootState) => root.toolPlaneDetection.isClosing)
  const isLoading = useSelector((root: RootState) => root.toolPlaneDetection.isLoading)

  // States
  const [movingAnchor, setMovingAnchor] = useState<{ anchorIndex: number; planeIndex: number } | undefined>(undefined)

  // Vars
  const isSelectedTool = useMemo(() => selectedTool === EDITOR_TOOLS.PLANE, [selectedTool])

  /**
   * Get tooltip to be used for the first anchor.
   */
  const getFirstAnchorTooltip = useCallback(() => {
    if (workingPoints.length < 3) return undefined

    return workingPoints.length > 3
      ? 'この平面の作成を完了'
      : `この平面の作成を完了するには、あと${4 - workingPoints.length}点以上が必要です`
  }, [workingPoints])

  /**
   * Event handler for selecting an anchor for adjustment.
   *
   * @param index Index of the anchor within it's respective plane.
   * @param planeType Type of the plane.
   */
  const onSelectAnchor = useCallback(
    (anchorIndex: number, planeIndex: number) => {
      setMovingAnchor({ anchorIndex, planeIndex })
      changeIsDragging(true) // This is toggled off in MainCanvas::onMouseUp
      dispatch(setCursor(CursorState.GRABBING))
    },
    [changeIsDragging, dispatch],
  )

  /**
   * Event handler for moving an anchor.
   *
   * @param position New position of the anchor.
   */
  const onMoveAnchor = useCallback(
    (point?: PointArray) => {
      if (!point || !movingAnchor) return

      const { anchorIndex, planeIndex } = movingAnchor

      // Get the other plane as reference point
      const plane = threePlanes[planeIndex]
      if (!plane) return

      dispatch(
        updatePlaneAnchor({
          planeIndex,
          anchorIndex,
          position: plane.projectPoint(new Vector3(...point), new Vector3()).toArray(),
        }),
      )
    },
    [movingAnchor, threePlanes, dispatch],
  )

  /**
   * Event handler for ending anchor movement.
   */
  const onEndMoveAnchor = useCallback(() => {
    setMovingAnchor(undefined)
  }, [])

  /**
   * Setup working point anchors
   */
  const workingPointAnchors = useMemo(() => {
    const anchors: CircleAnchorProps[] = []

    // During drawing, first anchor is always grey, unless there's only one, then it's red
    if (workingPoints.length && !movingAnchor) {
      let style: Partial<CircleAnchorProps> = {
        scale: 1,
        color: workingPoints.length === 1 && !lastTouchClient ? 'red' : '#777',
      }
      if ((!lastTouchClient && workingPoints.length > 3) || (lastTouchClient && workingPoints.length > 2)) {
        style = {
          color: '#248CE4',
          icon: CircleAnchorIcon.CHECK,
          iconColor: 'white',
          outlineOpacity: 0,
          scale: 1.3,
          iconScale: 1.1,
        }
      }

      anchors.push({
        id: 'polygon-working-points-first',
        point: workingPoints[0],
        ...style,
        tooltipText: getFirstAnchorTooltip(),
        onEnter: () => {
          if (workingPoints.length > 3) {
            dispatch(setCursor(CursorState.POINTER))
            dispatch(setIsClosing(true))
          }
        },
        onLeave: () => {
          if (workingPoints.length > 3) {
            dispatch(setIsClosing(false))
            dispatch(setCursor(CursorState.DEFAULT))
          }
        },
      })
    }

    // During drawing, if there are more than 1 points, set the last anchor as red
    if (workingPoints.length > 1 && !isClosing) {
      anchors.push({
        id: 'polygon-working-points-last',
        point: workingPoints.slice(-1)[0],
        color: 'red',
        scale: 1,
      })
    }

    return anchors
  }, [isClosing, workingPoints, movingAnchor, lastTouchClient, dispatch, getFirstAnchorTooltip])

  /**
   * Setup plane anchors, only for complete stage.
   */
  const planeAnchors = useMemo(() => {
    if (isLoading) return []

    let style: Partial<CircleAnchorProps> = { scale: 1 }
    if (isSelectedTool) {
      style = {
        icon: CircleAnchorIcon.MOVE,
        iconColor: 'white',
        outlineOpacity: 0,
        scale: 1.3,
        iconScale: 1.1,
      }
    }

    const anchors: CircleAnchorProps[] = planes
      .filter((polygon) => !polygon.invisible)
      .map((plane, planeIndex) =>
        plane.points.map((point, anchorIndex) => ({
          id: `polygon-plane-${plane.id}-anchor-${anchorIndex}-${point.join('-')}`,
          point,
          ...style,
          color:
            movingAnchor?.anchorIndex === anchorIndex && movingAnchor?.planeIndex === planeIndex ? 'red' : '#248CE4',
          tooltipText: isSelectedTool ? '点を移動' : undefined,
          onDown: isSelectedTool ? () => onSelectAnchor(anchorIndex, planeIndex) : undefined,
          onEnter: isSelectedTool
            ? () => {
                dispatch(setCursor(CursorState.GRAB))
              }
            : undefined,
          onLeave: () => {
            if (!movingAnchor) dispatch(setCursor(CursorState.DEFAULT))
          },
        })),
      )
      .flat()

    return anchors
  }, [isSelectedTool, planes, movingAnchor, isLoading, onSelectAnchor, dispatch])

  return {
    anchors: [workingPointAnchors, planeAnchors].flat(),
    movingAnchor,
    onMoveAnchor,
    onEndMoveAnchor,
  }
}
