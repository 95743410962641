import { FC, useCallback, useContext } from 'react'

import { Box, VStack } from '@chakra-ui/react'
import CollapsePanel from 'pages/projects/editor/infoPanels/components/CollapsePanel'
import LayerItem from 'pages/projects/editor/infoPanels/components/LayerItem'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'

import { EditorContext } from 'contexts/Editor'

import { EDITOR_COLLAPSE_TYPES, EDITOR_TOOLS } from 'config/constants'

import { zeroPad } from 'services/Util'
import { getPolygonIdsFromVolumeId } from 'services/VolumeEstimation'

import { resetPerVolumeId, setHighlightedWorkingGrid } from '../../store'

const WorkingGridInfoPanel: FC = () => {
  // Store
  const dispatch = useAppDispatch()
  const workingGridPoints = useSelector((state: RootState) => state.toolGrid.workingGridPoints)
  const selectedShapeIds = useSelector((state: RootState) => state.editor.selectedShapeIds)

  // Context
  const { selectedTool, isLayerModifying, inspectionItems, changeSelectedShapeIds } = useContext(EditorContext)

  /**
   * Delete a working grid
   */
  const handleDelete = useCallback(
    (volumeId: string) => {
      dispatch(resetPerVolumeId(volumeId))
      dispatch(setHighlightedWorkingGrid(''))

      // Remove selected shapes that are associated with the grid
      const polygonIds = getPolygonIdsFromVolumeId(inspectionItems, volumeId)
      const newSelectedShapeIds = selectedShapeIds.filter((shapeId) => !polygonIds.includes(shapeId))
      changeSelectedShapeIds(newSelectedShapeIds)
    },
    [dispatch, changeSelectedShapeIds, selectedShapeIds, inspectionItems],
  )

  if (selectedTool !== EDITOR_TOOLS.GRID && Object.keys(workingGridPoints).length === 0) {
    return null
  }

  return (
    <Box
      backgroundColor="gray.800"
      borderBottomLeftRadius="md"
      borderTopLeftRadius="md"
      w="100%"
      data-testid="working-grid-panel"
    >
      <CollapsePanel title="検出中のグリッド" type={EDITOR_COLLAPSE_TYPES.workingGrid} onChange={() => null}>
        {Object.keys(workingGridPoints).map((volumeId, itemIndex) => (
          <VStack w="100%" spacing={0} key={`grid-parent-${volumeId}`}>
            <LayerItem
              disabled={isLayerModifying}
              selected={false}
              key={`grid-listitem-${volumeId}`}
              label={`グリッド${zeroPad(itemIndex + 1, 2)}`}
              onMouseOver={() => dispatch(setHighlightedWorkingGrid(volumeId))}
              onMouseOut={() => dispatch(setHighlightedWorkingGrid(''))}
              deleteLayer={() => {
                handleDelete(volumeId)
              }}
            />
          </VStack>
        ))}
      </CollapsePanel>
    </Box>
  )
}

export default WorkingGridInfoPanel
