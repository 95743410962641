/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { FC, useContext, useEffect, useRef, useState } from 'react'

import { TransformControls } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'
import { isTablet } from 'react-device-detect'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'
import { BoxGeometry, EdgesGeometry, Matrix3, Matrix4, Mesh, Quaternion, Vector3 } from 'three'
import { TransformControls as TransformControlsImpl } from 'three-stdlib'

import { EditorContext } from 'contexts/Editor'

import { EDITOR_REQUIRED_ANCHORS, EDITOR_TOOL_KEYS } from 'config/constants'
import { EDITOR_FRAME_COLORS } from 'config/styles'

import { CuboidAnchor } from 'interfaces/interfaces'

import { removeUnsedGizmos } from 'services/Editor'
import { getCuboidFromPoints, pointsToVector3s } from 'services/Points'
import { millimeterToMeter } from 'services/Util'

import { setEditingCuboid } from '../shapes/cuboid/store'
import { Frame } from './AnchorFrames'

const MIN_DIAMETER_SCALE = 10

const CuboidFrame: FC<{
  cuboidAnchor?: CuboidAnchor
  baseDiameter: number
  maxSize: number
}> = ({ cuboidAnchor, baseDiameter, maxSize }) => {
  // Store
  const dispatch = useAppDispatch()
  const isLoading = useSelector((state: RootState) => state.maskPCD.isLoading)

  const { selectedSubTool, selectedTool, cuboidDirection } = useContext(EditorContext)
  const lineRef = useRef<Mesh>(null)
  const groupRef = useRef<Mesh>(null)
  const transformRef = useRef<TransformControlsImpl>(null)
  const [cuboidInfo, setCuboidInfo] = useState<{
    width: number
    depth: number
    height: number
    position: Vector3
    quaternion: Quaternion
    edgesGeometry: EdgesGeometry<BoxGeometry>
    rotationMatrix: Matrix4
    axisPoints: { x: Vector3[]; y: Vector3[]; z: Vector3[] }
  }>()
  const prevCuboidAnchorRef = useRef<CuboidAnchor>()

  const convertedDiameter = millimeterToMeter(baseDiameter || MIN_DIAMETER_SCALE)
  const updateCuboid = () => {
    if (lineRef.current && transformRef.current && cuboidInfo) {
      const minScaleX = convertedDiameter / (cuboidInfo?.width || 1)
      const minScaleY = convertedDiameter / (cuboidInfo?.height || 1)
      const minScaleZ = convertedDiameter / (cuboidInfo?.depth || 1)
      const maxScaleX = maxSize / (cuboidInfo?.width || 1)
      const maxScaleY = maxSize / (cuboidInfo?.height || 1)
      const maxScaleZ = maxSize / (cuboidInfo?.depth || 1)

      const { scale } = lineRef.current
      let { width, depth, height } = cuboidInfo
      width *= scale.x
      height *= scale.y
      depth *= scale.z

      let needReset = false
      if (minScaleX > scale.x) {
        needReset = true
        lineRef.current.scale.x = minScaleX
        width = convertedDiameter
      }
      if (minScaleY > scale.y) {
        needReset = true
        lineRef.current.scale.y = minScaleY
        height = convertedDiameter
      }
      if (minScaleZ > scale.z) {
        needReset = true
        lineRef.current.scale.z = minScaleZ
        depth = convertedDiameter
      }
      if (maxScaleX < scale.x) {
        needReset = true
        lineRef.current.scale.x = maxScaleX
        width = maxSize
      }
      if (maxScaleY < scale.y) {
        needReset = true
        lineRef.current.scale.y = maxScaleY
        height = maxSize
      }
      if (maxScaleZ < scale.z) {
        needReset = true
        lineRef.current.scale.z = maxScaleZ
        depth = maxSize
      }

      if (needReset) {
        transformRef.current.detach()
        transformRef.current.attach(lineRef.current)
      }

      dispatch(
        setEditingCuboid({
          region_id: '',
          center: lineRef.current.getWorldPosition(new Vector3()).toArray(),
          rotation: new Matrix3().setFromMatrix4(new Matrix4().extractRotation(lineRef.current.matrixWorld)).toArray(),
          extent: [width, height, depth],
        }),
      )
    }
  }

  useEffect(() => {
    if (cuboidAnchor?.points.length !== EDITOR_REQUIRED_ANCHORS.cuboid) {
      prevCuboidAnchorRef.current = undefined
      return
    }

    // if cuboid has been initialized, no need to recalculate
    if (prevCuboidAnchorRef.current) {
      return
    }
    prevCuboidAnchorRef.current = cuboidAnchor

    const { width, depth, height, position, quaternion, edgesGeometry, rotationMatrix, axisPoints } =
      getCuboidFromPoints(pointsToVector3s(cuboidAnchor.points), convertedDiameter)

    dispatch(
      setEditingCuboid({
        region_id: '',
        center: position.toArray(),
        rotation: new Matrix3().setFromMatrix4(new Matrix4().extractRotation(rotationMatrix)).toArray(),
        extent: [width, height, depth],
      }),
    )
    setCuboidInfo({
      width,
      depth,
      height,
      position,
      quaternion,
      edgesGeometry,
      rotationMatrix,
      axisPoints,
    })
  }, [convertedDiameter, cuboidAnchor, dispatch])

  useEffect(() => {
    // if cuboid has not been initialized, no need to recalculate
    if (!cuboidInfo) {
      return
    }
    updateCuboid()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [convertedDiameter])

  useFrame(() => {
    if (lineRef.current && transformRef.current && cuboidAnchor) {
      transformRef.current.visible = true
      lineRef.current.visible = true
      transformRef.current.attach(lineRef.current)
      if (selectedSubTool) {
        transformRef.current.setMode(selectedSubTool)
      }
    }
    // reset the values when cuboid destroyed
    if (lineRef.current && transformRef.current && !cuboidAnchor) {
      transformRef.current.visible = false
      transformRef.current.position.set(0, 0, 0)
      transformRef.current.setRotationFromMatrix(new Matrix4())
      transformRef.current.clear()
      lineRef.current.visible = false
      lineRef.current.position.set(0, 0, 0)
      lineRef.current.scale.set(1, 1, 1)
    }
  })

  useEffect(() => {
    // Remove some unused gizmos
    if (transformRef.current) {
      removeUnsedGizmos(transformRef.current)
    }
  }, [transformRef.current?.visible])

  if (cuboidAnchor?.points.length !== EDITOR_REQUIRED_ANCHORS.cuboid || !cuboidInfo) {
    return null
  }

  return (
    <>
      {/* ref must be any */}
      <TransformControls
        ref={transformRef as any}
        size={isTablet ? 1 : 0.75}
        onObjectChange={updateCuboid}
        space="local"
        enabled={!isLoading}
      />
      <group ref={groupRef as any} position={cuboidInfo.position} quaternion={cuboidInfo.quaternion}>
        <group ref={lineRef as any}>
          <lineSegments geometry={cuboidInfo.edgesGeometry}>
            <lineBasicMaterial color={EDITOR_FRAME_COLORS[EDITOR_TOOL_KEYS[selectedTool]]} />
          </lineSegments>
          {cuboidDirection && (
            <Frame framePoints={cuboidInfo.axisPoints[cuboidDirection]} color="yellow" needFillMissingPoint={false} />
          )}
        </group>
      </group>
    </>
  )
}
export default CuboidFrame
