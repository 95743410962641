import axios, { AxiosProgressEvent } from 'axios'

import { ERROR_PROCESS, processErrorHandler } from './ErrorHandler'

/**
 * ファイルアップロード
 * @param {string} url アップロード先URL
 * @param {string} content_type Content-Type
 * @param {File} file アップロード対象のファイル
 * @param {React.Dispatch<React.SetStateAction<string>> | null} setDownloadText アップロード中、進捗率設定ファンクション
 */
export const uploadFile = async (
  url: string,
  content_type: string,
  file: File,
  setUploadPercent: React.Dispatch<React.SetStateAction<number>> | ((value: number) => void) | null,
  showErrorModal: (message: string) => void,
): Promise<boolean> => {
  const result = await axios
    .put(url, file, {
      headers: {
        'Content-Type': content_type,
      },
      // アップロードパーセンテージ監視用
      onUploadProgress(progressEvent: AxiosProgressEvent) {
        if (progressEvent.total !== undefined) {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
          if (setUploadPercent) setUploadPercent(percentCompleted)
        }
      },
    })
    .then(() => true)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.CREATE_PROJECT, showErrorModal)
      return false
    })

  return result
}

/**
 * CADファイルをblob形式でダウンロード
 * @param {string} url ダウンロード先URL
 * @param {React.Dispatch<React.SetStateAction<string>> | null} setDownloadText ダウンロード中、表示テキスト設定ファンクション
 * @return {{blob: BlobPart[], type: string}} BlobPart[], type
 */
export const downloadCadFile = async (
  url: string,
  setProgress: React.Dispatch<React.SetStateAction<number>> | null,
  showErrorModal: (message: string) => void,
): Promise<{ blob: BlobPart[]; type: string } | null> => {
  const blob = await axios
    .get<Blob>(url, {
      responseType: 'blob',
      onDownloadProgress: (progressEvent: AxiosProgressEvent) => {
        if (progressEvent.total !== undefined) {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
          if (setProgress) setProgress(percentCompleted)
        }
      },
    })
    .then((response) => ({
      blob: [response.data],
      type: response.data.type,
    }))
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.DOWNLOAD_CAD_FILE, showErrorModal)
      return null
    })

  return blob
}
