/* istanbul ignore file */
const env = process.env.REACT_APP_ENV ? `_${process.env.REACT_APP_ENV}` : ''

const AUTH0_DOMAIN = process.env[`REACT_APP${env}_AUTH0_DOMAIN`]!
const AUTH0_CLIENT_ID = process.env[`REACT_APP${env}_AUTH0_CLIENT_ID`]!
const AUTH0_AUDIENCE = process.env[`REACT_APP${env}_AUTH0_AUDIENCE`]!
const API_GATEWAY_URL = process.env[`REACT_APP${env}_API_GATEWAY_URL`]!
const MIXPANEL_TOKEN = process.env[`REACT_APP${env}_MIXPANEL_TOKEN`]!
const APP_URL = process.env[`REACT_APP${env}_APP_URL`]!

export { AUTH0_DOMAIN, AUTH0_CLIENT_ID, AUTH0_AUDIENCE, API_GATEWAY_URL, MIXPANEL_TOKEN, APP_URL }
