import { useContext, useEffect } from 'react'

import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { RootState, useAppDispatch } from 'store/app'

import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import { USER_TYPES } from 'config/constants'

import { decideActionPermission } from 'services/Validation'

import { fetchInvitedProjects, fetchProjects, reset, setPermissionSet, setProjectsLoaded } from '../store/dashboard'

export const useDashboard = () => {
  // Search params
  const [searchParams] = useSearchParams()
  const targetUserId = searchParams.get('user_id') || sessionStorage.getItem('targetUserId') || undefined
  if (targetUserId) sessionStorage.setItem('targetUserId', targetUserId)

  // Store
  const dispatch = useAppDispatch()
  const userLoaded = useSelector((state: RootState) => state.user.userLoaded)
  const userType = useSelector((state: RootState) => state.user.userType)

  // Context
  const { getAccessToken } = useContext(UserContext)
  const { showErrorModal } = useContext(GlobalModalContext)

  /**
   * Get permission set for the dashboard
   */
  useEffect(() => {
    if (!userLoaded || !userType) return

    dispatch(
      setPermissionSet(
        decideActionPermission([USER_TYPES.PAYING_USER, USER_TYPES.BASE_USER, USER_TYPES.BETA_USER].includes(userType))
          .PROJECT_DASHBOARD,
      ),
    )

    void (async () => {
      const access_token = await getAccessToken()
      if (!access_token) return

      await Promise.all([
        dispatch(fetchProjects({ access_token, user_id: targetUserId, showErrorModal })),
        dispatch(fetchInvitedProjects({ access_token, user_id: targetUserId, showErrorModal })),
      ])

      dispatch(setProjectsLoaded(true))
    })()
  }, [userLoaded, userType, targetUserId, dispatch, getAccessToken, showErrorModal])

  /**
   * Reset store when unmounting
   */
  useEffect(
    () => () => {
      dispatch(reset())
    },
    [dispatch],
  )
}
