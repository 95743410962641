import React, { useCallback, useContext, useState } from 'react'

import {
  modifyComment,
  replaceComment,
  setEditingComment,
  setEditingCommentId,
  setIsCommentsLoading,
  setSelectedComment,
} from 'pages/projects/common/Comments/store/comments'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'

import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import { Comment, Position } from 'interfaces/interfaces'

import { editComment } from 'services/Comments'

export const useComment = (comment: Comment) => {
  // Local states
  const [initialPosition, setInitialPosition] = useState<Position>({
    x: comment.cartesian_position?.x || 0,
    y: comment.cartesian_position?.y || 0,
    z: comment.cartesian_position?.z || 0,
  })

  // Context
  const { getAccessToken } = useContext(UserContext)
  const { showErrorModal } = useContext(GlobalModalContext)

  // Store
  const dispatch = useAppDispatch()
  const project = useSelector((state: RootState) => state.page.project)
  const inspectionArea = useSelector((state: RootState) => state.page.inspectionArea)

  /**
   * On cancellation of moving comment.
   */
  const onCancelMove = useCallback(() => {
    dispatch(setEditingCommentId(null))
    dispatch(
      modifyComment({
        thread_id: comment.thread_id!,
        cartesian_position: comment.unplaced ? { x: 0, y: 0, z: 0 } : initialPosition,
      }),
    )
  }, [comment, dispatch, initialPosition])

  /**
   * Callback handler for handling saving of newly moved comment.
   */
  const onConfirmMove = useCallback(() => {
    if (!project || !inspectionArea) return

    dispatch(setIsCommentsLoading(true))
    void (async () => {
      if (!comment.cartesian_position) return

      const access_token = await getAccessToken()
      if (!access_token) {
        return
      }

      const result = await editComment(
        access_token,
        project.project_id,
        inspectionArea.inspection_area_id,
        comment,
        showErrorModal,
      )

      if (result) {
        dispatch(replaceComment({ thread_id: comment.thread_id!, comment: { ...result, images: comment.images } }))
        setInitialPosition(comment.cartesian_position)
        dispatch(setEditingCommentId(null))
        dispatch(setEditingComment(null))
      } else {
        onCancelMove()
      }
    })().finally(() => {
      dispatch(setIsCommentsLoading(false))
    })
  }, [comment, project, inspectionArea, dispatch, getAccessToken, showErrorModal, onCancelMove])
  /**
   * On click of comment icon.
   *
   * @param e Event
   * @returns
   */
  const onMouseUp = (e: React.MouseEvent) => {
    if (e.button !== 0) return
    e.stopPropagation()
    dispatch(setSelectedComment(comment))
    dispatch(setEditingCommentId(null))
  }

  return {
    onConfirmMove,
    onCancelMove,
    onMouseUp,
  }
}
