import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'

import { clamp } from 'lodash'
import {
  modifyComment,
  replaceComment,
  setEditingComment,
  setEditingCommentId,
  setSelectedComment,
} from 'pages/projects/common/Comments/store/comments'
import { useSelector } from 'react-redux'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { RootState, useAppDispatch } from 'store/app'

import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import { BLUEPRINT_COMMENT_AREA_MIN_SIZE, BLUEPRINT_VIEWIER_CONTAINER_ID, TEMP_COMMENT_ID } from 'config/constants'

import { Comment, Position2D, RectangleInBlueprint } from 'interfaces/interfaces'

import { editComment } from 'services/Comments'

export enum MODE {
  NONE,
  INITIAL_DRAW,
  MODIFY,
}

export enum RESIZE_CORNER {
  NONE,
  TOP_LEFT,
  BOTTOM_RIGHT,
}

const MAX_LOOP = 90

const MIN_FRAME_SIZE = 30

/**
 * A bit of a hack, we need to monitor the rendered page height to determine the
 * center of an unplaced comment. Simply having an effect for any ref values
 * or on load of blueprint will not fully render the Page yet so we can't get
 * actual width/height directly after those hooks/events.
 */
const positionUnplacedComment = (): Promise<void> =>
  new Promise((resolve) => {
    const pageElement = document.querySelector('.react-pdf__Page')
    let height = 0
    let count = 0

    if (pageElement) {
      const loop = () => {
        count += 1

        if (pageElement.clientHeight !== height && count < MAX_LOOP) {
          height = pageElement.clientHeight
          setTimeout(loop, 33) // 30fps
        } else {
          resolve()
        }
      }
      loop()
      return
    }

    resolve()
  })

export const useCommentFrame = (comment: Comment) => {
  // Get IDs from the URL
  const { project_id } = useParams<{ project_id: string }>()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  // If comment is valid, use the extent from the comment, if they're unplaced comments, use the minimum size.
  const initSize = useMemo(
    () => ({
      width: comment.blueprint_position?.extent.width || BLUEPRINT_COMMENT_AREA_MIN_SIZE,
      height: comment.blueprint_position?.extent.height || BLUEPRINT_COMMENT_AREA_MIN_SIZE,
    }),
    [comment.blueprint_position?.extent],
  )

  // Local vars
  const isTemp = comment.thread_id === TEMP_COMMENT_ID
  const initMode = isTemp ? MODE.INITIAL_DRAW : MODE.NONE
  const unplaced = useMemo(() => !comment.blueprint_id, [comment.blueprint_id])

  // Contexts
  const { getAccessToken } = useContext(UserContext)
  const { showErrorModal } = useContext(GlobalModalContext)

  // Store
  const dispatch = useAppDispatch()
  const scale = useSelector((state: RootState) => state.blueprint.scale)
  const selectedBlueprint = useSelector((state: RootState) => state.blueprint.selectedBlueprint)
  const editingCommentId = useSelector((state: RootState) => state.comments.editingCommentId)
  const selectedComment = useSelector((state: RootState) => state.comments.selectedComment)
  const inspectionAreas = useSelector((state: RootState) => state.page.inspectionAreas)

  // If comment is valid, use the position from the comment, if they're unplaced comments, default to 0, to be calculated later.
  // This is a state and not a memo because the initial position of an unplaced comment can only be calculated
  // after rendering, which we can't do at this stage. They will be calculated later and set to this state.
  const [initPosition, setInitPosition] = useState<Position2D>({
    x: comment.blueprint_position?.coordinate.x || 0,
    y: comment.blueprint_position?.coordinate.y || 0,
  })

  // States
  const [mode, setMode] = useState<MODE>(initMode)
  const [position, setPosition] = useState<Position2D>({ ...initPosition })
  const [extent, setExtent] = useState<{ width: number; height: number }>({ ...initSize })
  const [dragMouseStartPosition, setDragMouseStartPosition] = useState<Position2D>({ ...initPosition })
  const [dragOriginalPosition, setDragOriginalPosition] = useState<Position2D | null>(
    isTemp ? { ...initPosition } : null,
  )
  const [containerBounds, setContainerBounds] = useState<DOMRect | null>(null)
  const [resizeCorner, setResizeCorner] = useState<RESIZE_CORNER>(RESIZE_CORNER.NONE)
  const [loading, setLoading] = useState(false)
  const [isTooltipOpen, setIsTooltipOpen] = useState(false)

  // Element refs
  const containerRef = useRef<HTMLDivElement>(null)
  const frameRef = useRef<HTMLDivElement>(null)

  // Flags
  const isDisabled = (!!editingCommentId && editingCommentId !== comment.thread_id) || loading
  const isViewComment = selectedComment?.thread_id === comment.thread_id
  const isEditing = mode !== MODE.NONE

  const isHoldingPlace = false
  const blueprintScale = scale / 100
  const frameId = comment.thread_id || 'temp-comment'

  /**
   * Save the container bounds for later use.
   */
  const updateContainerBounds = useCallback(() => {
    const container = document.querySelector(BLUEPRINT_VIEWIER_CONTAINER_ID)
    if (container) {
      const bounds = container.getBoundingClientRect()
      setContainerBounds(bounds)
      return bounds
    }
    return null
  }, [])

  useEffect(() => {
    updateContainerBounds()
  }, [updateContainerBounds])

  /**
   * When in editing mode, scaling need to applied manually when being adjusted.
   * When not in editing mode, scaling is applied on react (check the component).
   */
  useEffect(() => {
    if (mode === MODE.NONE) return

    if (containerRef.current && frameRef.current) {
      containerRef.current.style.left = `${position.x * blueprintScale}px`
      containerRef.current.style.top = `${position.y * blueprintScale}px`
      frameRef.current.style.width = `${extent.width * blueprintScale}px`
      frameRef.current.style.height = `${extent.height * blueprintScale}px`
    }
    // Following is explicitly disabled because we only want to re-run
    // on change of scale, not the other vars being used.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scale, blueprintScale])

  /**
   * If the comment is an unplaced one, set the initial position
   * to the center of the first page of the blueprint.
   */
  useEffect(() => {
    if (!unplaced || !containerBounds) return

    void positionUnplacedComment().then(() => {
      const pageBounds = document
        .querySelector(BLUEPRINT_VIEWIER_CONTAINER_ID)
        ?.firstElementChild?.getBoundingClientRect()

      if (pageBounds) {
        const pos = {
          x: pageBounds.width / 2 - initSize.width / 2 + (comment.blueprint_position?.coordinate.x || 0),
          y: pageBounds.height / 2 - initSize.height / 2 + (comment.blueprint_position?.coordinate.y || 0),
        }
        setInitPosition(pos)
        setPosition(pos)
      }
    })
  }, [containerBounds, initSize, unplaced, comment])

  /**
   * Sets the initial position of the frame and the mouse position
   * to initialize frame adjustments.
   */
  const initMouseDown = useCallback(
    (pos: Position2D) => {
      setDragOriginalPosition({ ...position })

      const bounds = updateContainerBounds()
      setDragMouseStartPosition({ x: pos.x - (bounds?.x || 0), y: pos.y - (bounds?.y || 0) })
    },
    [position, updateContainerBounds],
  )

  /**
   * On mouse down of the frame itself.
   *
   * @param event Mouse event
   */
  const handleBoxStartDrag = useCallback(
    (pos: Position2D) => {
      if (mode !== MODE.MODIFY) return

      initMouseDown(pos)
    },
    [mode, initMouseDown],
  )

  /**
   * Starts adjustment of frame for resizing frame.
   */
  const handleBoxStartResize = useCallback(
    (pos: Position2D, corner: RESIZE_CORNER) => {
      if (mode === MODE.NONE) return
      setResizeCorner(corner)
      initMouseDown(pos)
    },
    [mode, initMouseDown],
  )

  /**
   * Get the latest position and size of the frame.
   */
  const getLatestRect = useCallback((): RectangleInBlueprint => {
    if (!frameRef?.current || !containerRef.current || !containerBounds) {
      return {
        coordinate: { x: 0, y: 0 },
        extent: { width: 0, height: 0 },
      }
    }

    const frameBounds = frameRef.current.getBoundingClientRect()

    return {
      coordinate: { x: frameBounds.x - containerBounds.x, y: frameBounds.y - containerBounds.y },
      extent: { width: frameBounds.width, height: frameBounds.height },
    }
  }, [containerBounds])

  /**
   * Finalize the frame modification by saving the comment into store (not DB) and resetting states.
   *
   * @param newPosition New position of the frame
   * @param newExtent New extent of the frame
   */
  const finalizeModification = useCallback(
    (newPosition: Position2D, newExtent: { width: number; height: number }) => {
      if (isTemp) {
        dispatch(
          setEditingComment({
            ...comment,
            blueprint_position: {
              coordinate: newPosition,
              extent: newExtent,
            },
          }),
        )
      } else {
        dispatch(
          modifyComment({
            blueprint_position: {
              coordinate: newPosition,
              extent: newExtent,
            },
          }),
        )
      }

      setMode(MODE.NONE)
      dispatch(setEditingCommentId(''))

      // Remove the property to let react take over the style
      if (containerRef.current && frameRef.current) {
        containerRef.current.style.removeProperty('left')
        containerRef.current.style.removeProperty('top')
        frameRef.current.style.removeProperty('width')
        frameRef.current.style.removeProperty('height')
      }
    },
    [dispatch, comment, containerRef, frameRef, isTemp],
  )

  /**
   * User had cancel the modification of the frame, reset the frame to its original position.
   */
  const onCancelMove = useCallback(() => {
    if (!containerRef.current || !frameRef.current) {
      return
    }

    setPosition(initPosition)
    setExtent(initSize)
    setMode(MODE.NONE)
    dispatch(setSelectedComment(null))
    dispatch(setEditingCommentId(''))
    navigate({ search: searchParams.toString() }, { replace: true })

    // Remove the property to let react take over the style
    containerRef.current.style.removeProperty('left')
    containerRef.current.style.removeProperty('top')
    frameRef.current.style.removeProperty('width')
    frameRef.current.style.removeProperty('height')
  }, [dispatch, navigate, initPosition, initSize, searchParams])

  /**
   * User had confirm the modification of the frame, save the updated frame into DB.
   */
  const onConfirmMove = useCallback(() => {
    if (!project_id || !comment.inspection_area_id) return

    dispatch(setSelectedComment(null))

    void (async () => {
      setLoading(true)
      const access_token = await getAccessToken()
      if (!access_token) {
        return
      }

      // All stored values are unscaled, so we need to scale it back to the unscaled size
      const rect = getLatestRect()
      const unscaledCoordinate = { x: rect.coordinate.x / blueprintScale, y: rect.coordinate.y / blueprintScale }
      const unscaledExtent = { width: rect.extent.width / blueprintScale, height: rect.extent.height / blueprintScale }

      const result = await editComment(
        access_token,
        project_id,
        comment.inspection_area_id,
        {
          ...comment,
          blueprint_id: selectedBlueprint?.blueprint_id || '', // For unplaced comments, we need to set the blueprint_id
          blueprint_position: { coordinate: unscaledCoordinate, extent: unscaledExtent },
        },
        showErrorModal,
      )
      setLoading(false)

      if (result) {
        dispatch(replaceComment({ thread_id: comment.thread_id!, comment: result }))
        finalizeModification(unscaledCoordinate, unscaledExtent)
      } else {
        onCancelMove()
      }
    })()
  }, [
    comment,
    project_id,
    selectedBlueprint,
    blueprintScale,
    dispatch,
    getAccessToken,
    getLatestRect,
    showErrorModal,
    finalizeModification,
    onCancelMove,
  ])

  /**
   * End of interaction such as mouse up or touch end.
   */
  const onInteractionEnd = useCallback(() => {
    if (
      (mode === MODE.NONE && resizeCorner === RESIZE_CORNER.NONE) ||
      !containerBounds ||
      !dragOriginalPosition ||
      !frameRef?.current
    )
      return

    const rect = getLatestRect()

    // All stored values are unscaled, so we need to scale it back to the unscaled size
    const unscaledCoordinate = { x: rect.coordinate.x / blueprintScale, y: rect.coordinate.y / blueprintScale }
    const unscaledExtent = { width: rect.extent.width / blueprintScale, height: rect.extent.height / blueprintScale }

    setPosition(unscaledCoordinate)
    setExtent(unscaledExtent)
    setDragOriginalPosition(null)
    setResizeCorner(RESIZE_CORNER.NONE)

    // Only finalize frame modification if we're creating a new frame (initial draw mode)
    if (mode === MODE.INITIAL_DRAW) {
      finalizeModification(unscaledCoordinate, unscaledExtent)
    }
  }, [
    resizeCorner,
    mode,
    dragOriginalPosition,
    containerBounds,
    blueprintScale,
    frameRef,
    getLatestRect,
    finalizeModification,
  ])

  /**
   * Mouse up.
   */
  const onWindowMouseUp = useCallback(
    (e: MouseEvent) => {
      // if any other button than left mouse button, reset.
      if (e.button !== 0 && isTemp && mode === MODE.INITIAL_DRAW) {
        dispatch(setEditingComment(null))
        dispatch(setEditingCommentId(null))
        setMode(MODE.NONE)
        setResizeCorner(RESIZE_CORNER.NONE)
        return
      }

      onInteractionEnd()
    },
    [onInteractionEnd, dispatch, isTemp, mode],
  )

  /**
   * Move the frame around the blueprint.
   */
  const moveBox = useCallback(
    (delta: Position2D) => {
      if (!containerRef?.current || !containerBounds || !dragOriginalPosition) {
        return
      }

      // The new position is old position + delta of mouse movement from the moment user clicked
      // A further Min/Max is required as we need to constrain the frame within the blueprint.
      const newPosition = {
        x: dragOriginalPosition.x * blueprintScale + delta.x,
        y: dragOriginalPosition.y * blueprintScale + delta.y,
      }
      if (newPosition.x !== position.x && newPosition.y !== position.y) {
        containerRef.current.style.left = `${Math.max(
          Math.min(newPosition.x, containerBounds.width - extent.width),
          0,
        )}px`
        containerRef.current.style.top = `${Math.max(newPosition.y, 0)}px`
      }
    },
    [position, containerBounds, dragOriginalPosition, extent, blueprintScale],
  )

  /**
   * Resize the frame from the top left corner handle.
   */
  const resizeFromTopLeft = useCallback(
    (delta: Position2D) => {
      if (!containerRef?.current || !frameRef.current || !containerBounds || !dragOriginalPosition) {
        return
      }

      // The new size is old size - delta of mouse movement from the moment user clicked
      // towards the top and left side of the box
      const newWidth = Math.max(MIN_FRAME_SIZE, extent.width * blueprintScale - delta.x)
      const newHeight = Math.max(MIN_FRAME_SIZE, extent.height * blueprintScale - delta.y)

      if (newWidth > MIN_FRAME_SIZE) {
        frameRef.current.style.width = `${newWidth}px`
        containerRef.current.style.left = `${dragOriginalPosition.x * blueprintScale + delta.x}px`
      }

      if (newHeight > MIN_FRAME_SIZE) {
        frameRef.current.style.height = `${newHeight}px`
        containerRef.current.style.top = `${dragOriginalPosition.y * blueprintScale + delta.y}px`
      }
    },
    [containerBounds, dragOriginalPosition, extent, blueprintScale],
  )

  /**
   * Resize the frame from the bottom right corner handle.
   */
  const resizeFromBottomRight = useCallback(
    (delta: Position2D) => {
      if (!containerRef?.current || !frameRef.current) {
        return
      }

      // The new size is old size + delta of mouse movement from the moment user clicked
      // towards the bottom and right side of the box
      const newWidth = Math.max(MIN_FRAME_SIZE, extent.width * blueprintScale + delta.x)
      const newHeight = Math.max(MIN_FRAME_SIZE, extent.height * blueprintScale + delta.y)

      frameRef.current.style.width = `${newWidth}px`
      frameRef.current.style.height = `${newHeight}px`
    },
    [extent, blueprintScale],
  )

  /**
   * Keep track of mouse movement and adjusts frame based on which mode we're in.
   */
  const onWindowMouseMove = useCallback(
    (e: MouseEvent) => {
      if (!containerBounds || (mode === MODE.NONE && resizeCorner === RESIZE_CORNER.NONE)) {
        return
      }

      // Constrain the frame adjustments to the container bounds.
      // Only horizontal constraint as the document can be multiple pages going over the height of the container.
      const deltaX =
        clamp(e.clientX, containerBounds.left, containerBounds.right) - dragMouseStartPosition.x - containerBounds.x
      const deltaY = e.clientY - dragMouseStartPosition.y - containerBounds.y

      if (resizeCorner === RESIZE_CORNER.TOP_LEFT) {
        resizeFromTopLeft({ x: deltaX, y: deltaY })
      } else if (resizeCorner === RESIZE_CORNER.BOTTOM_RIGHT || mode === MODE.INITIAL_DRAW) {
        resizeFromBottomRight({ x: deltaX, y: deltaY })
      } else if (mode === MODE.MODIFY) {
        moveBox({ x: deltaX, y: deltaY })
      }
    },
    [containerBounds, mode, resizeCorner, dragMouseStartPosition, moveBox, resizeFromTopLeft, resizeFromBottomRight],
  )

  /**
   * Keep track of touch movement and adjusts frame based on which mode we're in.
   */
  const onWindowTouchMove = useCallback(
    (e: TouchEvent) => {
      if (!containerBounds || (mode === MODE.NONE && resizeCorner === RESIZE_CORNER.NONE)) {
        return
      }

      // Constrain the frame adjustments to the container bounds.
      // Only horizontal constraint as the document can be multiple pages going over the height of the container.
      const deltaX =
        clamp(e.touches[0].clientX, containerBounds.left, containerBounds.right) -
        dragMouseStartPosition.x -
        containerBounds.x
      const deltaY = e.touches[0].clientY - dragMouseStartPosition.y - containerBounds.y

      if (resizeCorner === RESIZE_CORNER.TOP_LEFT) {
        resizeFromTopLeft({ x: deltaX, y: deltaY })
      } else if (resizeCorner === RESIZE_CORNER.BOTTOM_RIGHT || mode === MODE.INITIAL_DRAW) {
        resizeFromBottomRight({ x: deltaX, y: deltaY })
      } else if (mode === MODE.MODIFY) {
        moveBox({ x: deltaX, y: deltaY })
      }
    },
    [containerBounds, mode, resizeCorner, dragMouseStartPosition, moveBox, resizeFromTopLeft, resizeFromBottomRight],
  )

  /**
   * On click of the frame, set the comment/frame as selected to display the comment.
   */
  const onClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation()
      if (isDisabled || mode !== MODE.NONE) return

      setIsTooltipOpen(false)
      dispatch(setSelectedComment(comment))
      navigate(
        {
          search: searchParams.toString(),
          hash: `comments-${comment.thread_id || ''}`,
        },
        { replace: true },
      )
    },
    [isDisabled, mode, comment, navigate, dispatch, searchParams],
  )

  /**
   * Closing of comment ppopup
   */
  const onCloseCommentPopup = useCallback(() => {
    dispatch(setSelectedComment(null))
    setIsTooltipOpen(false)
    navigate(
      {
        search: searchParams.toString(),
      },
      { replace: true },
    )
  }, [dispatch, navigate, searchParams])

  /**
   * User initiated modification of frame.
   */
  const onStartModify = () => {
    setMode(MODE.MODIFY)
    dispatch(setEditingCommentId(comment.thread_id!))
  }

  /**
   * On end of touch event.
   */
  const onTouchEnd = useCallback(() => onInteractionEnd(), [onInteractionEnd])

  /**
   * Keep track of mouse movement from the window level as we need to keep listening even if
   * user had moved the mouse outside of the frame or blueprint container.
   *
   * Will also keep track of mouseup on window level so that if user had moved the mouse outside
   * of the frame or blueprint container, we can still stop the adjustment.
   */
  useEffect(() => {
    window.addEventListener('mousemove', onWindowMouseMove)
    window.addEventListener('mouseup', onWindowMouseUp)

    window.addEventListener('touchmove', onWindowTouchMove)
    window.addEventListener('touchend', onTouchEnd)

    return () => {
      window.removeEventListener('mousemove', onWindowMouseMove)
      window.removeEventListener('mouseup', onWindowMouseUp)

      window.removeEventListener('touchend', onTouchEnd)
      window.removeEventListener('touchmove', onWindowTouchMove)
    }
  }, [onWindowMouseMove, onWindowMouseUp, onWindowTouchMove, onTouchEnd])

  return {
    frameId,

    // flags
    unplaced,
    isHoldingPlace,
    isDisabled,
    mode,
    isViewComment,
    isEditing,
    isTooltipOpen,
    setIsTooltipOpen,

    // states
    frameSize: extent,
    blueprintScale,
    position,
    scale,
    selectedComment,

    // Name  of the inspection area for this comment. Will be empty if not in inspection area specific page.
    inspectionAreaName: inspectionAreas.find((row) => comment.inspection_area_id === row.inspection_area_id)
      ?.inspection_area_name,

    // event handlers
    onStartModify,
    onCancelMove,
    onConfirmMove,
    onClick,
    onCloseCommentPopup,

    // frame modification handlers
    handleBoxStartDrag,
    handleBoxStartResize,

    // refs
    containerRef,
    frameRef,
  }
}
