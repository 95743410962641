import { FC, useRef, useState } from 'react'

import { ProjectRowEvents } from 'project-dashboard-library/dist/components/projectTable/molecules/ProjectRow'
import ProjectTable from 'project-dashboard-library/dist/components/projectTable/organisms/ProjectTable'
import { InspectionArea as PDFLInspectionArea } from 'project-dashboard-library/dist/interfaces/inspectionArea'
import { Project as PDLProject } from 'project-dashboard-library/dist/interfaces/project'
import { useSelector } from 'react-redux'
import { RootState } from 'store/app'

import { USER_TYPES } from 'config/constants'

import { InspectionArea, Project } from 'interfaces/interfaces'

import InspectionAreaFormModal from './components/InspectionAreaFormModal'
import InviteUserModal from './components/InviteUserModal'
import ProjectFormModal from './components/ProjectFormModal'
import { useProjectsTable } from './hooks/projectsTable'

const ProjectsTable: FC = () => {
  // Store
  const permissionSet = useSelector((state: RootState) => state.dashboard.permissionSet)
  const userType = useSelector((state: RootState) => state.user.userType)
  const projects = useSelector((state: RootState) => state.dashboard.projects)
  const invitedProjects = useSelector((state: RootState) => state.dashboard.invitedProjects)
  const projectsLoaded = useSelector((state: RootState) => state.dashboard.projectsLoaded)

  // Project Table
  const {
    goToBlueprints,
    goToInspectionSheet,
    handleProjectDelete,
    InspectionAreaFormModalRef,
    onProjectUpdated,
    openInspectionAreaFormModal,
    renderGroupHeader,
    confirmInspectionAreaDelete,
  } = useProjectsTable()

  // States
  const [invitingProject, setInvitingProject] = useState<PDLProject>()
  const [updatingFileProject] = useState<Project>()

  // Refs
  const projectFormModalRef = useRef<{ openModal: (project: Project) => void }>()

  // Permission check
  const isAllowedToBrowseProject = permissionSet.BROWSE.includes(userType)

  // Action handlers depend on permission set.
  const projectEventHandlers: ProjectRowEvents = {
    onInvite: permissionSet.INVITE_USER_TO_PROJECT.includes(userType) ? setInvitingProject : undefined,
    onEdit: permissionSet.MODIFY.includes(userType)
      ? (prj: PDLProject) => {
          projectFormModalRef.current?.openModal(prj as Project)
        }
      : undefined,
    onDelete: permissionSet.MODIFY.includes(userType) ? handleProjectDelete : undefined,
  }
  const inspectionAreaEventHandlers = {
    onCreate: () =>
      permissionSet.MODIFY.includes(userType)
        ? (project: PDLProject, inspectionAreas: PDFLInspectionArea[], onSaved: () => Promise<void>) =>
            openInspectionAreaFormModal(project, undefined, inspectionAreas as InspectionArea[], onSaved)
        : undefined,
    onEdit: () =>
      permissionSet.MODIFY.includes(userType)
        ? (
            project: PDLProject,
            inspectionArea: PDFLInspectionArea,
            inspectionAreas: PDFLInspectionArea[],
            onSaved: () => Promise<void>,
          ) =>
            openInspectionAreaFormModal(
              project,
              inspectionArea as InspectionArea,
              inspectionAreas as InspectionArea[],
              onSaved,
            )
        : undefined,
    onDelete: () =>
      permissionSet.MODIFY.includes(userType)
        ? (project: PDLProject, inspectionArea: PDFLInspectionArea, onDeleted: () => Promise<void>) =>
            confirmInspectionAreaDelete(project, inspectionArea as InspectionArea, onDeleted)
        : undefined,
  }

  if (!projectsLoaded) {
    return <p>読み込み中…</p>
  }

  return (
    <>
      {isAllowedToBrowseProject && renderGroupHeader(`作成した工事${!projects.length ? ' (0)' : ''}`)}
      {isAllowedToBrowseProject && (
        <ProjectTable
          projects={projects}
          isLoading={!projectsLoaded}
          isInvited={userType === USER_TYPES.BASE_USER}
          projectEventHandlers={projectEventHandlers}
          inspectionAreaEventHandlers={inspectionAreaEventHandlers}
          additionalProjectMenuItems={[
            {
              label: '設計図面',
              onClick: goToBlueprints,
            },
            {
              label: '帳票詳細',
              onClick: goToInspectionSheet,
            },
          ]}
        />
      )}

      {renderGroupHeader(`招待された工事${!invitedProjects.length ? ' (0)' : ''}`)}
      {projectsLoaded && invitedProjects.length ? (
        <ProjectTable
          projects={invitedProjects}
          isLoading={!projectsLoaded}
          isInvited
          additionalProjectMenuItems={[
            {
              label: '設計図面',
              onClick: goToBlueprints,
            },
            {
              label: '帳票詳細',
              onClick: goToInspectionSheet,
            },
          ]}
        />
      ) : (
        ''
      )}

      <InviteUserModal
        isOpen={!!invitingProject}
        invitingProject={invitingProject as Project}
        onConfirm={() => setInvitingProject(undefined)}
      />
      <ProjectFormModal
        ref={projectFormModalRef}
        onProjectCreated={onProjectUpdated}
        selectedProject={updatingFileProject}
      />
      <InspectionAreaFormModal ref={InspectionAreaFormModalRef} onSaved2={() => null} />
    </>
  )
}

export default ProjectsTable
