import { FC, useCallback, useContext, useState } from 'react'

import { Flex, Text, VStack } from '@chakra-ui/react'
import mixpanel from 'mixpanel-browser'
import { setAttentionText } from 'pages/projects/common/AttentionText/store/attentionText'
import CollapsePanel from 'pages/projects/editor/infoPanels/components/CollapsePanel'
import LayerItem from 'pages/projects/editor/infoPanels/components/LayerItem'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'

import { EditorContext } from 'contexts/Editor'
import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import { EDITOR_COLLAPSE_TYPES, MODAL_TYPES } from 'config/constants'
import { INFO_PANEL_PADDING } from 'config/styles'

import { Cuboid } from 'interfaces/shape'

import { deleteMaskingRegion } from 'services/MaskingRegion'

import { removeMaskRegion, setMaskRegions, updateMaskRegion } from '../../store'

const MaskingRegionsPanel: FC = () => {
  // Store
  const dispatch = useAppDispatch()
  const maskRegions = useSelector((state: RootState) => state.maskPCD.regions)
  const permissionSet = useSelector((state: RootState) => state.editor.permissionSet)
  const project = useSelector((state: RootState) => state.page.project)
  const inspectionArea = useSelector((state: RootState) => state.page.inspectionArea)
  const userType = useSelector((state: RootState) => state.user.userType)

  // Context
  const { getAccessToken } = useContext(UserContext)
  const { isLayerModifying } = useContext(EditorContext)
  const { showModal, showErrorModal } = useContext(GlobalModalContext)

  const [isCollapseOpen, setIsCollapseOpen] = useState(true)

  // Permission check
  const isAllowedToModify = permissionSet.MODIFY.includes(userType)

  /**
   * Handle deletion
   */
  const handleDelete = useCallback(
    (region: Cuboid) => {
      if (!project || !inspectionArea) {
        return
      }

      showModal({
        title: '注目領域',
        body: <Text>一度削除してしまうと、元に戻せません。</Text>,
        confirmText: '削除',
        modalType: MODAL_TYPES.CONFIRMATION_CRITICAL,
        onConfirmPromised: async () => {
          const token = await getAccessToken()
          if (!token) {
            return false
          }

          dispatch(setAttentionText({ message: 'データを更新中...' }))

          const result = await deleteMaskingRegion(
            token,
            project.project_id,
            inspectionArea.inspection_area_id,
            region.region_id,
            showErrorModal,
          )

          dispatch(setAttentionText({ message: '' }))

          if (!result) {
            return false
          }

          mixpanel.track('Deleted mask region', {
            'Number of mask region': maskRegions.length - 1,
          })

          dispatch(removeMaskRegion(region.region_id))
          return true
        },
      })
    },
    [project, inspectionArea, maskRegions.length, getAccessToken, dispatch, showModal, showErrorModal],
  )

  /**
   * Update visibility of all mode value's visibility
   * @param {DepthType} depthType The object should contain the new value of visibility
   */
  const updateAllLayerVisibility = useCallback(
    (visibility: boolean) => {
      dispatch(setMaskRegions(maskRegions.map((region) => ({ ...region, invisible: visibility }))))
    },
    [maskRegions, dispatch],
  )

  /**
   * Update specfic mode value's visibility
   * @param {boolean} visibility New visibility
   * @param {number} index Array index of the mode
   */
  const updateLayerVisibility = useCallback(
    (visibility: boolean, cuboid: Cuboid) => {
      dispatch(updateMaskRegion({ ...cuboid, invisible: visibility }))
    },
    [dispatch],
  )

  if (maskRegions.length === 0) {
    return null
  }

  return (
    <Flex
      backgroundColor="gray.800"
      borderBottomLeftRadius="md"
      borderTopLeftRadius="md"
      w="100%"
      flex={1}
      minH={isCollapseOpen ? 20 : 10}
      data-testid="masking-regions-panel"
    >
      <CollapsePanel title="注目領域" type={EDITOR_COLLAPSE_TYPES.maskRegion} onChange={setIsCollapseOpen}>
        <VStack w="100%" spacing={0} pb={INFO_PANEL_PADDING - 1} overflowY="auto">
          <LayerItem
            disabled={isLayerModifying}
            invisible={maskRegions.filter((region) => !region.invisible).length > 0}
            label="すべての点を表示"
            updateVisibility={(invisible) => updateAllLayerVisibility(!invisible)}
          />
          {maskRegions.map((region, index) => (
            <LayerItem
              disabled={isLayerModifying}
              selected={false}
              invisible={region.invisible}
              key={`cuboid-listitem-${region.region_id}}`}
              label={`注目領域 ${index + 1}`}
              updateVisibility={(invisible) => updateLayerVisibility(invisible, region)}
              deleteLayer={isAllowedToModify ? () => handleDelete(region) : undefined}
              isChild
            />
          ))}
        </VStack>
      </CollapsePanel>
    </Flex>
  )
}

export default MaskingRegionsPanel
