import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export interface DebugState {
  /**
   * Debug messages.
   */
  messages: { text: string; timestamp: number }[]
}

const initialState: DebugState = {
  messages: [],
}

export const slice = createSlice({
  name: 'debugPanel',
  initialState,
  reducers: {
    addMessage: (state, action: PayloadAction<string>) => {
      state.messages.push({ text: action.payload, timestamp: Date.now() })
    },
    clearMessages: (state) => {
      state.messages = []
    },
  },
})

export const { addMessage, clearMessages } = slice.actions

export default slice.reducer
