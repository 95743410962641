import { useSelector } from 'react-redux'
import { RootState } from 'store/app'

import { DistanceLabelProps } from 'interfaces/canvas'
import { CanvasConfig } from 'interfaces/editor'

import { getDepthTypeColor } from 'services/DepthEstimation'
import { meterToMilimeterWhole } from 'services/Util'

export default function useMainCanvas(): CanvasConfig {
  // Store
  const depthEstimationTypes = useSelector((state: RootState) => state.toolDepthEstimation.depthEstimationTypes)

  return {
    objects: {
      distanceLabels: [
        // Old items have no plane_side so treat the first plane to cylinder as Type 1
        ...(depthEstimationTypes[0].inspectionItems && !depthEstimationTypes[0].invisible
          ? depthEstimationTypes[0].inspectionItems
              .map<DistanceLabelProps[]>((item) => {
                const distances = item.plane_to_cylinders_distance?.individual_distances
                if (!distances) return []

                return distances.map<DistanceLabelProps>((distance, index) => ({
                  id: `${item.inspection_item_id}-${index}`,
                  label: `${meterToMilimeterWhole(distance.distance)}mm`,
                  points: distance.positions_for_distance,
                  anchorProps: {
                    color: '#777',
                  },
                  labelBgColor: getDepthTypeColor(1),
                  labelTextColor: '#fff',
                }))
              })
              .flat()
              .filter(Boolean)
          : []),
        // If there's another plane to cylinder different from found on Type 1, then that shall be Type 2
        ...(depthEstimationTypes[1].inspectionItems && !depthEstimationTypes[1].invisible
          ? depthEstimationTypes[1].inspectionItems
              .map<DistanceLabelProps[]>((item) => {
                const distances = item.plane_to_cylinders_distance?.individual_distances
                if (!distances) return []

                return distances.map<DistanceLabelProps>((distance, index) => ({
                  id: `${item.inspection_item_id}-${index}`,
                  label: `${meterToMilimeterWhole(distance.distance)}mm`,
                  points: distance.positions_for_distance,
                  anchorProps: {
                    color: '#777',
                  },
                  labelBgColor: getDepthTypeColor(2),
                  labelTextColor: '#fff',
                }))
              })
              .flat()
              .filter(Boolean)
          : []),
        // Top side
        ...(depthEstimationTypes[2].inspectionItems && !depthEstimationTypes[2].invisible
          ? depthEstimationTypes[2].inspectionItems
              .map<DistanceLabelProps[]>((item) => {
                const distances = item.plane_to_cylinders_distance?.individual_distances
                if (!distances) return []

                return distances.map<DistanceLabelProps>((distance, index) => ({
                  id: `${item.inspection_item_id}-${index}`,
                  label: `${meterToMilimeterWhole(distance.distance)}mm`,
                  points: distance.positions_for_distance,
                  anchorProps: {
                    color: '#777',
                  },
                  labelBgColor: getDepthTypeColor(3),
                  labelTextColor: '#fff',
                }))
              })
              .flat()
              .filter(Boolean)
          : []),
        // Bottom side
        ...(depthEstimationTypes[3].inspectionItems && !depthEstimationTypes[3].invisible
          ? depthEstimationTypes[3].inspectionItems
              .map<DistanceLabelProps[]>((item) => {
                const distances = item.plane_to_cylinders_distance?.individual_distances
                if (!distances) return []

                return distances.map<DistanceLabelProps>((distance, index) => ({
                  id: `${item.inspection_item_id}-${index}`,
                  label: `${meterToMilimeterWhole(distance.distance)}mm`,
                  points: distance.positions_for_distance,
                  anchorProps: {
                    color: '#777',
                  },
                  labelBgColor: getDepthTypeColor(4),
                }))
              })
              .flat()
              .filter(Boolean)
          : []),
      ],
    },
  }
}
