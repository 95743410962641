import { ToolbarPlaneIcon } from 'assets/icons'

import { EDITOR_TOOLS, EDITOR_TOOLS_LABELS, USER_TYPES } from 'config/constants'

import { EditorTool, ToolbarCategory } from 'interfaces/editor'
import { PermissionSets } from 'interfaces/validation'

import useEditor from './hooks/useEditor'
import useMainCanvas from './hooks/useMainCanvas'

const RebarDetection: EditorTool = {
  key: EDITOR_TOOLS.PLANE,
  authCheck: (permissionSets: PermissionSets, userType: keyof typeof USER_TYPES) =>
    permissionSets.MAIN_CANVAS.DETECT.includes(userType),
  toolbar: {
    icon: <ToolbarPlaneIcon width="50%" height="50%" />,
    label: EDITOR_TOOLS_LABELS.PLANE,
    category: ToolbarCategory.Generate,
  },
  components: {
    panel: [],
  },
  hooks: {
    useEditor,
    useMainCanvas,
  },
  config: {
    anchor: {
      notPlaceableOnObjects: true,
    },
  },
}

export default RebarDetection
