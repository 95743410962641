/* eslint-disable prefer-const */
//* config, interfaces, services
import {
  Box3,
  BoxGeometry,
  Camera,
  EdgesGeometry,
  Float32BufferAttribute,
  Intersection,
  Line3,
  Matrix3,
  Matrix4,
  Object3D,
  Object3DEventMap,
  Plane,
  Points,
  Quaternion,
  Raycaster,
  Vector2,
  Vector3,
} from 'three'

import { EDITOR_CUBOID_DIRECTIONS, EDITOR_SHAPE_KEYS } from 'config/constants'

import {
  Cuboid,
  CuboidDirection,
  CylinderDetectionResult,
  PointArray,
  PolygonDetectionResult,
  ShapeDetectionResult,
  ShapeKey,
} from 'interfaces/interfaces'

import { roundNumber } from './Util'

/**
 * 選択された点のポジション情報を返す
 * @param {Points} point 点群データ
 * @param {number} pointIndex 選択された点の要素番号
 * @return {PointArray} [number, number, number]
 */
export const createSelectedPoint = (
  points: Points,
  intersection: Intersection<Object3D<Object3DEventMap>>,
): PointArray | undefined => {
  if (intersection.index !== undefined && intersection.index >= 0) {
    const position: Float32BufferAttribute = points.geometry.getAttribute('position') as Float32BufferAttribute

    //* 選択座標は小数点以下6桁に変換
    return [
      roundNumber(position.getX(intersection.index), '0.000001'),
      roundNumber(position.getY(intersection.index), '0.000001'),
      roundNumber(position.getZ(intersection.index), '0.000001'),
    ]
  }

  if (intersection.faceIndex !== undefined && intersection.faceIndex >= 0) {
    return intersection.point.toArray().map((point) => roundNumber(point, '0.000001')) as [number, number, number]
  }

  return undefined
}

/**
 * 点群の表示パラメータを設定
 * @param {boolean} isVisible true: 表示, false: 非表示
 * @return {Points} three.js Points
 */
export const setPointsVisible = (points: Points, isVisible: boolean): Points => {
  const clonedPoints = points.clone()
  clonedPoints.visible = isVisible
  return clonedPoints
}

/**
 * 光線生成
 * @return {Raycaster} 光線の太さが0.001の光線インスタンス
 */
export const createRayCaster = () => {
  const raycaster = new Raycaster()
  if (raycaster.params.Points) raycaster.params.Points.threshold = 0.001
  return raycaster
}

/**
 * クリック座標からcanvasの中心座標を(0, 0)としたときに(x,y)を-1〜1の相対位置で取得
 * @param {{x: number, y: number}} clickPosition クリックされた場所の画面左上からのx,y座標
 * @param {{x: number, y: number, width: number, height: number}} clickElement クリックされた要素の左上のx,y座標と幅、高さ
 * @return {{coords: {x: number, y:number}}} {coords: {x: number, y:number}}
 */
export const getClickCoords = (
  clickPosition: { x: number; y: number },
  clickElement: { x: number; y: number; width: number; height: number },
) => {
  //* クリック位置のcanvasからの相対位置を取得
  const relativeX = clickPosition.x - clickElement.x
  const relativeY = clickPosition.y - clickElement.y

  //* canvasの中心座標を(0, 0)としたときの-1〜1での相対位置に変換
  return {
    coords: new Vector2((relativeX / clickElement.width) * 2 - 1, -(relativeY / clickElement.height) * 2 + 1),
  }
}

export const getCameraDistance = (position: Vector3, camera: Camera | null) => {
  if (!camera) {
    return 0
  }

  const distance = camera.position.clone().distanceTo(position)
  return distance
}

// https://docs.google.com/presentation/d/1-_qYSQdIpZ6SeF9G_5n0scfgPz6bTrJhJIVS4wJttcY/edit#slide=id.g1a91a669376_0_0
// Fix the third point (C -> D) to make it place on the normal vector of the line between point 1 (A) and point 2 (B)
export const fixVertexOnNormal = (points: Vector3[]): Vector3[] => {
  if (points.length !== 3) {
    return points
  }

  const directionAB = points[1].clone().sub(points[0]).normalize()
  const directionNormal = points[2].clone().sub(points[1]).cross(directionAB).normalize()
  const directionAD = directionAB.cross(directionNormal).normalize()
  const distance = new Line3(points[0], points[1])
    .closestPointToPoint(points[2], false, new Vector3())
    .distanceTo(points[2])
  const newPoint = points[1].clone().add(directionAD.multiplyScalar(distance))

  return [points[0], points[1], newPoint]
}

// Find the last point of a Parallelogram from previous 3 points
export const findMissingVertexParallelogram = (points: Vector3[]): Vector3 => {
  if (points.length !== 3) {
    return new Vector3()
  }

  // Find the center point between point1 and point3
  const centerPoint = new Line3(points[0].clone(), points[2].clone()).getCenter(new Vector3())
  // Missing point is the point go from point2 across the center point,
  // with 2 times distance from point2 to the center point
  const missingCrossVector = centerPoint.sub(points[1])
  const missingCrossLength = missingCrossVector.length()
  const missingCenterVector = missingCrossVector.normalize().multiplyScalar(missingCrossLength * 2)
  const missingCenterPoint = points[1].clone().add(missingCenterVector)

  return missingCenterPoint
}

export const pointArrayToVector3 = (point: PointArray) => new Vector3(...point)
export const pointsToVector3s = (points: PointArray[]) => points.map(pointArrayToVector3)

const movePointUp = (target: Vector3, planeNormal: Vector3, distance: number) =>
  new Vector3().addVectors(target.clone(), planeNormal.clone().multiplyScalar(distance))

export const getCuboidFromPoints = (points: Vector3[], minSize: number) => {
  const bottomPlane = new Plane().setFromCoplanarPoints(points[0], points[1], points[2])
  const bottomPlaneNormal = bottomPlane.normal.normalize()

  const width = Math.max(minSize, Math.abs(points[0].distanceTo(points[1])))
  const depth = Math.max(minSize, Math.abs(points[1].distanceTo(points[2])))
  const height = bottomPlane.distanceToPoint(points[3])
  const absHeight = Math.max(minSize, Math.abs(height))

  const center = new Line3(points[0], points[2]).getCenter(new Vector3())
  const position = movePointUp(center, bottomPlaneNormal, height / 2)

  const rotationMatrix = new Matrix4().makeBasis(
    points[0].clone().sub(points[1]).normalize(),
    new Plane().setFromCoplanarPoints(points[0], points[1], points[2]).normal.normalize(),
    points[2].clone().sub(points[1]).normalize(),
  )
  const quaternion = new Quaternion().setFromRotationMatrix(rotationMatrix)

  // use for drawing bounding box
  const edgesGeometry = new EdgesGeometry(new BoxGeometry(width, absHeight, depth))

  // use for drawing virtual axis
  const startX = new Vector3(-width / 2, 0, 0)
  const endX = new Vector3(width / 2, 0, 0)
  const startY = new Vector3(0, -height / 2, 0)
  const endY = new Vector3(0, height / 2, 0)
  const startZ = new Vector3(0, 0, -depth / 2)
  const endZ = new Vector3(0, 0, depth / 2)

  return {
    width,
    depth,
    height: absHeight,
    position,
    quaternion,
    edgesGeometry,
    rotationMatrix,
    axisPoints: {
      x: [startX, endX],
      y: [startY, endY],
      z: [startZ, endZ],
    },
  }
}

export const rotateCuboid = (cuboid: Cuboid, cuboidDirection?: CuboidDirection): Cuboid => {
  const newCuboid = {
    region_id: cuboid.region_id,
    center: [...cuboid.center] as PointArray,
    extent: [...cuboid.extent] as PointArray,
    rotation: [...cuboid.rotation],
  }
  if (cuboidDirection === EDITOR_CUBOID_DIRECTIONS.X) {
    newCuboid.extent = [newCuboid.extent[1], newCuboid.extent[2], newCuboid.extent[0]]
    const rotationArray = newCuboid.rotation
    newCuboid.rotation = [
      rotationArray[3], // y
      rotationArray[4], // y
      rotationArray[5], // y
      rotationArray[6], // z
      rotationArray[7], // z
      rotationArray[8], // z
      rotationArray[0], // x
      rotationArray[1], // x
      rotationArray[2], // x
    ]
  } else if (cuboidDirection === EDITOR_CUBOID_DIRECTIONS.Y) {
    newCuboid.extent = [newCuboid.extent[2], newCuboid.extent[0], newCuboid.extent[1]]
    const rotationArray = newCuboid.rotation
    newCuboid.rotation = [
      rotationArray[6], // z
      rotationArray[7], // z
      rotationArray[8], // z
      rotationArray[0], // x
      rotationArray[1], // x
      rotationArray[2], // x
      rotationArray[3], // y
      rotationArray[4], // y
      rotationArray[5], // y
    ]
  }
  return newCuboid
}

export const getVerticesFromCuboid = (cuboid: Cuboid) => {
  const centerVector = new Vector3(cuboid.center[0], cuboid.center[1], cuboid.center[2])
  const vertex0 = centerVector
    .clone()
    .add(
      new Vector3(-cuboid.extent[0] / 2, -cuboid.extent[1] / 2, -cuboid.extent[2] / 2).applyMatrix3(
        new Matrix3().fromArray(cuboid.rotation),
      ),
    )
  const vertex1 = centerVector
    .clone()
    .add(
      new Vector3(cuboid.extent[0] / 2, -cuboid.extent[1] / 2, -cuboid.extent[2] / 2).applyMatrix3(
        new Matrix3().fromArray(cuboid.rotation),
      ),
    )
  const vertex2 = centerVector
    .clone()
    .add(
      new Vector3(cuboid.extent[0] / 2, -cuboid.extent[1] / 2, cuboid.extent[2] / 2).applyMatrix3(
        new Matrix3().fromArray(cuboid.rotation),
      ),
    )
  const vertex3 = centerVector
    .clone()
    .add(
      new Vector3(-cuboid.extent[0] / 2, -cuboid.extent[1] / 2, cuboid.extent[2] / 2).applyMatrix3(
        new Matrix3().fromArray(cuboid.rotation),
      ),
    )
  const vertex4 = centerVector
    .clone()
    .add(
      new Vector3(-cuboid.extent[0] / 2, cuboid.extent[1] / 2, -cuboid.extent[2] / 2).applyMatrix3(
        new Matrix3().fromArray(cuboid.rotation),
      ),
    )
  const vertex5 = centerVector
    .clone()
    .add(
      new Vector3(cuboid.extent[0] / 2, cuboid.extent[1] / 2, -cuboid.extent[2] / 2).applyMatrix3(
        new Matrix3().fromArray(cuboid.rotation),
      ),
    )
  const vertex6 = centerVector
    .clone()
    .add(
      new Vector3(cuboid.extent[0] / 2, cuboid.extent[1] / 2, cuboid.extent[2] / 2).applyMatrix3(
        new Matrix3().fromArray(cuboid.rotation),
      ),
    )

  return [vertex0, vertex1, vertex2, vertex3, vertex4, vertex5, vertex6]
}

/**
 * Generate model matrix (world space) for a cuboid so we can calculate each
 * fragment location in the cuboid's local space.
 * Min and max values are also calculated for each cuboid to determine
 * if the fragment is inside the cuboid.
 *
 * @param cuboid Cuboid to generate data
 */
export const generateCuboidUniforms = (cuboid: Cuboid) => {
  const { center, rotation, extent } = cuboid

  const boundingBox = new Box3(
    new Vector3(-extent[0] / 2, -extent[1] / 2, -extent[2] / 2),
    new Vector3(extent[0] / 2, extent[1] / 2, extent[2] / 2),
  )

  // Calculate the model matrix
  const modelMatrix = new Matrix4()
  modelMatrix
    .compose(
      new Vector3(...center),
      new Quaternion().setFromRotationMatrix(new Matrix4().setFromMatrix3(new Matrix3().fromArray(rotation))),
      new Vector3(1, 1, 1),
    )
    .invert()

  return {
    modelMatrix,
    min: boundingBox.min,
    max: boundingBox.max,
  }
}

// TODO: Remove these converters when cylinder detection result is the same with shape
export const getParametersByShapeType = (
  shapeType: ShapeKey,
  result: CylinderDetectionResult | PolygonDetectionResult,
) => {
  if (shapeType === EDITOR_SHAPE_KEYS.CYLINDERS) {
    const cylinder = result as CylinderDetectionResult
    return { diameter: cylinder.parameters_cylinder?.diameter || 0, length: cylinder.parameters_cylinder?.length || 0 }
  }

  if (shapeType === EDITOR_SHAPE_KEYS.POLYGON) {
    const polygon = result as PolygonDetectionResult
    return {
      vertices: polygon.parameters_polygon?.vertices || [],
      plane_side: polygon.plane_side,
    }
  }

  return {}
}

/**
 * Convert tekkin-detector result to shapes.
 * @param shapeType
 * @param result
 * @returns
 */
export const convertDetectionResultToShapes = (
  shapeType: ShapeKey,
  result: CylinderDetectionResult[] | PolygonDetectionResult[] | ShapeDetectionResult[],
) =>
  result.map((c) => ({
    shape_id: c.shape_id,
    transformation: c.registration_result ? Object.values(c.registration_result.transformation) : [],
    is_shown_on_final_sheet: c.is_shown_on_final_sheet,
    ...getParametersByShapeType(shapeType, c),
  }))

/**
 * Convert 2D points to 3D with Matrix transformation.
 *
 * @param points 2D points
 * @param transformation Transformation matrix
 */
export const transform2Dto3D = (points: Vector2[] | Vector3[], transformation: number[]): PointArray[] => {
  // Convert 2D vertices to 3D positions
  const transformationMatrix = transformation
  const vertices = points.map((v) => [v.x, v.y, v instanceof Vector3 ? v.z : 0, 1]) // Assuming Z-coordinate is 0 for 2D vertices

  // Apply transformation
  const transformedVertices: PointArray[] = []
  for (let i = 0; i < vertices.length; i += 1) {
    const vertex = vertices[i]
    const transformedVertex = [0, 0, 0] as PointArray
    for (let j = 0; j < 3; j += 1) {
      for (let k = 0; k < 4; k += 1) {
        transformedVertex[j] += vertex[k] * transformationMatrix[j * 4 + k]
      }
    }
    transformedVertices.push(transformedVertex)
  }

  return transformedVertices
}

/**
 * Find the center of a set of points.
 * @param points Set of Vector3 points
 * @returns
 */
export const findCenter = (points: Vector3[]): Vector3 | null => {
  if (points.length === 0) {
    return null
  }

  const sumX = points.reduce((acc, point) => acc + point.x, 0)
  const sumY = points.reduce((acc, point) => acc + point.y, 0)
  const sumZ = points.reduce((acc, point) => acc + point.z, 0)

  const centerX = sumX / points.length
  const centerY = sumY / points.length
  const centerZ = sumZ / points.length

  return new Vector3(centerX, centerY, centerZ)
}

/**
 * Convert degrees to radians.
 * @param degrees
 * @returns
 */
export const degreesToRadians = (degrees: number): number => (degrees * Math.PI) / 180

/**
 * Convert radians to degrees.
 * @param radians
 * @returns
 */
export const radiansToDegrees = (radians: number): number => radians * (180 / Math.PI)

/**
 * Rotate a point by a specific angle.
 *
 * @param point Point to rotate
 * @param angle Angle in radians
 */
export function rotatePoint(point: Vector2 | Vector3, angle: number): Vector2 {
  const newX = point.x * Math.cos(angle) - point.y * Math.sin(angle)
  const newY = point.x * Math.sin(angle) + point.y * Math.cos(angle)
  return new Vector2(newX, newY)
}

/**
 * Rotate a point around a pivot point by a specified Quaternion.
 *
 * @param point
 * @param pivot
 * @param quaternion
 */
export const rotatePointAroundPivot = (point: Vector3, pivot: Vector3, quaternion: Quaternion): Vector3 => {
  const translatedPoint = point.clone().sub(pivot)
  const rotationMatrix = new Matrix4()
  rotationMatrix.makeRotationFromQuaternion(quaternion)
  translatedPoint.applyMatrix4(rotationMatrix)
  translatedPoint.add(pivot)
  return translatedPoint
}

/**
 * Find the orientation of a set of points.
 * @param points - The points to find the orientation of.
 * @returns
 */
export function pointsOrientation(points: Vector3[]): 'clockwise' | 'counterclockwise' | 'collinear' {
  let sum = 0
  const numPoints = points.length

  for (let i = 0; i < numPoints; i += 1) {
    const currPoint = points[i]
    const nextPoint = points[(i + 1) % numPoints]

    sum += (nextPoint.x - currPoint.x) * (nextPoint.y + currPoint.y)
  }

  if (sum > 0) {
    return 'counterclockwise'
  }

  if (sum < 0) {
    return 'clockwise'
  }

  return 'collinear'
}

// Function to calculate the centroid of a set of points
export const getCentroidOfPolygon = (points: Vector2[]): Vector2 => {
  if (!points.length) {
    return new Vector2()
  }

  const sum = points.reduce(
    (acc, point) => {
      acc.x += point.x
      acc.y += point.y
      return acc
    },
    { x: 0, y: 0 },
  )

  return new Vector2(sum.x / points.length, sum.y / points.length)
}

/**
 * Calculates the centroid (geometric center) of a 3D polygon.
 *
 * @param vertices - An array of vertices representing the 3D polygon.
 * @returns  - The centroid of the polygon.
 */
export const getCentroidOfPolygon3d = (vertices: Vector3[]): Vector3 => {
  let centroid = new Vector3()

  // Sum up the coordinates of each vertex
  vertices.forEach((vertex) => {
    centroid.x += vertex.x
    centroid.y += vertex.y
    centroid.z += vertex.z
  })

  // Divide by the total number of vertices to get the average
  const numVertices = vertices.length
  centroid.x /= numVertices
  centroid.y /= numVertices
  centroid.z /= numVertices

  return centroid
}

/**
 * 
/**
 * Finds the point on PlaneB where PointA on PlaneA will be perpendicular to PlaneA.
 *
 * @param {THREE.Vector3} pointA - The point on PlaneA.
 * @param {THREE.Plane} planeA - The plane where PointA is located.
 * @param {THREE.Plane} planeB - The target plane where the projection is to be found.
 * @returns {THREE.Vector3} - The projected point on PlaneB.
 */
export const findPerpendicularProjection = (pointA: Vector3, planeA: Plane, planeB: Plane): Vector3 => {
  // Direction vector is the normal of PlaneA
  const direction = planeA.normal.clone()

  // Calculate the distance from pointA to planeB along the direction vector
  const t = -(planeB.normal.dot(pointA) + planeB.constant) / planeB.normal.dot(direction)

  // Calculate the intersection point
  const projectedPoint = pointA.clone().add(direction.multiplyScalar(t))

  return projectedPoint
}

/**
 * Projects a 3D world coordinate to 2D screen coordinates.
 * @param worldPosition - The 3D world position to project.
 * @param camera - The camera being used for the scene.
 * @param screenWidth - Width of the screen (viewport).
 * @param screenHeight - Height of the screen (viewport).
 * @returns The 2D screen coordinates as { x, y }.
 */
export const projectToScreen = (
  worldPosition: Vector3,
  camera: Camera,
  screenWidth: number,
  screenHeight: number,
): Vector2 => {
  // Clone the world position and project it with the camera
  const ndc = worldPosition.clone().project(camera)

  // Map NDC (-1 to 1) to screen coordinates
  const screenX = (ndc.x + 1) * 0.5 * screenWidth
  const screenY = (1 - ndc.y) * 0.5 * screenHeight // Flip Y-axis

  return new Vector2(screenX, screenY)
}

/**
 * Determines the rotation direction of an object based on drag positions.
 * @param center - The center of the object { x, y }.
 * @param start - The initial drag start position { x, y }.
 * @param current - The current drag position { x, y }.
 * @returns 1 for clockwise, -1 for counterclockwise.
 */
export const getRotationDirection = (center: Vector2, start: Vector2, current: Vector2): 1 | -1 => {
  // Vectors from the center to the start and current positions
  const vectorStart = { x: start.x - center.x, y: start.y - center.y }
  const vectorCurrent = { x: current.x - center.x, y: current.y - center.y }

  // Cross product of the two vectors to determine the rotation direction
  const crossProduct = vectorStart.x * vectorCurrent.y - vectorStart.y * vectorCurrent.x

  // If cross product is positive, rotate clockwise; if negative, counterclockwise
  return crossProduct > 0 ? -1 : 1
}
