import { useEffect } from 'react'

import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'

import { decideActionPermission } from 'services/Validation'

import { reset, setPermissionSet } from '../store/editor'

export const useEditor = () => {
  // Store
  const dispatch = useAppDispatch()
  const isLoading = useSelector((state: RootState) => state.page.isLoading)
  const isInvited = useSelector((state: RootState) => state.page.isInvited)
  const isOwner = useSelector((state: RootState) => state.page.isOwner)

  /**
   * Get permission set for the Editor
   */
  useEffect(() => {
    if (isLoading) return

    dispatch(setPermissionSet(decideActionPermission(isOwner, isInvited).MAIN_CANVAS))
  }, [isLoading, isInvited, isOwner, dispatch])

  /**
   * Reset store when unmounting
   */
  useEffect(
    () => () => {
      dispatch(reset())
    },
    [dispatch],
  )
}
