import { useCallback, useContext, useEffect, useMemo } from 'react'

import { setAttentionText } from 'pages/projects/common/AttentionText/store/attentionText'
import { CursorState, setCursor, setDisablePanning, setDisableRotation } from 'pages/projects/editor/store/editor'
import { FaForwardStep } from 'react-icons/fa6'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'
import { patchInspectionAreas } from 'store/page'
import { Vector3 } from 'three'

import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import { EDITOR_TOOLS } from 'config/constants'

import { Editor } from 'interfaces/canvas'
import { EditorButton, EditorConfig } from 'interfaces/editor'

import { getArcballControlsCameraState } from 'services/Editor'
import { patchInspectionArea } from 'services/InspectionArea'

import { SelectionStage, reset, resetWorkingState, setCameraProfile, setSelectionStage } from '../store'

const attentionMessages = {
  [SelectionStage.DRAW]: '正面を指定するために、3点を選択してください。',
  [SelectionStage.ROTATE]:
    '面の位置を確認してください。点を移動して調整することもできます。正面を指定ボタンをクリックして次に進んでください。',
  [SelectionStage.FOCUS]:
    'カメラを回転、ズーム、平行移動してさらに調整できます。面に対して横方向に回転することはできません。点群を選択するとその点を画面の中心に設定できます。',
  [SelectionStage.SAVING]: '正面視野を保存中...',
}

export default function useEditor({
  initCompleted,
  selectedTool,
  prevSelectedTool,
  arcballControlsRef,
  cameraRef,
  changeTool,
  changeIsToolProcessing,
}: Editor): EditorConfig {
  // Context
  const { getAccessToken } = useContext(UserContext)
  const { showErrorModal } = useContext(GlobalModalContext)

  // Store
  const dispatch = useAppDispatch()
  const selectionStage = useSelector((state: RootState) => state.toolCameraProfile.selectionStage)
  const project = useSelector((state: RootState) => state.page.project)
  const inspectionArea = useSelector((state: RootState) => state.page.inspectionArea)
  const target = useSelector((state: RootState) => state.toolCameraProfile.target)
  const isDirty = useSelector((state: RootState) => state.toolCameraProfile.isDirty)

  // Vars
  const isToolSelected = useMemo(() => selectedTool === EDITOR_TOOLS.CAMERA_PROFILE, [selectedTool])
  const isPreviousToolSelected = useMemo(() => prevSelectedTool === EDITOR_TOOLS.CAMERA_PROFILE, [prevSelectedTool])

  /**
   * Generate camera profile.
   */
  const getCameraProfile = useCallback(() => {
    if (!cameraRef.current || !arcballControlsRef.current || !target) return null

    const targetV3 = new Vector3(...target)
    const direction = new Vector3().subVectors(cameraRef.current.position, targetV3).normalize()
    const right = new Vector3().crossVectors(cameraRef.current.up, direction).normalize()
    const up = new Vector3().crossVectors(direction, right).normalize()
    const distance = cameraRef.current.position.distanceTo(targetV3)

    return {
      state: getArcballControlsCameraState(arcballControlsRef.current),
      target: targetV3.toArray(),
      positions: {
        front: cameraRef.current.position.toArray(),
        back: new Vector3().copy(direction).negate().multiplyScalar(distance).add(targetV3).toArray(),
        top: new Vector3().copy(up).multiplyScalar(distance).add(targetV3).toArray(),
        bottom: new Vector3().copy(up).negate().multiplyScalar(distance).add(targetV3).toArray(),
        left: new Vector3().copy(right).negate().multiplyScalar(distance).add(targetV3).toArray(),
        right: new Vector3().copy(right).multiplyScalar(distance).add(targetV3).toArray(),
      },
    }
  }, [arcballControlsRef, cameraRef, target])

  /**
   * Load camera profile.
   */
  useEffect(
    () => {
      if (!initCompleted) {
        dispatch(setCameraProfile(undefined))
      }

      if (!arcballControlsRef.current || !initCompleted || !inspectionArea?.camera_profile) return

      // load from inspection area
      dispatch(setCameraProfile(inspectionArea.camera_profile))

      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-explicit-any
      ;(arcballControlsRef.current as any).setStateFromJSON(JSON.stringify(inspectionArea.camera_profile.state))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, initCompleted, inspectionArea, arcballControlsRef.current],
  )

  /**
   * Save camera profile.
   */
  const onClick = useCallback(async () => {
    const profile = getCameraProfile()
    if (!project || !inspectionArea || !profile) return

    const accessToken = await getAccessToken()
    if (!accessToken) return

    dispatch(setSelectionStage(SelectionStage.SAVING))
    changeIsToolProcessing(true)

    const result = await patchInspectionArea(
      accessToken,
      project.project_id,
      {
        ...inspectionArea,
        camera_profile: profile,
      },
      showErrorModal,
    )

    // Change to move tool once saved
    if (result) {
      changeIsToolProcessing(false)
      dispatch(patchInspectionAreas([result]))
      changeTool(EDITOR_TOOLS.MOVE, true)
    } else {
      dispatch(setSelectionStage(SelectionStage.FOCUS))
    }
  }, [
    project,
    inspectionArea,
    changeIsToolProcessing,
    getAccessToken,
    showErrorModal,
    changeTool,
    getCameraProfile,
    dispatch,
  ])

  /**
   * Set attention text and cursor when tool is active, according to the selection stage.
   */
  useEffect(() => {
    if (isToolSelected) {
      if (attentionMessages[selectionStage]) {
        dispatch(setAttentionText({ message: attentionMessages[selectionStage] }))
      }

      dispatch(setCursor(CursorState.CROSSHAIR))
    } else if (isPreviousToolSelected) {
      dispatch(setCursor(CursorState.DEFAULT))
      dispatch(setAttentionText({ message: '' }))
      dispatch(setDisablePanning(false))
      dispatch(setDisableRotation(false))
    }
  }, [isPreviousToolSelected, isToolSelected, selectedTool, selectionStage, dispatch])

  /**
   * Reset working state when tool is deselected.
   */
  useEffect(() => {
    if (!isToolSelected && isPreviousToolSelected) {
      dispatch(resetWorkingState())
    }
  }, [isToolSelected, isPreviousToolSelected, dispatch])

  /**
   * Reset on unmount.
   */
  useEffect(
    () => () => {
      dispatch(reset())
    },
    [dispatch],
  )

  /**
   * Depending on the selection stage, the submit button will have different behavior.
   */
  const submitButton = useMemo((): EditorButton => {
    const commonProps = {
      isShown: () => isToolSelected,
    }

    if ([SelectionStage.DRAW, SelectionStage.ROTATE].includes(selectionStage)) {
      return {
        ...commonProps,
        key: 'confirm-camera-profile',
        label: '正面を指定',
        loadingLabel: '',
        icon: <FaForwardStep />,
        onClick: () => {
          dispatch(setSelectionStage(SelectionStage.FOCUS))
          dispatch(setDisableRotation(true))
          dispatch(setCursor(CursorState.GRAB))
        },
        isLoading: () => false,
        isDisabled: () => selectionStage === SelectionStage.DRAW,
      }
    }

    return {
      ...commonProps,
      key: 'save-camera-profile',
      label: '保存',
      loadingLabel: '保存中',
      onClick,
      isLoading: () => selectionStage === SelectionStage.SAVING,
      isDisabled: () => [SelectionStage.SAVING].includes(selectionStage),
    }
  }, [selectionStage, isToolSelected, dispatch, onClick])

  return {
    buttons: {
      submit: submitButton,
      reset: {
        onClick: useCallback(() => {
          dispatch(resetWorkingState())
          dispatch(setDisablePanning(false))
          dispatch(setDisableRotation(false))
        }, [dispatch]),
        isShown: useCallback(() => isToolSelected, [isToolSelected]),
        isDisabled: () => !isDirty || selectionStage === SelectionStage.SAVING,
      },
      undo: {
        label: selectionStage === SelectionStage.FOCUS ? '正面の編集に戻る' : undefined,
        isShown: useCallback(
          () => isToolSelected && ![SelectionStage.SAVING].includes(selectionStage),
          [isToolSelected, selectionStage],
        ),
        isDisabled: () => !isDirty || selectionStage === SelectionStage.SAVING,
      },
    },
  }
}
