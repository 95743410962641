import { FC } from 'react'

import { Button, Flex, Text } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'
import { RootState } from 'store/app'

import { CubeIcon } from 'assets/icons'

import { InspectionArea } from 'interfaces/inspection'

const NoData: FC<{ message: string; inspectionArea: InspectionArea; hidden?: boolean }> = ({
  message,
  inspectionArea,
  hidden,
}) => {
  // Store
  const project = useSelector((state: RootState) => state.page.project)

  return (
    <Flex className={['no-data', hidden ? 'hidden' : ''].join(' ')}>
      <Text>{message}</Text>
      <Button
        as={RouterLink}
        to={`/projects/${project?.project_id}/editor?area=${inspectionArea.inspection_area_id}`}
        mt={6}
        variant="outline"
        bg="white"
        fontSize="md"
        size="lg"
        leftIcon={<CubeIcon />}
      >
        3D画面でエリアを開く
      </Button>
    </Flex>
  )
}

export default NoData
