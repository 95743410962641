import { FC } from 'react'

import { Button, Container, Flex, HStack, Heading, Image, VStack } from '@chakra-ui/react'
import Navbar from 'components/Navbar/Navbar'
import PageFooter from 'pages/common/PageFooter'
import { useDashboard } from 'pages/dashboard/hooks/dashboard'
import { useSelector } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'
import { RootState } from 'store/app'

import BackgroundImage from 'assets/imgs/landing-page.png'

import { CONTAINER_MAX_WIDTH } from 'config/styles'

const LandingPage: FC = () => {
  useDashboard()

  // Store
  const userLoaded = useSelector((state: RootState) => state.user.userLoaded)

  return (
    <VStack h="100%">
      <Navbar isInverted />

      <Container flex="1" maxW={CONTAINER_MAX_WIDTH}>
        <Flex pos="relative" h="100%">
          <Image src={BackgroundImage} pos="absolute" right="0" top="5%" w="80%" />
          <VStack flex="1" pos="relative" align="left" color="secondary.600">
            <VStack w={{ sm: '50%', xl: '55%' }} flex="1" pt="10%" align="left">
              <Heading as="h2" size="3xl" lineHeight={1.2}>
                iPad1台で
                <br />
                斫り検査を
                <br />
                簡単に。
              </Heading>
              <HStack>
                <Button as={RouterLink} to={userLoaded ? '/dashboard' : '/register'} colorScheme="primary" mr="6">
                  はじめる
                </Button>
              </HStack>
            </VStack>
            <PageFooter />
          </VStack>
        </Flex>
      </Container>
    </VStack>
  )
}

export default LandingPage
