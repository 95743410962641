import { FC, PropsWithChildren } from 'react'

import { Outlet } from 'react-router-dom'

import { usePage } from 'hooks/page'

const RouteProjectsLayout: FC<PropsWithChildren> = ({ children }) => {
  usePage()

  return children ?? <Outlet />
}

export default RouteProjectsLayout
