import { EDITOR_TOOLS, USER_TYPES } from 'config/constants'

import { EditorTool } from 'interfaces/editor'
import { PermissionSets } from 'interfaces/validation'

import { CameraToolbar } from './components/CameraToolbar'
import useEditor from './hooks/useEditor'
import useMainCanvas from './hooks/useMainCanvas'

const CameraProfile: EditorTool = {
  key: EDITOR_TOOLS.CAMERA_PROFILE,
  authCheck: (permissionSets: PermissionSets, userType: keyof typeof USER_TYPES) =>
    permissionSets.MAIN_CANVAS.MEASURE.includes(userType),
  components: {
    editor: [CameraToolbar],
  },
  hooks: {
    useEditor,
    useMainCanvas,
  },
  config: {
    anchor: {
      notPlaceableOnObjects: true,
    },
  },
}

export default CameraProfile
