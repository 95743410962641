import { FC, useRef, useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import {
  Avatar,
  Box,
  Button,
  Container,
  Flex,
  HStack,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Spacer,
  Stack,
  Text,
  VStack,
  useOutsideClick,
} from '@chakra-ui/react'
import mixpanel from 'mixpanel-browser'
import { isTablet } from 'react-device-detect'
import { useSelector } from 'react-redux'
import { Link as RouterLink, NavLink as RouterNavLink, useLocation } from 'react-router-dom'
import { RootState } from 'store/app'

import { ChevronDownIcon, ChevronLeftIcon, LogoDark, LogoLight, MenuIcon } from 'assets/icons'

import { USER_TYPES } from 'config/constants'
import { CONTAINER_MAX_WIDTH, NAV_BAR_HEIGHT, NOTIFICATION_STYLES } from 'config/styles'

import InviteUserModal from './InviteUserModal'

// isInverted: used for Landing paged
const Navbar: FC<{ isInverted?: boolean }> = ({ isInverted }) => {
  const { user, logout } = useAuth0()
  const location = useLocation()

  // Store
  const permissionSet = useSelector((state: RootState) => state.dashboard.permissionSet)
  const userType = useSelector((state: RootState) => state.user.userType)
  const serviceStatus = useSelector((state: RootState) => state.system.serviceStatus)

  const [isTabletMenuOpen, setIsTabletMenuOpen] = useState(false)
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false)
  const navLinksRef = useRef<HTMLDivElement>(null)
  const navbarButtonRef = useRef<HTMLButtonElement>(null)

  // Permission check
  const isAllowedToInvite = permissionSet.INVITE_TO_CREATE_ACCOUNT.includes(userType)

  const userActions = () => {
    if (!user) {
      return (
        <HStack spacing={6}>
          {!!isInverted && (
            <Button variant="ghost" as={RouterLink} to="/dashboard">
              ログイン
            </Button>
          )}
          {!isInverted && (
            <Button colorScheme="primary" as={RouterLink} to="/dashboard">
              ログイン
            </Button>
          )}
          <Button colorScheme="primary" as={RouterLink} to="/register">
            新規登録
          </Button>
        </HStack>
      )
    }

    return (
      <HStack spacing={6}>
        {isAllowedToInvite && (
          <>
            <Button
              variant={isInverted ? 'outlineStaticInvert' : 'outlineStatic'}
              size="sm"
              onClick={() => setIsInviteModalOpen(true)}
            >
              関係者を招待
            </Button>
            <InviteUserModal isOpen={isInviteModalOpen} onConfirm={() => setIsInviteModalOpen(false)} />
          </>
        )}
        <Menu>
          <MenuButton py={2} transition="all 0.3s" _focus={{ boxShadow: 'none' }}>
            <HStack>
              <Avatar size="sm" src={user?.picture} />
              <VStack display={{ base: 'none', md: 'flex' }} alignItems="flex-start" spacing="1px" ml="2">
                <Text fontSize="sm" fontWeight="semibold">
                  {user?.nickname}
                </Text>
              </VStack>
              <Box display={{ base: 'none', md: 'flex' }}>
                <ChevronDownIcon />
              </Box>
            </HStack>
          </MenuButton>
          <MenuList color="secondary.600" data-testid="navbar-user-menu">
            <MenuItem as={RouterLink} data-testid="account-settings" to="/user">
              アカウント設定
            </MenuItem>
            <MenuDivider borderColor="var(--chakra-colors-chakra-border-color)" />
            <MenuItem
              data-testid="logout"
              onClick={() => {
                mixpanel.track('Logout')
                return logout({ logoutParams: { returnTo: window.location.origin } })
              }}
            >
              ログアウト
            </MenuItem>
          </MenuList>
        </Menu>
      </HStack>
    )
  }

  useOutsideClick({
    ref: navLinksRef,
    handler: () => {
      if (isTabletMenuOpen) {
        setIsTabletMenuOpen(false)
      }
    },
  })

  const navLinkClass = isTablet ? 'nav-link-tablet' : 'nav-link'
  const getNavLinksClass = () => {
    if (!isTablet) {
      return ''
    }
    if (isTabletMenuOpen) {
      return 'nav-links-tablet active'
    }
    return 'nav-links-tablet'
  }
  const isInspectionSheetSettings = location.pathname.includes('user') && location.hash === '#inspection-sheet'

  const navLinks = () => (
    <Stack
      spacing={0}
      px={isTablet ? 0 : 8}
      position={isTablet ? 'absolute' : 'static'}
      direction={isTablet ? 'column' : 'row'}
      height={isTablet ? '100vh' : 'auto'}
      backgroundColor={isInverted ? 'white' : 'primary.500'}
      zIndex={999}
      top={`${(navbarButtonRef.current?.offsetTop || 0) + (isInverted ? 60 : 54)}px`}
      left={0}
      shadow={isTablet ? '2xl' : 'none'}
      className={getNavLinksClass()}
    >
      {!!user && (
        <>
          <Link as={RouterNavLink} className={navLinkClass} to="/dashboard" variant="button">
            工事一覧
          </Link>
          <Link
            as={RouterLink}
            className={[navLinkClass, isInspectionSheetSettings ? 'active' : null].filter(Boolean).join(' ')}
            to="/user#inspection-sheet"
            variant="button"
          >
            工事設定
          </Link>
        </>
      )}
      {!!user && userType === USER_TYPES.ADMIN && (
        <Link as={RouterNavLink} className={navLinkClass} to="/user-list" variant="button">
          ユーザー一覧
        </Link>
      )}
    </Stack>
  )

  const notification = () =>
    serviceStatus?.notification?.enabled ? (
      <Box w="100%" {...NOTIFICATION_STYLES[serviceStatus.notification.type].box}>
        <Container maxW={CONTAINER_MAX_WIDTH} py={2}>
          <Text {...NOTIFICATION_STYLES[serviceStatus.notification.type].text}>
            {serviceStatus.notification.message} &nbsp;
            {serviceStatus.notification.url && serviceStatus.notification.urlText && (
              <Button
                as={Link}
                href={serviceStatus?.notification.url}
                {...NOTIFICATION_STYLES[serviceStatus.notification.type].button}
              >
                {serviceStatus?.notification.urlText}
              </Button>
            )}
          </Text>
        </Container>
      </Box>
    ) : null

  return (
    <VStack w="100%" spacing={0}>
      {notification()}
      <Box
        backgroundColor={isInverted ? 'white' : 'primary.500'}
        boxShadow={isInverted ? '' : 'md'}
        color={isInverted ? '' : 'white'}
        w="100%"
      >
        <Container maxW={CONTAINER_MAX_WIDTH} ref={navLinksRef}>
          <Flex align="center" w="100%" minH={`${NAV_BAR_HEIGHT}px`} pt={isInverted ? 12 : 0}>
            {isTablet && (
              <IconButton
                aria-label="nav-menu"
                variant="primary"
                icon={isTabletMenuOpen ? <ChevronLeftIcon /> : <MenuIcon />}
                size="lg"
                onClick={() => {
                  setIsTabletMenuOpen(!isTabletMenuOpen)
                }}
                ref={navbarButtonRef}
              />
            )}
            <Link as={RouterLink} to="/">
              {isInverted ? <LogoDark /> : <LogoLight />}
            </Link>

            {navLinks()}

            <Spacer />

            {userActions()}
          </Flex>
        </Container>
      </Box>
    </VStack>
  )
}

export default Navbar
