import { FC, useContext, useEffect, useState } from 'react'

import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react'

import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import { TOAST_CONFIG } from 'config/styles'

import { inviteUser, sendInvitationEmail } from 'services/Users'
import { validateEmail } from 'services/Util'

const InviteUserModal: FC<{
  isOpen: boolean
  onConfirm: () => void
  email?: string
}> = ({ isOpen, onConfirm, email }) => {
  const { showErrorModal } = useContext(GlobalModalContext)
  const { getAccessToken } = useContext(UserContext)

  const toast = useToast()

  const [emailAddress, setEmailAddress] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (email) {
      setEmailAddress(email)
    }
  }, [email])

  const onInviteUser = async () => {
    setIsLoading(true)

    const token = await getAccessToken()
    if (!token) {
      onConfirm()
      setIsLoading(false)
      return false
    }

    const invitation = await inviteUser(token, emailAddress, showErrorModal)
    if (!invitation) {
      onConfirm()
      setIsLoading(false)
      return false
    }

    const sendEmailNotificationResult = await sendInvitationEmail(token, emailAddress, showErrorModal)
    if (sendEmailNotificationResult) {
      toast({
        ...TOAST_CONFIG,
        title: '閲覧ユーザーを招待し、通知メールを送信しました',
      })
    }

    onConfirm()
    setIsLoading(false)
    return true
  }

  return (
    <Modal closeOnOverlayClick isOpen={isOpen} onClose={() => onConfirm()} trapFocus={false} isCentered size="md">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="md">関係者を招待</ModalHeader>
        <ModalCloseButton hidden={isLoading} />
        <ModalBody position="relative">
          <FormControl>
            <FormLabel>アカウントを持っていない関係者にアカウント作成の招待を行う</FormLabel>
            <Input
              placeholder="メールアドレスを入力してください"
              id="email_address"
              type="text"
              value={emailAddress}
              onChange={(e) => setEmailAddress(e.target.value)}
            />
            <FormHelperText>
              招待の有効期限：
              <Text fontWeight="bold" display="inline-block">
                7日
              </Text>
            </FormHelperText>
          </FormControl>
        </ModalBody>

        <ModalFooter mt={8} justifyContent="center">
          <Button me={3} py={2} minW="100px" onClick={() => onConfirm()}>
            キャンセル
          </Button>
          <Button
            isDisabled={isLoading || !validateEmail(emailAddress)}
            colorScheme="primary"
            me={3}
            py={2}
            minW="100px"
            onClick={() => onInviteUser()}
          >
            招待
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default InviteUserModal
