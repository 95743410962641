import { Navigate, useLocation, useMatches, useParams } from 'react-router-dom'

const OldInspectionAreaURLRedirect = () => {
  // URL Params
  const { project_id, inspection_area_id } = useParams<{ project_id: string; inspection_area_id: string }>()
  const location = useLocation()
  const childPage = location.pathname.split('/').pop()

  // Route matching to check current page
  const matches = useMatches()
  const isEditorPage = matches.some((row) => row.id.includes('editor'))

  return (
    <Navigate
      to={`/projects/${project_id}/${isEditorPage ? 'editor' : childPage}/?area=${inspection_area_id}`}
      replace
    />
  )
}

export default OldInspectionAreaURLRedirect
