/* eslint-disable */
import { FC } from 'react'

import { Box, Heading } from '@chakra-ui/react'

const Plan: FC = () => {
  return (
    <Box className="content-container">
      <Heading fontSize="2xl">プラン</Heading>
    </Box>
  )
}

export default Plan
