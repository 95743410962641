/**
 * Creates a box from a collection of points.
 * ⚠️This is a debug only shape. Do not use in production.
 */
import { FC, useEffect, useState } from 'react'

import { Mesh, MeshBasicMaterial, Vector3 } from 'three'
import { ConvexGeometry } from 'three-stdlib'

const standardMaterial = new MeshBasicMaterial({
  color: 0xadd8e6,
  transparent: true,
  opacity: 0.5,
})

export const BoxBoundaryMesh: FC<{
  points: Vector3[]
}> = ({ points }) => {
  const [mesh, setMesh] = useState<Mesh>()

  useEffect(() => {
    if (points) {
      const geometryBox = new ConvexGeometry(points)
      const msh = new Mesh()
      msh.geometry = geometryBox

      setMesh(msh)
    }
  }, [points])

  return mesh ? (
    <mesh quaternion={mesh.quaternion} position={mesh.position} geometry={mesh.geometry} material={standardMaterial} />
  ) : null
}
