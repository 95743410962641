import { FC, useEffect } from 'react'

import { Box, HStack, Icon, Link, Text, VStack } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'

import { AttentionTextIcon, reset } from './store/attentionText'

/**
 * Shows a guide text on the top of the page.
 * The usage is pretty simple, just set the message in attentionText store (setAttentionText).
 */
const AttentionText: FC = () => {
  // Store
  const dispatch = useAppDispatch()
  const message = useSelector((state: RootState) => state.attentionText.message)
  const icon = useSelector((state: RootState) => state.attentionText.icon)
  const linkMessage = useSelector((state: RootState) => state.attentionText.linkMessage)
  const linkUrl = useSelector((state: RootState) => state.attentionText.linkUrl)

  /**
   * Reset on unmount
   */
  useEffect(
    () => () => {
      dispatch(reset())
    },
    [dispatch],
  )

  return message ? (
    <Box
      id="attention-text"
      position="absolute"
      textAlign="center"
      minWidth={200}
      top="20px"
      left="50%"
      transform="translateX(-50%)"
      background="whiteAlpha.900"
      color="gray.800"
      p="10px 20px"
      borderRadius={5}
      whiteSpace="pre-line"
    >
      <HStack>
        {icon && <Icon as={AttentionTextIcon[icon]} w={14} h={14} color="gray.800" />}
        <VStack>
          <Text>{message}</Text>
          {linkMessage && linkUrl && (
            <Link variant="underline" isExternal href={linkUrl}>
              {linkMessage}
            </Link>
          )}
        </VStack>
      </HStack>
    </Box>
  ) : null
}

export default AttentionText
