import './styles.css'

import { FC, useCallback, useContext, useEffect, useState } from 'react'

import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Heading,
  Spacer,
  Spinner,
  Text,
  useToast,
} from '@chakra-ui/react'
import InspectionSheetSettingsForm from 'components/InspectionSheetSettings'
import { merge } from 'lodash'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'
import { setUserProfile } from 'store/user'

import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import { DefaultSettings } from 'config/constants'

import { ProjectSheetSettings } from 'interfaces/project'
import { UserInfo } from 'interfaces/user'

import { ERROR_PROCESS, processErrorHandler } from 'services/ErrorHandler'
import { patchUser } from 'services/Users'

const InspectionSheetSettings: FC = () => {
  const toast = useToast()

  // Context
  const { getAccessToken } = useContext(UserContext)
  const { showErrorModal } = useContext(GlobalModalContext)

  // Store
  const dispatch = useAppDispatch()
  const userProfile = useSelector((state: RootState) => state.user.userProfile)
  const userLoaded = useSelector((state: RootState) => state.user.userLoaded)

  // States
  const [settings, setSettings] = useState<ProjectSheetSettings>(DefaultSettings)
  const [isSaving, setIsSaving] = useState(false)

  /**
   * Set settings after user is loaded
   */
  useEffect(() => {
    if (userProfile) {
      setSettings(userProfile)
    }
  }, [userProfile])

  /**
   * Handle save settings
   */
  const handleSave = useCallback(async () => {
    setIsSaving(true)

    const token = await getAccessToken()
    if (!token) return

    const updatedUser = await patchUser(token, settings as UserInfo).catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.PATCH_USER, showErrorModal)
      return null
    })

    if (!updatedUser) return

    dispatch(setUserProfile(updatedUser))
    setIsSaving(false)
    toast({
      description: 'Inspection sheet settings saved successfully',
      status: 'success',
      position: 'top',
      containerStyle: { top: '120px', position: 'relative' }, // push it down to a more visible position
    })
  }, [settings, toast, dispatch, getAccessToken, showErrorModal])

  return (
    <Box className="content-container">
      {!userLoaded ? (
        <Flex gap={4}>
          <Spinner />
          <Text fontWeight="bold">読み込み中…</Text>
        </Flex>
      ) : (
        <>
          <HStack>
            <Heading fontSize="2xl">帳票詳細</Heading>
            <Spacer />
            <Button colorScheme="primary" onClick={handleSave} isLoading={isSaving}>
              保存
            </Button>
          </HStack>

          <Divider mt={4} />

          <Alert status="info" borderRadius={5} mt={5} mb={10} w="90%" fontSize="md">
            <AlertIcon />
            以下の設定は、新しく作成されるすべての工事にデフォルトとして適用されます。これらの設定は、個々の工事の設定で工事ごとに上書きすることができます。
          </Alert>

          <InspectionSheetSettingsForm
            settings={settings}
            onChange={(newSettings) => setSettings((prev) => merge({}, prev, newSettings))}
          />
        </>
      )}
    </Box>
  )
}

export default InspectionSheetSettings
