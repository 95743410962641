import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { InspectionSheetDiagram } from 'interfaces/inspection'

export interface DiagramState {
  /**
   * Plane diagrams for the inspection sheet.
   */
  diagrams: Record<string, InspectionSheetDiagram>
}

export const initialState: DiagramState = {
  diagrams: {},
}

export const diagramSlice = createSlice({
  name: 'diagram',
  initialState,
  reducers: {
    setDiagram: (state, action: PayloadAction<{ imageId: string; diagram: InspectionSheetDiagram }>) => {
      state.diagrams[action.payload.imageId] = action.payload.diagram
    },
    reset: () => initialState,
  },
})

export const { setDiagram, reset } = diagramSlice.actions

export default diagramSlice.reducer
