import { FC, ReactNode } from 'react'

import { Box, Circle, Flex, HStack, IconButton, Spacer, Square, Tooltip } from '@chakra-ui/react'
import { isTablet } from 'react-device-detect'

import { AlertFillIcon, CollapseIcon, DeleteIcon, ExpandIcon, HideIcon, ShowIcon } from 'assets/icons'

import { LAYER_ITEM_STYLES } from 'config/styles'

const LayerItem: FC<{
  label: ReactNode
  collapsed?: boolean
  collapsible?: boolean
  invisible?: boolean
  selected?: boolean
  disabled?: boolean
  errorMessage?: string
  colorLabel?: string
  onClick?: () => void
  onMouseOver?: () => void
  onMouseOut?: () => void
  updateVisibility?: (invisible: boolean) => void
  updateExpansion?: (expanded: boolean) => void
  deleteLayer?: () => void
  isChild?: boolean
}> = ({
  label,
  collapsible,
  collapsed,
  invisible,
  selected,
  disabled,
  errorMessage,
  colorLabel,
  onClick,
  onMouseOver,
  onMouseOut,
  updateVisibility,
  updateExpansion = () => null,
  deleteLayer,
  isChild,
}) => {
  const iconSize = isTablet ? 'sm' : 'xs'
  const fontSize = isTablet ? 'md' : 'sm'

  const className = [onClick && !disabled ? 'editor-clickable-item' : '']
  if (selected) className.push('selected')

  return (
    <Flex
      position="relative"
      className={className.join(' ')}
      backgroundColor={selected ? 'whiteAlpha.200' : 'transparent'}
      w="100%"
      alignItems="center"
      {...LAYER_ITEM_STYLES}
      onClick={(e) => {
        if (onClick) {
          e.stopPropagation()
          onClick()
        }
      }}
      onMouseOver={() => {
        if (onMouseOver) onMouseOver()
      }}
      onMouseOut={() => {
        if (onMouseOut) onMouseOut()
      }}
      data-testid="layer-item"
    >
      {/* Color label */}
      {colorLabel && <Circle size="1em" bg={colorLabel} />}

      {/* Main text */}
      <Box pl={isChild ? 3 : 0} whiteSpace="nowrap">
        {label}
      </Box>

      <Spacer />

      <HStack spacing={0}>
        {/* Visibility toggle */}
        {updateVisibility && !invisible && (
          <IconButton
            aria-label="show"
            fontSize={fontSize}
            icon={<ShowIcon />}
            onClick={(e) => {
              e.stopPropagation()
              updateVisibility(true)
            }}
            size={iconSize}
            variant="toolbarIcon"
          />
        )}
        {updateVisibility && invisible && (
          <IconButton
            aria-label="hide"
            fontSize={fontSize}
            icon={<HideIcon />}
            onClick={(e) => {
              e.stopPropagation()
              updateVisibility(false)
            }}
            size={iconSize}
            variant="toolbarIcon"
          />
        )}
        {/* Delete button */}
        {deleteLayer && !disabled && (
          <IconButton
            aria-label="delete"
            fontSize={fontSize}
            icon={<DeleteIcon />}
            onClick={(e) => {
              e.stopPropagation()
              deleteLayer()
            }}
            size={iconSize}
            variant="toolbarIcon"
          />
        )}
        {deleteLayer && disabled && <Square size={6} />}
        {collapsible && (
          <IconButton
            aria-label={collapsed ? 'expand' : 'collapse'}
            fontSize={fontSize}
            icon={collapsed ? <ExpandIcon /> : <CollapseIcon />}
            onClick={(e) => {
              e.stopPropagation()
              if (updateExpansion) updateExpansion(!collapsed)
            }}
            size={iconSize}
            variant="toolbarIcon"
          />
        )}
      </HStack>

      {/* Error message */}
      {!!errorMessage && (
        <Tooltip hasArrow placement="right" label={errorMessage} p={2} fontSize="xs" fontWeight="normal">
          <Box position="absolute" left={2} top={2} fontSize="80%">
            <AlertFillIcon color="orange" />
          </Box>
        </Tooltip>
      )}
    </Flex>
  )
}

export default LayerItem
