import { useCallback, useContext } from 'react'

import { Text } from '@chakra-ui/react'
import { setAttentionText } from 'pages/projects/common/AttentionText/store/attentionText'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'

import { EditorContext } from 'contexts/Editor'
import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import { MODAL_TYPES } from 'config/constants'

import { LayerStatusExtended } from 'interfaces/attribute'

import { batchDeleteInspectionItem, deleteInspectionItem } from 'services/InspectionSheet'

import {
  Polyline,
  deleteWorkingPolylines,
  patchPolylines,
  patchWorkingPolyline,
  setPolylines,
  setWorkingPolylines,
} from '../store'

export default function usePolylinePanel() {
  // Context
  const { inspectionSheet, setIsLayerModifying, fetchInspectionItems } = useContext(EditorContext)
  const { showModal, showErrorModal } = useContext(GlobalModalContext)
  const { getAccessToken } = useContext(UserContext)

  // Store
  const dispatch = useAppDispatch()
  const project = useSelector((state: RootState) => state.page.project)
  const inspectionArea = useSelector((state: RootState) => state.page.inspectionArea)
  const polylines = useSelector((state: RootState) => state.toolPolyline.polylines)
  const workingPolylines = useSelector((state: RootState) => state.toolPolyline.workingPolylines)

  // Data
  const selectedPolylines = polylines.concat(workingPolylines).filter((polyline) => polyline.selected)

  /**
   * Update all distance anchors status
   * @param item Layer status
   */
  const updateAllPolylineStatus = useCallback(
    (item: LayerStatusExtended) => {
      const newStatus = { ...item }
      if (newStatus.invisible === true) {
        newStatus.selected = false
      }
      dispatch(setPolylines(polylines.map((polyline) => ({ ...polyline, ...newStatus }))))
      dispatch(setWorkingPolylines(workingPolylines.map((polyline) => ({ ...polyline, ...newStatus }))))
    },
    [polylines, workingPolylines, dispatch],
  )

  /**
   * Update layer visibility
   * @param status Layer status
   * @param polyline Polyline to update
   */
  const updatePolylineStatus = useCallback(
    (status: LayerStatusExtended, polyline: Polyline) => {
      if (polyline.inspection_item_id?.includes('working')) {
        dispatch(patchWorkingPolyline({ ...polyline, ...status }))
      } else {
        dispatch(patchPolylines([{ ...polyline, ...status }]))
      }
    },
    [dispatch],
  )

  /**
   * Delete layer for temporary polylines
   * @param polyline Polyline to delete
   */
  const deletePolyline = useCallback(
    (polyline: Polyline) => {
      if (!project || !inspectionArea || !inspectionSheet) {
        return
      }

      // Deleting working polyline
      if (polyline.inspection_item_id?.includes('working')) {
        dispatch(deleteWorkingPolylines([polyline.inspection_item_id]))
        return
      }

      // Deleting polyline from DB
      showModal({
        title: polyline.part_name || `延長`,
        body: <Text>一度削除してしまうと、元に戻せません。</Text>,
        confirmText: '削除',
        modalType: MODAL_TYPES.CONFIRMATION_CRITICAL,
        onConfirmPromised: async () => {
          if (!polyline?.inspection_item_id) {
            return false
          }

          setIsLayerModifying(true)
          dispatch(setAttentionText({ message: 'データを更新中...' }))
          const token = await getAccessToken()
          if (!token) {
            return false
          }

          const result = await deleteInspectionItem(
            token,
            project.project_id,
            inspectionArea.inspection_area_id,
            inspectionSheet.inspection_sheet_id,
            polyline.inspection_item_id,
            showErrorModal,
          )

          // Refresh inspection sheet
          if (result) await fetchInspectionItems()

          setIsLayerModifying(false)
          dispatch(setAttentionText({ message: '' }))

          return true
        },
      })
    },
    [
      dispatch,
      fetchInspectionItems,
      getAccessToken,
      inspectionArea,
      inspectionSheet,
      project,
      setIsLayerModifying,
      showErrorModal,
      showModal,
    ],
  )

  /**
   * Delete selected polylines
   */
  const deleteSelectedPolylines = useCallback(() => {
    if (!project || !inspectionArea || !inspectionSheet || !selectedPolylines.length) {
      return
    }

    const toDeleteExistingPolylines = polylines.filter((polyline) => polyline.selected)
    const toDeleteWorkingPolylines = workingPolylines.filter((polyline) => polyline.selected)

    // Delete working polylines
    if (toDeleteWorkingPolylines.length > 0) {
      dispatch(deleteWorkingPolylines(toDeleteWorkingPolylines.map((polyline) => polyline.inspection_item_id!)))
    }

    // Delete polylines from DB
    if (toDeleteExistingPolylines.length > 0) {
      showModal({
        title: `${selectedPolylines.length} 延長を削除しますか？`,
        body: <Text>一度削除してしまうと、元に戻せません。</Text>,
        confirmText: '削除',
        modalType: MODAL_TYPES.CONFIRMATION_CRITICAL,
        onConfirmPromised: async () => {
          setIsLayerModifying(true)
          dispatch(setAttentionText({ message: 'データを更新中...' }))
          const token = await getAccessToken()
          if (!token) {
            return false
          }

          const result = await batchDeleteInspectionItem(
            token,
            project.project_id,
            inspectionArea.inspection_area_id,
            inspectionSheet.inspection_sheet_id,
            toDeleteExistingPolylines.map((polyline) => polyline?.inspection_item_id || '').filter(Boolean),
            showErrorModal,
          )

          // Refresh inspection sheet
          if (result) await fetchInspectionItems()

          setIsLayerModifying(false)
          dispatch(setAttentionText({ message: '' }))

          return true
        },
      })
    }
  }, [
    polylines,
    workingPolylines,
    inspectionArea,
    inspectionSheet,
    project,
    selectedPolylines,
    setIsLayerModifying,
    dispatch,
    showModal,
    showErrorModal,
    fetchInspectionItems,
    getAccessToken,
  ])

  /**
   * Update selected polylines' status
   * @param status New layer status
   */
  const updateSelectedPolylines = useCallback(
    (status: LayerStatusExtended) => {
      dispatch(
        setPolylines(
          polylines.map((polyline) => {
            if (polyline.selected) {
              return { ...polyline, ...status }
            }
            return polyline
          }),
        ),
      )

      dispatch(
        setWorkingPolylines(
          workingPolylines.map((polyline) => {
            if (polyline.selected) {
              return { ...polyline, ...status }
            }
            return polyline
          }),
        ),
      )
    },
    [polylines, workingPolylines, dispatch],
  )

  /**
   * Deselect all selected polylines
   */
  const deselectSelectedPolylines = useCallback(() => {
    dispatch(setPolylines(polylines.map((polyline) => ({ ...polyline, selected: false }))))
    dispatch(setWorkingPolylines(workingPolylines.map((polyline) => ({ ...polyline, selected: false }))))
  }, [polylines, workingPolylines, dispatch])

  return {
    // Measurements
    polylines,
    updatePolylineStatus,
    deletePolyline,
    updateAllPolylineStatus,

    // Working polylines
    workingPolylines,

    // Selected polylines
    selectedPolylines,
    deleteSelectedPolylines,
    updateSelectedPolylines,
    deselectSelectedPolylines,
  }
}
