import { FC, useState } from 'react'

import { Flex, VStack } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { RootState } from 'store/app'

import { EDITOR_COLLAPSE_TYPES } from 'config/constants'
import { INFO_PANEL_PADDING } from 'config/styles'

import CollapsePanel from './components/CollapsePanel'
import LayerItemDivider from './components/LayerItemDivider'
import WorkingCylinders from './components/WorkingCylinders'
import WorkingPlanes from './components/WorkingPlanes'
import WorkingPolygon from './components/WorkingPolygon'

const WorkingShapePanel: FC = () => {
  const polygons = useSelector((state: RootState) => state.toolVolumeEstimationPolygon.planes)
  const polygonWorkingPoints = useSelector((root: RootState) => root.toolVolumeEstimationPolygon.workingPoints)
  const planes = useSelector((state: RootState) => state.toolPlaneDetection.planes)
  const planeWorkingPoints = useSelector((root: RootState) => root.toolPlaneDetection.workingPoints)
  const workingCylinders = useSelector((state: RootState) => state.toolRebarDetection.workingDistanceLabels)

  const [, setIsCollapseOpen] = useState(true)

  const isSomeCylinderAnchorsVisible = workingCylinders.length
  const isSomePolygonsVisible = polygonWorkingPoints.length || polygons.lower || polygons.upper
  const isSomePlanesVisible = planeWorkingPoints.length || planes.length

  if (
    !polygons.lower &&
    !polygons.upper &&
    !polygonWorkingPoints.length &&
    !workingCylinders.length &&
    !planes.length &&
    !planeWorkingPoints.length
  ) {
    return null
  }

  return (
    <Flex
      backgroundColor="gray.800"
      borderBottomLeftRadius="md"
      borderTopLeftRadius="md"
      w="100%"
      flex={1}
      minH={10}
      data-testid="frame-panel"
    >
      <CollapsePanel title="検出中の要素" type={EDITOR_COLLAPSE_TYPES.detecting} onChange={setIsCollapseOpen}>
        <VStack w="100%" spacing={0} pb={INFO_PANEL_PADDING - 1} divider={<LayerItemDivider />} overflowY="auto">
          {isSomePolygonsVisible && <WorkingPolygon />}
          {isSomePlanesVisible && <WorkingPlanes />}
          {isSomeCylinderAnchorsVisible && <WorkingCylinders />}
        </VStack>
      </CollapsePanel>
    </Flex>
  )
}

export default WorkingShapePanel
