import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'

import { debounce } from 'lodash'
import mixpanel from 'mixpanel-browser'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { RootState, useAppDispatch } from 'store/app'
import { reset as resetDiagram } from 'store/diagram'
import { setProject } from 'store/page'

import { INITIAL_SHAPE_STATE } from 'contexts/Editor'
import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import { useDocumentTitle } from 'hooks/useDocumentTitle'

import { InspectionArea, InspectionItem } from 'interfaces/inspection'
import { Project } from 'interfaces/project'
import { Shapes } from 'interfaces/shape'

import { parseInspectionItemPartitionKey } from 'services/InspectionArea'
import { debouncedUpdateInspectionItems } from 'services/InspectionSheet'
import {
  getProjectInspectionItems,
  getProjectInspectionSheets,
  getProjectShapes,
  updateProject,
} from 'services/Projects'
import { calculateRebarSurfaceArea, meterToMillimeter } from 'services/Util'
import { decideActionPermission } from 'services/Validation'
import { generateXLSX } from 'services/exports/XLSX'

import {
  patchInspectionItems,
  reset,
  resetEditedInspectionItemIds,
  resetEditedProject,
  setAllowedToModifyProject,
  setAllowedToModifySheet,
  setInspectionItems,
  setInspectionSheets,
  setIsSaving,
} from '../store'

const debouncedUpdateProject = debounce(
  async (
    getAccessToken: () => Promise<string>,
    project: Project,
    showErrorModal: (message: string) => void,
    beforeSaving: () => void,
    onSaved: (result: Project) => void,
    setIsSavingCb: (isSaving: boolean) => void,
  ) => {
    beforeSaving()
    setIsSavingCb(true)
    const token = await getAccessToken()
    if (!token) {
      return
    }

    const result = await updateProject(token, project, showErrorModal)
    if (!result) {
      return
    }

    onSaved(result)
    setIsSavingCb(false)
  },
  4000,
)

export default function useInspectionSheet() {
  const location = useLocation()
  const queries = new URLSearchParams(location.search)
  const inspection_area_id = queries.get('area')

  // Context
  const { showErrorModal } = useContext(GlobalModalContext)
  const { getAccessToken } = useContext(UserContext)

  // Store
  const dispatch = useAppDispatch()
  const project = useSelector((state: RootState) => state.page.project)
  const editedProject = useSelector((state: RootState) => state.inspectionSheet.editedProject)
  const inspectionSheets = useSelector((state: RootState) => state.inspectionSheet.inspectionSheets)
  const inspectionItems = useSelector((state: RootState) => state.inspectionSheet.inspectionItems)
  const editedInspectionItemIds = useSelector((state: RootState) => state.inspectionSheet.editedInspectionItemIds)
  const areas = useSelector((state: RootState) => state.page.inspectionAreas)
  const area = useSelector((state: RootState) => state.page.inspectionArea)
  const diagrams = useSelector((state: RootState) => state.diagram.diagrams)
  const isOwner = useSelector((state: RootState) => state.page.isOwner)
  const isInvited = useSelector((state: RootState) => state.page.isInvited)
  const userType = useSelector((state: RootState) => state.user.userType)
  const isAllowedToModifySheet = useSelector((state: RootState) => state.inspectionSheet.isAllowedToModifySheet)
  const isAllowedToModifyProject = useSelector((state: RootState) => state.inspectionSheet.isAllowedToModifyProject)
  const settings = useSelector((state: RootState) => state.inspectionSheet.settings)
  const inspectionAreaIdToEdit = useSelector((state: RootState) => state.inspectionSheet.inspectionAreaIdToEdit)
  const isPageLoading = useSelector((state: RootState) => state.page.isLoading)
  const editedInspectionAreas = useSelector((state: RootState) => state.inspectionSheet.editedInspectionAreas)

  // States
  const [isLoading, setIsLoading] = useState(true)
  const [initComplete, setInitComplete] = useState(false)
  const [shapes, setShapes] = useState<Shapes>(INITIAL_SHAPE_STATE())

  // refs
  const settingsDrawerRef = useRef<{ openDrawer: () => void }>(null)

  // Summaries
  const rebarsSurfaceArea = useMemo(
    () => shapes.cylinders.reduce((sum, cylinder) => sum + calculateRebarSurfaceArea(cylinder), 0),
    [shapes.cylinders],
  )
  const totalVolume = useMemo(
    () =>
      inspectionItems
        .filter((item) => item.item_type === 'volume')
        .reduce((total, item) => total + (item.volume?.estimated_value || 0), 0),
    [inspectionItems],
  )
  const totalArea = useMemo(
    () =>
      inspectionItems
        .filter((item) => item.item_type === 'polygon_area' && !item.volume_id)
        .reduce((total, item) => total + (item.polygon_area?.estimated_value || 0), 0),
    [inspectionItems],
  )
  const totalPolyline = useMemo(
    () =>
      meterToMillimeter(
        inspectionItems
          .filter((item) => item.item_type === 'polyline_length')
          .reduce((total, item) => total + (item.polyline_length?.estimated_value || 0), 0),
      ),
    [inspectionItems],
  )

  /**
   * All additional metrics for any modeled item.
   * This will be used to instruct the other metrics to add padding.
   */
  const allAdditionalMetricsToggle = [
    !(settings.sheet_rows_visibility?.grid && settings.sheet_rows_visibility?.min_grid_depth) || false,
    !(settings.sheet_rows_visibility?.grid && settings.sheet_rows_visibility?.max_grid_depth) || false,
  ]

  /**
   * Sort inspection items by inspection area
   */
  const sortedInspectionItems = useMemo(() => {
    // Group by inspection area
    const grouped = inspectionItems.reduce<{ area: InspectionArea; items: InspectionItem[] }[]>((collection, item) => {
      if (!item.partition_key) return collection

      const ids = parseInspectionItemPartitionKey(item.partition_key)
      if (ids.inspectionAreaId) {
        const ar = areas.find((a) => a.inspection_area_id === ids.inspectionAreaId)
        if (ar) {
          const areaIndex = collection.findIndex((c) => c.area.inspection_area_id === ar.inspection_area_id)
          if (areaIndex === -1) {
            collection.push({ area: ar, items: [item] })
          } else {
            collection[areaIndex].items.push(item)
          }
        }
      }

      return collection
    }, [])

    // Fill the list with areas that have no items
    areas.forEach((ar) => {
      if (!grouped.find((s) => s.area.inspection_area_id === ar.inspection_area_id)) {
        grouped.push({ area: ar, items: [] })
      }
    })

    return grouped.sort((a, b) => {
      const editedA = editedInspectionAreas.find((edited) => edited.inspection_area_id === a.area.inspection_area_id)
      const editedB = editedInspectionAreas.find((edited) => edited.inspection_area_id === b.area.inspection_area_id)
      if (editedA && editedB) {
        return editedA.order! - editedB.order!
      }
      if (editedA) {
        return -1
      }
      if (editedB) {
        return 1
      }
      return a.area.order! - b.area.order!
    })
  }, [inspectionItems, editedInspectionAreas, areas])

  /**
   * Scroll to currently active inspection area
   */
  const keepTryingScrollToArea = useCallback(() => {
    const interval = setInterval(() => {
      const ele = document.getElementById(`inspection-area-${inspection_area_id}`)
      if (ele) {
        clearInterval(interval)
        ele.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        })
      }
    }, 100)
  }, [inspection_area_id])

  /**
   * Set user permission to be used later
   */
  useEffect(() => {
    const permissionSet = decideActionPermission(isOwner, isInvited)
    dispatch(setAllowedToModifyProject(permissionSet.PROJECT_DASHBOARD.MODIFY.includes(userType)))
    dispatch(setAllowedToModifySheet(permissionSet.INSPECTION_SHEET.MODIFY.includes(userType)))
  }, [isOwner, isInvited, userType, dispatch])

  /**
   * Listen to changes in inspection items and update them on the server.
   */
  useEffect(() => {
    if (isAllowedToModifySheet && project && editedInspectionItemIds.length) {
      void debouncedUpdateInspectionItems(
        getAccessToken,
        project.project_id,
        inspectionItems.filter((i) => editedInspectionItemIds.includes(i.inspection_item_id!)),
        showErrorModal,
        // we reset the edited sheet ids before saving to immediately recognize any new changes while saving
        () => {
          dispatch(resetEditedInspectionItemIds())
        },
        (results) => {
          dispatch(resetEditedInspectionItemIds())
          dispatch(patchInspectionItems(results))
        },
        (flag) => dispatch(setIsSaving(flag)),
      )
    }
  }, [
    inspectionItems,
    editedInspectionItemIds,
    project,
    isAllowedToModifySheet,
    dispatch,
    getAccessToken,
    showErrorModal,
  ])

  /**
   * Listen to changes in project and update them on the server.
   */
  useEffect(() => {
    if (isAllowedToModifySheet && editedProject) {
      void debouncedUpdateProject(
        getAccessToken,
        editedProject,
        showErrorModal,
        () => null,
        (result) => {
          dispatch(setProject(result))
          dispatch(resetEditedProject())
        },
        (flag) => dispatch(setIsSaving(flag)),
      )
    }
  }, [editedProject, isAllowedToModifySheet, dispatch, getAccessToken, showErrorModal])

  /**
   * Initial fetch of inspection items and shapes
   */
  useEffect(() => {
    if (!project || isPageLoading || initComplete) return () => null

    const controller = new AbortController()

    void (async ({ project: prj }: { project: Project }) => {
      if (!prj) return

      setIsLoading(true)

      const token = await getAccessToken()
      if (!token) {
        setIsLoading(false)
        return
      }

      // Get inspection items and shapes
      const results = await Promise.all([
        getProjectInspectionSheets(token, prj.project_id, showErrorModal, controller.signal),
        getProjectInspectionItems(token, prj.project_id, showErrorModal, controller.signal),
        getProjectShapes(token, prj.project_id, showErrorModal, controller.signal),
      ])
      const [shts, its, shps] = results

      if (shps !== false) setShapes(shps || INITIAL_SHAPE_STATE())
      if (its !== false) dispatch(setInspectionItems(its || []))
      if (shts !== false) dispatch(setInspectionSheets(shts))
      if (!results.some((r) => r === false)) {
        setIsLoading(false)
        setInitComplete(true)
      }
    })({ project })

    mixpanel.track_pageview({
      Page: 'Inspection Sheet',
      'Project ID': project.project_id,
      'Inspection Area ID': inspection_area_id,
    })

    return () => {
      controller.abort()
    }
  }, [project, isPageLoading, initComplete, getAccessToken, showErrorModal, dispatch, inspection_area_id])

  /**
   * Generate a safe file name for exports, with all special characters removed and without file extension.
   */
  const exportFileName = useMemo(
    () => `${project?.project_name || ''}_${area?.inspection_area_name || ''}`.replaceAll(/[<>:"/\\|?*]+/g, '_'),
    [project?.project_name, area?.inspection_area_name],
  )

  /**
   * Generate XLSX file
   */
  const handleGenerateXLSX = useCallback(
    async (bleedingEdgeItems: InspectionItem[]) => {
      if (!project || !sortedInspectionItems.map((s) => s.area).length) {
        return
      }

      await generateXLSX(
        exportFileName,
        project,
        sortedInspectionItems.map((s) => s.area),
        inspectionSheets,
        bleedingEdgeItems,
        shapes,
        diagrams,
        true,
      )

      // Tracking
      mixpanel.track('Export inspection sheet', { 'File format': 'XLSX' })
    },
    [project, sortedInspectionItems, inspectionSheets, shapes, diagrams, exportFileName],
  )

  /**
   * If an inspection area is marked for editing, open the drawer.
   */
  useEffect(() => {
    if (inspectionAreaIdToEdit) {
      settingsDrawerRef.current?.openDrawer()
    }
  }, [inspectionAreaIdToEdit])

  /**
   * Reset store on unmount
   */
  useEffect(
    () => () => {
      dispatch(resetDiagram())
      dispatch(reset())
    },
    [dispatch],
  )

  /**
   * Set document title
   */
  useDocumentTitle(
    [
      !area && !project ? 'Hatsuly' : null,
      area ? area.inspection_area_name : null,
      project ? project.project_name : null,
    ]
      .filter(Boolean)
      .join(' · '),
  )

  /**
   * If area has already been set and inspection_area_id is changed, scroll to area.
   */
  useEffect(() => {
    if (inspection_area_id) {
      keepTryingScrollToArea()
    }
  }, [inspection_area_id, keepTryingScrollToArea])

  /**
   * Mixpanel super properties
   */
  const mixpanelSuperPropsRef = useRef({})
  useEffect(() => {
    mixpanelSuperPropsRef.current = {
      Page: 'Inspection Sheet',
      'Project ID': project?.project_id,
      'Inspection Area ID': inspection_area_id,
    }
    mixpanel.register(mixpanelSuperPropsRef.current)
  }, [project, inspection_area_id])

  // Cleanup super properties on unmount
  useEffect(() => () => Object.keys(mixpanelSuperPropsRef.current).forEach((prop) => mixpanel.unregister(prop)), [])

  return {
    handleGenerateXLSX,
    isLoading,
    shapes,
    isAllowedToModifyProject,
    isAllowedToModifySheet,
    allAdditionalMetricsToggle,
    sortedInspectionItems,
    rebarsSurfaceArea,
    totalVolume,
    totalArea,
    totalPolyline,
    settingsDrawerRef,
  }
}
