import { FC, useCallback, useContext, useMemo } from 'react'

import { StackDivider, Text, VStack } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { RootState } from 'store/app'

import { ToolbarMoveIcon } from 'assets/icons'

import { EditorContext } from 'contexts/Editor'

import { EDITOR_TOOLS, EDITOR_TOOLS_LABELS, EDITOR_TOOL_BUTTON_SIZE, USER_TYPES } from 'config/constants'

import { EditorTool, ToolbarCategory } from 'interfaces/editor'

import { hashCode } from 'services/Util'
import { decideActionPermission } from 'services/Validation'
import { getVolumeEstimationItem } from 'services/VolumeEstimation'

import { Tools } from '../tools/setup'
import HelpButton from './HelpButton'
import ToolbarButton from './components/ToolbarButton'

const Toolbar: FC = () => {
  // Context
  const { selectedTool, isJobRunning, inspectionItems, shapes, changeTool } = useContext(EditorContext)

  // Store
  const userType = useSelector((state: RootState) => state.user.userType) as keyof typeof USER_TYPES
  const isInvited = useSelector((state: RootState) => state.page.isInvited)
  const isOwner = useSelector((state: RootState) => state.page.isOwner)

  // Vars
  const volumeInspectionItem = useMemo(() => getVolumeEstimationItem(inspectionItems), [inspectionItems])
  const hasCylinders = useMemo(() => shapes.cylinders?.length > 0, [shapes])

  // Group tools by category
  const groupedTools = useMemo(() => {
    const categories = Tools.reduce<{ category: ToolbarCategory; tools: EditorTool[] }[]>((collection, tool) => {
      const category = tool.toolbar?.category || ToolbarCategory.None
      const groupIndex = category as number
      const group = collection[groupIndex] || { category, tools: [] }

      if (tool.toolbar && tool.authCheck(decideActionPermission(isOwner, isInvited), userType)) {
        group.tools.push(tool)
      }

      if (!collection[groupIndex]) {
        collection[groupIndex] = group
      }

      return collection
    }, [])

    return categories
  }, [userType, isInvited, isOwner])

  const getWarningLabel = useCallback(
    (tool: EditorTool) => {
      const msgs = []

      if (tool?.config?.volume?.required && !volumeInspectionItem) {
        msgs.push('️体積測定を先に実施してください')
      }

      if (tool?.config?.cylinder?.required && !hasCylinders) {
        msgs.push('先に鉄筋を検出してください')
      }

      if (!msgs.length) return undefined

      return (
        <>
          {msgs.map((s) => (
            <Text key={hashCode(s)}>{s}</Text>
          ))}
        </>
      )
    },
    [volumeInspectionItem, hasCylinders],
  )

  return (
    <VStack left={2} overflow="hidden" position="absolute" top={2} w={EDITOR_TOOL_BUTTON_SIZE} spacing={3}>
      <VStack
        backgroundColor="gray.800"
        overflow="hidden"
        borderRadius="md"
        divider={<StackDivider borderColor="whiteAlpha.200" />}
        spacing={0}
        w={EDITOR_TOOL_BUTTON_SIZE}
      >
        <ToolbarButton
          icon={<ToolbarMoveIcon width="50%" height="50%" />}
          label={EDITOR_TOOLS_LABELS.MOVE}
          toolType={EDITOR_TOOLS.MOVE}
          buttonSize={EDITOR_TOOL_BUTTON_SIZE}
          selectedTool={selectedTool}
          changeTool={changeTool}
        />
      </VStack>
      {groupedTools.map(
        (group) =>
          group.tools.length && (
            <VStack
              key={group.category}
              data-testid={`ToolbarGroup-${Object.values(ToolbarCategory)[group.category]}`}
              backgroundColor="gray.800"
              overflow="hidden"
              borderRadius="md"
              divider={<StackDivider borderColor="whiteAlpha.200" />}
              spacing={0}
              w={EDITOR_TOOL_BUTTON_SIZE}
            >
              {group.tools.map((tool) => (
                <ToolbarButton
                  key={tool.key}
                  icon={tool.toolbar!.icon}
                  label={tool.toolbar!.label}
                  warningLabel={getWarningLabel(tool)}
                  disabled={
                    (tool?.config?.volume?.required && !volumeInspectionItem) ||
                    (tool?.config?.cylinder?.required && !hasCylinders) ||
                    isJobRunning
                  }
                  toolType={tool.key}
                  buttonSize={EDITOR_TOOL_BUTTON_SIZE}
                  selectedTool={selectedTool}
                  changeTool={changeTool}
                  variants={tool.toolbar?.variants?.reduce<
                    {
                      icon: React.ReactElement
                      toolType: string
                      label: string
                    }[]
                  >((collection, variant) => {
                    if (variant.toolbar) {
                      collection.push({
                        toolType: variant.key,
                        icon: variant.toolbar.icon,
                        label: variant.toolbar.label,
                      })
                    }

                    return collection
                  }, [])}
                />
              ))}
            </VStack>
          ),
      )}
      <VStack
        backgroundColor="gray.800"
        overflow="hidden"
        borderRadius="md"
        divider={<StackDivider borderColor="whiteAlpha.200" />}
        spacing={0}
        w={EDITOR_TOOL_BUTTON_SIZE}
      >
        <HelpButton />
      </VStack>
    </VStack>
  )
}

export default Toolbar
