import './styles.css'

import { FC, useCallback, useRef, useState } from 'react'

import {
  Button,
  Flex,
  HStack,
  Icon,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Square,
  Text,
  useDisclosure,
} from '@chakra-ui/react'

import { EditBoxIcon, InputEditorConfirmIcon } from 'assets/icons'

const PopoverEditing: FC<{
  titlePostfix?: string
  allowEmpty?: boolean
  value: string
  defaultValue?: string
  onChange: (value: string) => void
}> = ({ titlePostfix, allowEmpty = true, value, defaultValue, onChange }) => {
  const { onOpen, onClose, isOpen } = useDisclosure()

  // State
  const [editedValue, setEditedValue] = useState(value || defaultValue)

  // Ref
  const nameEditRef = useRef<HTMLInputElement>(null)

  /**
   * Try to focus the input element.
   */
  const tryFocus = useCallback(() => {
    if (nameEditRef.current) {
      nameEditRef.current.focus()
      return
    }

    setTimeout(() => tryFocus(), 100)
  }, [nameEditRef])

  return (
    <Flex gap={2} className="popover-editing">
      <Popover
        placement="top-start"
        isOpen={isOpen}
        onOpen={() => {
          tryFocus()
          onOpen()
        }}
        onClose={onClose}
        isLazy
      >
        <PopoverTrigger>
          <Square>
            <Icon as={EditBoxIcon} boxSize={5} />
          </Square>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverBody>
            <HStack>
              <Input
                ref={nameEditRef}
                defaultValue={value}
                placeholder={defaultValue}
                onChange={(event) => {
                  if (!allowEmpty && !event.target.value) {
                    return
                  }

                  event.preventDefault()
                  event.stopPropagation()
                  setEditedValue(event.target.value || defaultValue)
                  onChange(event.target.value)
                }}
              />
              <Button colorScheme="primary" onClick={onClose}>
                <Icon as={InputEditorConfirmIcon} boxSize={5} />
              </Button>
            </HStack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
      <Text>
        {editedValue} {titlePostfix}
      </Text>
    </Flex>
  )
}

export default PopoverEditing
