import { useCallback, useContext, useEffect, useMemo, useRef } from 'react'

import { ToastId, useToast } from '@chakra-ui/react'
import { CursorState, setCursor } from 'pages/projects/editor/store/editor'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'
import { Vector3 } from 'three'

import { WarningTwoIcon } from 'assets/icons'

import { EditorContext } from 'contexts/Editor'

import { EDITOR_TOOLS, MAXIMUM_NUMBER_OF_SHAPE_DETECTION_CYLINDER, MAX_EDITOR_LAYERS } from 'config/constants'

import { PointArray } from 'interfaces/attribute'
import { CircleAnchorIcon } from 'interfaces/canvas'
import { CanvasConfig, CanvasEventsExtra } from 'interfaces/editor'

import { calculateRebarSurfaceArea, meterToMillimeter, zeroPad } from 'services/Util'

import {
  DistanceLabelWorkingCylinderProps,
  addWorkingDistanceLabel,
  finishDraggingDistanceLabel,
  setDraggingDistanceLabel,
  setFirstPointPlaced,
  setTempDistanceLabel,
  updateDraggingDistanceLabelAnchor,
} from '../store'

enum AnchorColors {
  Normal = '#333',
  Drawing = 'red',
  Selected = 'orange',
  Movable = '#248CE4',
}

let lastTouchPoint: PointArray | undefined
let lastTouchClient: { x: number; y: number } | undefined
const zeroPlaces = MAX_EDITOR_LAYERS.toString().length

export default function useMainCanvas(): CanvasConfig {
  // Toast
  const toast = useToast()
  const toastIdRef = useRef<ToastId>()

  // Context
  const { selectedTool, shapes, collidingShapeIds, changeIsDragging } = useContext(EditorContext)

  // Store
  const dispatch = useAppDispatch()
  const selectedShapeIds = useSelector((state: RootState) => state.editor.selectedShapeIds)
  const workingDistanceLabels = useSelector((state: RootState) => state.toolRebarDetection.workingDistanceLabels)
  const tempDistanceLabel = useSelector((state: RootState) => state.toolRebarDetection.tempDistanceLabel)
  const baseDiameter = useSelector((state: RootState) => state.toolRebarDetection.baseDiameter)
  const draggingDistanceLabel = useSelector((state: RootState) => state.toolRebarDetection.draggingDistanceLabel)
  const isLoading = useSelector((state: RootState) => state.toolRebarDetection.isLoading)
  const firstPointPlaced = useSelector((state: RootState) => state.toolRebarDetection.firstPointPlaced)

  // Vars
  const isSelectedTool = useMemo(() => selectedTool === EDITOR_TOOLS.CYLINDER, [selectedTool])

  /**
   * Mouse click will either add a new distance label (cylinder marker) or finish the current working distance label.
   */
  const onMouseUp = useCallback(
    (screenPosition: { x: number; y: number }, point: PointArray | undefined, { isDragged }: CanvasEventsExtra) => {
      if ((!isSelectedTool && selectedTool !== EDITOR_TOOLS.MOVE) || !point) return

      // check for dragging distance label first
      if (draggingDistanceLabel) {
        dispatch(finishDraggingDistanceLabel())
        return
      }

      // If the user has been dragging the canvas, don't add a new distance label.
      if (isDragged) return

      // Add a temp distance label if there's none yet.
      if (!isSelectedTool) return

      if (!tempDistanceLabel) {
        dispatch(
          setTempDistanceLabel({
            id: 'rebar-detection-temp-distance-label',
            points: [point],
            anchorProps: {
              color: AnchorColors.Drawing,
            },
            lineColor: 'red',
            labelBgColor: 'yellow',
          }),
        )
        dispatch(setFirstPointPlaced(true))
      } else if (firstPointPlaced) {
        dispatch(
          addWorkingDistanceLabel({
            ...tempDistanceLabel,
            id: crypto.randomUUID(),
            diameter: baseDiameter,
            points: [tempDistanceLabel.points[0], point],
            anchorProps: {
              color: AnchorColors.Movable,
              icon: CircleAnchorIcon.MOVE,
              iconColor: 'white',
              outlineOpacity: 0,
              scale: 1.3,
              iconScale: 1.1,
            },
          }),
        )
        dispatch(setTempDistanceLabel(undefined))
        dispatch(setFirstPointPlaced(false))
      } else if (workingDistanceLabels.length >= MAXIMUM_NUMBER_OF_SHAPE_DETECTION_CYLINDER) {
        if (!toastIdRef.current) {
          toastIdRef.current = toast({
            title: `同時に検出できる要素は${MAXIMUM_NUMBER_OF_SHAPE_DETECTION_CYLINDER}個までです。`,
            description: `右側の「検出中の要素」パネルから削除して${MAXIMUM_NUMBER_OF_SHAPE_DETECTION_CYLINDER}個以下にしてください。`,
            status: 'warning',
            duration: null,
          })
        }
      } else {
        dispatch(
          setTempDistanceLabel({
            ...tempDistanceLabel,
            points: [point],
          }),
        )
        dispatch(setFirstPointPlaced(true))
      }
    },
    [
      tempDistanceLabel,
      selectedTool,
      isSelectedTool,
      baseDiameter,
      draggingDistanceLabel,
      toast,
      workingDistanceLabels.length,
      firstPointPlaced,
      dispatch,
    ],
  )

  /**
   * On mouse move, update the temp distance label, if there is one.
   */
  const onMouseMove = useCallback(
    (point: PointArray | undefined) => {
      if (!point || (!isSelectedTool && selectedTool !== EDITOR_TOOLS.MOVE)) {
        return
      }

      if (draggingDistanceLabel) {
        dispatch(updateDraggingDistanceLabelAnchor(point))
      } else if (isSelectedTool) {
        if (!tempDistanceLabel) {
          dispatch(
            setTempDistanceLabel({
              id: 'rebar-detection-temp-distance-label',
              points: [point],
              anchorProps: {
                color: AnchorColors.Drawing,
              },
              lineColor: 'red',
              labelBgColor: 'yellow',
            }),
          )
        } else if (tempDistanceLabel) {
          dispatch(
            setTempDistanceLabel({
              ...tempDistanceLabel,
              points: !firstPointPlaced ? [point] : [tempDistanceLabel.points[0], point],
              label: !firstPointPlaced
                ? undefined
                : `${meterToMillimeter(
                    new Vector3(...tempDistanceLabel.points[0]).distanceTo(new Vector3(...point)),
                  ).toFixed(0)}mm`,
            }),
          )
        }
      }
    },
    [isSelectedTool, selectedTool, tempDistanceLabel, draggingDistanceLabel, firstPointPlaced, dispatch],
  )

  /**
   * When a distance label is mouse down, change the cursor to grabbing.
   * @param labelIndex Index of the distance label
   * @param anchorIndex Which anchor was interacted with.
   */
  const onDistanceLabelDown = useCallback(
    (labelIndex: number, anchorIndex: number) => {
      if (isSelectedTool || selectedTool === EDITOR_TOOLS.MOVE) {
        dispatch(setDraggingDistanceLabel({ labelIndex, anchorIndex }))
        dispatch(setCursor(CursorState.GRABBING))
        changeIsDragging(true)
      }
    },
    [isSelectedTool, selectedTool, changeIsDragging, dispatch],
  )

  /**
   * When user removes a working distance label, remove the toast if available.
   */
  useEffect(() => {
    if (
      (workingDistanceLabels.length < MAXIMUM_NUMBER_OF_SHAPE_DETECTION_CYLINDER || isLoading) &&
      toastIdRef.current
    ) {
      toast.close(toastIdRef.current)
      toastIdRef.current = undefined
    }
  }, [workingDistanceLabels.length, isLoading, toast])

  return {
    objects: {
      cylinderMeshes: useMemo(
        () =>
          shapes.cylinders

            .map((cylinder, index) => {
              const isSelected = selectedShapeIds.includes(cylinder.shape_id)
              const isColliding = collidingShapeIds.includes(cylinder.shape_id)
              const surfaceArea = calculateRebarSurfaceArea(cylinder)

              return {
                cylinder,
                labelLeftIcon: isColliding ? <WarningTwoIcon color="orange" rounded={1} /> : undefined,
                label: isSelected ? (
                  <>
                    鉄筋{zeroPad(index + 1, zeroPlaces)}: {surfaceArea.toFixed(4)}m&sup2;
                  </>
                ) : undefined,
              }
            })
            .filter(({ cylinder }) => !cylinder.invisible),
        [shapes.cylinders, collidingShapeIds, selectedShapeIds],
      ),
      distanceLabels: workingDistanceLabels
        .filter(
          (label, index) =>
            !label.invisible &&
            // If there's a dragging distance label, skip it, we'll use a dummy instead.
            (!draggingDistanceLabel || (draggingDistanceLabel && draggingDistanceLabel.labelIndex !== index)),
        )
        .map<DistanceLabelWorkingCylinderProps>((label, labelIndex) => ({
          ...label,
          lineColor: label.selected ? 'yellow' : 'white',
          // FIXME: `anchorProps` events can't differentiate between the two anchors. so we have to use the deprecated `onDown` prop in the meantime.
          onDown: isSelectedTool
            ? (anchorIndex: number) => (isLoading ? undefined : onDistanceLabelDown(labelIndex, anchorIndex))
            : undefined,
          anchorProps: {
            icon: CircleAnchorIcon.MOVE,
            iconColor: 'white',
            outlineOpacity: 0,
            iconScale: 1.1,
            scale: label.selected ? 1.5 : 1.3,
            color: label.selected ? AnchorColors.Selected : AnchorColors.Movable,
            tooltipText: isSelectedTool ? '点を移動' : undefined,
            onEnter: isSelectedTool
              ? () => {
                  if (!draggingDistanceLabel && !isLoading) dispatch(setCursor(CursorState.GRAB))
                }
              : undefined,
            onLeave: () => {
              if (!draggingDistanceLabel && !isLoading) dispatch(setCursor(CursorState.CROSSHAIR))
            },
          },
        }))
        // Add the currently drawing label if there is one, and not dragging anything when the current drawing already has 2 points.
        .concat(
          (!draggingDistanceLabel || (draggingDistanceLabel && (tempDistanceLabel?.points.length || 0) > 1)
            ? tempDistanceLabel
            : []) || [],
        )
        // Add the temporary dragging distance label if there is one.
        .concat(draggingDistanceLabel ? [draggingDistanceLabel.label] : []),
    },
    events: {
      onTouchStart: (ev, point) => {
        lastTouchPoint = point
        lastTouchClient = { x: ev.touches[0].clientX, y: ev.touches[0].clientY }
      },
      onTouchEnd: (ev, extra) => {
        if (!isSelectedTool) return

        // Last touch point and last touch client are obtained from touchstart and touchstart because touchmove does not have `touches` data.
        if (lastTouchPoint && lastTouchClient) {
          onMouseUp(
            {
              x: lastTouchClient.x,
              y: lastTouchClient.y,
            },
            lastTouchPoint,
            extra,
          )
        }

        lastTouchPoint = undefined
      },
      onTouchMoveCapture: (ev, point) => {
        onMouseMove(point)
      },
      onMouseUp: (ev, point, extra) => {
        if (!isSelectedTool || lastTouchClient) {
          lastTouchClient = undefined
          return
        }

        onMouseUp(
          {
            x: ev.clientX,
            y: ev.clientY,
          },
          point,
          extra,
        )
      },
      onMove: (point) => {
        if (!isSelectedTool || lastTouchClient) return

        onMouseMove(point)
      },
    },
  }
}
