import axios from 'axios'

import { Comment, CommentReply, CommentUploadedImage } from 'interfaces/interfaces'

import { ERROR_PROCESS, processErrorHandler } from './ErrorHandler'
import { GET_RPOJECTS_API_URL } from './Projects'

const GET_INSPECTION_AREA_API_URL = (projectId: string, inspectionAreaId: string) =>
  GET_RPOJECTS_API_URL(projectId, 'inspection-areas', inspectionAreaId)
const GET_THREADS_API_URL = (projectId: string, inspectionAreaId: string) =>
  inspectionAreaId
    ? `${GET_INSPECTION_AREA_API_URL(projectId, inspectionAreaId)}/threads`
    : GET_RPOJECTS_API_URL(projectId, 'threads')

/**
 * Additional FE-specific properties for comment
 *
 * @param comment Comment to be decorated
 * @returns
 */
const commentDecorator = (comment: Comment): Comment => {
  comment.unplaced = comment.cartesian_position?.x === null
  return comment
}

/**
 * Get all comments
 * @param {string} access_token access token
 * @param {string} project_id project id
 * @param {function} showErrorModal show error modal function
 * @returns {Comment[]}
 */
export const getComments = async (
  access_token: string,
  project_id: string,
  inspection_area_id: string,
  showErrorModal: (message: string) => void,
): Promise<Comment[] | null> => {
  const comments = await axios
    .get<{ results: Comment[] }>(`${GET_THREADS_API_URL(project_id, inspection_area_id)}?compress_size_url=true`, {
      responseType: 'json',
      headers: { 'X-Authorization': `Bearer ${access_token}` },
    })
    .then((response) => response.data.results.map(commentDecorator))
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.GET_COMMENTS, showErrorModal)
      return null
    })

  return comments
}

/**
 * Create a new comment
 * @param {string} access_token access token
 * @param {string} project_id project id
 * @param {function} showErrorModal show error modal function
 * @returns {Comment}
 */
export const createComment = async (
  access_token: string,
  project_id: string,
  inspection_area_id: string,
  comment: Comment,
  showErrorModal: (message: string) => void,
): Promise<Comment | null> => {
  const imageObject = comment.images?.length
    ? {
        images: comment.images,
      }
    : {}
  const result = await axios
    .post<Comment>(
      GET_THREADS_API_URL(project_id, inspection_area_id),
      {
        author_name: comment.author_name,
        thread_body: comment.thread_body,
        cartesian_position: comment.cartesian_position || {
          x: null,
          y: null,
          z: null,
        },
        blueprint_position: comment.blueprint_position || {
          coordinate: {
            x: null,
            y: null,
          },
          extent: {
            width: null,
            height: null,
          },
        },
        blueprint_id: comment.blueprint_id,
        ...imageObject,
      },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      },
    )
    .then((response) => commentDecorator(response.data))
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.CREATE_COMMENT, showErrorModal)
      return null
    })

  return result
}

/**
 * Edit a comment
 * @param {string} access_token access token
 * @param {string} project_id project id
 * @param {string} inspection_area_id Inspecttion area id
 * @param {Comment} comment Comment to be updated
 * @param {function} showErrorModal show error modal function
 * @returns {Comment}
 */
export const editComment = async (
  access_token: string,
  project_id: string,
  inspection_area_id: string,
  comment: Comment,
  showErrorModal: (message: string) => void,
): Promise<Comment | null> => {
  if (!comment.thread_id) {
    return null
  }

  return axios
    .patch<Comment>(
      `${GET_THREADS_API_URL(project_id, inspection_area_id)}/${comment.thread_id}`,
      {
        author_name: comment.author_name,
        thread_body: comment.thread_body,
        cartesian_position: comment.cartesian_position || {
          x: null,
          y: null,
          z: null,
        },
        blueprint_position: comment.blueprint_position || {
          coordinate: {
            x: null,
            y: null,
          },
          extent: {
            width: null,
            height: null,
          },
        },
        blueprint_id: comment.blueprint_id,
      },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      },
    )
    .then((response) => commentDecorator(response.data))
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.UPDATE_COMMENT, showErrorModal)
      return null
    })
}

/**
 * Delete a comment
 * @param {string} access_token access token
 * @param {string} project_id project id
 * @param {function} showErrorModal show error modal function
 * @returns {boolean}
 */
export const deleteComment = async (
  access_token: string,
  project_id: string,
  inspection_area_id: string,
  thread_id: string,
  showErrorModal: (message: string) => void,
): Promise<boolean> => {
  const result = await axios
    .delete<Comment>(`${GET_THREADS_API_URL(project_id, inspection_area_id)}/${thread_id}`, {
      responseType: 'json',
      headers: { 'X-Authorization': `Bearer ${access_token}` },
    })
    .then(() => true)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.DELETE_COMMENT, showErrorModal)
      return false
    })

  return result
}

/**
 * Get all replies
 * @param {string} access_token access token
 * @param {string} project_id project id
 * @param {string} thread_id comment thread id
 * @param {function} showErrorModal show error modal function
 * @returns {CommentReply[]}
 */
export const getReplies = async (
  access_token: string,
  project_id: string,
  inspection_area_id: string,
  thread_id: string,
  showErrorModal: (message: string) => void,
): Promise<CommentReply[] | null> => {
  const replies = await axios
    .get<{ results: CommentReply[] }>(
      `${GET_THREADS_API_URL(project_id, inspection_area_id)}/${thread_id}/replies?compress_size_url=true`,
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      },
    )
    .then((response) => response.data.results)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.GET_COMMENT_REPLIES, showErrorModal)
      return null
    })

  return replies
}

/**
 * Create a new reply
 * @param {string} access_token access token
 * @param {string} project_id project id
 * @param {string} thread_id comment thread id
 * @param {function} showErrorModal show error modal function
 * @returns {CommentReply}
 */
export const createReply = async (
  access_token: string,
  project_id: string,
  inspection_area_id: string,
  thread_id: string,
  reply: CommentReply,
  showErrorModal: (message: string) => void,
): Promise<CommentReply | null> => {
  const imageObject = reply.images?.length
    ? {
        images: reply.images,
      }
    : {}
  const result = await axios
    .post<CommentReply>(
      `${GET_THREADS_API_URL(project_id, inspection_area_id)}/${thread_id}/replies`,
      {
        author_name: reply.author_name,
        reply_body: reply.reply_body,
        ...imageObject,
      },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      },
    )
    .then((response) => response.data)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.CREATE_COMMENT_REPLY, showErrorModal)
      return null
    })

  return result
}

/**
 * Edit a reply
 * @param {string} access_token access token
 * @param {string} project_id project id
 * @param {string} thread_id comment thread id
 * @param {string} reply_id comment reply id
 * @param {string} author_name author name
 * @param {string} reply_body comment thread body
 * @param {function} showErrorModal show error modal function
 * @returns {CommentReply}
 */
export const editReply = async (
  access_token: string,
  project_id: string,
  inspection_area_id: string,
  thread_id: string,
  reply: CommentReply,
  showErrorModal: (message: string) => void,
): Promise<CommentReply | null> =>
  axios
    .patch<CommentReply>(
      `${GET_THREADS_API_URL(project_id, inspection_area_id)}/${thread_id}/replies/${reply.reply_id!}`,
      {
        author_name: reply.author_name,
        reply_body: reply.reply_body,
      },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      },
    )
    .then((response) => response.data)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.UPDATE_COMMENT_REPLY, showErrorModal)
      return null
    })

/**
 * Delete a reply
 * @param {string} access_token access token
 * @param {string} project_id project id
 * @param {string} thread_id comment thread id
 * @param {string} reply_id comment reply id
 * @param {function} showErrorModal show error modal function
 * @returns {boolean}
 */
export const deleteReply = async (
  access_token: string,
  project_id: string,
  inspection_area_id: string,
  thread_id: string,
  reply_id: string,
  showErrorModal: (message: string) => void,
): Promise<boolean> => {
  const result = await axios
    .delete<Comment>(`${GET_THREADS_API_URL(project_id, inspection_area_id)}/${thread_id}/replies/${reply_id}`, {
      responseType: 'json',
      headers: { 'X-Authorization': `Bearer ${access_token}` },
    })
    .then(() => true)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.DELETE_COMMENT_REPLY, showErrorModal)
      return false
    })

  return result
}

/**
 * Get signed url for upload image
 * @param {string} access_token access token
 * @param {string} project_id project id
 * @param {string} filename filename
 * @param {function} showErrorModal show error modal function
 * @returns {CommentUploadedImage}
 */
export const getSignedUrlForUploadImage = async (
  access_token: string,
  project_id: string,
  inspection_area_id: string,
  filename: string,
  showErrorModal: (message: string) => void,
): Promise<CommentUploadedImage | null> => {
  const signedImage = await axios
    .post<CommentUploadedImage>(
      `${GET_INSPECTION_AREA_API_URL(project_id, inspection_area_id)}/image-files`,
      {
        filename,
      },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      },
    )
    .then((response) => response.data)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.CREATE_COMMENT, showErrorModal)
      return null
    })

  return signedImage
}

/**
 * Get an original image
 * @param {string} access_token access token
 * @param {string} project_id project id
 * @param {string} thread_id thread id
 * @param {string} reply_id reply id
 * @param {string} image_id image id
 * @param {function} showErrorModal show error modal function
 * @returns {string}
 */
export const getOriginalImageUrl = async (
  access_token: string,
  project_id: string,
  inspection_area_id: string,
  thread_id: string,
  reply_id: string | null,
  image_id: string,
  showErrorModal: (message: string) => void,
): Promise<string | null> => {
  const replyUrl = reply_id ? `/replies/${reply_id}` : ''
  const image = await axios
    .get<{ original_size_url: string }>(
      `${GET_THREADS_API_URL(
        project_id,
        inspection_area_id,
      )}/${thread_id}${replyUrl}/images/${image_id}?original_size_url=true`,
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      },
    )
    .then((response) => response.data.original_size_url)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.GET_COMMENTS, showErrorModal)
      return null
    })

  return image
}

/**
 * Update or insert an image metadata
 * @param {string} access_token access token
 * @param {string} project_id project id
 * @param {string} thread_id thread id
 * @param {string} reply_id reply id
 * @param {string} image_id image id
 * @param {string} caption caption
 * @param {string} filename filename
 * @param {function} showErrorModal show error modal function
 * @returns {CommentUploadedImage}
 */
export const upsertImageMetaData = async (
  access_token: string,
  project_id: string,
  inspection_area_id: string,
  thread_id: string,
  reply_id: string | null,
  image_id: string,
  caption: string,
  filename: string,
  showErrorModal: (message: string) => void,
): Promise<CommentUploadedImage | null> => {
  const replyUrl = reply_id ? `/replies/${reply_id}` : ''
  const image = await axios
    .put<CommentUploadedImage>(
      `${GET_THREADS_API_URL(project_id, inspection_area_id)}/${thread_id}${replyUrl}/images/${image_id}`,
      {
        caption,
        filename,
      },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      },
    )
    .then((response) => response.data)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.UPDATE_COMMENT, showErrorModal)
      return null
    })

  return image
}

/**
 * Delete an image
 * @param {string} access_token access token
 * @param {string} project_id project id
 * @param {string} thread_id thread id
 * @param {string} reply_id reply id
 * @param {string} image_id image id
 * @param {function} showErrorModal show error modal function
 * @returns {boolean}
 */
export const deleteImage = async (
  access_token: string,
  project_id: string,
  inspection_area_id: string,
  thread_id: string,
  reply_id: string | null,
  image_id: string,
  showErrorModal: (message: string) => void,
): Promise<boolean> => {
  const replyUrl = reply_id ? `/replies/${reply_id}` : ''
  const result = await axios
    .delete<CommentUploadedImage>(
      `${GET_THREADS_API_URL(project_id, inspection_area_id)}/${thread_id}${replyUrl}/images/${image_id}`,
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      },
    )
    .then(() => true)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.UPDATE_COMMENT, showErrorModal)
      return false
    })

  return result
}
