import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { PointArray } from 'interfaces/attribute'

export interface CameraFocusState {
  /**
   * Camera focus target.
   */
  target?: PointArray
}

const initialState: CameraFocusState = {}

export const slice = createSlice({
  name: 'editor/tools/camera_focus',
  initialState,
  reducers: {
    setTarget: (state, action: PayloadAction<PointArray>) => {
      state.target = action.payload
    },
    clearTarget: (state) => {
      state.target = undefined
    },
  },
})

export const { setTarget, clearTarget } = slice.actions
export default slice.reducer
