import './styles.css'

import { FC, useCallback, useEffect, useState } from 'react'

import { Button, Container, Flex, Grid, Spacer, VStack } from '@chakra-ui/react'
import Navbar from 'components/Navbar/Navbar'
import PageHeading from 'components/PageHeading'
import PageFooter from 'pages/common/PageFooter'
import { useLocation, useNavigate } from 'react-router-dom'

import { CONTAINER_MAX_WIDTH } from 'config/styles'

import AccountInformation from './AccountInformation'
import InspectionSheetSettings from './InspectionSheetSettings'
import PlanPage from './Plan'

enum UserAccountTab {
  None = 'none',
  Account = 'account',
  InspectionSheet = 'inspection-sheet',
  Plan = 'plan',
}

const UserAccount: FC = () => {
  // router
  const navigate = useNavigate()
  const location = useLocation()

  // States
  const [tab, setTab] = useState<UserAccountTab>(UserAccountTab.None)

  /**
   * Open a tab
   */
  const openTab = useCallback(
    (newTab: UserAccountTab) => {
      const search = new URLSearchParams(location.search)
      const queryString = search.size ? `?${search.toString()}` : ''

      navigate(`${location.pathname}${queryString}#${newTab}`, {
        replace: true,
      })
    },
    [location, navigate],
  )

  /**
   * Open the tab based on the URL, or the default tab
   */
  useEffect(() => {
    const hash = location.hash.slice(1) as UserAccountTab
    if (Object.values(UserAccountTab).includes(hash)) {
      setTab(hash)
    } else {
      openTab(UserAccountTab.Account)
    }
  }, [tab, location, openTab])

  return (
    <Flex flex={1} bg="gray.50" flexDirection="column">
      <Navbar />

      <Container
        maxW={CONTAINER_MAX_WIDTH}
        data-testid="user-account"
        id="inner-container"
        className="user-account"
        flex={1}
      >
        {/* Page title */}
        <PageHeading>設定</PageHeading>

        <Grid gap={4} gridTemplateColumns="250px 1fr">
          {/* Sidebar */}
          <VStack className="sidebar">
            <Button
              className={tab === UserAccountTab.Account ? 'active' : undefined}
              variant="ghost"
              onClick={() => openTab(UserAccountTab.Account)}
            >
              アカウント
            </Button>
            <Button
              className={tab === UserAccountTab.InspectionSheet ? 'active' : undefined}
              variant="ghost"
              onClick={() => openTab(UserAccountTab.InspectionSheet)}
            >
              帳票詳細
            </Button>
            {/* <Divider />
            <Button
              className={tab === UserAccountTab.Plan ? 'active' : undefined}
              variant="ghost"
              onClick={() => openTab(UserAccountTab.Plan)}
            >
              プラン
            </Button> */}
          </VStack>

          {/* Content */}
          {tab === UserAccountTab.Account && <AccountInformation />}
          {tab === UserAccountTab.InspectionSheet && <InspectionSheetSettings />}
          {tab === UserAccountTab.Plan && <PlanPage />}
        </Grid>
        <Spacer />
        <PageFooter />
      </Container>
    </Flex>
  )
}

export default UserAccount
