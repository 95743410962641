import { FC } from 'react'

import { Box, Flex, Heading, Image, Link, VStack } from '@chakra-ui/react'
import Navbar from 'components/Navbar/Navbar'
import { Link as RouterLink } from 'react-router-dom'

export const AppError: FC<{
  description: string
  image: string
}> = ({ description, image }) => (
  <VStack w="100%" h="100%" spacing={0}>
    <Navbar />
    <Flex w="100%" h="100%" flex={1} justify="center" align="center">
      <Box>
        <Box>
          <Image src={image} alt={description} />
        </Box>
        <Box textAlign="center">
          <Heading fontSize="lg">{description}</Heading>
        </Box>
        <Box textAlign="center" mt={8}>
          <Link as={RouterLink} to="/" fontSize="sm" variant="underline">
            トップへ
          </Link>
        </Box>
      </Box>
    </Flex>
  </VStack>
)
