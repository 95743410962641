import { FC, useContext, useMemo, useState } from 'react'

import { ChevronDownIcon } from '@chakra-ui/icons'
import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Square,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import fileDownload from 'js-file-download'
import { setAttentionText } from 'pages/projects/common/AttentionText/store/attentionText'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'

import { ToolbarPlaneIcon, ToolbarVolumeIconPolygon } from 'assets/icons'

import { EditorContext } from 'contexts/Editor'
import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import { CAD_FILE_TYPES, MODAL_TYPES } from 'config/constants'

import { CADFileType, InspectionArea } from 'interfaces/interfaces'

import { downloadCadFile } from 'services/AWS'
import { getSignedUrlForCadFile, post2dCadFiles, postCadFiles } from 'services/InspectionArea'
import { shapesExist } from 'services/Util'

const CADExportButton: FC = () => {
  // Store
  const dispatch = useAppDispatch()
  const project = useSelector((state: RootState) => state.page.project)
  const inspectionArea = useSelector((state: RootState) => state.page.inspectionArea)
  const cameraProfile = useSelector((state: RootState) => state.toolCameraProfile.cameraProfile)

  // Context
  const { showModal, showErrorModal } = useContext(GlobalModalContext)
  const { shapes } = useContext(EditorContext)
  const { getAccessToken } = useContext(UserContext)

  //* ダウンロード処理用
  const [extension, setExtension] = useState<CADFileType>(CAD_FILE_TYPES.ifc)
  const [isLoading3D, setIsLoading3D] = useState(false)
  const [isLoading2D, setIsLoading2D] = useState(false)
  const [progress, setProgress] = useState(0)
  const [isModalOpen, setIsModalOpen] = useState(false)

  // Derived
  const cad2dError = useMemo(() => {
    if (!shapes?.polygons?.length) {
      return '平面の要素がありません'
    }

    if (!cameraProfile) {
      return '先にカメラの正面を設定してください'
    }

    return null
  }, [shapes, cameraProfile])

  //* ダウンロード処理
  const download = async (download2d = false, setLoading = setIsLoading3D) => {
    if (!project?.project_id || !inspectionArea?.inspection_area_id) {
      showModal({
        body: '工事が存在しません。',
        modalType: MODAL_TYPES.ERROR,
      })
      return false
    }

    const reset = () => {
      setLoading(false)
      setProgress(0)
      dispatch(setAttentionText({ message: '' }))
    }

    //* cadファイルの生成
    setLoading(true)
    setIsModalOpen(false)

    const token = await getAccessToken()
    if (!token) {
      reset()
      return false
    }

    dispatch(
      setAttentionText({
        message: 'CADファイルを生成中です。しばらくお待ちください。',
      }),
    )

    let generatedCad: InspectionArea | null = null
    if (download2d) {
      // prettier-ignore
      generatedCad = await post2dCadFiles(
        token,
        project.project_id,
        inspectionArea.inspection_area_id,
        showErrorModal,
      )
    } else {
      generatedCad = await postCadFiles(
        token,
        project.project_id,
        inspectionArea.inspection_area_id,
        extension,
        showErrorModal,
      )
    }

    // if fails, reset and return.
    // note that the error message is shown in the showErrorModal function.
    if (!generatedCad) {
      reset()
      return false
    }

    if (!generatedCad?.cad_file?.name) {
      reset()
      showModal({
        body: 'データが存在しません。',
        modalType: MODAL_TYPES.ERROR,
      })
      return false
    }

    //* cadファイル用の署名付きURL取得
    const downloadUrl = await getSignedUrlForCadFile(
      token,
      project.project_id,
      inspectionArea.inspection_area_id,
      showErrorModal,
    )
    if (!downloadUrl) {
      reset()
      return false
    }

    //* 署名付きURLからcadファイルをダウンロード
    const downloadResult = await downloadCadFile(downloadUrl, setProgress, showErrorModal)
    if (!downloadResult) {
      reset()
      return false
    }

    //* ファイルダウンロード処理
    const { blob, type } = downloadResult
    const fileBlob = new Blob(blob, { type })
    fileDownload(fileBlob, generatedCad.cad_file?.name)

    reset()
    return true
  }

  return (
    <>
      <Tooltip
        label={!shapesExist(shapes) ? <Text color="orange.400">鉄筋、平面等の要素がありません</Text> : undefined}
        hasArrow
        placement="left"
      >
        <Button
          _disabled={{ cursor: 'not-allowed', opacity: 0.5 }}
          onClick={() => setIsModalOpen(true)}
          isDisabled={isLoading3D || !shapesExist(shapes)}
          disabled={isLoading3D || !shapesExist(shapes)}
          spinnerPlacement="end"
          justifyContent="space-between"
          whiteSpace="nowrap"
          textAlign="left"
          data-testid="cad-export-button"
          w="100%"
          px={3}
        >
          <HStack>
            <Square fontSize={{ base: 'xl', xl: 'lg' }} className="chakra-menu__icon-wrapper">
              {isLoading3D ? <Spinner size="sm" /> : <ToolbarVolumeIconPolygon />}
            </Square>
            <Text>{isLoading3D ? `3D CAD出力中 (${progress}%)` : '3D CAD出力'}</Text>
          </HStack>
        </Button>
      </Tooltip>
      <Tooltip label={cad2dError ? <Text color="orange.400">{cad2dError}</Text> : undefined} hasArrow placement="left">
        <Button
          _disabled={{ cursor: 'not-allowed', opacity: 0.5 }}
          onClick={() => download(true, setIsLoading2D)}
          isDisabled={isLoading2D || !!cad2dError}
          disabled={isLoading2D || !!cad2dError}
          spinnerPlacement="end"
          justifyContent="space-between"
          whiteSpace="nowrap"
          textAlign="left"
          data-testid="2d-cad-export-button"
          w="100%"
          px={3}
        >
          <HStack>
            <Square fontSize={{ base: 'xl', xl: 'lg' }} className="chakra-menu__icon-wrapper">
              {isLoading2D ? <Spinner size="sm" /> : <ToolbarPlaneIcon />}
            </Square>
            <Text>{isLoading2D ? `2D CAD出力中 (${progress}%)` : '2D CAD出力'}</Text>
          </HStack>
        </Button>
      </Tooltip>

      <Modal
        closeOnOverlayClick
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        trapFocus={false}
        isCentered
        size="xs"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize="md">CADファイルを生成</ModalHeader>
          <ModalCloseButton hidden={isLoading3D} />
          <ModalBody position="relative">
            <FormControl>
              <FormLabel fontSize="sm">ファイル形式を選択してください</FormLabel>
              <Menu>
                <MenuButton
                  as={Button}
                  rightIcon={<ChevronDownIcon />}
                  backgroundColor="transparent"
                  borderWidth={1}
                  width="100%"
                  textAlign="left"
                  fontWeight="normal"
                >
                  .{extension}
                </MenuButton>
                <MenuList>
                  {Object.values(CAD_FILE_TYPES).map((val: CADFileType) => (
                    <MenuItem key={val} onClick={() => setExtension(val)}>
                      .{val}
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
            </FormControl>
          </ModalBody>

          <ModalFooter mt={8} justifyContent="center">
            <Button me={3} py={2} minW="100px" onClick={() => setIsModalOpen(false)}>
              キャンセル
            </Button>
            <Button colorScheme="primary" me={3} py={2} minW="100px" onClick={() => download()}>
              ファイル生成
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default CADExportButton
