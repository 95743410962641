import './styles.css'

import { FC, useCallback, useEffect, useRef, useState } from 'react'

import { Box, Button, Flex, Input, Portal, Text, Tooltip } from '@chakra-ui/react'
import ja from 'date-fns/locale/ja'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { debounce, merge } from 'lodash'
import {
  patchEditingInspectionAreas,
  patchEditingInspectionSheets,
  setGrabbedAreaId,
  setHasConflictedAreaName,
  setInitialPos,
  setMouseStartPos,
} from 'pages/projects/inspection-sheet/store'
import { DayPicker } from 'react-day-picker'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'

import { OrderGrabIcon } from 'assets/icons'

import { InspectionArea, InspectionSheet } from 'interfaces/inspection'

dayjs.extend(utc)

/**
 * Debounced patch inspection sheet onChange handler.
 * Without it, the input field would be laggy.
 */
const debouncedPatchInspectionSheet = debounce(
  (dispatch: ReturnType<typeof useAppDispatch>, inspectionSheet: InspectionSheet) => {
    dispatch(patchEditingInspectionSheets([inspectionSheet]))
  },
  200,
)

const debouncedPatchEditedInspectionArea = debounce(
  (dispatch: ReturnType<typeof useAppDispatch>, inspectionArea: Partial<InspectionArea>) => {
    dispatch(patchEditingInspectionAreas([inspectionArea]))
  },
  200,
)

const AreaItem: FC<{
  inspectionArea: InspectionArea
  inspectionSheet: InspectionSheet
}> = ({ inspectionArea, inspectionSheet }) => {
  // Store
  const dispatch = useAppDispatch()
  const inspectionAreaIdToEdit = useSelector((state: RootState) => state.inspectionSheet.inspectionAreaIdToEdit)
  const inspectionAreas = useSelector((state: RootState) => state.page.inspectionAreas)
  const editedInspectionSheets = useSelector((state: RootState) => state.inspectionSheet.editedInspectionSheets)
  const editedInspectionSheet = editedInspectionSheets.find(
    (sheet) => sheet.inspection_sheet_id === inspectionSheet.inspection_sheet_id,
  )
  const editedInspectionAreas = useSelector((state: RootState) => state.inspectionSheet.editedInspectionAreas)
  const editedInspectionArea = editedInspectionAreas.find(
    (area) => area.inspection_area_id === inspectionArea.inspection_area_id,
  )
  const initialPos = useSelector((state: RootState) => state.inspectionSheet.initialPos)

  const grabbedAreaId = useSelector((state: RootState) => state.inspectionSheet.grabbedAreaId)

  // States
  const [isEditingDate, setIsEditingDate] = useState(false)
  const [showNameConflicted, setShowNameConflicted] = useState(false)

  // Refs
  const nameRef = useRef<HTMLDivElement>(null)
  const nameInputRef = useRef<HTMLInputElement>(null)
  const nameButtonRef = useRef<HTMLButtonElement>(null)
  const grabbedNameButtonRef = useRef<HTMLButtonElement>(null)

  // Derived
  const isBeingDragged = grabbedAreaId === inspectionArea.inspection_area_id

  /**
   * Check if the name is conflicted with other areas.
   */
  const isNameConflicted = useCallback(() => {
    // exclude areas that has been edited, otherwise it will conflict with old data
    const orignalNameExists = inspectionAreas
      .filter(
        (area) =>
          !editedInspectionAreas.find((editedArea) => editedArea.inspection_area_id === area.inspection_area_id),
      )
      .some(
        (area) =>
          area.inspection_area_name === editedInspectionArea?.inspection_area_name &&
          area.inspection_area_id !== inspectionArea.inspection_area_id,
      )

    // again, exclude if the area has been edited to avoid conflicts with old data
    const isCurrentAreaEdited = editedInspectionAreas.some(
      (area) => area.inspection_area_id === inspectionArea.inspection_area_id,
    )
    const editedNameExists = editedInspectionAreas.some(
      (area) =>
        ((!isCurrentAreaEdited && area.inspection_area_name === inspectionArea.inspection_area_name) ||
          area.inspection_area_name === editedInspectionArea?.inspection_area_name) &&
        area.inspection_area_id !== inspectionArea.inspection_area_id,
    )

    return orignalNameExists || editedNameExists
  }, [
    inspectionAreas,
    editedInspectionArea,
    editedInspectionAreas,
    inspectionArea.inspection_area_name,
    inspectionArea.inspection_area_id,
  ])

  const onGrab = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (!nameButtonRef.current || !grabbedNameButtonRef.current) return

      const rect = nameButtonRef.current.getBoundingClientRect()
      dispatch(setInitialPos({ x: rect.x, y: rect.y }))
      dispatch(setMouseStartPos({ x: event.clientX, y: event.clientY }))
      dispatch(setGrabbedAreaId(inspectionArea.inspection_area_id))

      grabbedNameButtonRef.current.style.width = `${rect.width}px`
    },
    [dispatch, inspectionArea.inspection_area_id],
  )
  /**
   * Update name field if the original was updated, generally to conflicts and inspection areas was re-fetched
   */
  useEffect(() => {
    if (nameInputRef.current) {
      nameInputRef.current.value = editedInspectionArea?.inspection_area_name || inspectionArea.inspection_area_name
    }
  }, [editedInspectionArea?.inspection_area_name, inspectionArea.inspection_area_name])

  /**
   * Set the conflicted area name state on load.
   * Generally, this is used for errors thrown by BE instead of user input.
   * There's a delay for tooltip due to sliding drawer animation.
   * Also sets the global state for the conflicted area name to prevent saving.
   */
  useEffect(() => {
    const flag = isNameConflicted()

    // only set if there's a conflict
    // this is to avoid an area with no conflict to override an already true state
    if (flag) dispatch(setHasConflictedAreaName(flag))

    // wait for the drawer to slide in
    setTimeout(() => {
      setShowNameConflicted(flag)
    }, 250)
  }, [dispatch, isNameConflicted])

  return (
    <Box
      className={[
        'item',
        inspectionAreaIdToEdit === inspectionArea.inspection_area_id ? 'selected' : '',
        isBeingDragged ? 'dragging' : '',
      ]
        .filter(Boolean)
        .join(' ')}
      id={`edit-inspection-area-${inspectionArea.inspection_area_id}`}
    >
      <Flex className="header order">
        <Button
          ref={nameButtonRef}
          leftIcon={<OrderGrabIcon size={20} />}
          fontSize="md"
          onMouseDown={onGrab}
          className={['area-list-grabbable-item'].filter(Boolean).join(' ')}
        >
          <span className="text">
            {editedInspectionArea?.inspection_area_name || inspectionArea.inspection_area_name}
          </span>
        </Button>
        <Portal>
          <Button
            ref={grabbedNameButtonRef}
            id={`grabbed-area-${inspectionArea.inspection_area_id}`}
            leftIcon={<OrderGrabIcon size={20} />}
            display={isBeingDragged ? 'flex' : 'none'}
            fontSize="md"
            className={['area-list-grabbable-item', 'grabbed'].filter(Boolean).join(' ')}
            style={{ top: `${initialPos?.y || 0}px`, left: `${initialPos?.x || 0}px` }}
          >
            <span className="text">
              {editedInspectionArea?.inspection_area_name || inspectionArea.inspection_area_name}
            </span>
          </Button>
        </Portal>
      </Flex>
      <Box className="header edit" ref={nameRef}>
        <Tooltip
          label="同じエリア名が既に存在します"
          isOpen={showNameConflicted}
          hasArrow
          placement="top-start"
          portalProps={{ containerRef: nameRef }}
          bg="red.100"
          color="red.600"
        >
          <Input
            ref={nameInputRef}
            defaultValue={inspectionArea.inspection_area_name}
            required
            onBlur={(event) => {
              if (event.target.value === '') {
                event.target.value = inspectionArea.inspection_area_name
              }
            }}
            onChange={(event) => {
              if (event.target.value === '') return

              dispatch(setHasConflictedAreaName(false))
              setShowNameConflicted(isNameConflicted())
              debouncedPatchEditedInspectionArea(dispatch, {
                ...editedInspectionArea,
                inspection_area_id: inspectionArea.inspection_area_id,
                inspection_area_name: event.target.value,
              })
            }}
          />
        </Tooltip>
      </Box>
      <Flex className="content">
        <Flex className="input-row" ref={nameRef}>
          <Text>工種名</Text>
          <Input
            defaultValue={
              editedInspectionSheet?.construction_properties.construction_type ||
              inspectionSheet.construction_properties.construction_type
            }
            onChange={(event) => {
              debouncedPatchInspectionSheet(
                dispatch,
                merge({}, inspectionSheet, editedInspectionSheet, {
                  inspection_area_id: inspectionArea.inspection_area_id,
                  construction_properties: {
                    construction_type: event.target.value,
                  },
                }),
              )
            }}
          />
        </Flex>
        <Flex className="input-row">
          <Text>種別</Text>
          <Input
            defaultValue={
              editedInspectionSheet?.construction_properties.construction_type_detailed ||
              inspectionSheet.construction_properties.construction_type_detailed
            }
            onChange={(event) => {
              debouncedPatchInspectionSheet(
                dispatch,
                merge({}, inspectionSheet, editedInspectionSheet, {
                  inspection_area_id: inspectionArea.inspection_area_id,
                  construction_properties: {
                    construction_type_detailed: event.target.value,
                  },
                }),
              )
            }}
          />
        </Flex>
        <Flex className="input-row">
          <Text>作成者</Text>
          <Input
            defaultValue={editedInspectionSheet?.creator_name || inspectionSheet.creator_name}
            onChange={(event) => {
              debouncedPatchInspectionSheet(dispatch, {
                ...inspectionSheet,
                ...editedInspectionSheet,
                creator_name: event.target.value,
              })
            }}
          />
        </Flex>
        <Flex className="input-row">
          <Text>作成日</Text>
          <Input
            id="construction_date"
            type="text"
            value={
              (editedInspectionSheet || inspectionSheet)?.create_time_user_specified
                ? dayjs((editedInspectionSheet || inspectionSheet)?.create_time_user_specified).format('YYYY/MM/DD')
                : undefined
            }
            readOnly
            onClick={() => setIsEditingDate(true)}
          />
          {isEditingDate && (
            <Box
              fontSize="sm"
              position="absolute"
              boxShadow="md"
              borderColor="secondary.200"
              backgroundColor="white"
              borderWidth={1}
              rounded={8}
              right={0}
              top="100%"
              zIndex={10}
            >
              <DayPicker
                mode="single"
                locale={ja}
                selected={
                  (editedInspectionSheet || inspectionSheet)?.create_time_user_specified
                    ? dayjs((editedInspectionSheet || inspectionSheet)?.create_time_user_specified).toDate()
                    : undefined
                }
                defaultMonth={
                  (editedInspectionSheet || inspectionSheet)?.create_time_user_specified
                    ? dayjs((editedInspectionSheet || inspectionSheet)?.create_time_user_specified).toDate()
                    : undefined
                }
                onSelect={(date) => {
                  setIsEditingDate(false)
                  dispatch(
                    patchEditingInspectionSheets([
                      {
                        ...inspectionSheet,
                        ...editedInspectionSheet,
                        inspection_area_id: inspectionArea.inspection_area_id,
                        create_time_user_specified: dayjs.utc(date).toISOString(),
                      },
                    ]),
                  )
                }}
              />
            </Box>
          )}
        </Flex>
      </Flex>
    </Box>
  )
}

export default AreaItem
