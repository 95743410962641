import { FC } from 'react'

import { Box } from '@chakra-ui/react'
import { useInView } from 'react-intersection-observer'
import { Page } from 'react-pdf'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'

import { add, remove } from '../store/visiblePages'

export const DocumentPage: FC<{
  pageNumber: number
}> = ({ pageNumber }) => {
  // Store
  const dispatch = useAppDispatch()
  const scale = useSelector((state: RootState) => state.blueprint.scale)

  const { ref } = useInView({
    threshold: 0.3,
    onChange: (inView) => {
      dispatch(inView ? add(pageNumber + 1) : remove(pageNumber + 1))
    },
  })

  return (
    <Box ref={ref} key={`page-${pageNumber + 1}`} className="blueprint-page">
      <Page pageNumber={pageNumber + 1} scale={scale / 100} />
    </Box>
  )
}
