import { useCallback, useContext, useEffect, useMemo } from 'react'

import { setAttentionText } from 'pages/projects/common/AttentionText/store/attentionText'
import useDetectPolygonsVolume from 'pages/projects/editor/tools/VolumeEstimation/Polygon/hooks/useDetectPolygonsVolume'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'
import { Vector3 } from 'three'

import { INITIAL_SHAPE_STATE } from 'contexts/Editor'
import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import { EDITOR_COLLAPSE_TYPES, EDITOR_TOOLS } from 'config/constants'

import { EditorConfig } from 'interfaces/editor'
import { Editor, ShapeKeyType, VolumeEstimationMethods } from 'interfaces/interfaces'
import { PlaneSide } from 'interfaces/shape'

import { pointsOrientation } from 'services/Points'
import { VOLUME_ESTIMATION_IN_PROGRESS_TEXT, detectPolygonsVolume } from 'services/VolumeEstimation'

import { reset, resetWorkingPoints, setIsLoading } from '../store'

export default function useEditor(props: Editor): EditorConfig {
  // Props
  const { selectedTool, isPreviousTool, inspectionSheet, changeIsJobRunning, updateToggledCollapses } = props

  // Context
  const { getAccessToken } = useContext(UserContext)
  const { showErrorModal } = useContext(GlobalModalContext)

  // Store
  const dispatch = useAppDispatch()
  const isLoading = useSelector((root: RootState) => root.toolVolumeEstimationPolygon.isLoading)
  const isDirty = useSelector((root: RootState) => root.toolVolumeEstimationPolygon.isDirty)
  const planes = useSelector((root: RootState) => root.toolVolumeEstimationPolygon.planes)
  const project = useSelector((root: RootState) => root.page.project)
  const inspectionArea = useSelector((root: RootState) => root.page.inspectionArea)
  const maskRegions = useSelector((root: RootState) => root.maskPCD.regions)
  const planeCreationOrder = useSelector((root: RootState) => root.toolVolumeEstimationPolygon.planeCreationOrder)
  const rebarModelledConfirmed = useSelector(
    (root: RootState) => root.toolVolumeEstimationPolygon.rebarModelledConfirmed,
  )
  const estimationMethod = useSelector((root: RootState) => root.toolVolumeEstimationPolygon.estimationMethod)

  // Flags
  const isToolSelected = useMemo(() => selectedTool === EDITOR_TOOLS.VOLUME_POLYGON, [selectedTool])

  // Hooks
  const { poll } = useDetectPolygonsVolume(props, ShapeKeyType.POLYGON, {
    onFinished: useCallback(() => {
      dispatch(reset())
    }, [dispatch]),
  })

  // Sort points in clock-wise
  const orderedPoints = useMemo(() => {
    const upperPoints = planes.upper?.points.slice(0, -1) || []
    const lowerPoints = planes.lower?.points.slice(0, -1) || []
    const orientation = pointsOrientation(upperPoints.map((point) => new Vector3(...point)))

    const result = {
      upper: orientation === 'clockwise' ? upperPoints : [...upperPoints].reverse(),
      lower: orientation === 'clockwise' ? lowerPoints : [...lowerPoints].reverse(),
    }

    return result
  }, [planes.upper, planes.lower])

  /**
   * Toggle info panels when tool is selected
   */
  useEffect(() => {
    if (!isToolSelected) {
      if (isPreviousTool(EDITOR_TOOLS.VOLUME_POLYGON) && selectedTool !== EDITOR_TOOLS.FOCUS) {
        dispatch(resetWorkingPoints())
      }

      return
    }

    updateToggledCollapses([
      EDITOR_COLLAPSE_TYPES.detecting,
      EDITOR_COLLAPSE_TYPES.planeSide,
      EDITOR_COLLAPSE_TYPES.volumeEstimationMethod,
    ])
  }, [isToolSelected, selectedTool, isPreviousTool, planes.upper, planes.lower, updateToggledCollapses, dispatch])

  /**
   * Reset on unmount
   */
  useEffect(
    () => () => {
      dispatch(reset())
    },
    [dispatch],
  )

  return {
    buttons: {
      submit: {
        key: 'save-polygon',
        label: '体積を測定',
        loadingLabel: '測定中',
        onClick: async () => {
          if (!project || !inspectionArea?.down_sampled_file?.path || !inspectionSheet?.inspection_sheet_id) return

          const token = await getAccessToken()
          if (!token) return

          dispatch(setIsLoading(true))
          changeIsJobRunning(true)
          dispatch(setAttentionText({ message: VOLUME_ESTIMATION_IN_PROGRESS_TEXT }))

          const result = await detectPolygonsVolume(
            token,
            project.project_id,
            inspectionArea.inspection_area_id,
            inspectionSheet.inspection_sheet_id,
            ShapeKeyType.POLYGON,
            {
              ...INITIAL_SHAPE_STATE(),
              polygons: [
                {
                  points: orderedPoints.lower,
                  plane_side: PlaneSide.LOWER,
                },
                {
                  points: orderedPoints.upper,
                  plane_side: PlaneSide.UPPER,
                  is_virtual: planeCreationOrder[1] === PlaneSide.UPPER, // if starting from lower plane, upper plane is always virtual
                },
              ],
            },
            maskRegions.filter((region) => !region.invisible),
            estimationMethod,
            showErrorModal,
          )

          if (result) {
            void poll(result)
            return
          }

          dispatch(setIsLoading(false))
          changeIsJobRunning(false)
          dispatch(setAttentionText({ message: '' }))
        },
        isLoading: () => isLoading,
        isShown: () => isToolSelected || isLoading,
        isDisabled: () =>
          planes.upper === null ||
          planes.lower === null ||
          isLoading ||
          (estimationMethod === VolumeEstimationMethods.Advanced && !rebarModelledConfirmed),
      },
      reset: {
        onClick: useCallback(() => dispatch(reset()), [dispatch]),
        isShown: useCallback(() => isToolSelected || isLoading, [isToolSelected, isLoading]),
        isDisabled: useCallback(() => isLoading || !isDirty, [isLoading, isDirty]),
      },
      undo: {
        isShown: useCallback(() => isToolSelected || isLoading, [isToolSelected, isLoading]),
        isDisabled: useCallback(() => isLoading || !isDirty, [isLoading, isDirty]),
      },
    },
  }
}
