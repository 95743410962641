import { FC, useCallback, useContext } from 'react'

import { Box } from '@chakra-ui/react'
import CommentPopup from 'pages/projects/common/Comments/CommentPopup'
import { setEditingCommentId, setSelectedComment } from 'pages/projects/common/Comments/store/comments'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'

import { EditorContext } from 'contexts/Editor'

import { EDITOR_TOOLS } from 'config/constants'

import { Comment as IComment } from 'interfaces/interfaces'

import CommentIcon from './CommentIcon'
import { CommentPopup3d } from './CommentPopup3d'
import { useComment } from './hooks/comment'

const Comment: FC<{
  comment: IComment
  unplaced?: boolean
}> = ({ comment, unplaced }) => {
  // Hooks & Contexts
  const { onCancelMove, onConfirmMove, onMouseUp } = useComment(comment)
  const { changeTool } = useContext(EditorContext)

  // Store
  const dispatch = useAppDispatch()
  const selectedComment = useSelector((state: RootState) => state.comments.selectedComment)
  const editingCommentId = useSelector((state: RootState) => state.comments.editingCommentId)
  const isPageLoading = useSelector((state: RootState) => state.page.isLoading)
  const isCommentsLoading = useSelector((state: RootState) => state.comments.isLoading)

  /**
   * Remove hash from browser URL
   */
  const removeHashFromBrowserURL = useCallback(() => {
    if (window.location.hash.includes('comment')) {
      // get all hash and remove ones starting with 'comment'
      const hashes = window.location.hash
        .split('#')
        .filter((hash) => !hash.startsWith('comment'))
        .join('#')

      // router is not available in ThreeJS, so we use events to navigate, handled by Editor.tsx.
      document.dispatchEvent(
        new CustomEvent('app:navigate', {
          detail: { url: window.location.pathname + window.location.search + hashes, replace: true },
        }),
      )
    }
  }, [])

  return (
    <>
      <CommentIcon
        cartesianPosition={{
          x: comment.cartesian_position?.x || 0,
          y: comment.cartesian_position?.y || 0,
          z: comment.cartesian_position?.z || 0,
        }}
        disabled={(editingCommentId && editingCommentId !== comment.thread_id) || isPageLoading || isCommentsLoading}
        visible={selectedComment !== comment}
        isMoving={editingCommentId === comment.thread_id}
        onMouseUp={onMouseUp}
        onConfirmMove={onConfirmMove}
        onCancelMove={onCancelMove}
      />

      {/* Render comment popup when a comment is selected. Only for comment with fixed position */}
      {selectedComment?.thread_id === comment.thread_id && !unplaced && (
        <CommentPopup3d
          comment={comment}
          onClose={() => {
            dispatch(setEditingCommentId(null))
            dispatch(setSelectedComment(null))
            removeHashFromBrowserURL()
          }}
          onMove={() => {
            changeTool(EDITOR_TOOLS.COMMENT)
            dispatch(setSelectedComment(null))
            dispatch(setEditingCommentId(comment.thread_id!))
            removeHashFromBrowserURL()
          }}
        />
      )}

      {/* Render popup for unplaced comments */}
      {/* We use the CommentPopup directly as the parent is the one wrapped with drei-HTML. */}
      {selectedComment?.thread_id === comment.thread_id && unplaced && (
        <Box position="relative" top="-2px" left="4px">
          <CommentPopup
            comment={comment}
            onClose={() => {
              dispatch(setEditingCommentId(null))
              dispatch(setSelectedComment(null))
              removeHashFromBrowserURL()
            }}
            onMove={() => {
              changeTool(EDITOR_TOOLS.COMMENT)
              dispatch(setSelectedComment(null))
              dispatch(setEditingCommentId(comment.thread_id!))
              removeHashFromBrowserURL()
            }}
          />
        </Box>
      )}
    </>
  )
}

export default Comment
