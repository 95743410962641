import { FC, useRef } from 'react'

import { Box, HStack, Switch, Text, Tooltip } from '@chakra-ui/react'

export interface ToggleFieldProps {
  label: string
  value: boolean
  disabled?: boolean
  tooltip?: string
  onChange: (value: boolean) => void
}

const ToggleField: FC<ToggleFieldProps> = ({ label, value, disabled = false, tooltip, onChange }) => {
  const ref = useRef(null)

  return (
    <HStack w="100%" ref={ref}>
      <Tooltip label={tooltip} hasArrow placement="top-start" portalProps={{ containerRef: ref }}>
        <Box>
          <Switch
            id="email-alerts"
            w="50px"
            isChecked={value}
            onChange={(e) => onChange(!!e.target.value)}
            disabled={disabled}
          />
        </Box>
      </Tooltip>
      <Text flex={1}>{label}</Text>
    </HStack>
  )
}

export default ToggleField
