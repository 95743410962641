import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { isMobile } from 'react-device-detect'
import { Vector3 } from 'three'

import { PointArray } from 'interfaces/attribute'
import { CameraProfile } from 'interfaces/inspection'

import { getCentroidOfPolygon3d } from 'services/Points'

export enum SelectionStage {
  DRAW = 'DRAW',
  ROTATE = 'ROTATE',
  FOCUS = 'FOCUS',
  SAVING = 'SAVING',
}

interface CameraProfileState {
  /**
   * Selection stage.
   */
  selectionStage: SelectionStage

  /**
   * Saved camera profile.
   */
  cameraProfile?: CameraProfile

  /**
   * Camera target (center/pivot point).
   */
  target?: PointArray

  /**
   * Plane drawn by user, defined by 3 points.
   */
  drawnPlanePoints: PointArray[]

  /**
   * Preview plane derived from drawPlanePoints, defined by 3 points.
   */
  previewPlanePoints: PointArray[]

  /**
   * Dirty flag.
   */
  isDirty: boolean
}

export const initialState: CameraProfileState = {
  selectionStage: SelectionStage.DRAW,
  target: undefined,
  drawnPlanePoints: [],
  previewPlanePoints: [],
  isDirty: false,
}

export const slice = createSlice({
  name: 'cameraProfile',
  initialState,
  reducers: {
    setSelectionStage: (state, action: PayloadAction<SelectionStage>) => {
      state.selectionStage = action.payload
    },
    setCameraProfile: (state, action: PayloadAction<CameraProfile | undefined>) => {
      state.cameraProfile = action.payload
    },
    setTarget: (state, action: PayloadAction<PointArray>) => {
      state.target = action.payload
    },
    addPlanePoint: (
      state,
      { payload: { point, isTouched } }: PayloadAction<{ point: PointArray; isTouched: boolean }>,
    ) => {
      state.isDirty = true
      state.drawnPlanePoints.push(point)

      if (state.drawnPlanePoints.length === (isTouched ? 3 : 4)) {
        state.target = getCentroidOfPolygon3d(state.drawnPlanePoints.map((p) => new Vector3(...p))).toArray()
        state.selectionStage = SelectionStage.ROTATE
      }
    },
    updateLastPlanePoint: (state, action: PayloadAction<PointArray>) => {
      // If there's no point yet, add it
      if (state.drawnPlanePoints.length === 0) {
        state.drawnPlanePoints.push(action.payload)
      }

      state.drawnPlanePoints[state.drawnPlanePoints.length - 1] = action.payload
    },
    updatePlanePoint: (state, action: PayloadAction<{ index: number; point: PointArray }>) => {
      state.drawnPlanePoints[action.payload.index] = action.payload.point
    },
    undoLastPlanePoint: (state) => {
      if (isMobile) {
        state.drawnPlanePoints = state.drawnPlanePoints.slice(0, -1)
      } else {
        state.drawnPlanePoints = state.drawnPlanePoints.slice(0, -2).concat(state.drawnPlanePoints.slice(-1))
      }
    },
    setPreviewPlanePoints: (state, action: PayloadAction<PointArray[]>) => {
      state.previewPlanePoints = action.payload
    },
    resetWorkingState: (state) => {
      state.selectionStage = SelectionStage.DRAW
      state.target = undefined
      state.drawnPlanePoints = []
      state.previewPlanePoints = []
      state.isDirty = false
    },
    reset: () => initialState,
  },
})

export const {
  setSelectionStage,
  setCameraProfile,
  setTarget,

  addPlanePoint,
  updatePlanePoint,
  updateLastPlanePoint,
  undoLastPlanePoint,
  setPreviewPlanePoints,

  resetWorkingState,
  reset,
} = slice.actions

export default slice.reducer
