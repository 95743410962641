import { EditorTool } from 'interfaces/editor'

import CameraFocus from './CameraFocus/CameraFocus'
import CameraProfile from './CameraProfile/CameraProfile'
import Comment from './Comment/Comment'
import CustomMeasurement from './CustomMeasurement/CustomMeasurement'
import DepthEstimation from './DepthEstimation/DepthEstimation'
import GridTool from './Grid/Grid'
import MaskPCD from './MaskPCD/MaskPCD'
import PlaneDetection from './PlaneDetection/PlaneDetection'
import Polyline from './Polyline/Polyline'
import RebarDetection from './RebarDetection/RebarDetection'
import VolumeEstimation from './VolumeEstimation/VolumeEstimation'

export const Tools: EditorTool[] = [
  MaskPCD,
  RebarDetection,
  Polyline,
  PlaneDetection,
  VolumeEstimation,
  DepthEstimation,
  GridTool,
  CustomMeasurement,
  CameraProfile,
  CameraFocus,
  Comment,
]

export const getCurrentEditorTool = (selectedTool: string): EditorTool | undefined => {
  let currentTool: EditorTool | undefined

  Tools.forEach((tool) => {
    // Prioritize variants
    if (tool.toolbar?.variants) {
      tool.toolbar.variants.forEach((variant) => {
        if (variant.key === selectedTool) {
          currentTool = variant
        }
      })
    }

    // If we can't find in variants, check parent
    if (!currentTool && tool.key === selectedTool) {
      currentTool = tool
    }
  })

  return currentTool
}
