import './styles.css'

import { FC } from 'react'

import { Flex, HStack, IconButton, Input, Square, Text } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { RootState } from 'store/app'

import { AddIcon, MinusIcon } from 'assets/icons'

import { BLUEPRINT_SCALE_STEP } from 'config/constants'

import { useActionsPanel } from '../../hooks/actionsPanel'

const ActionsPanel: FC = () => {
  // Store
  const totalPages = useSelector((state: RootState) => state.blueprint.totalPages)

  // use Hook
  const {
    localScale,
    localPage,
    setLocalPage,
    scrollToPage,
    handleInputScaleChange,
    handleButtonScaleChange,
    onInputScaleChange,
  } = useActionsPanel(totalPages)

  return (
    <Flex position="relative" h={{ base: 12, xl: 10 }} gap={1} color="white" w="100%" id="blueprint-actions">
      <HStack backgroundColor="secondary.800" p={1} pr={2} borderRadius="md" fontSize={{ base: 'md', xl: 'sm' }}>
        <Input
          className="navbar"
          value={localPage}
          minW={{ base: 16, xl: 10 }}
          height={{ base: 10, xl: 8 }}
          fontSize={{ base: 'md', xl: 'sm' }}
          size="sm"
          type="number"
          min={1}
          max={totalPages}
          onChange={(e) => setLocalPage(Math.min(Math.max(Number(e.target.value), 1), totalPages))}
          onKeyUp={(e) => {
            if (e.key === 'Enter') {
              scrollToPage(localPage)
            }
          }}
          onBlur={() => {
            scrollToPage(localPage)
          }}
        />
        <Text color="secondary.400"> /&nbsp;{totalPages}</Text>
      </HStack>
      <HStack backgroundColor="secondary.800" p={1} borderRadius="md" spacing={1} flex={1}>
        <IconButton
          aria-label="minus-scale"
          icon={
            <Square fontSize={{ base: 'xl', xl: 'lg' }}>
              <MinusIcon />
            </Square>
          }
          onClick={() => handleButtonScaleChange(-BLUEPRINT_SCALE_STEP)}
          variant="toolbarIcon"
        />
        <Input
          className="navbar"
          value={localScale}
          minW={{ base: 20, xl: 14 }}
          height={{ base: 10, xl: 8 }}
          fontSize={{ base: 'md', xl: 'sm' }}
          size="sm"
          onChange={(e) => {
            onInputScaleChange(e.target.value)
          }}
          onKeyUp={(e) => {
            if (e.key === 'Enter') {
              handleInputScaleChange(localScale)
            }
          }}
          onBlur={() => {
            handleInputScaleChange(localScale)
          }}
        />
        <IconButton
          aria-label="plus-scale"
          icon={
            <Square fontSize={{ base: 'xl', xl: 'lg' }}>
              <AddIcon />
            </Square>
          }
          onClick={() => handleButtonScaleChange(BLUEPRINT_SCALE_STEP)}
          variant="toolbarIcon"
        />
      </HStack>
    </Flex>
  )
}

export default ActionsPanel
