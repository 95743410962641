import { useCallback, useContext } from 'react'

import { Text } from '@chakra-ui/react'
import mixpanel from 'mixpanel-browser'
import { setAttentionText } from 'pages/projects/common/AttentionText/store/attentionText'
import { setSelectedShapeIds } from 'pages/projects/editor/store/editor'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'

import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import { MODAL_TYPES } from 'config/constants'

import { Editor } from 'interfaces/canvas'
import { Shape, ShapeDetectionResult, ShapeKeyType } from 'interfaces/shape'
import { PendingAsyncJobToken } from 'interfaces/system'

import { checkJobStatusForDetectShapes } from 'services/InspectionArea'
import { convertDetectionResultToShapes } from 'services/Points'

interface PollReturnValue {
  shapes: Shape[]
  reevaluate_volume_job_tokens?: PendingAsyncJobToken[]
}

export default function useDetectShapes(
  /**
   * Editor context.
   */
  { inspectionSheet, fetchInspectionItems, fetchShapes }: Editor,

  /**
   * Shape key to be detected.
   */
  shapeKey: ShapeKeyType,
) {
  // Context
  const { getAccessToken } = useContext(UserContext)
  const { showModal, showErrorModal } = useContext(GlobalModalContext)

  // Store
  const dispatch = useAppDispatch()
  const project = useSelector((root: RootState) => root.page.project)
  const inspectionArea = useSelector((root: RootState) => root.page.inspectionArea)

  /**
   * Polls the server for completion of volume estimation.
   */
  const poll = useCallback(
    async (
      jobToken: string,
      interval = 5000,
      /**
       * Callback to be called during various stages of detecting shapes.
       */
      callbacks: {
        /**
         * Callback to be called after detecting shapes, but before updating the state.
         */
        onPostDetect?: (result: PollReturnValue) => Promise<boolean>
      } = {},
    ): Promise<PollReturnValue | null> => {
      if (!project || !inspectionArea?.down_sampled_file?.path || !jobToken || !inspectionSheet) return null

      const token = await getAccessToken()
      if (!token) return null

      return new Promise<PollReturnValue>((resolve, reject) => {
        const check = async () => {
          const errorResults: ShapeDetectionResult[] = []
          const jobResult = await checkJobStatusForDetectShapes(token, jobToken, showErrorModal)
          if (jobResult === null) {
            setTimeout(() => {
              void check()
            }, interval)
          } else if (jobResult === false) {
            reject()
          } else if (typeof jobResult === 'object') {
            const results = jobResult.list_shapes as ShapeDetectionResult[]
            const newShapes = convertDetectionResultToShapes(
              shapeKey,
              results.filter((shape) => !shape.error_id),
            ) as Shape[]
            errorResults.push(...(results.filter((shape) => !!shape.error_id) || []))

            const returnResult = {
              shapes: newShapes,
              reevaluate_volume_job_tokens: jobResult.reevaluate_volume_job_tokens,
            }

            if (newShapes.length > 0) {
              if (callbacks?.onPostDetect) await callbacks.onPostDetect(returnResult)

              // refetch inspection sheet items as various behavior related to estimate volume
              // depends on inspection item generated by estimateVolume API call
              // do this regardless of errors since if some succeeds, those need to be shown to user
              await Promise.all([fetchInspectionItems(), fetchShapes()])

              // set the new planes as selected planes
              dispatch(setSelectedShapeIds(newShapes.map((shape) => shape.shape_id)))
            }

            const totalErrorShapes = errorResults.length

            // track with mixpanel
            mixpanel.track('Finish detecting shapes', {
              'Shape type': shapeKey,
              'Number of shapes (success)': jobResult.list_shapes.length - totalErrorShapes,
              'Number of shapes (error)': totalErrorShapes,
            })

            // if job has failed or no shape has been detected, show a message
            if (!jobResult.list_shapes.length) {
              dispatch(setAttentionText({ message: '' }))
              showModal({
                body: '要素は検出されませんでした。',
                modalType: MODAL_TYPES.ALERT,
                title: '要素検出',
                confirmText: '了解',
              })
              // if there are some shapes failed to detected, show a message
            } else if (totalErrorShapes) {
              dispatch(setAttentionText({ message: '' }))
              showModal({
                body: (
                  <Text>
                    <Text fontWeight="bold" display="inline-block">
                      {totalErrorShapes}
                    </Text>
                    箇所の領域で要素が検出されませんでした。
                  </Text>
                ),
                modalType: MODAL_TYPES.ALERT,
                title: '要素検出',
                confirmText: '了解',
              })
            }

            resolve(returnResult)
          }
        }

        void check()
      })
    },
    [
      project,
      inspectionArea,
      inspectionSheet,
      shapeKey,
      dispatch,
      showErrorModal,
      getAccessToken,
      showModal,
      fetchShapes,
      fetchInspectionItems,
    ],
  )

  return {
    poll,
  }
}
