/* istanbul ignore file */
import { Position } from './attribute'
import { AxiosCuboid, Cuboid } from './shape'

export enum IMAGE_UPLOAD_STATUS {
  NONE = 'none',
  PENDING = 'pending', // waiting for upload
  DELETING = 'deleting', // waiting for delete
  LOADING = 'loading', // action in progress (uploading, deleting, etc.)
  DONE = 'done',
  ERROR = 'error',
}

export interface Position2D {
  x: number
  y: number
}

export interface RectangleInBlueprint {
  coordinate: Position2D
  extent: {
    width: number
    height: number
  }
}

interface BaseComment extends CommentCore {
  cartesian_position?: Position
  blueprint_position?: RectangleInBlueprint
  blueprint_id?: string
  reply_amount?: number
  thread_body: string
  thread_id?: string
  images?: CommentUploadedImage[]
}

export interface Comment extends BaseComment {
  cuboid_position?: Cuboid
  replies?: CommentReply[]

  // used only on FE side to indicate that comment does not have fixed position
  // either on blueprint or in 3D space
  unplaced?: boolean
}

export interface AxiosComment extends BaseComment {
  cuboid_position?: AxiosCuboid
}

export interface CommentReply extends CommentCore {
  reply_body: string
  reply_id?: string
  images?: CommentUploadedImage[]
}

export interface CommentCore {
  author_email?: string
  author_id?: string
  author_name: string
  inspection_area_id: string
  created_at?: number
  updated_at?: number
}

export interface CommentUploadedImage {
  image_id: string
  filename: string
  caption?: string
  url?: string
  compressed_size_url?: string
  content_type?: string

  // used only on FE side
  status?: IMAGE_UPLOAD_STATUS
}

export interface CommentImage {
  file: File
  caption: string
}
