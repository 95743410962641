import { FC } from 'react'

import { Flex } from '@chakra-ui/react'

import { InspectionItem, InspectionItemNumberValues } from 'interfaces/inspection'

import Metric from './Metric/Metric'

const MetricsGroup: FC<{
  /**
   * Title of the metrics group
   */
  title: string

  /**
   * Function to get the default title of the inspection item.
   * @param item Current inspection item
   * @param index Index of the current inspection item
   */
  itemsDefaulTitle: (item: InspectionItem, index: number) => string

  /**
   * Unit of the values in the metrics group
   */
  unit: string

  /**
   * All inspection items in the metrics group
   */
  inspectionItems: InspectionItem[]

  /**
   * Values of the inspection items in the metrics.
   * Make sure that the order of the values matches the order of the inspection items.
   */
  inspectionItemValues: InspectionItemNumberValues[]

  /**
   * Whether the individual metrics are hidden or not.
   */
  isMetricsHidden: boolean

  /**
   * Whether the name of the inspection item is changeable or not.
   */
  nameChangeable?: boolean

  /**
   * All additional metrics' toggle state, including ones not belonging to this metric.
   */
  allAdditionalMetricsToggle?: boolean[]
}> = ({
  title,
  itemsDefaulTitle,
  inspectionItems,
  inspectionItemValues,
  unit,
  isMetricsHidden,
  nameChangeable,
  allAdditionalMetricsToggle,
}) => (
  <Flex
    alignItems="center"
    bg="gray.50"
    borderBottomColor="gray.300"
    borderBottomWidth={1}
    className="metrics-group data-rows"
  >
    <Flex style={{ writingMode: 'vertical-lr' }} p={4} fontSize="xl">
      {title}
    </Flex>
    <Flex flexDirection="column" bg="white" alignSelf="stretch" borderRightWidth={1} borderRightColor="gray.300">
      {/* Metrics go here */}
      {inspectionItems.map((inspectionItem, index) => (
        <Metric
          key={`${inspectionItem.inspection_item_id}-metric`}
          inspectionItem={inspectionItem}
          title={inspectionItem.part_name}
          defaulTitle={itemsDefaulTitle(inspectionItem, index)}
          inspectionItemValue={inspectionItemValues[index]}
          unit={unit}
          isHidden={isMetricsHidden}
          nameChangeable={nameChangeable}
          allAdditionalMetricsToggle={allAdditionalMetricsToggle}
        />
      ))}
    </Flex>
  </Flex>
)

export default MetricsGroup
