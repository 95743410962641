import { FC, useEffect, useRef } from 'react'

import { Box, Button, HStack } from '@chakra-ui/react'
import dayjs from 'dayjs'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { RootState, useAppDispatch } from 'store/app'

import { hashCode } from 'services/Util'

import { addMessage, clearMessages } from './store'

dayjs.extend(LocalizedFormat)

const DebugPanel: FC = () => {
  const ref = useRef<HTMLDivElement>(null)
  const dispatch = useAppDispatch()
  const messages = useSelector((state: RootState) => state.debug.messages)
  const [searchParams] = useSearchParams()

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTop = ref.current.scrollHeight
    }
  }, [messages])

  if (!searchParams.has('debug')) return null

  return (
    <Box>
      <HStack position="absolute" bottom="20%" right={2}>
        <Button
          onClick={() => {
            dispatch(addMessage('--------'))
          }}
          borderBottomRadius={0}
          size="sm"
        >
          Border
        </Button>

        <Button
          onClick={() => {
            dispatch(clearMessages())
          }}
          borderBottomRadius={0}
          size="sm"
        >
          Clear
        </Button>
      </HStack>
      <Box
        position="absolute"
        bottom="0"
        left="0"
        w="100%"
        h="20%"
        bg="#222"
        borderTop="1px solid #ccc"
        overflow="auto"
        ref={ref}
      >
        {messages.map((message) => (
          <Box
            key={`debug-line-${hashCode(`${message.text}-${message.timestamp}`)}`}
            borderBottom="1px solid #333"
            px="5px"
            fontFamily="monospace"
            color="#ccc"
          >
            [{dayjs(message.timestamp).format('L LTS')}] {message.text}
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export default DebugPanel
