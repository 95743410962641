import { FC, useCallback, useContext, useState } from 'react'

import LayerItem from 'pages/projects/editor/infoPanels/components/LayerItem'
import { isMobile } from 'react-device-detect'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'

import { EditorContext } from 'contexts/Editor'

import { EDITOR_TOOLS } from 'config/constants'

import { zeroPad } from 'services/Util'

import {
  removePlane,
  resetWorkingPoints,
  toggleAllPlaneVisibility,
  togglePlaneVisibility,
} from '../../tools/PlaneDetection/store'

const WorkingPlanes: FC = () => {
  // Store
  const dispatch = useAppDispatch()
  const planes = useSelector((state: RootState) => state.toolPlaneDetection.planes)
  const planeWorkingPoints = useSelector((root: RootState) => root.toolPlaneDetection.workingPoints)
  const isLoading = useSelector((state: RootState) => state.toolPlaneDetection.isLoading)

  // Context
  const { selectedTool, isLayerModifying } = useContext(EditorContext)

  // States
  const [collapsed, setCollapsed] = useState(false)

  // Flags
  const isAllInvisible = planes.every((plane) => !plane || (plane && plane.invisible === true))

  /**
   * Update visibility of both planes
   */
  const handleUpdateAllVisibility = useCallback(() => {
    dispatch(toggleAllPlaneVisibility())
  }, [dispatch])

  if (selectedTool !== EDITOR_TOOLS.PLANE) {
    return null
  }

  return (
    <>
      <LayerItem
        collapsible
        collapsed={collapsed}
        updateExpansion={setCollapsed}
        disabled={isLayerModifying}
        invisible={isAllInvisible}
        label="平面"
        updateVisibility={handleUpdateAllVisibility}
      />

      {/* Completed planes */}
      {!collapsed &&
        planes.map((plane, planeIndex) => (
          <LayerItem
            key={plane.id}
            isChild
            disabled={isLoading || isLayerModifying}
            selected={false}
            invisible={plane.invisible}
            updateVisibility={() => dispatch(togglePlaneVisibility(planeIndex))}
            label={`面積${zeroPad(planeIndex + 1, 2)}`}
            deleteLayer={() => {
              dispatch(removePlane(planeIndex))
            }}
          />
        ))}

      {/* Working plane */}
      {planeWorkingPoints.length > (isMobile ? 0 : 1) && (
        <LayerItem
          isChild
          disabled={planeWorkingPoints.length <= 1}
          selected={false}
          label={`面積${zeroPad(planes.length + 1, 2)}`}
          deleteLayer={() => {
            dispatch(resetWorkingPoints())
          }}
        />
      )}
    </>
  )
}

export default WorkingPlanes
