import { FC, PropsWithChildren } from 'react'

import { AppError } from 'pages/_error/AppError'
import { useSelector } from 'react-redux'
import { RootState } from 'store/app'

import MaintenanceImage from 'assets/imgs/maintenance-page.png'

import { PageError } from 'interfaces/system'

const PageErrorHandler: FC<PropsWithChildren> = ({ children }) => {
  // Store
  const pageError = useSelector((state: RootState) => state.page.pageError)

  switch (pageError) {
    case PageError.NotFound:
      return <AppError description="ページが存在しません" image={MaintenanceImage} />
    case PageError.InternalServerError:
      return (
        <AppError description="エラーが発生しました。時間をおいて、再度実行してください。" image={MaintenanceImage} />
      )
    case PageError.Forbidden:
      return <AppError description="アクセス権限がありません" image={MaintenanceImage} />
    default:
      break
  }

  return <div>{children}</div>
}

export default PageErrorHandler
