import { FC, useCallback, useContext, useEffect, useState } from 'react'

import { Container, Link, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import Navbar from 'components/Navbar/Navbar'
import PageHeading from 'components/PageHeading'
import { useSelector } from 'react-redux'
import { NavLink as RouterNavLink, useNavigate } from 'react-router-dom'
import { RootState } from 'store/app'

import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import { USER_TYPES } from 'config/constants'
import { CONTAINER_MAX_WIDTH } from 'config/styles'

import { UserProfile } from 'interfaces/user'

import { getUserList } from 'services/Users'

const UserList: FC = () => {
  const navigate = useNavigate()

  // Context
  const { showErrorModal } = useContext(GlobalModalContext)
  const { getAccessToken } = useContext(UserContext)

  // Store
  const userLoaded = useSelector((state: RootState) => state.user.userLoaded)
  const userType = useSelector((state: RootState) => state.user.userType)

  const [users, setUsers] = useState<UserProfile[]>([])

  const fetchData = useCallback(async () => {
    if (userType !== USER_TYPES.ADMIN) {
      return null
    }

    const token = await getAccessToken()
    if (!token) return Promise.resolve()

    const result = await getUserList(token, showErrorModal)
    if (result) setUsers(result)

    return Promise.resolve()
  }, [userType, getAccessToken, showErrorModal])

  useEffect(() => {
    if (!userLoaded) return

    if (userType !== USER_TYPES.ADMIN) {
      navigate('/')
    }
  }, [userType, userLoaded, navigate])

  useEffect(() => {
    void fetchData()
  }, [fetchData])

  if (userType !== USER_TYPES.ADMIN) {
    return null
  }

  return (
    <>
      <Navbar />

      <Container maxW={CONTAINER_MAX_WIDTH}>
        <PageHeading>ユーザーリスト</PageHeading>
        <TableContainer mb={10}>
          <Table w="100%" variant="simple" size="sm">
            <Thead fontSize="sm">
              <Tr>
                <Th>User</Th>
                <Th textAlign="right">Hubspot ID</Th>
                <Th textAlign="right">氏名</Th>
                <Th textAlign="right">会社名</Th>
                <Th textAlign="right">所属部署</Th>
                <Th textAlign="right">user type</Th>
                <Th textAlign="right">Stripe ID</Th>
                <Th textAlign="right">Subscription</Th>
              </Tr>
            </Thead>
            <Tbody>
              {users.map((user) => (
                <Tr key={user.hubspot_properties.email}>
                  <Td>
                    <Link
                      as={RouterNavLink}
                      variant="underline"
                      to={`/dashboard?user_id=${user.user_id}&user_email=${user.hubspot_properties.email}`}
                    >
                      {user.hubspot_properties.email}
                    </Link>
                  </Td>
                  <Td textAlign="right">{user.hubspot_id}</Td>
                  <Td textAlign="right">
                    {user.hubspot_properties.last_name} {user.hubspot_properties.first_name}
                  </Td>
                  <Td textAlign="right">{user.hubspot_properties.company}</Td>
                  <Td textAlign="right">{user.hubspot_properties.department}</Td>
                  <Td textAlign="right">{user.user_type}</Td>
                  <Td textAlign="right">{user.stripe_id}</Td>
                  <Td textAlign="right">{user.subscriptions?.type}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Container>
    </>
  )
}

export default UserList
