import axios from 'axios'

import { API_GATEWAY_URL } from 'config/environments'

import { ServiceStatus, TutorialVideoConfig } from 'interfaces/interfaces'

import { ERROR_PROCESS, processErrorHandler } from './ErrorHandler'

/**
 * Get service status. Even if the user is not logged in, this function can be called.
 *
 * @param showErrorModal Error modal function
 * @param token Access token
 */
export const getServiceStatus = (
  showErrorModal: (message: string) => void,
  token?: string,
): Promise<ServiceStatus | null> =>
  axios
    .get<ServiceStatus>(token ? `${API_GATEWAY_URL}/authorized/service-status` : `${API_GATEWAY_URL}/service-status`, {
      responseType: 'json',
      headers: token ? { 'X-Authorization': `Bearer ${token}` } : {},
    })
    .then((response) => response.data)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.GET_SERVICE_STATUS, showErrorModal)
      return null
    })

/**
 * Get tutorial video config. Even if the user is not logged in, this function can be called.
 *
 * @param showErrorModal Error modal function
 * @param token Access token
 */
export const getTutorialVideoConfig = (
  showErrorModal: (message: string) => void,
  token?: string,
): Promise<TutorialVideoConfig[] | null> =>
  axios
    .get<TutorialVideoConfig[]>(`${API_GATEWAY_URL}/tutorial-videos-config`, {
      responseType: 'json',
      headers: token ? { 'X-Authorization': `Bearer ${token}` } : {},
    })
    .then((response) => response.data)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.GET_TUTORIAL_VIDEO_CONFIG, showErrorModal)
      return null
    })
