import { useCallback, useContext, useEffect, useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { cloneDeep } from 'lodash'
import {
  setComments,
  setEditingComment,
  setEditingCommentId,
  setSelectedComment,
} from 'pages/projects/common/Comments/store/comments'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { RootState, useAppDispatch } from 'store/app'

import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import {
  BLUEPRINT_COMMENT_AREA_BORDER_SIZE,
  BLUEPRINT_COMMENT_AREA_MIN_SIZE,
  BLUEPRINT_TOOLS,
  BLUEPRINT_VIEWIER_CONTAINER_ID,
  TEMP_COMMENT_ID,
} from 'config/constants'

import { getBlueprintUrl } from 'services/Blueprint'

const UNPLACED_FRAME_WIDTH = BLUEPRINT_COMMENT_AREA_BORDER_SIZE * 2 + BLUEPRINT_COMMENT_AREA_MIN_SIZE
const UNPLACED_FRAME_HEIGHT = UNPLACED_FRAME_WIDTH
const UNPLACED_FRAME_GAP = 5

export const useDocumentViewer = () => {
  // Get IDs from the URL
  const { project_id } = useParams<{ project_id: string }>()
  const location = useLocation()
  const queries = new URLSearchParams(location.search)
  const inspection_area_id = queries.get('area')
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  // Contexts
  const { user } = useAuth0()
  const { getAccessToken } = useContext(UserContext)
  const { showErrorModal } = useContext(GlobalModalContext)

  // Store
  const dispatch = useAppDispatch()
  const comments = useSelector((state: RootState) => state.comments.comments)
  const selectedBlueprint = useSelector((state: RootState) => state.blueprint.selectedBlueprint)
  const selectedTool = useSelector((state: RootState) => state.blueprint.selectedTool)
  const editingCommentId = useSelector((state: RootState) => state.comments.editingCommentId)
  const editingComment = useSelector((state: RootState) => state.comments.editingComment)
  const selectedComment = useSelector((state: RootState) => state.comments.selectedComment)

  // State
  const [totalPages, setTotalPages] = useState(0)
  const [blueprintUrl, setBlueprintUrl] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  /**
   * Wrap async function with proper handling of loading state
   *
   * @param fn Function to run
   */
  const withLoading = async (fn: () => Promise<boolean>): Promise<boolean> => {
    setIsLoading(true)
    return fn().finally(() => setIsLoading(false))
  }

  /**
   * Get signed URL for blueprint
   */
  useEffect(() => {
    if (project_id) {
      // if there's a blueprint selected, get the signed URL
      if (selectedBlueprint) {
        void withLoading(async () => {
          const token = await getAccessToken()
          if (!token) {
            return false
          }

          const url = await getBlueprintUrl(token, project_id, selectedBlueprint.blueprint_id, showErrorModal)
          setBlueprintUrl(url)

          return true
        })
      }
      // otherwise, clear the URL. This is to prevent the previous blueprint from showing up after deletion.
      else {
        setBlueprintUrl(null)
      }
    }
  }, [getAccessToken, project_id, selectedBlueprint, showErrorModal, dispatch])

  /**
   * On click of an empty area, create a new comment
   */
  const onClick = useCallback(
    (e: MouseEvent) => {
      if (!inspection_area_id) return

      if (editingComment && editingComment.thread_id === TEMP_COMMENT_ID && !selectedComment) {
        dispatch(setSelectedComment(editingComment))
        navigate(
          {
            search: searchParams.toString(),
            hash: `comments-${editingComment.thread_id || ''}`,
          },
          { replace: true },
        )
        return
      }

      // Make sure we're on right tool and not editing any frame or viewing any comment
      if (selectedTool === BLUEPRINT_TOOLS.COMMENT && !editingCommentId && !selectedComment) {
        const container = document.querySelector(BLUEPRINT_VIEWIER_CONTAINER_ID)
        if (!container) return

        const rect = container.getBoundingClientRect()
        dispatch(setEditingCommentId(TEMP_COMMENT_ID))
        dispatch(
          setEditingComment({
            thread_id: TEMP_COMMENT_ID,
            blueprint_id: selectedBlueprint?.blueprint_id || '',
            inspection_area_id,
            author_name: user?.nickname || '',
            thread_body: '',
            blueprint_position: {
              coordinate: {
                x: e.clientX - rect.left,
                y: e.clientY - rect.top,
              },
              extent: {
                width: BLUEPRINT_COMMENT_AREA_MIN_SIZE,
                height: BLUEPRINT_COMMENT_AREA_MIN_SIZE,
              },
            },
          }),
        )
      }
    },
    [
      selectedTool,
      editingComment,
      editingCommentId,
      selectedBlueprint,
      selectedComment,
      user,
      inspection_area_id,
      dispatch,
      navigate,
      searchParams,
    ],
  )

  /**
   * Auto-position unplaced comments. This is to prevent the comments from overlapping.
   * The positions are not bound to the document container as it has not been rendered
   * yet at this stage. To position the comment relative to the document container,
   * it will be calculated in commentsFrame.ts _after_ the document container has been
   * rendered.
   */
  useEffect(() => {
    const totalUnplaced = comments.filter((c) => c.blueprint_position?.coordinate.x === null).length
    if (totalUnplaced) {
      const totalRows = Math.round(Math.sqrt(totalUnplaced))
      const totalCols = Math.ceil(totalUnplaced / totalRows)
      const totalWidth = totalCols * UNPLACED_FRAME_WIDTH + UNPLACED_FRAME_GAP * (totalCols - 1)
      const totalHeight = totalRows * UNPLACED_FRAME_HEIGHT + UNPLACED_FRAME_GAP * (totalRows - 1)
      const halfWidth = totalWidth / 2
      const halfHeight = totalHeight / 2
      let i = 0
      dispatch(
        setComments(
          comments.map((c) => {
            const updated = cloneDeep(c)

            if (!updated.blueprint_id) {
              const cellY = Math.floor(i / totalCols)
              const cellX = i % totalCols

              updated.blueprint_position = {
                coordinate: {
                  x: cellX * (UNPLACED_FRAME_WIDTH + UNPLACED_FRAME_GAP) - halfWidth,
                  y: cellY * (UNPLACED_FRAME_HEIGHT + UNPLACED_FRAME_GAP) - halfHeight,
                },
                extent: {
                  width: BLUEPRINT_COMMENT_AREA_MIN_SIZE,
                  height: BLUEPRINT_COMMENT_AREA_MIN_SIZE,
                },
              }
              i += 1
            }

            return updated
          }),
        ),
      )
    }
  }, [comments, dispatch])

  return {
    // states
    totalPages,
    setTotalPages,
    blueprintUrl,
    isLoading,

    // event handlers
    onClick,
  }
}
