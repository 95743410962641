import axios from 'axios'

import { Cuboid } from 'interfaces/shape'

import { ERROR_PROCESS, processErrorHandler } from './ErrorHandler'
import { GET_INSPECTION_AREA_API_URL } from './InspectionArea'

export const MASKING_REGIONS_URL = (projectId: string, inspectionAreaId: string) =>
  `${GET_INSPECTION_AREA_API_URL(projectId, inspectionAreaId)}/masking-regions`

/**
 * Fetches the masking regions for a given project and inspection area.
 *
 * @param {string} access_token - The access token for authentication.
 * @param {string} projectId - The ID of the project.
 * @param {string} inspectionAreaId - The ID of the inspection area.
 * @param {function} showErrorModal - Function to show error modal.
 * @returns {Promise<Cuboid[] | null>} - A promise that resolves to an array of Cuboids if successful, or null if an error occurs.
 */
export const getMaskingRegions = (
  access_token: string,
  projectId: string,
  inspectionAreaId: string,
  showErrorModal: (message: string) => void,
): Promise<Cuboid[] | null> =>
  axios
    .get<{ results: Cuboid[] }>(MASKING_REGIONS_URL(projectId, inspectionAreaId), {
      responseType: 'json',
      headers: { 'X-Authorization': `Bearer ${access_token}` },
    })
    .then((response) => response.data.results)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.GET_INSPECTION_AREA, showErrorModal)
      return null
    })

/**
 * Creates a new masking region for a given project and inspection area.
 *
 * @param {string} access_token - The access token for authentication.
 * @param {string} projectId - The ID of the project.
 * @param {string} inspectionAreaId - The ID of the inspection area.
 * @param {function} showErrorModal - Function to show error modal.
 * @returns {Promise<Cuboid | null>} - A promise that resolves to a Cuboid if successful, or null if an error occurs.
 */
export const createMaskingRegion = (
  access_token: string,
  projectId: string,
  inspectionAreaId: string,
  maskRegion: Cuboid,
  showErrorModal: (message: string) => void,
): Promise<Cuboid | null> =>
  axios
    .post<Cuboid>(
      MASKING_REGIONS_URL(projectId, inspectionAreaId),
      {
        use_mask: true,
        center: maskRegion.center,
        rotation: maskRegion.rotation,
        extent: maskRegion.extent,
      },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      },
    )
    .then((response) => response.data)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.GET_INSPECTION_AREA, showErrorModal)
      return null
    })

/**
 * Updates a masking region for a given project and inspection area.
 *
 * @param {string} access_token - The access token for authentication.
 * @param {string} projectId - The ID of the project.
 * @param {string} inspectionAreaId - The ID of the inspection area.
 * @param {string} maskRegionId - The ID of the masking region to update.
 * @param {function} showErrorModal - Function to show error modal.
 * @returns {Promise<Cuboid | null>} - A promise that resolves to a Cuboid if successful, or null if an error occurs.
 */
export const updateMaskingRegion = (
  access_token: string,
  projectId: string,
  inspectionAreaId: string,
  maskRegion: Cuboid,
  showErrorModal: (message: string) => void,
): Promise<Cuboid | null> =>
  axios
    .patch<Cuboid>(
      `${MASKING_REGIONS_URL(projectId, inspectionAreaId)}/${maskRegion.region_id}`,
      {
        use_mask: true,
        center: maskRegion.center,
        rotation: maskRegion.rotation,
        extent: maskRegion.extent,
      },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      },
    )
    .then((response) => response.data)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.GET_INSPECTION_AREA, showErrorModal)
      return null
    })

/**
 * Deletes a masking region for a given project and inspection area.
 *
 * @param {string} access_token - The access token for authentication.
 * @param {string} projectId - The ID of the project.
 * @param {string} inspectionAreaId - The ID of the inspection area.
 * @param {string} maskRegionId - The ID of the masking region to delete.
 * @param {function} showErrorModal - Function to show error modal.
 * @returns {Promise<boolean>} - A promise that resolves to true if successful, or false if an error occurs.
 */
export const deleteMaskingRegion = (
  access_token: string,
  projectId: string,
  inspectionAreaId: string,
  maskRegionId: string,
  showErrorModal: (message: string) => void,
): Promise<boolean> =>
  axios
    .delete(`${MASKING_REGIONS_URL(projectId, inspectionAreaId)}/${maskRegionId}`, {
      responseType: 'json',
      headers: { 'X-Authorization': `Bearer ${access_token}` },
    })
    .then(() => true)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.GET_INSPECTION_AREA, showErrorModal)
      return false
    })
