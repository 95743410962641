/**
 * Holds the form for adding new comments and replies.
 * Refer to CommentPopup.tsx for more overview details.
 */
import { FC } from 'react'

import { Button, HStack, IconButton, Input, Spacer, Text, Textarea } from '@chakra-ui/react'
import { isMobile } from 'react-device-detect'
import { useSelector } from 'react-redux'
import { RootState } from 'store/app'

import { AddImageIcon, InputEditorCancelIcon, InputEditorConfirmIcon } from 'assets/icons'

import { IMAGE_UPLOAD_STATUS } from 'interfaces/comment'

import { CommentInputProps, useCommentInput } from '../hooks/commentInput'
import { CommentInputImage } from './CommentImageInput'

/**
 * TODO: These does not belong here. Use Chakra-UI's theme instead.
 */
const ICON_BUTTON_PROPS = {
  size: 'xs',
  variant: 'outline',
  fontSize: 'sm',
  borderWidth: '1px',
  borderRadius: '6px',
  padding: '4px',
}
const BUTTON_STYLE = {
  width: 'auto',
  lineHeight: 1.2,
  height: '32px',
  fontWeight: 'bold',
  borderRadius: 'var(--chakra-radii-md)',
  paddingX: '12px',
}
const PRIMARY_BUTTON_STYLE = {
  ...BUTTON_STYLE,
  backgroundColor: 'var(--chakra-colors-primary-500)',
  color: 'white',
  _hover: { backgroundColor: 'var(--chakra-colors-primary-600)' },
  _disabled: { backgroundColor: 'var(--chakra-colors-primary-300)' },
}
const SECONDARY_BUTTON_STYLE = {
  ...BUTTON_STYLE,
  borderColor: 'var(--chakra-colors-gray-200)',
  borderWidth: 1,
  _hover: { backgroundColor: 'var(--chakra-colors-gray-100)' },
  _disabled: { backgroundColor: 'white', color: 'var(--chakra-colors-gray-400)' },
}

export const CommentInput: FC<CommentInputProps> = (props) => {
  const { isDisabled, onCancel } = props
  const {
    commentName,
    commentBody,
    loading,
    isNewComment,
    isNewReply,
    updateCommentName,
    updateCommentBody,
    onMultipleFilesChange,
    handleSave,
    handleCancel,
    uploadMultipleFilesRef,
    textareaRef,
  } = useCommentInput(props)

  // Store
  const formImages = useSelector((state: RootState) => state.comments.formImages)

  return (
    <>
      {/* Show author's name field if it's a new comment/reply or just text if it's editing */}
      {isNewComment || isNewReply ? (
        <Input
          size="sm"
          placeholder="名前"
          value={commentName}
          onChange={(e) => updateCommentName(e.target.value)}
          maxLength={30}
          isDisabled={loading}
          borderWidth="1px"
          borderRadius="6px"
          padding="4px"
          width="100%"
        />
      ) : (
        <Text color="secondary.400" fontWeight="bold" as="span" marginBottom={-0.5} alignSelf="flex-start">
          {commentName}
        </Text>
      )}

      <Textarea
        ref={textareaRef}
        size="sm"
        placeholder="コメント"
        resize="vertical"
        value={commentBody}
        onChange={(e) => updateCommentBody(e.target.value)}
        maxLength={300}
        isDisabled={loading}
        borderWidth="1px"
        borderRadius="6px"
        padding="4px"
        width="100%"
        minHeight="50px"
      />

      <Input
        data-testid="multiple-file-upload"
        hidden
        ref={uploadMultipleFilesRef}
        type="file"
        accept={isMobile ? '' : '.jpg,.jpeg,.png,.svg'}
        onChange={onMultipleFilesChange}
        multiple
      />

      {formImages
        ?.filter((image) => image.status !== IMAGE_UPLOAD_STATUS.DELETING)
        .map((image) => <CommentInputImage key={image.image_id} image={image} isDisabled={isDisabled} />)}

      {/* ## Buttons for adding new reply ##  */}
      {isNewReply && (
        <>
          <IconButton
            {...ICON_BUTTON_PROPS}
            alignSelf="flex-start"
            aria-label="Add Image"
            icon={<AddImageIcon />}
            isDisabled={isDisabled || loading}
            onClick={() => {
              uploadMultipleFilesRef.current?.click()
            }}
          />
          <HStack alignSelf="stretch" pt={0} w="100%">
            <Button
              colorScheme="primary"
              size="sm"
              onClick={() => handleSave()}
              isDisabled={isDisabled || loading}
              {...PRIMARY_BUTTON_STYLE}
            >
              返信を送る
            </Button>
            <Spacer />
            <Button
              variant="outline"
              size="sm"
              onClick={() => {
                if (!isDisabled) onCancel()
              }}
              isDisabled={isDisabled || loading}
              {...SECONDARY_BUTTON_STYLE}
            >
              閉じる
            </Button>
          </HStack>
        </>
      )}

      {/* ## Buttons for adding new comment ##  */}
      {isNewComment && (
        <>
          <IconButton
            {...ICON_BUTTON_PROPS}
            alignSelf="flex-start"
            aria-label="Add Image"
            icon={<AddImageIcon />}
            isDisabled={isDisabled || loading}
            onClick={() => {
              uploadMultipleFilesRef.current?.click()
            }}
          />
          <HStack width="100%" justifyContent="end">
            <Button
              variant="outline"
              size="sm"
              onClick={() => {
                if (!isDisabled) onCancel()
              }}
              isDisabled={isDisabled || loading}
              {...SECONDARY_BUTTON_STYLE}
            >
              キャンセル
            </Button>
            <Button
              colorScheme="primary"
              size="sm"
              onClick={() => handleSave()}
              isDisabled={isDisabled || loading}
              {...PRIMARY_BUTTON_STYLE}
            >
              追加
            </Button>
          </HStack>
        </>
      )}

      {/* ## Buttons for editing comment ## */}
      {!isNewComment && !isNewReply && (
        <HStack width="100%">
          <IconButton
            {...ICON_BUTTON_PROPS}
            aria-label="Add Image"
            icon={<AddImageIcon />}
            isDisabled={isDisabled || loading}
            onClick={() => {
              uploadMultipleFilesRef.current?.click()
            }}
          />
          <Spacer />
          <IconButton
            {...ICON_BUTTON_PROPS}
            aria-label="Cancel Edit"
            icon={<InputEditorCancelIcon />}
            isDisabled={isDisabled || loading}
            onClick={() => {
              if (!isDisabled) {
                handleCancel()
                onCancel()
              }
            }}
          />
          <IconButton
            {...ICON_BUTTON_PROPS}
            aria-label="Confirm Edit"
            icon={<InputEditorConfirmIcon />}
            isDisabled={isDisabled || loading}
            onClick={() => handleSave()}
          />
        </HStack>
      )}
    </>
  )
}
