import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

import { DEFAULT_BLUEPRINT_TOOL } from 'config/constants'

import { Blueprint } from 'interfaces/interfaces'

import { getBlueprints } from 'services/Blueprint'

export interface BlueprintState {
  /**
   * Whether the user is allowed to add/edit/delete blueprints
   */
  isAllowedModify: boolean

  /**
   * Currently selected tool
   */
  selectedTool: string

  /**
   * Currently selected blueprint
   */
  selectedBlueprint: Blueprint | null

  /**
   * All blueprints for the current project
   */
  blueprints: Blueprint[]

  /**
   * Current page number
   */
  currentPage: number

  /**
   * Current scale. Value is in percentage.
   */
  scale: number

  /**
   * Total number of pages
   */
  totalPages: number
}

/**
 * Wrapper for getBlueprints to be used in createAsyncThunk
 * @param {string} access_token token
 * @param {string} project_id project id
 * @param {Function} showErrorModal function to show error modal
 * @return {Blueprint[]}} Blueprints
 */
const getBlueprintsWrapper = ({
  access_token,
  project_id,
  showErrorModal,
}: {
  access_token: string
  project_id: string
  showErrorModal: (message: string) => void
  abortController?: AbortController
}) => getBlueprints(access_token, project_id, showErrorModal)

/**
 * Thunk for fetching blueprints by project and set into store.
 *
 * @param {string} access_token token
 * @param {string} project_id project id
 * @param {Function} showErrorModal function to show error modal
 * @return {Blueprint[]}} Blueprints
 */
export const fetchBlueprintsByProject = createAsyncThunk('blueprint/fetchBlueprintsByProject', getBlueprintsWrapper)

/**
 * Thunk for fetching blueprints by project and set into store and auto-select the first blueprint.
 *
 * @param {string} access_token token
 * @param {string} project_id project id
 * @param {Function} showErrorModal function to show error modal
 * @return {Blueprint[]}} Blueprints
 */
export const fetchAndAutoSelectBlueprintsByProject = createAsyncThunk(
  'blueprint/fetchAndAutoSelectBlueprintsByProject',
  getBlueprintsWrapper,
)

const initialState: BlueprintState = {
  isAllowedModify: false,
  selectedTool: DEFAULT_BLUEPRINT_TOOL,
  selectedBlueprint: null,
  blueprints: [],
  currentPage: 1,
  scale: 100,
  totalPages: 0,
}

export const slice = createSlice({
  name: 'blueprint',
  initialState,
  reducers: {
    setSelectedTool: (state, action: PayloadAction<string>) => {
      state.selectedTool = action.payload
    },
    setSelectedBlueprint: (state, action: PayloadAction<Blueprint | null>) => {
      state.selectedBlueprint = action.payload
    },
    setIsAllowedModify: (state, action: PayloadAction<boolean>) => {
      state.isAllowedModify = action.payload
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload
    },
    setScale: (state, action: PayloadAction<number>) => {
      state.scale = action.payload
    },
    setTotalPages: (state, action: PayloadAction<number>) => {
      state.totalPages = action.payload
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBlueprintsByProject.fulfilled, (state, action) => {
      if (action.payload) state.blueprints = action.payload
    })
    builder.addCase(fetchAndAutoSelectBlueprintsByProject.fulfilled, (state, action) => {
      if (action.payload) {
        state.blueprints = action.payload
        if (!state.selectedBlueprint) state.selectedBlueprint = action.payload[0] || null
      }
    })
  },
})

export const {
  setSelectedTool,
  setSelectedBlueprint,
  setIsAllowedModify,
  setCurrentPage,
  setScale,
  setTotalPages,
  reset,
} = slice.actions

export default slice.reducer
