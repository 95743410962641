import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { merge } from 'lodash'

import { DefaultSettings } from 'config/constants'

import { InspectionArea, InspectionItem, InspectionSheet } from 'interfaces/inspection'
import { Project, ProjectSheetSettings } from 'interfaces/project'

export enum SettingDrawerTab {
  Data = 'data',
  Edit = 'edit',
}

export interface InspectionSheetState {
  /**
   * Project sheet settings
   */
  settings: ProjectSheetSettings

  /**
   * Edited project values
   */
  editedProject: Project | null

  /**
   * Edited inspection area IDs
   */
  editedInspectionAreas: InspectionArea[]

  /**
   * Inspection sheets
   */
  inspectionSheets: InspectionSheet[]

  /**
   * Edited inspection sheets ids
   */
  editedInspectionSheets: InspectionSheet[]

  /**
   * inspection items
   */
  inspectionItems: InspectionItem[]

  /**
   * Edited inspection item ids
   */
  editedInspectionItemIds: string[]
  editedInspectionItems: InspectionItem[]

  /**
   * Latest inspection items saved on the server.
   */
  latestInspectionItems: InspectionItem[]

  /**
   * Whether the user is allowed to modify the inspection sheet
   */
  isAllowedToModifySheet: boolean

  /**
   * Whether the user is allowed to modify the project.
   */
  isAllowedToModifyProject: boolean

  /**
   * Inspection area id to edit
   */
  inspectionAreaIdToEdit: string | null

  /**
   * Setting drawer tab
   */
  settingDrawerTab: SettingDrawerTab

  /**
   * Whether a save is in progress.
   * This covers the sheet itself as well as the settings drawer.
   */
  isSaving: boolean

  /**
   * Settings drawer is saving.
   * This is separate from the sheet saving as the settings drawer
   * can save independently of the sheet.
   */
  isSavingSettings: boolean

  /**
   * Error message
   */
  errorMessage?: string

  /**
   * Whether the edited inspection area has a conflicted
   * name with another area.
   */
  hasConflictedAreaName: boolean

  /**
   * The ID of the area item to grab on SettingsDrawer.
   */
  grabbedAreaId: string

  initialPos: { x: number; y: number } | null
  mouseStartPos: { x: number; y: number } | null
  offsetPos: { x: number; y: number } | null
}

export const initialState: InspectionSheetState = {
  settings: DefaultSettings,
  editedProject: null,
  editedInspectionAreas: [],
  inspectionSheets: [],
  editedInspectionSheets: [],
  inspectionItems: [],
  editedInspectionItemIds: [],
  editedInspectionItems: [],
  latestInspectionItems: [],
  isAllowedToModifySheet: false,
  isAllowedToModifyProject: false,
  inspectionAreaIdToEdit: null,
  isSaving: false,
  isSavingSettings: false,
  hasConflictedAreaName: false,
  settingDrawerTab: SettingDrawerTab.Data,
  grabbedAreaId: '',
  initialPos: null,
  mouseStartPos: null,
  offsetPos: null,
}

const inspectionSheet = createSlice({
  name: 'inspectionSheet',
  initialState,
  reducers: {
    /* ## Settings ## */
    setSettings(state, action: PayloadAction<ProjectSheetSettings>) {
      state.settings = action.payload
    },
    patchSettings(state, action: PayloadAction<Partial<ProjectSheetSettings>>) {
      state.settings = merge(state.settings, action.payload)
    },

    /* ## Project (on sheet header) ## */
    patchEditedProject(state, { payload: project }: PayloadAction<Partial<Project>>) {
      state.editedProject = merge(state.editedProject, project)
    },
    resetEditedProject(state) {
      state.editedProject = null
    },

    /* ## Inspection Area (on SettingDrawer) ## */
    patchEditingInspectionAreas(state, { payload: inspectionAreas }: PayloadAction<Partial<InspectionArea>[]>) {
      inspectionAreas.forEach((area) => {
        const existingArea = state.editedInspectionAreas.findIndex(
          (editedArea) => editedArea.inspection_area_id === area.inspection_area_id,
        )

        if (existingArea === -1) {
          state.editedInspectionAreas.push(area as InspectionArea)
        } else {
          state.editedInspectionAreas[existingArea] = merge(state.editedInspectionAreas[existingArea], area)
        }
      })
    },
    resetEditedInspectionAreas(state) {
      state.editedInspectionAreas = []
    },

    /* ## Inspection Sheet (on inspection shet itself) ## */
    setInspectionSheets(state, { payload: inspectionSheets }: PayloadAction<InspectionSheet[]>) {
      state.inspectionSheets = inspectionSheets
    },
    patchInspectionSheets(state, { payload: sheets }: PayloadAction<Partial<InspectionSheet>[]>) {
      state.inspectionSheets = state.inspectionSheets.map((sheet) => {
        const newSheet = sheets.find((s) => s.inspection_sheet_id === sheet.inspection_sheet_id)
        return newSheet ? merge(sheet, newSheet) : sheet
      })
    },

    /* ## Inspection Sheet (on SettingDrawer) ## */
    patchEditingInspectionSheets(state, { payload: sheets }: PayloadAction<InspectionSheet[]>) {
      sheets.forEach((sheet) => {
        const existingSheet = state.editedInspectionSheets.findIndex(
          (editedSheet) => editedSheet.inspection_sheet_id === sheet.inspection_sheet_id,
        )

        if (existingSheet === -1) {
          state.editedInspectionSheets.push(sheet)
        } else {
          state.editedInspectionSheets[existingSheet] = merge(state.editedInspectionSheets[existingSheet], sheet)
        }
      })
    },
    resetEditedInspectionSheetIds(state) {
      state.editedInspectionSheets = []
    },

    /* ## Inspection Items (on Sheet itself) ## */
    setInspectionItems(state, { payload: inspectionItems }: PayloadAction<InspectionItem[]>) {
      state.inspectionItems = inspectionItems
    },
    patchInspectionItems(state, { payload: items }: PayloadAction<Partial<InspectionItem>[]>) {
      items.forEach((item) => {
        const existingItem = state.inspectionItems.findIndex((i) => i.inspection_item_id === item.inspection_item_id)

        if (existingItem) {
          state.inspectionItems[existingItem] = merge(state.inspectionItems[existingItem], item)
        }
      })
    },
    addEditedInspectionItemId(state, { payload: id }: PayloadAction<string>) {
      if (!state.editedInspectionItemIds.includes(id)) {
        state.editedInspectionItemIds.push(id)
      }
    },
    resetEditedInspectionItemIds: (state) => {
      state.editedInspectionItemIds = []
    },

    /* ## Edited Inspection Items (on Sheet itself) ## */
    patchEditedInspectionItems(state, { payload: items }: PayloadAction<Partial<InspectionItem>[]>) {
      items.forEach((item) => {
        const existingItem = state.editedInspectionItems.findIndex(
          (i) => i.inspection_item_id === item.inspection_item_id,
        )
        if (existingItem === -1) {
          const orginalItem = state.inspectionItems.findIndex((i) => i.inspection_item_id === item.inspection_item_id)
          state.editedInspectionItems.push(merge({}, state.inspectionItems[orginalItem], item))
        } else {
          state.editedInspectionItems[existingItem] = merge(state.editedInspectionItems[existingItem], item)
        }
      })
    },
    setLatestInspectionItems(state, { payload: items }: PayloadAction<InspectionItem[]>) {
      state.latestInspectionItems = items
    },
    resetEditedInspectionItems: (state) => {
      state.editedInspectionItems = []
    },

    /* ## Permission ## */
    setAllowedToModifySheet(state, { payload: isAllowedToModifySheet }: PayloadAction<boolean>) {
      state.isAllowedToModifySheet = isAllowedToModifySheet
    },
    setAllowedToModifyProject(state, { payload: isAllowedToModifyProject }: PayloadAction<boolean>) {
      state.isAllowedToModifyProject = isAllowedToModifyProject
    },

    /* ## SettingDrawer itself ## */
    setInspectionAreaIdToEdit(state, { payload: inspectionAreaId }: PayloadAction<string | null>) {
      state.settingDrawerTab = SettingDrawerTab.Edit
      state.inspectionAreaIdToEdit = inspectionAreaId
    },
    setSettingDrawerTab(state, { payload: tab }: PayloadAction<SettingDrawerTab>) {
      state.settingDrawerTab = tab
    },

    /* ## Ordering areas */
    setGrabbedAreaId(state, { payload: areaId }: PayloadAction<string>) {
      state.grabbedAreaId = areaId
    },
    setInitialPos(state, { payload: pos }: PayloadAction<{ x: number; y: number } | null>) {
      state.initialPos = pos
    },
    setMouseStartPos(state, { payload: pos }: PayloadAction<{ x: number; y: number } | null>) {
      state.mouseStartPos = pos
    },
    setOffsetPos(state, { payload: pos }: PayloadAction<{ x: number; y: number } | null>) {
      state.offsetPos = pos
    },

    /* ## Saving ## */
    setIsSaving(state, { payload: isSaving }: PayloadAction<boolean>) {
      state.isSaving = isSaving
    },
    setSavingSettings(state, { payload: isSavingSettings }: PayloadAction<boolean>) {
      state.isSavingSettings = isSavingSettings
    },
    setError(state, { payload: errorMessage }: PayloadAction<string>) {
      state.errorMessage = errorMessage
    },
    setHasConflictedAreaName(state, { payload: hasConflictedAreaName }: PayloadAction<boolean>) {
      state.hasConflictedAreaName = hasConflictedAreaName
    },

    /* ## Reset ## */
    reset: () => initialState,
  },
})

export const {
  setAllowedToModifySheet,
  setAllowedToModifyProject,

  setSettings,
  patchSettings,

  patchEditedProject,
  resetEditedProject,

  patchEditingInspectionAreas,
  resetEditedInspectionAreas,

  setInspectionSheets,
  patchInspectionSheets,

  patchEditingInspectionSheets,
  setLatestInspectionItems,
  resetEditedInspectionSheetIds,

  setInspectionItems,
  patchInspectionItems,

  patchEditedInspectionItems,
  resetEditedInspectionItems,

  addEditedInspectionItemId,
  resetEditedInspectionItemIds,

  setInspectionAreaIdToEdit,
  setSettingDrawerTab,

  setInitialPos,
  setMouseStartPos,
  setOffsetPos,
  setGrabbedAreaId,

  setHasConflictedAreaName,
  setIsSaving,
  setSavingSettings,
  setError,

  reset,
} = inspectionSheet.actions

export default inspectionSheet.reducer
