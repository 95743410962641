import { FC, useContext, useRef } from 'react'

import { Flex, Square, Tooltip } from '@chakra-ui/react'
import { isTablet } from 'react-device-detect'

import { EditorContext } from 'contexts/Editor'

const SubToolbarButton: FC<{
  icon: React.ReactElement
  label: string
  toolType: string
  buttonSize: number
  selected?: boolean
  onClick?: () => void
}> = ({ icon, label, toolType, buttonSize, selected = false, onClick }) => {
  // Context
  const { selectedSubTool, changeSubTool } = useContext(EditorContext)

  // Refs
  const buttonRef = useRef<HTMLDivElement | null>(null)

  return (
    <Tooltip hasArrow placement="bottom" label={label} p={2} fontSize="xs" fontWeight="normal">
      <Flex>
        <Square
          ref={buttonRef}
          position="relative"
          backgroundColor={selectedSubTool === toolType || selected ? 'gray.600' : undefined}
          color="white"
          fontSize={isTablet ? '4xl' : 'lg'}
          size={buttonSize}
          onClick={
            onClick ||
            (() => {
              changeSubTool(toolType)
            })
          }
        >
          {icon}
        </Square>
      </Flex>
    </Tooltip>
  )
}

export default SubToolbarButton
