import { FC } from 'react'

import { Box, Spacer } from '@chakra-ui/react'
import InspectionSheetSettings from 'components/InspectionSheetSettings'
import { patchSettings } from 'pages/projects/inspection-sheet/store'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { RootState, useAppDispatch } from 'store/app'

const SettingsDrawerData: FC = () => {
  // Stores
  const dispatch = useAppDispatch()
  const settings = useSelector((state: RootState) => state.inspectionSheet.settings)

  return (
    <>
      <Box borderLeft="4px solid var(--chakra-colors-blue-400)" pl={2}>
        設定は工事全体に適用されます。 <br />
        初期設定を変更するには{' '}
        <Link to="/user#inspection-sheet" target="_blank">
          アカウント設定
        </Link>
        をご覧ください。
      </Box>

      <Spacer my={6} />

      <InspectionSheetSettings settings={settings} onChange={(newSettings) => dispatch(patchSettings(newSettings))} />
    </>
  )
}

export default SettingsDrawerData
