import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { BottomPlaneIcon, TopPlaneIcon } from 'assets/icons'

/**
 * Possible icons to be used with Attention Text
 */
export const AttentionTextIcon = {
  bottomPlane: BottomPlaneIcon,
  topPlane: TopPlaneIcon,
}

export interface AttentionTextState {
  /**
   * The message to be displayed
   */
  message: string

  /**
   * The icon to be displayed
   */
  icon?: keyof typeof AttentionTextIcon

  /**
   * The message for the link
   */
  linkMessage?: string

  /**
   * The URL for the link
   */
  linkUrl?: string
}

const initialState: AttentionTextState = {
  message: '',
}

export const slice = createSlice({
  name: 'attentionText',
  initialState,
  reducers: {
    /**
     * Set the message to be displayed
     *
     * @param state Current state
     * @param action Payload action containing the message to be displayed
     */
    setAttentionText: (
      state,
      action: PayloadAction<{
        message: string
        icon?: keyof typeof AttentionTextIcon
        linkMessage?: string
        linkUrl?: string
      }>,
    ) => {
      state.message = action.payload.message
      state.icon = action.payload.icon
      state.linkMessage = action.payload.linkMessage
      state.linkUrl = action.payload.linkUrl
    },
    /**
     * Reset the attention text state.
     *
     * @returns The initial state
     */
    reset: () => initialState,
  },
})

export const { setAttentionText, reset } = slice.actions

export default slice.reducer
