import axios from 'axios'
import { IntervalsConfig } from 'pages/projects/editor/tools/Grid/store'

import { PointArray } from 'interfaces/attribute'
import { InspectionItem } from 'interfaces/inspection'

import { ERROR_PROCESS, processErrorHandler } from './ErrorHandler'
import { GET_INSPECTION_SHEET_API_URL } from './InspectionSheet'

/**
 * Estimate depth based on a grid. No shapes are provided as back-end will figure
 * out what shapes to be used.
 *
 * @param accessToken Access token to access API
 * @param projectId Project ID
 * @param inspectionAreaId Inspection Area ID
 * @param inspectionSheetId Inspection Sheet ID
 * @param partName Name of this inspection item row.
 * @param planes Plane IDs
 * @param showErrorModal Function to show error modal
 */
export const estimateGridDepth = async (
  accessToken: string,
  projectId: string,
  inspectionAreaId: string,
  inspectionSheetId: string,
  part_name: string,
  volume_id: string,
  interval: IntervalsConfig,
  polygons: string[] | undefined,
  grid_points: PointArray[],
  showErrorModal: (message: string) => void,
): Promise<InspectionItem | null> =>
  axios
    .post<InspectionItem | null>(
      `${GET_INSPECTION_SHEET_API_URL(projectId, inspectionAreaId, inspectionSheetId)}/evaluate-grid`,
      {
        volume_id,
        part_name,
        polygons,
        grid_points,
        intervals: {
          long_axis: interval.longAxis.value,
          short_axis: interval.shortAxis.value,
        },
      },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${accessToken}` },
      },
    )
    .then((res) => res.data)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.ESTIMATE_GRID, showErrorModal)
      return null
    })
