/**
 * Handles the display of a comment or reply.
 * Refer to CommentPopup.tsx for more high-level details.
 */
import { FC } from 'react'

import {
  Box,
  Center,
  Flex,
  HStack,
  IconButton,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Spinner,
  Text,
  VStack,
} from '@chakra-ui/react'
import Linkify from 'linkify-react'
import { useSelector } from 'react-redux'
import { RootState } from 'store/app'

import { HDotsIcon } from 'assets/icons'

import { CommentBodyProps, useCommentBody } from '../hooks/commentBody'
import { CommentInput } from './CommentInput'

const BUTTON_STYLE = {
  width: 'auto',
  lineHeight: 1.2,
  height: '32px',
  fontWeight: 'bold',
  borderRadius: 'var(--chakra-radii-md)',
  paddingX: '12px',
}

export const CommentBody: FC<CommentBodyProps> = (props) => {
  const { comment, reply, onMove } = props
  const {
    editingComment,
    commentBody,
    commentCore,
    handleEdit,
    handleDelete,
    handleCancelled,
    handleSaved,
    handleOpenOriginalImage,
    isDisabled,
    isEditing,
    isLoading,
    openingImageIds,
    timestamp,
  } = useCommentBody(props)

  // Store
  const userProfile = useSelector((state: RootState) => state.user.userProfile)

  if (!userProfile || (!comment && !reply)) return null

  return (
    <>
      {!isEditing && (
        <Flex flexDirection="column" width="100%">
          <HStack alignItems="center">
            {/* NAME & TIMESTMAP */}
            <Box color="var(--chakra-colors-secondary-400)" fontWeight="bold" alignItems="baseline">
              <Text as="span">{commentCore?.author_name}</Text>
              <Text as="span" fontSize="80%" marginLeft={2}>
                {timestamp}
              </Text>
            </Box>

            <Spacer />

            {/* ## MENU ## */}
            {commentCore.author_id && commentCore.author_id === userProfile?.user_id && (
              <Box>
                <Menu>
                  <MenuButton
                    variant="ghost"
                    as={IconButton}
                    aria-label="Actions"
                    fontSize="md"
                    size="xs"
                    icon={<HDotsIcon />}
                    isDisabled={isLoading || isDisabled}
                    _hover={{ backgroundColor: 'var(--chakra-colors-gray-100)' }}
                    {...BUTTON_STYLE}
                    paddingX="6px"
                    height="24px"
                  />
                  <MenuList
                    backgroundColor="white"
                    paddingY="8px"
                    boxShadow="var(--chakra-shadows-md)"
                    borderRadius="6px"
                    zIndex={1}
                    minW="80px"
                  >
                    <MenuItem
                      onClick={handleEdit}
                      paddingX="12px"
                      paddingY="10px"
                      _hover={{ backgroundColor: 'var(--chakra-colors-gray-100)' }}
                    >
                      編集
                    </MenuItem>
                    {!!onMove && (
                      <MenuItem
                        onClick={onMove}
                        paddingX="12px"
                        paddingY="10px"
                        _hover={{ backgroundColor: 'var(--chakra-colors-gray-100)' }}
                      >
                        移動
                      </MenuItem>
                    )}
                    <MenuItem
                      onClick={handleDelete}
                      paddingX="12px"
                      paddingY="10px"
                      _hover={{ backgroundColor: 'var(--chakra-colors-gray-100)' }}
                    >
                      削除
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Box>
            )}
          </HStack>

          {/* BODY */}
          <Box color="var(--chakra-colors-secondary-400)" alignItems="baseline" mt={-1}>
            <Text fontSize="80%">{commentCore.author_email}</Text>
          </Box>
          <Linkify
            options={{
              render: ({ attributes: { href }, content }) => (
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                <Link isExternal variant="underline" href={href}>
                  {content}
                </Link>
              ),
            }}
          >
            <Text>{commentBody}</Text>
          </Linkify>

          {/* IMAGES */}
          <Flex gap={2} flexWrap="wrap" marginTop="var(--chakra-space-2)">
            {(reply || comment).images?.map((image) => (
              <VStack
                spacing={0}
                key={image.image_id}
                cursor="pointer"
                onClick={() => handleOpenOriginalImage(image.image_id)}
                position="relative"
                width="135px"
                borderWidth="1px"
                borderRadius="5px"
                overflow="hidden"
              >
                <Image src={image.compressed_size_url} />
                {image.caption && (
                  <Text
                    width="100%"
                    backgroundColor="var(--chakra-colors-secondary-50)"
                    color="var(--chakra-colors-secondary-500)"
                    p={1}
                    fontSize="80%"
                    textAlign="center"
                  >
                    {image.caption}
                  </Text>
                )}
                {openingImageIds.includes(image.image_id) && (
                  <Center
                    position="absolute"
                    top={0}
                    left={0}
                    w="100%"
                    h="100%"
                    backgroundColor="var(--chakra-colors-blackAlpha-700)"
                  >
                    <Spinner
                      width="50px"
                      height="50px"
                      thickness="2px"
                      speed="0.65s"
                      color="var(--chakra-colors-secondary-400)"
                    />
                  </Center>
                )}
              </VStack>
            ))}
          </Flex>
        </Flex>
      )}

      {/* Form for editing comment or reply */}
      {isEditing && (
        <CommentInput
          parentComment={editingComment || comment}
          isDisabled={false}
          onCancel={handleCancelled}
          onSaved={handleSaved}
        />
      )}
    </>
  )
}
