/**
 * Wrapper for CommentPopup to position it in 2D space.
 * The actual positioning is done in CommentFrame, this just adds a Box wrapper for zIndex.
 */
import { FC } from 'react'

import { Box } from '@chakra-ui/react'
import CommentPopup from 'pages/projects/common/Comments/CommentPopup'
import { CommentPopupProps } from 'pages/projects/common/Comments/hooks/commentPopup'

export const CommentPopup2d: FC<CommentPopupProps> = (props) => (
  // zIndex should be less than --chakra-zIndices ~ 1000, for Global modal overlaying purposes
  <Box position="absolute" top="0" left="0" zIndex="999">
    <CommentPopup {...props} />
  </Box>
)
