import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

import { Comment, CommentReply, CommentUploadedImage } from 'interfaces/interfaces'
import { CommentThreadPermissionSet } from 'interfaces/validation'

import { getComments, getReplies } from 'services/Comments'

export interface CommentsState {
  /**
   * Blueprint comments
   */
  comments: Comment[]

  /**
   * Current comment being viewed.
   * When editing a comment, this will also serve as the original copy.
   */
  selectedComment: Comment | null

  /**
   * Current comment being edited (for comment body modification)
   */
  editingComment: Comment | null

  /**
   * Original copy of a reply being edited.
   */
  editingReply: CommentReply | null

  /**
   * Comment ID being edited (for blueprint frame modification)
   */
  editingCommentId: string | null

  /**
   * Images to be uploaded.
   */
  formImages: CommentUploadedImage[]

  /**
   * An API call is in progress.
   */
  isLoading: boolean

  /**
   * Permission set for the current user to perform comments-related actions.
   */
  permissionSet: CommentThreadPermissionSet
}

/**
 * Thunk for fetching blueprints by inspection area or project and set into store.
 * Set inspection_are_id to empty string to fetch by project.
 *
 * @param {string} access_token token
 * @param {string} project_id Project id
 * @param {string} inspection_area_id Inspection Area id
 * @param {Function} showErrorModal function to show error modal
 * @return {Blueprint[]}} Blueprints
 */
export const fetchComments = createAsyncThunk(
  'blueprint/fetchComments',
  ({
    access_token,
    project_id,
    inspection_area_id,
    showErrorModal,
  }: {
    access_token: string
    project_id: string
    inspection_area_id: string
    showErrorModal: (message: string) => void
  }) => getComments(access_token, project_id, inspection_area_id, showErrorModal),
)

/**
 * Thunk for fetching comment's replies
 *
 * @param {string} access_token token
 * @param {string} project_id project id
 * @param {string} inspection_area_id inspection_area_id
 * @param {string} thread_id thread_id
 * @param {Function} showErrorModal function to show error modal
 * @return {Blueprint[]}} Blueprints
 */
export const fetchCommentReplies = createAsyncThunk(
  'blueprint/fetchCommentReplies',
  async ({
    token,
    project_id,
    inspection_area_id,
    thread_id,
    showErrorModal,
  }: {
    token: string
    project_id: string
    inspection_area_id: string
    thread_id: string
    showErrorModal: (message: string) => void
  }) => ({
    thread_id,
    replies: await getReplies(token, project_id, inspection_area_id, thread_id, showErrorModal),
  }),
)

const initialState: CommentsState = {
  comments: [],
  selectedComment: null,
  editingCommentId: null,
  editingComment: null,
  editingReply: null,
  isLoading: false,
  formImages: [],
  permissionSet: {
    BROWSE: [],
    CREATE: [],
    EDIT: [],
  },
}

/**
 * Run an action on a comment
 *
 * @param comments Array of comments
 * @param thread_id Comment ID
 * @param fn Function to execute
 * @returns The updated comment
 */
const updateComment = (comments: Comment[], thread_id: string, fn: (comment: Comment) => Comment): Comment[] => {
  let updated = [...comments]
  updated = updated.map((comment) => {
    if (comment.thread_id === thread_id) {
      return fn(comment)
    }

    return comment
  })

  return updated
}

/**
 * Run an action on a reply
 *
 * @param comments Array of comments
 * @param thread_id Comment ID
 * @param reply_id Reply ID
 * @param fn Function to execute
 * @returns The updated reply
 */
const updateReply = (
  comments: Comment[],
  thread_id: string,
  reply_id: string,
  fn: (comment: CommentReply) => CommentReply,
): Comment[] => {
  let updated = [...comments]
  updated = updated.map((comment) => {
    if (comment.thread_id === thread_id) {
      comment.replies = comment.replies?.map((reply) => {
        if (reply.reply_id === reply_id) {
          return fn(reply)
        }

        return reply
      })
    }

    return comment
  })

  return updated
}

export const slice = createSlice({
  name: 'comments',
  initialState,
  reducers: {
    setIsCommentsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setSelectedComment: (state, action: PayloadAction<Comment | null>) => {
      state.selectedComment = action.payload
    },
    setEditingCommentId: (state, action: PayloadAction<string | null>) => {
      state.editingCommentId = action.payload
    },
    setEditingComment: (state, action: PayloadAction<Comment | null>) => {
      state.editingComment = action.payload
    },
    setEditingReply: (state, action: PayloadAction<CommentReply | null>) => {
      state.editingReply = action.payload
    },
    setFormImages: (state, action: PayloadAction<CommentUploadedImage[]>) => {
      state.formImages = action.payload
    },
    replaceFormImage: (state, action: PayloadAction<{ image_id: string; image: CommentUploadedImage }>) => {
      state.formImages = state.formImages.map((image) =>
        image.image_id === action.payload.image_id ? action.payload.image : image,
      )
    },
    removeFromFormImages: (state, action: PayloadAction<string>) => {
      state.formImages = state.formImages.filter((image) => image.image_id !== action.payload)
    },
    setComments: (state, action: PayloadAction<Comment[]>) => {
      state.comments = action.payload
    },
    addComment: (state, action: PayloadAction<{ comment: Comment; selected?: boolean }>) => {
      state.comments.push(action.payload.comment)
      if (action.payload.selected) {
        state.selectedComment = action.payload.comment
      }
    },
    modifyComment: (state, action: PayloadAction<Partial<Comment>>) => {
      state.comments = updateComment(state.comments, action.payload.thread_id!, (comment) => ({
        ...comment,
        ...action.payload,
      }))
    },
    replaceComment: (state, action: PayloadAction<{ thread_id: string; comment: Comment }>) => {
      state.comments = updateComment(state.comments, action.payload.thread_id, () => action.payload.comment)
    },
    removeComment: (state, action: PayloadAction<string>) => {
      state.comments = state.comments.filter((comment) => comment.thread_id !== action.payload)
    },
    decreaseReplyCounter: (state, action: PayloadAction<string>) => {
      state.comments = updateComment(state.comments, action.payload, (comment) => ({
        ...comment,
        reply_amount: Math.max((comment.reply_amount || 0) - 1, 0),
      }))
    },
    increaseReplyCounter: (state, action: PayloadAction<string>) => {
      state.comments = updateComment(state.comments, action.payload, (comment) => ({
        ...comment,
        reply_amount: Math.max((comment.reply_amount || 0) + 1, 0),
      }))
    },
    replaceReply: (state, action: PayloadAction<{ thread_id: string; reply_id?: string; reply: CommentReply }>) => {
      state.comments = updateReply(
        state.comments,
        action.payload.thread_id,
        action.payload.reply_id || action.payload.reply.reply_id!,
        () => action.payload.reply,
      )
    },
    removeReply: (state, action: PayloadAction<{ thread_id: string; reply_id: string }>) => {
      state.comments = updateComment(state.comments, action.payload.thread_id, (comment) => ({
        ...comment,
        replies: comment.replies?.filter((reply) => reply.reply_id !== action.payload.reply_id),
      }))
    },
    setPermissionSet: (state, action: PayloadAction<CommentThreadPermissionSet>) => {
      state.permissionSet = action.payload
    },
    /**
     * Reset everything except for permission set.
     */
    resetComments: (state) => ({ ...initialState, permissionSet: state.permissionSet }),
    resetPopup: (state) => ({ ...initialState, comments: state.comments }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchComments.fulfilled, (state, action) => {
        if (action.payload) state.comments = action.payload
      })
      .addCase(fetchCommentReplies.fulfilled, (state, action) => {
        state.comments = state.comments.map((comment) => {
          if (comment.thread_id === action.payload.thread_id && action.payload.replies) {
            return {
              ...comment,
              replies: action.payload.replies,
            }
          }

          return comment
        })
      })
  },
})

export const {
  setIsCommentsLoading,
  setPermissionSet,
  resetComments,
  resetPopup,

  // Comments
  setSelectedComment,
  setEditingComment,
  setEditingCommentId,
  setComments,
  addComment,
  modifyComment,
  replaceComment,
  removeComment,
  increaseReplyCounter,
  decreaseReplyCounter,

  // Replies
  setEditingReply,
  replaceReply,
  removeReply,

  // Reply Form Images (temporary reply instance used on form)
  setFormImages,
  removeFromFormImages,
  replaceFormImage,
} = slice.actions

export default slice.reducer
