import { FC } from 'react'

import { Flex, Square, Tooltip } from '@chakra-ui/react'

import useLongPress from 'hooks/LongPress'

import { EDITOR_TOOL_BUTTON_SIZE } from 'config/constants'

interface CameraViewButtonProps {
  label: string
  icon: React.ReactElement
  hasSubtoolbar?: boolean
  onClick: () => void
  onLongPress?: () => void
}

const CameraViewButton: FC<CameraViewButtonProps> = ({ label, icon, hasSubtoolbar, onClick, onLongPress }) => {
  const mouseLongPress = useLongPress(
    { onLongPress: onLongPress || (() => null), onClick },
    { delay: 300, shouldPreventDefault: true },
  )

  return (
    <Tooltip hasArrow placement="top" label={label} p={2} fontSize="xs" fontWeight="normal">
      <Flex>
        <Square position="relative" color="white" fontSize="xl" size={EDITOR_TOOL_BUTTON_SIZE} {...mouseLongPress}>
          {icon}
        </Square>
        {hasSubtoolbar && (
          <Square
            position="absolute"
            top={0}
            right={0}
            size={2}
            borderBottomColor="transparent"
            borderRightColor="secondary.400"
            borderTopColor="secondary.400"
            borderLeftColor="transparent"
            borderWidth={4}
          />
        )}
      </Flex>
    </Tooltip>
  )
}

export default CameraViewButton
