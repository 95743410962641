import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface VisiblePagesState {
  /**
   * Collection of visible pages
   */
  collection: number[]
}

const initialState: VisiblePagesState = {
  collection: [],
}

export const slice = createSlice({
  name: 'blueprint/visiblePages',
  initialState,
  reducers: {
    add: (state, action: PayloadAction<number>) => {
      if (state.collection.includes(action.payload)) return

      state.collection.push(action.payload)
    },
    remove: (state, action: PayloadAction<number>) => {
      state.collection = state.collection.filter((num) => num !== action.payload)
    },
  },
})

export const { add, remove } = slice.actions

export default slice.reducer
