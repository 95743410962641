import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { CuboidAnchor } from 'interfaces/canvas'
import { Cuboid } from 'interfaces/shape'

export interface CuboidState {
  /**
   * Anchors placed by user to create a cuboid.
   */
  anchor: CuboidAnchor | undefined

  /**
   * Cuboid currently being edited.
   */
  editingCuboid: Cuboid | undefined
}

const initialState: CuboidState = {
  anchor: undefined,
  editingCuboid: undefined,
}

export const slice = createSlice({
  name: 'editor/shapes/cuboid',
  initialState,
  reducers: {
    setCuboidAnchor: (state, action: PayloadAction<CuboidAnchor | undefined>) => {
      state.anchor = action.payload
    },
    setEditingCuboid: (state, action: PayloadAction<Cuboid | undefined>) => {
      state.editingCuboid = action.payload
    },
    reset: () => initialState,
  },
})

export const { setCuboidAnchor, setEditingCuboid, reset } = slice.actions

export default slice.reducer
