import { FC, useRef } from 'react'

import { Flex, HStack, Square, Text, Tooltip } from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'

import { BUTTON_FONT_SIZE, BUTTON_ICON_SIZE } from 'config/constants'

const NavbarPageButton: FC<{
  /**
   * The ID of the button
   */
  id: string

  /**
   * The title of the button
   */
  title: string

  /**
   * Tooltip text
   */
  tooltip: JSX.Element | string

  /**
   * The icon of the button
   */
  icon: JSX.Element

  /**
   * The URL path to be navigated to
   */
  path: string

  /**
   * Whether the current page is the active page
   */
  isActive: boolean

  /**
   * Whether the button is disabled
   */
  isDisabled?: boolean
}> = ({ id, title, tooltip, icon, path, isActive, isDisabled }) => {
  const ref = useRef(null)

  return (
    <Flex ref={ref}>
      <Tooltip
        hasArrow
        placement="bottom"
        label={tooltip}
        p={2}
        fontSize="xs"
        fontWeight="normal"
        portalProps={{ containerRef: ref }}
      >
        <Square
          as={RouterLink}
          position="relative"
          color="white"
          opacity={isDisabled ? 0.35 : 1}
          fontSize={BUTTON_FONT_SIZE}
          minHeight={{ base: 12, xl: 10 }}
          maxHeight={{ base: 12, xl: 10 }}
          px={4}
          to={isDisabled ? '#' : path}
          backgroundColor={isActive ? 'gray.600' : 'transparent'}
          data-testid={id}
        >
          <HStack gap={0}>
            <Square fontSize={BUTTON_ICON_SIZE}>
              {/* <CubeIcon /> */}
              {icon}
            </Square>
            <Text whiteSpace="nowrap" className={['width-expand', isActive ? 'active' : ''].join(' ')}>
              {title}
            </Text>
          </HStack>
        </Square>
      </Tooltip>
    </Flex>
  )
}

export default NavbarPageButton
