import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { DepthType } from 'interfaces/canvas'

export const INITIAL_DEPTH_ESTIMATION_TYPES: DepthType[] = [
  { invisible: false, label: 'かぶり厚' },
  { invisible: false, label: 'かぶり厚' },
  { invisible: false, label: '表かぶり厚' },
  { invisible: false, label: '裏かぶり厚' },
]

export interface DepthEstimationState {
  depthEstimationTypes: DepthType[]
}

const initialState: DepthEstimationState = {
  depthEstimationTypes: INITIAL_DEPTH_ESTIMATION_TYPES,
}

export const slice = createSlice({
  name: 'depthEstimation',
  initialState,
  reducers: {
    setDepthEstimationTypes: (state, { payload }: PayloadAction<DepthType[]>) => {
      state.depthEstimationTypes = payload
    },
  },
})

export const { setDepthEstimationTypes } = slice.actions
export default slice.reducer
