import { EDITOR_TOOLS } from 'config/constants'

import { EditorTool } from 'interfaces/editor'

import useMainCanvas from './hooks/useMainCanvas'

const CameraFocus: EditorTool = {
  key: EDITOR_TOOLS.FOCUS,
  authCheck: () => true,
  hooks: {
    useMainCanvas,
  },
}

export default CameraFocus
