// Sentry initialization should be imported first
// https://docs.sentry.io/platforms/javascript/guides/react/
import 'config/sentry'

import 'styles/override.css'

import { FC } from 'react'

import { Flex } from '@chakra-ui/react'
import AppContent from 'components/AppContent'
import GlobalModal from 'components/GlobalModal'

const App: FC = () => (
  <Flex data-testid="root" id="app-container" h="100%" flexDirection="column">
    <GlobalModal>
      <AppContent />
    </GlobalModal>
  </Flex>
)

export default App
