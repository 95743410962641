import { useCallback, useContext, useState } from 'react'

import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'

import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import { MODAL_TYPES } from 'config/constants'

import { Comment, CommentCore, CommentReply } from 'interfaces/interfaces'

import { deleteComment, deleteReply, getOriginalImageUrl } from 'services/Comments'

import {
  decreaseReplyCounter,
  removeComment,
  removeReply,
  setEditingComment,
  setEditingReply,
  setFormImages,
  setSelectedComment,
} from '../store/comments'

export interface CommentBodyProps {
  /**
   * Current comment to be displayed.
   * Either this or `reply` must be provided.
   */
  comment: Comment

  /**
   * Current reply to be displayed
   * Either this or `comment` must be provided.
   */
  reply?: CommentReply

  /**
   * Callback to be called when user initiates moving of the frame.
   */
  onMove?: () => void

  /**
   * Calls this callback when an operation needs to block the entire page.
   *
   * @param loading New loading state
   */
  onLoading: (loading: boolean) => void
}

export const useCommentBody = ({ comment, reply, onLoading }: CommentBodyProps) => {
  const { getAccessToken } = useContext(UserContext)
  const { showModal, showErrorModal } = useContext(GlobalModalContext)

  // Store
  const dispatch = useAppDispatch()
  const project = useSelector((state: RootState) => state.page.project)
  const editingComment = useSelector((state: RootState) => state.comments.editingComment)
  const editingReply = useSelector((state: RootState) => state.comments.editingReply)

  // States
  const [showInput, setShowInput] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [openingImageIds, setOpeningImageIds] = useState<string[]>([])

  // Merge comment and reply
  const commentBody = (reply ? reply.reply_body : comment?.thread_body) || ''
  const commentCore: CommentCore = reply || comment

  // Local vars
  const date = commentCore.updated_at ? new Date(commentCore.updated_at) : null
  const timestamp = date ? date.toLocaleString('en-ZA', { hour12: false }) : ''

  /**
   * Handle deletion of comment or reply
   */
  const handleDelete = useCallback(() => {
    if (!project) {
      return
    }

    showModal({
      body: '一度削除してしまうと、元に戻せません。',
      confirmText: '削除',
      modalType: MODAL_TYPES.CONFIRMATION_CRITICAL,
      onConfirm: () => {
        void (async () => {
          if (!comment.thread_id) {
            return false
          }

          onLoading(true)
          const token = await getAccessToken()
          if (!token) {
            onLoading(false)
            return false
          }

          // delete reply
          if (
            reply?.reply_id &&
            (await deleteReply(
              token,
              project.project_id,
              comment.inspection_area_id,
              comment.thread_id,
              reply.reply_id,
              showErrorModal,
            ))
          ) {
            dispatch(removeReply({ thread_id: comment.thread_id, reply_id: reply.reply_id }))
            dispatch(decreaseReplyCounter(comment.thread_id))
          } else if (
            !reply &&
            (await deleteComment(
              token,
              project.project_id,
              comment.inspection_area_id,
              comment.thread_id,
              showErrorModal,
            ))
          ) {
            dispatch(setSelectedComment(null))
            dispatch(removeComment(comment.thread_id))
          }

          onLoading(false)
          return false
        })()
        return true
      },
      title: '確認',
    })
  }, [comment, dispatch, getAccessToken, project, reply, onLoading, showErrorModal, showModal])

  /**
   * Handle opening of original image. This will open a new tab.
   *
   * @param imageId Image ID to be opened.
   */
  const handleOpenOriginalImage = async (imageId: string) => {
    if (!project || !comment.thread_id || !imageId || openingImageIds.includes(imageId)) {
      return false
    }

    // Show loading spinner
    setIsLoading(true)
    setOpeningImageIds([...openingImageIds, imageId])

    const token = await getAccessToken()
    if (!token) {
      setOpeningImageIds(openingImageIds.filter((id) => id !== imageId))
      setIsLoading(false)
      return false
    }

    const url = await getOriginalImageUrl(
      token,
      project.project_id,
      comment.inspection_area_id,
      comment.thread_id,
      reply?.reply_id || null,
      imageId,
      showErrorModal,
    )

    // Hide loading spinner
    setIsLoading(false)
    setOpeningImageIds(openingImageIds.filter((id) => id !== imageId))

    if (url) {
      window.open(url, '_blank', 'noreferrer')
    }
    return true
  }

  /**
   * User initiates editing of comment or reply.
   */
  const handleEdit = () => {
    if (reply) {
      dispatch(setEditingReply(reply))
      dispatch(setEditingComment(null))
      dispatch(setFormImages(reply.images || []))
    } else {
      dispatch(setEditingComment(comment))
      dispatch(setEditingReply(null))
      dispatch(setFormImages(comment.images || []))
    }

    // dispatch(setFormReply(undefined))
    setShowInput(true)
  }

  /**
   * Reset editing form
   */
  const resetForm = () => {
    dispatch(setEditingReply(null))
    dispatch(setEditingComment(null))
    dispatch(setFormImages([]))
  }

  /**
   * Only need to reset form on cancellation or saved.
   */
  const handleCancelled = resetForm
  const handleSaved = resetForm

  return {
    // Flags
    isLoading,
    isDisabled: isLoading,
    isEditing:
      (reply && reply.reply_id === editingReply?.reply_id) ||
      (!reply && comment.thread_id === editingComment?.thread_id),

    // Comment data / states
    editingComment,
    editingReply,
    commentBody,
    commentCore,
    openingImageIds,
    timestamp,

    showInput,
    setShowInput,

    // Handlers
    handleDelete,
    handleEdit,
    handleCancelled,
    handleSaved,
    handleOpenOriginalImage,
  }
}
