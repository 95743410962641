import { v4 as uuidv4 } from 'uuid'

type State = {
  [name: string]: string
}
let trackingIds: State = {}

/**
 * Start tracking a workflow. This will always generate a new tracking ID.
 *
 * @param name Name of the workflow.
 * @return New tracking ID.
 */
export const trackWorkflow = (name: string): string => {
  const trackingId = uuidv4()

  trackingIds = { ...trackingIds, [name]: trackingId }

  return trackingId
}

/**
 * Get a running workflow tracking ID.
 *
 * @param name Name of workflow
 * @return An existing tracking ID or a new one if not existed yet.
 */
export const getWorkflowTrackingId = (name: string): string => trackingIds[name] || trackWorkflow(name)
