import { FC } from 'react'

import { useSelector } from 'react-redux'
import { RootState } from 'store/app'

import { TEMP_COMMENT_ID } from 'config/constants'

import Comment from './Comment'
import UnplacedComments from './UnplacedComments'

const Comments: FC = () => {
  // Store
  const comments = useSelector((state: RootState) => state.comments.comments)
  const editingComment = useSelector((state: RootState) => state.comments.editingComment)

  return (
    <>
      {/* Existing comments with fixed positions */}
      {comments
        .filter((c) => !!c.cartesian_position?.x)
        .map((comment) => (
          <Comment key={comment.thread_id} comment={comment} />
        ))}

      {/* Existing comments without fixed positions (unplaced) */}
      <UnplacedComments />

      {/* Temporary frame for adding new comment */}
      {editingComment?.thread_id === TEMP_COMMENT_ID && <Comment key={TEMP_COMMENT_ID} comment={editingComment} />}
    </>
  )
}
export default Comments
