import { FC, useContext, useState } from 'react'

import { Box, Flex, HStack, Text, VStack } from '@chakra-ui/react'
import CollapsePanel from 'pages/projects/editor/infoPanels/components/CollapsePanel'
import LayerItem from 'pages/projects/editor/infoPanels/components/LayerItem'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'

import { EditorContext } from 'contexts/Editor'

import { EDITOR_COLLAPSE_TYPES } from 'config/constants'
import { INFO_PANEL_PADDING } from 'config/styles'

import { getDistanceLabel } from 'services/Util'

import useCustomMeasurementPanel from '../../hooks/CustomMeasurementPanel'
import { updateAllCustomMeasurementStatus } from '../../store'

const CustomMeasurementPanel: FC = () => {
  const {
    measurements,
    tempMeasurements,
    selectedMeasurements,
    updateAllMeasurementStatus,
    updateMeasurement,
    updateTemporaryMeasurement,
    deleteMeasurement,
    deleteTemporaryMeasurement,
    updateAllTemporaryMeasurementStatus,
    deleteSelectedMeasurements,
    updateSelectedMeasurements,
    deselectSelectedMeasurements,
  } = useCustomMeasurementPanel()

  // Context
  const { isLayerModifying } = useContext(EditorContext)

  // Store
  const dispatch = useAppDispatch()
  const permissionSet = useSelector((state: RootState) => state.editor.permissionSet)
  const userType = useSelector((state: RootState) => state.user.userType)

  // States
  const [isCollapseOpen, setIsCollapseOpen] = useState(true)

  // Flags
  const isAllowedToDetect = permissionSet.DETECT.includes(userType)
  const isSomeMeasurementsVisible = measurements.some((anchor) => !anchor.invisible)
  const isAllMeasurementsSelected = measurements
    .filter((measurement) => !!measurement.inspectionItem)
    .every((anchor) => anchor.selected)
  const isSomeTempMeasurementsVisible = tempMeasurements.some((anchor) => !anchor.invisible)
  const isSomeSelectedShapesVisible = selectedMeasurements.some((anchor) => !anchor.invisible)

  if (!measurements?.length) {
    return null
  }

  return (
    <Flex
      backgroundColor="gray.800"
      borderBottomLeftRadius="md"
      borderTopLeftRadius="md"
      w="100%"
      flex={1}
      minH={isCollapseOpen ? 20 : 10}
      data-testid="distance-panel"
    >
      <CollapsePanel title="距離" type={EDITOR_COLLAPSE_TYPES.distance} onChange={setIsCollapseOpen}>
        <VStack w="100%" spacing={0} pb={INFO_PANEL_PADDING - 1} overflowY="auto">
          <LayerItem
            disabled={isLayerModifying}
            invisible={!isSomeMeasurementsVisible}
            selected={isAllMeasurementsSelected}
            label="保存済みの距離"
            updateVisibility={(invisible) => updateAllMeasurementStatus({ invisible })}
            onClick={() =>
              dispatch(updateAllCustomMeasurementStatus({ selected: !isAllMeasurementsSelected, invisible: false }))
            }
          />
          {measurements.map(
            (measurement) =>
              measurement.inspectionItem && (
                <LayerItem
                  disabled={isLayerModifying}
                  selected={measurement.selected}
                  deleteLayer={isAllowedToDetect ? () => deleteMeasurement(measurement) : undefined}
                  invisible={measurement.invisible}
                  key={measurement.inspectionItem.inspection_item_id}
                  label={
                    <HStack fontSize={10} alignItems="baseline" spacing={1}>
                      <Text>
                        {measurement.inspectionItem.part_name} (
                        {getDistanceLabel(measurement.inspectionItem?.length_with_distance_tool?.estimated_value || 0)})
                      </Text>
                    </HStack>
                  }
                  updateVisibility={(invisible) => updateMeasurement({ invisible }, measurement)}
                  onClick={() => updateMeasurement({ selected: !measurement.selected, invisible: false }, measurement)}
                  isChild
                />
              ),
          )}

          {/* Only show this for user without permission. No point to show to users that can save measurements. */}
          {!isAllowedToDetect && tempMeasurements.length && (
            <>
              <LayerItem
                disabled={isLayerModifying}
                invisible={!isSomeTempMeasurementsVisible}
                label="一時的な距離"
                updateVisibility={(invisible) => updateAllTemporaryMeasurementStatus({ invisible })}
              />
              {tempMeasurements.map(
                (measurement) =>
                  measurement.inspectionItem && (
                    <LayerItem
                      disabled={isLayerModifying}
                      selected={measurement.selected}
                      deleteLayer={() => deleteTemporaryMeasurement(measurement)}
                      invisible={measurement.invisible}
                      key={measurement.id}
                      label={
                        <HStack fontSize={10} alignItems="baseline" spacing={1}>
                          <Text>
                            {measurement.inspectionItem.part_name} (
                            {getDistanceLabel(
                              measurement.inspectionItem?.length_with_distance_tool?.estimated_value || 0,
                            )}
                            )
                          </Text>
                        </HStack>
                      }
                      updateVisibility={(invisible) => updateTemporaryMeasurement({ invisible }, measurement)}
                      onClick={() =>
                        updateTemporaryMeasurement({ selected: !measurement.selected, invisible: false }, measurement)
                      }
                      isChild
                    />
                  ),
              )}
            </>
          )}

          {/* Batch deletion */}
          {selectedMeasurements.length > 0 && (
            <Box fontWeight="bold" w="100%">
              <LayerItem
                onClick={deselectSelectedMeasurements}
                invisible={!isSomeSelectedShapesVisible}
                label={`選択した距離(${selectedMeasurements.length})`}
                updateVisibility={(invisible) => updateSelectedMeasurements({ invisible })}
                deleteLayer={deleteSelectedMeasurements}
              />
            </Box>
          )}
        </VStack>
      </CollapsePanel>
    </Flex>
  )
}

export default CustomMeasurementPanel
