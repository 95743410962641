import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { InspectionItem, PointArray, ShapeKeyType } from 'interfaces/interfaces'

export interface IntervalsConfig {
  shapeKey: ShapeKeyType
  topPlaneId: string
  bottomPlaneId: string
  longAxis: {
    max: number
    value: number

    /**
     * Long-axis specific offset
     */
    offset: number
  }
  shortAxis: {
    max: number
    value: number

    /**
     * Short-axis specific offset
     */
    offset: number
  }

  /**
   * Angle to rotate the grid.
   */
  angle: number

  /**
   * Which axis was used as the long axis.
   * 1 = x/extent[0]
   * 2 = y/extent[1]
   */
  whichLongAxis: 1 | 2

  /**
   * Flag to indicate if the grid is locked.
   */
  locked?: boolean
}

export interface GridState {
  /**
   * Grid being processed.
   */
  isLoading: boolean

  /**
   * Flag to indicate if a volume has been selected.
   */
  selectedVolumeId: string

  /**
   * Intervals config.
   */
  intervals: Record<string, IntervalsConfig>

  /**
   * Working grid points.
   */
  workingGridPoints: Record<string, PointArray[][]>

  /**
   * Highlighted working grid.
   */
  highlightedWorkingGrid: string

  /**
   * Grid data.
   */
  grids: InspectionItem[]
}

const initialState: GridState = {
  isLoading: false,
  selectedVolumeId: '',
  grids: [],
  intervals: {},
  workingGridPoints: {},
  highlightedWorkingGrid: '',
}

export const slice = createSlice({
  name: 'editor/tools/grid',
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setSelectedVolumeId: (state, action: PayloadAction<string>) => {
      state.selectedVolumeId = action.payload
    },
    setLongAxisInterval: (state, action: PayloadAction<number>) => {
      state.intervals[state.selectedVolumeId].longAxis.value = action.payload
    },
    setShortAxisInterval: (state, action: PayloadAction<number>) => {
      state.intervals[state.selectedVolumeId].shortAxis.value = action.payload
    },
    updateInterval: (state, action: PayloadAction<{ id: string; interval: IntervalsConfig }>) => {
      state.intervals[action.payload.id] = action.payload.interval
    },
    setWorkingGridPoints: (state, action: PayloadAction<Record<string, PointArray[][]>>) => {
      state.workingGridPoints = action.payload
    },
    updateWorkingGridPoints: (state, action: PayloadAction<{ id: string; points: PointArray[][] }>) => {
      state.workingGridPoints[action.payload.id] = action.payload.points
    },
    removeWorkingGridPoint: (state, action: PayloadAction<{ id: string; gridPointIndex: number }>) => {
      state.workingGridPoints[action.payload.id].splice(action.payload.gridPointIndex, 1)
    },
    setGrids: (state, action: PayloadAction<InspectionItem[]>) => {
      // If grids has been set before, take LayerStatus of existing data into account
      state.grids = action.payload.map((grid) => {
        const updatedGrid = { ...grid }
        const existingGrid = state.grids.find((g) => g.inspection_item_id === grid.inspection_item_id)

        updatedGrid.grid?.list_distances.map((distance, index) => {
          // The undefined check is to make sure we're only doing this if a new data was set into the store
          if (existingGrid?.grid?.list_distances[index] && distance.invisible === undefined) {
            distance.invisible = existingGrid?.grid?.list_distances[index].invisible
          }
          return distance
        })

        return updatedGrid
      })
    },
    updateGrid: (state, action: PayloadAction<InspectionItem>) => {
      state.grids = state.grids.map((grid) => {
        if (grid.inspection_item_id === action.payload.inspection_item_id) {
          return action.payload
        }
        return grid
      })
    },
    setHighlightedWorkingGrid: (state, action: PayloadAction<string>) => {
      state.highlightedWorkingGrid = action.payload
    },
    resetWorking: (state) => ({ ...initialState, grids: state.grids }),
    resetPerVolumeId: (state, action: PayloadAction<string>) => {
      delete state.intervals[action.payload]
      delete state.workingGridPoints[action.payload]
    },
    reset: () => initialState,
  },
})

export const {
  setIsLoading,
  setSelectedVolumeId,
  setLongAxisInterval,
  setShortAxisInterval,
  updateInterval,
  setWorkingGridPoints,
  updateWorkingGridPoints,
  removeWorkingGridPoint,
  setGrids,
  updateGrid,
  setHighlightedWorkingGrid,
  reset,
  resetWorking,
  resetPerVolumeId,
} = slice.actions

export default slice.reducer
