import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react'

import {
  Alert,
  Button,
  Checkbox,
  Flex,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react'
import TutorialVideoModal from 'components/TutorialVideoModal'
import { setAttentionText } from 'pages/projects/common/AttentionText/store/attentionText'
import CollapsePanel from 'pages/projects/editor/infoPanels/components/CollapsePanel'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'

import { ChevronDownIcon, HelpCircleIcon } from 'assets/icons'

import { EditorContext } from 'contexts/Editor'
import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import { EDITOR_COLLAPSE_TYPES, EDITOR_TOOLS } from 'config/constants'
import { INFO_PANEL_PADDING } from 'config/styles'

import { VolumeEstimationMethods } from 'interfaces/inspection'

import { withPromiseContained } from 'services/Util'
import { pollAsyncJobStatus, reevaluateVolumeEstimation } from 'services/VolumeEstimation'

import { setEstimationMethod, setRebarModelledConfirmed } from '../store'

enum Mode {
  None,
  Modelling,
  Reevaluation,
}

const EstimationMethodPanel: FC = () => {
  // Context
  const { getAccessToken } = useContext(UserContext)
  const { showErrorModal } = useContext(GlobalModalContext)
  const editorContext = useContext(EditorContext)
  const { inspectionItems, inspectionSheet, selectedTool, updateToggledCollapses, fetchInspectionItems } = editorContext

  // Store
  const dispatch = useAppDispatch()
  const videos = useSelector((root: RootState) => root.videos.videos)
  const selectedShapeIds = useSelector((state: RootState) => state.editor.selectedShapeIds)
  const project = useSelector((state: RootState) => state.page.project)
  const inspectionArea = useSelector((state: RootState) => state.page.inspectionArea)
  const estimationMethod = useSelector((state: RootState) => state.toolVolumeEstimationPolygon.estimationMethod)
  const rebarModelledConfirmed = useSelector(
    (state: RootState) => state.toolVolumeEstimationPolygon.rebarModelledConfirmed,
  )

  // States
  const [isLoading, setIsLoading] = useState(false)

  // Derived
  const selectedVolumes = useMemo(
    () =>
      inspectionItems.filter(
        (item) => item.item_type === 'volume' && selectedShapeIds.find((id) => item.shape_ids.polygons.includes(id)),
      ),
    [selectedShapeIds, inspectionItems],
  )
  const selectedVolume = selectedVolumes[0]
  const isSelectedMultiple = selectedVolumes.length > 1
  const isSelectedTool = selectedTool === EDITOR_TOOLS.VOLUME_POLYGON
  const mode = useMemo(() => {
    if (isSelectedTool) return Mode.Modelling
    if (selectedVolume) return Mode.Reevaluation
    return Mode.None
  }, [isSelectedTool, selectedVolume])

  // Video modal
  const useDisclosureProps = useDisclosure()
  const videoConfig = useMemo(
    () =>
      videos.find(
        (video) => video.toolType === EDITOR_TOOLS.VOLUME_POLYGON && video.variant === 'volume-estimation-method',
      ),
    [videos],
  )
  /**
   * Changed selected method based on the selected volume or
   * set to Standard if user is on Volume tool.
   */
  useEffect(() => {
    if (mode === Mode.None) return

    if (mode === Mode.Modelling) {
      dispatch(setEstimationMethod(VolumeEstimationMethods.Standard))
      return
    }

    const method =
      selectedVolume?.volume?.is_flat_plane === false
        ? VolumeEstimationMethods.Advanced
        : VolumeEstimationMethods.Standard
    dispatch(setEstimationMethod(method))
    if (method === VolumeEstimationMethods.Standard && selectedVolume?.volume?.is_valid_method === false) {
      updateToggledCollapses([EDITOR_COLLAPSE_TYPES.volumeEstimationMethod, EDITOR_COLLAPSE_TYPES.detected])
    }
  }, [selectedVolume, mode, updateToggledCollapses, dispatch])

  /**
   * Handle re-estimation of the volume based on the selected method.
   */
  const handleSubmit = useCallback(async () => {
    if (
      !selectedVolume ||
      !project ||
      !inspectionArea ||
      !inspectionSheet ||
      (estimationMethod === VolumeEstimationMethods.Advanced && !rebarModelledConfirmed)
    )
      return

    const token = await getAccessToken()
    if (!token) return

    setIsLoading(true)
    dispatch(setAttentionText({ message: '体積の再評価中...' }))
    await withPromiseContained(async () => {
      const jobToken = await reevaluateVolumeEstimation(
        token,
        project.project_id,
        inspectionArea.inspection_area_id,
        inspectionSheet.inspection_sheet_id,
        selectedVolume.inspection_item_id!,
        estimationMethod,
        showErrorModal,
      )
      if (!jobToken) return

      const result = await pollAsyncJobStatus(token, jobToken, showErrorModal)
      if (!result) return

      await fetchInspectionItems()
    }).finally(() => {
      setIsLoading(false)
      dispatch(setAttentionText({ message: '' }))
      dispatch(setRebarModelledConfirmed(false))
    })
  }, [
    selectedVolume,
    estimationMethod,
    rebarModelledConfirmed,
    project,
    inspectionArea,
    inspectionSheet,
    showErrorModal,
    getAccessToken,
    fetchInspectionItems,
    dispatch,
  ])

  // Only for when volume is selected or tool is selected
  if (mode === Mode.None) {
    return null
  }

  return (
    <Flex
      backgroundColor="gray.800"
      borderBottomLeftRadius="md"
      borderTopLeftRadius="md"
      w="100%"
      flex={1}
      data-testid="plane-side-selection-panel"
    >
      <HStack w="100%" spacing={0} pb={INFO_PANEL_PADDING - 1} overflowY="auto">
        <CollapsePanel title="体積の設定" type={EDITOR_COLLAPSE_TYPES.volumeEstimationMethod} onChange={() => null}>
          <VStack px={INFO_PANEL_PADDING} py={INFO_PANEL_PADDING}>
            <Text fontSize="sm" color="gray.400" alignSelf="start">
              体積測定方法
            </Text>
            <VStack width="95%">
              <HStack>
                <HelpCircleIcon size="1.4em" />
                <Button
                  variant="link"
                  whiteSpace="normal"
                  textAlign="left"
                  alignSelf="start"
                  onClick={() => useDisclosureProps.onOpen()}
                  fontSize="11px"
                  borderBottom="1px dotted var(--chakra-colors-gray-500)"
                  borderRadius={0}
                  _hover={{
                    textDecoration: 'none',
                    borderBottom: '1px solid var(--chakra-colors-gray-300)',
                    color: 'gray.300',
                  }}
                >
                  体積測定の詳細はこちら
                </Button>
              </HStack>
              {mode === Mode.Reevaluation && (
                <>
                  {isSelectedMultiple && <Alert variant="panel-warning">1つの体積だけを選択してください。</Alert>}

                  {selectedVolume?.volume?.is_flat_plane === true &&
                    selectedVolume?.volume?.is_valid_method === false && (
                      <Alert variant="panel-info">
                        斫り面が平坦ではないと検出しました。体積測定方法の設定を変更することで、精度が向上する可能性あります。
                      </Alert>
                    )}
                </>
              )}
              <Menu variant="panel" placement="bottom-end" gutter={4}>
                <MenuButton as={Button} rightIcon={<ChevronDownIcon />} variant="panel-dropdown">
                  {estimationMethod}の測定方法
                </MenuButton>
                <Portal>
                  <MenuList>
                    <MenuItem onClick={() => dispatch(setEstimationMethod(VolumeEstimationMethods.Standard))}>
                      {VolumeEstimationMethods.Standard}（平坦な斫り面）
                    </MenuItem>
                    <MenuItem onClick={() => dispatch(setEstimationMethod(VolumeEstimationMethods.Advanced))}>
                      {VolumeEstimationMethods.Advanced}（平坦ではない斫り面）
                    </MenuItem>
                  </MenuList>
                </Portal>
              </Menu>
              {estimationMethod === VolumeEstimationMethods.Advanced && (
                <Checkbox
                  isChecked={rebarModelledConfirmed}
                  onChange={(e) => dispatch(setRebarModelledConfirmed(e.target.checked))}
                  required
                >
                  <Text fontSize="xs">鉄筋がモデリング済み</Text>
                </Checkbox>
              )}
              {mode === Mode.Reevaluation && (
                <Button
                  variant="panel"
                  size="sm"
                  onClick={handleSubmit}
                  isLoading={isLoading}
                  disabled={
                    !(
                      estimationMethod === VolumeEstimationMethods.Standard ||
                      (estimationMethod === VolumeEstimationMethods.Advanced && rebarModelledConfirmed)
                    ) ||
                    isLoading ||
                    isSelectedMultiple
                  }
                >
                  再測定
                </Button>
              )}
            </VStack>
          </VStack>
        </CollapsePanel>
      </HStack>

      {videoConfig && <TutorialVideoModal useDisclosureProps={useDisclosureProps} {...videoConfig} />}
    </Flex>
  )
}

export default EstimationMethodPanel
