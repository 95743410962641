import { FC, useCallback, useContext, useEffect, useMemo, useRef } from 'react'

import { TransformControls } from '@react-three/drei'
import { debounce } from 'lodash'
import { isTablet } from 'react-device-detect'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'
import { Mesh, Quaternion, Vector3 } from 'three'
import { TransformControls as TransformControlsImpl } from 'three-stdlib'

import { EditorContext } from 'contexts/Editor'

import { PointArray } from 'interfaces/attribute'
import { PolygonPlaneMeshTransformableProps, TransformTypes } from 'interfaces/canvas'

import { removeUnsedGizmos } from 'services/Editor'
import { rotatePointAroundPivot } from 'services/Points'

import { resetTransformControls, setAllowedTransformControls, setShouldResetTransforms } from '../store/editor'
import { restoreTopPlane, setCurrentTransformQuaternion, updateTopPlane } from '../tools/VolumeEstimation/Polygon/store'
import { PolygonPlaneMesh } from './PolygonPlaneMesh'

/**
 * Rate to which transform will be applied.
 * Value is in milliseconds.
 */
const TRANSFORM_UPDATE_RATE = (1 / 120) * 1000 // 1/x frame-per-seconds

const PolygonPlaneMeshTransformable: FC<PolygonPlaneMeshTransformableProps> = (props) => {
  // Desctructure props
  const { polygon, allowedTransforms, pivotPoint, enabled = true } = props

  // Context
  const { meshRefs } = useContext(EditorContext)

  // Store
  const dispatch = useAppDispatch()
  const planes = useSelector((state: RootState) => state.toolVolumeEstimationPolygon.planes)
  const currentTransformType = useSelector((state: RootState) => state.editor.currentTransformType)
  const shouldResetTransforms = useSelector((state: RootState) => state.editor.shouldResetTransforms)

  // Refs
  const transformRef = useRef<TransformControlsImpl>(null)

  // Vars
  const pivot = useMemo(() => pivotPoint || polygon.center || [0, 0, 0], [pivotPoint, polygon.center]) // [0,0,0] should never happen as polygon always comes with center defined

  const applyTransform = useCallback(
    (points: PointArray[], quaternion: Quaternion) =>
      debounce(
        () => {
          dispatch(
            updateTopPlane({
              points: points.map((point) =>
                rotatePointAroundPivot(new Vector3(...point), new Vector3(...pivot), quaternion).toArray(),
              ),
            }),
          )
          dispatch(setCurrentTransformQuaternion(quaternion.toArray()))
        },
        TRANSFORM_UPDATE_RATE,
        { trailing: true, leading: true },
      ),

    [dispatch, pivot],
  )

  /**
   * Receive updates from TransformControls.
   */
  const onUpdate = useCallback(() => {
    const meshRef = meshRefs ? meshRefs[polygon.shape_id] : null
    if (!meshRef?.current || !planes.upper?.anchorAdjustedPoints) return

    const mesh = meshRef.current as Mesh

    // update polygon data
    if (currentTransformType === TransformTypes.ROTATE) {
      applyTransform(planes.upper.anchorAdjustedPoints, mesh.quaternion)()
    }
  }, [meshRefs, polygon.shape_id, planes.upper, currentTransformType, applyTransform])

  /**
   * Setup transform controls.
   */
  useEffect(() => {
    const meshRef = meshRefs ? meshRefs[polygon.shape_id] : null

    if (!transformRef.current || !meshRef?.current) return

    transformRef.current.attach(meshRef.current)
  }, [meshRefs, polygon.shape_id])

  /**
   * Setup transform control toolbar.
   */
  useEffect(() => {
    dispatch(setAllowedTransformControls(allowedTransforms))

    return () => {
      dispatch(resetTransformControls())
    }
  }, [allowedTransforms, dispatch])

  /**
   * Restore the original points when user wants to reset the transformation.
   */
  useEffect(() => {
    const meshRef = meshRefs ? meshRefs[polygon.shape_id] : null

    if (!shouldResetTransforms || !transformRef.current || !meshRef?.current) return

    dispatch(restoreTopPlane())
    dispatch(setShouldResetTransforms(false))

    meshRef.current.quaternion.set(0, 0, 0, 1)
    meshRef.current.scale.set(1, 1, 1)
    meshRef.current.position.set(pivot[0], pivot[1], pivot[2])
    transformRef.current.reset()
  }, [shouldResetTransforms, meshRefs, polygon.shape_id, pivot, dispatch])

  useEffect(() => {
    // Remove some unused gizmos
    if (transformRef.current) {
      removeUnsedGizmos(transformRef.current)
    }
  }, [transformRef.current?.visible])

  return (
    <group>
      <TransformControls
        ref={transformRef}
        size={isTablet ? 1 : 0.75}
        onObjectChange={onUpdate}
        space="local"
        mode={currentTransformType}
        enabled={enabled}
      />
      <PolygonPlaneMesh {...props} origin={pivot} />
    </group>
  )
}

export default PolygonPlaneMeshTransformable
