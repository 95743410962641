import { createContext, useCallback, useContext, useEffect } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { Text } from '@chakra-ui/react'
import mixpanel from 'mixpanel-browser'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'
import { setUser, setUserLoaded, setUserProfile } from 'store/user'

import { MODAL_TYPES, SubscriptionType, USER_TYPES } from 'config/constants'

import { UserContextProps } from 'interfaces/interfaces'

import { ERROR_PROCESS, processErrorHandler } from 'services/ErrorHandler'
import { getUser } from 'services/Users'

import { GlobalModalContext } from './GlobalModal'

const SUBSCRIPTION_USER_TYPE_MAP: Record<SubscriptionType, string> = {
  [SubscriptionType.None]: USER_TYPES.NON_PAYING_USER,
  [SubscriptionType.Base]: USER_TYPES.BASE_USER,
  [SubscriptionType.Features]: USER_TYPES.PAYING_USER,
}

//* アプリ全体としてユーザー情報を管理するプロバイダーの生成
export const UserContext = createContext<UserContextProps>({
  getAccessToken: async () => Promise.resolve(''),
})

//* プロバイダーに渡すユーザー情報の項目や操作処理
export function useUserContext(): UserContextProps {
  const { showErrorModal, showModal } = useContext(GlobalModalContext)
  const { error, logout } = useAuth0()

  const handleUserNotFound = useCallback(() => {
    showModal({
      body: (
        <>
          <Text>Hatsulyのアカウントを持っていないため、ログアウトします。</Text>
          <Text>
            Hatsulyの閲覧用アカウントを作成するには、右上の
            <b>「新規登録」</b>
            ボタンを押してください。
          </Text>
          <Text>
            有償のアカウントを作成するには、弊社の営業
            <b>（sales@datalabs.jp）</b>
            に連絡してください。
          </Text>
        </>
      ),
      modalType: MODAL_TYPES.CONFIRMATION_CRITICAL,
      closable: false,
      size: '2xl',
      confirmText: 'ログアウト',
      onConfirmPromised: async () => {
        await logout({ logoutParams: { returnTo: window.location.origin } })
        return false
      },
    })
  }, [showModal, logout])

  useEffect(() => {
    if (error) {
      handleUserNotFound()
    }
  }, [error, handleUserNotFound])

  // Store
  const dispatch = useAppDispatch()
  const userLoaded = useSelector((root: RootState) => root.user.userLoaded)
  const serviceStatus = useSelector((state: RootState) => state.system.serviceStatus)

  //* Auth0
  const { user, getAccessTokenSilently } = useAuth0()

  //* ユーザー情報の有無確認
  const refreshAccessToken = useCallback(async () => {
    await getAccessTokenSilently()
  }, [getAccessTokenSilently])

  //* アクセストークン取得
  const getAccessToken = useCallback(
    async (): Promise<string> =>
      getAccessTokenSilently().catch((err) => {
        processErrorHandler(err, ERROR_PROCESS.GET_ACCESS_TOKEN, showErrorModal)
        return ''
      }),
    [getAccessTokenSilently, showErrorModal],
  )

  //* 初回ロード
  useEffect(() => {
    if (!serviceStatus || serviceStatus.isDown.enabled) return

    if (user && !userLoaded) {
      refreshAccessToken()
        .then(async () => {
          const access_token = await getAccessToken()
          if (!access_token) {
            return
          }

          const userInfo = await getUser(access_token, (message: string) => {
            if (message === '40401') {
              handleUserNotFound()
            } else {
              showErrorModal(message)
            }
          })
          if (!userInfo?.user_id) {
            return
          }

          // TODO: Once all users have been migrated to Stripe,
          // check directly by subscription, without using user types.
          if (userInfo.subscriptions) {
            userInfo.user_type = SUBSCRIPTION_USER_TYPE_MAP[userInfo.subscriptions.type]
          }

          dispatch(setUser(user))
          dispatch(setUserProfile(userInfo))
          dispatch(setUserLoaded())

          // mixpanel init
          mixpanel.identify(userInfo.user_id)
          mixpanel.people.set({
            $name: user.email?.split('@')[1],
            $email: user.email,
            'User type': userInfo.user_type,
          })
        })
        .catch((err) => {
          processErrorHandler(err, ERROR_PROCESS.GET_ACCESS_TOKEN, showErrorModal)
        })
    }
  }, [
    user,
    userLoaded,
    serviceStatus,
    getAccessToken,
    dispatch,
    refreshAccessToken,
    showErrorModal,
    handleUserNotFound,
  ])

  return {
    getAccessToken,
  }
}
