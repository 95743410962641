import { FC, useCallback, useContext } from 'react'

import { Text, useToast } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'

import { EditorContext } from 'contexts/Editor'

import { TOAST_ID } from 'hooks/Shape'

import { EDITOR_SHAPE_KEYS, EDITOR_SHAPE_TEMP_ID_PREFIX, MAX_EDITOR_LAYERS, PLANE_SIDE_LABELS } from 'config/constants'
import { TOAST_CONFIG } from 'config/styles'

import { InspectionItem } from 'interfaces/inspection'
import { Cylinder, Polygon, Shape, ShapeKey, ShapeKeyType } from 'interfaces/shape'

import { findDiameterKeyByValue, meterToMillimeter, validateSelectedShape, zeroPad } from 'services/Util'
import { getVolumeEstimationItem, getVolumeEstimationItems } from 'services/VolumeEstimation'

import { setHoveredShapeId } from '../../store/editor'
import { Tools } from '../../tools/setup'
import LayerItem from './LayerItem'

const zeroPlaces = MAX_EDITOR_LAYERS.toString().length

const getLabel = (
  index: number,
  skey: ShapeKey,
  shapeId: string,
  isTemporary: boolean,
  inspectionItems: InspectionItem[],
  postfix = '',
) => {
  let prefix = ''
  if (skey === EDITOR_SHAPE_KEYS.POLYGONS) {
    const volume = getVolumeEstimationItem(inspectionItems, shapeId)
    if (volume) {
      return volume.part_name
    }

    const item = inspectionItems.find(
      (it) => it.shape_ids.polygons?.includes(shapeId) && it.item_type === 'polygon_area',
    )
    if (item?.part_name) {
      return `${item.part_name} ${postfix}`
    }
  }

  if (!prefix.length) {
    prefix = [EDITOR_SHAPE_KEYS.POLYGONS].includes(skey) ? '面積' : '鉄筋'
  }

  if (isTemporary) prefix = '! '
  return `${prefix}${zeroPad(index + 1, zeroPlaces)} ${postfix}`
}

const getDiameter = (shape: Shape, skey: ShapeKey) => {
  let diameter = 0
  if (skey === EDITOR_SHAPE_KEYS.CYLINDERS) {
    diameter = (shape as Cylinder).diameter
  }
  if (!diameter) {
    return ''
  }
  return `(${findDiameterKeyByValue(meterToMillimeter(diameter))})`
}

/**
 * Get all shape IDs associated with a specific shape ID.
 *
 * @param inspectionItems Inspection items.
 * @param shape_id Shape ID.
 */
const getAllVolumeShapeIds = (inspectionItems: InspectionItem[], shape_id: string): string[] =>
  inspectionItems
    .filter((item) => item.shape_ids.polygons?.includes(shape_id))
    .map((item) => item.shape_ids.polygons)
    .flat()

const ShapeLayer: FC<{
  shapes: Shape[]
  shapeKey: ShapeKey
  label: string
  isSomeShapesVisible: boolean
  collapsed: boolean
  setExpansion: (expanded: boolean) => void
}> = ({ shapes, shapeKey, label, isSomeShapesVisible, collapsed, setExpansion }) => {
  // Toast
  const toast = useToast()

  // Store
  const dispatch = useAppDispatch()
  const permissionSet = useSelector((state: RootState) => state.editor.permissionSet)
  const userType = useSelector((state: RootState) => state.user.userType)
  const selectedShapeIds = useSelector((state: RootState) => state.editor.selectedShapeIds)

  // Context
  const {
    shapes: allShapes,
    isLayerModifying,
    deleteShapes,
    updateShapeStatusByIds,
    changeSelectedShapeIds,
    selectedTool,
    inspectionItems,
  } = useContext(EditorContext)

  // Permission check
  const isAllowedToModify = permissionSet.MODIFY.includes(userType)

  // Editor tools
  const currentEditorTool = Tools.find((tool) => tool.key === selectedTool)

  // Collect all shape IDs to include paired planes for volumes
  const allShapeIds = shapes
    .map((shape) => {
      const res = getAllVolumeShapeIds(inspectionItems, shape.shape_id)
      if (res.length) {
        return res
      }

      return [shape.shape_id]
    })
    .flat()

  /**
   * Update single layer visibility
   */
  const updateLayerVisibility = useCallback(
    (invisible: boolean, shape_id: string) => {
      const volumes = getVolumeEstimationItems(inspectionItems, shape_id)
      if (volumes.length) {
        updateShapeStatusByIds(
          shapeKey,
          getAllVolumeShapeIds(inspectionItems, shape_id).map((id) => ({ shape_id: id, invisible })),
        )
      } else {
        updateShapeStatusByIds(shapeKey, [{ shape_id, invisible }])
      }
    },
    [shapeKey, inspectionItems, updateShapeStatusByIds],
  )

  /**
   * Delete a layer
   */
  const deleteLayer = useCallback(
    (shapeId: string) => {
      deleteShapes(false, shapeId, shapeKey)
    },
    [shapeKey, deleteShapes],
  )

  const onClick = useCallback(
    (shape: Shape) => () => {
      try {
        validateSelectedShape(shape, shapeKey, getVolumeEstimationItems(inspectionItems), currentEditorTool)
      } catch (e) {
        if (!toast.isActive(TOAST_ID)) {
          toast({
            ...TOAST_CONFIG,
            id: TOAST_ID,
            status: 'error',
            position: 'bottom',
            title: e instanceof Error ? e.message : 'エラー',
          })
        }
        return
      }

      let newShapeIds = [...selectedShapeIds]

      // If only 1 volume can be selected, deselect all other volumes
      if (
        currentEditorTool &&
        currentEditorTool.config?.volume?.onlyOneSelectable &&
        shapeKey === ShapeKeyType.POLYGON
      ) {
        newShapeIds = newShapeIds
          .filter((id) => !allShapes.polygons.find((poly) => poly.shape_id === id))
          .filter((id) => !allShapes.cylinders.find((cylinder) => cylinder.shape_id === id))
      }

      const idIndex = newShapeIds.indexOf(shape.shape_id)
      if (idIndex >= 0) {
        newShapeIds.splice(idIndex, 1)
      } else {
        newShapeIds.push(shape.shape_id)
      }

      changeSelectedShapeIds(newShapeIds)
    },
    [currentEditorTool, selectedShapeIds, inspectionItems, shapeKey, allShapes, toast, changeSelectedShapeIds],
  )

  const getPlaneSideLabel = useCallback(
    (shape: Shape) => {
      if (shapeKey !== EDITOR_SHAPE_KEYS.POLYGONS) return ''
      if (!(shape as Polygon).plane_side) return ''
      if (getVolumeEstimationItems(inspectionItems, shape.shape_id).length)
        return `(${PLANE_SIDE_LABELS[(shape as Polygon).plane_side!]})`
      return ''
    },
    [inspectionItems, shapeKey],
  )

  /**
   * Handle toggle visibility for all shapes
   */
  const handleUpdateVisibility = useCallback(
    (invisible: boolean) => {
      updateShapeStatusByIds(
        shapeKey,
        shapes.map((shp) => {
          const volumes = getVolumeEstimationItems(inspectionItems, shp.shape_id)
          if (volumes.length) {
            updateShapeStatusByIds(
              shapeKey,
              getAllVolumeShapeIds(inspectionItems, shp.shape_id).map((id) => ({ shape_id: id, invisible })),
            )
          }

          return { shape_id: shp.shape_id, invisible }
        }),
      )
    },
    [shapes, shapeKey, inspectionItems, updateShapeStatusByIds],
  )

  return (
    <>
      <LayerItem
        invisible={!isSomeShapesVisible}
        collapsible
        collapsed={collapsed}
        updateExpansion={setExpansion}
        disabled={isLayerModifying || !isAllowedToModify}
        selected={!!allShapeIds.length && !allShapeIds.some((id) => !selectedShapeIds.includes(id))}
        label={label}
        updateVisibility={handleUpdateVisibility}
        onClick={() => {
          // Don't allow selecting all shapes if only one volume can be selected
          if (currentEditorTool && currentEditorTool.config?.volume?.onlyOneSelectable) return

          // if some of the shapes are not selected, select all the shapes
          if (shapes.some((shape) => !selectedShapeIds.includes(shape.shape_id))) {
            changeSelectedShapeIds(Array.from(new Set([...selectedShapeIds, ...allShapeIds])))
          } else {
            // if all of the shapes are selected, unselect all the shapes
            changeSelectedShapeIds(selectedShapeIds.filter((id) => !allShapeIds.includes(id)))
          }
        }}
      />
      {!collapsed &&
        shapes.map(
          (shape, index) =>
            !shape.deleted && (
              <LayerItem
                disabled={isLayerModifying || !isAllowedToModify}
                selected={selectedShapeIds.includes(shape.shape_id)}
                deleteLayer={() => deleteLayer(shape.shape_id)}
                invisible={shape.invisible}
                key={`info-panel-shape-layer-${shape.shape_id}`}
                label={
                  <Text fontSize={10} alignItems="baseline">
                    {getLabel(
                      index,
                      shapeKey,
                      shape.shape_id,
                      shape.shape_id?.startsWith(EDITOR_SHAPE_TEMP_ID_PREFIX),
                      inspectionItems,
                      getDiameter(shape, shapeKey) || getPlaneSideLabel(shape),
                    )}
                  </Text>
                }
                updateVisibility={(invisible) => updateLayerVisibility(invisible, shape.shape_id)}
                onMouseOver={() => dispatch(setHoveredShapeId(shape.shape_id))}
                onMouseOut={() => dispatch(setHoveredShapeId(''))}
                onClick={onClick(shape)}
                isChild
              />
            ),
        )}
    </>
  )
}

export default ShapeLayer
