import { RefObject, createContext, useContext } from 'react'

import { PerspectiveCamera } from 'three'
import { ArcballControls } from 'three-stdlib'

import { DEFAULT_EDITOR_TOOL } from 'config/constants'

import { Editor } from 'interfaces/interfaces'

// To prevent mis-reuse of this object, use this function to create a new object
export const INITIAL_SHAPE_STATE = () => ({
  cylinders: [],
  tori: [],
  planes: [],
  cuboid: [],
  polygons: [],
})
const initialState: Editor = {
  backgroundColor: '',
  changeBackgroundColor: () => null,
  changeCuboidDirection: () => null,
  changeIsJobRunning: () => null,
  changeIsDragging: () => null,
  changeIsMouseDown: () => null,
  changeIsToolProcessing: () => null,
  changePointSize: () => null,
  changeSelectedShapeIds: () => null,
  changeTool: () => null,
  changeSubTool: () => null,
  setPrevSelectedTool: () => null,
  deleteShapes: () => null,
  initCompleted: false,
  isJobRunning: false,
  isDragging: false,
  isLayerModifying: false,
  isMouseDown: false,
  isPointCloudInvisible: false,
  isToolProcessing: false,
  selectedTool: DEFAULT_EDITOR_TOOL,
  prevSelectedTool: DEFAULT_EDITOR_TOOL,
  prevPrevSelectedTool: DEFAULT_EDITOR_TOOL,
  isPreviousTool: () => false,
  shapes: INITIAL_SHAPE_STATE(),
  setShapes: () => null,
  toggledCollapses: [],
  updateAllSelectedShapesStatus: () => null,
  updateAllShapesStatus: () => null,
  updatePointCloudVisibility: () => null,
  updateMeshRefs: () => null,
  updateShapeStatus: () => null,
  updateShapeStatusById: () => null,
  updateShapeStatusByIds: () => null,
  updateToggledCollapses: () => null,
  setInspectionSheet: () => null,
  inspectionItems: [],
  setInspectionItems: () => null,
  fetchInspectionSheet: () => Promise.reject(),
  fetchInspectionItems: () => Promise.reject(),
  fetchShapes: () => Promise.reject(),
  setIsLayerModifying: () => null,
  changeCollidingShapeIds: () => null,
  collidingShapeIds: [],
  selectionBoundaryBoxVectors: [],
  meshPoints: {},
  setMeshPoints: () => null,
  cameraRef: { current: null } as RefObject<PerspectiveCamera>,
  arcballControlsRef: {} as RefObject<ArcballControls>,
  focusCamera: () => null,
  focusCameraDirectional: () => null,
}

export const EditorContext = createContext(initialState)
export const useEditorContext = () => useContext(EditorContext)
