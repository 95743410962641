import { FC } from 'react'

import { Box, HStack } from '@chakra-ui/react'
import { Html, useContextBridge } from '@react-three/drei'
import { Provider, useSelector } from 'react-redux'
import { RootState, store } from 'store/app'

import { EditorContext } from 'contexts/Editor'
import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import Comment from './Comment'

const UnplacedComments: FC = () => {
  // Store
  const comments = useSelector((state: RootState) => state.comments.comments)
  const selectedComment = useSelector((state: RootState) => state.comments.selectedComment)

  // Forward contexts. This has to be done again due to
  // another Html wrapping causing lose of context
  const ContextBridge = useContextBridge(UserContext, EditorContext, GlobalModalContext)

  // Unplaced comments are wrapped in a single parent container so they would rotate as a whole
  // to face user as they pan around the 3D space. Not doing this means depending on the angle of the camera,
  // they would rotate individually on their own pivot and can become overlapped on top of each other.
  return (
    <Html
      style={{
        position: 'relative',
      }}
      zIndexRange={[10, 10]}
    >
      <ContextBridge>
        <Provider store={store}>
          <HStack
            flexWrap="wrap"
            // icon size = 24px
            // padding left and right = 4px
            // size is just for alignment in 2D HTML element, not relate to 3D space
            maxW={`${Math.ceil(Math.sqrt(comments.filter((c) => !c.cartesian_position?.x).length)) * (24 + 4 * 2)}px`}
            spacing={0}
            transform="translateX(-50%) translateY(-50%)"
          >
            {comments
              .filter((c) => !c.cartesian_position?.x)
              .map((comment) => (
                <Box
                  key={comment.thread_id}
                  position="relative"
                  zIndex={selectedComment?.thread_id === comment.thread_id ? 10 : 0}
                >
                  <Comment key={comment.thread_id} comment={comment} unplaced />
                </Box>
              ))}
          </HStack>
        </Provider>
      </ContextBridge>
    </Html>
  )
}
export default UnplacedComments
