import { FC, MutableRefObject, useContext, useEffect, useMemo, useRef, useState } from 'react'

import { Box } from '@chakra-ui/react'
import { Html } from '@react-three/drei'
import { BufferAttribute, BufferGeometry, DoubleSide, Mesh, Object3D, ShapeUtils, Vector2, Vector3 } from 'three'

import { EditorContext } from 'contexts/Editor'

import useShape from 'hooks/Shape'

import { PolygonPlaneMeshProps, ShapeKeyType } from 'interfaces/interfaces'

import { meterToMillimeter } from 'services/Util'

import DistanceLabel from './DistanceLabel'

export const PolygonPlaneMesh: FC<PolygonPlaneMeshProps> = ({
  polygon,
  origin,
  transparent = true,
  label,
  labelBgColor = 'yellow',
  labelTextColor = 'black',
  color = 'hotpink',
  opacity = 1,
  showGuides,
  showGuidesLabel = showGuides,
  guideColor = '#000',
  guideStyle,
  guideThickness,
  guideLabelTextColor = '#000',
  guideLabelBgColor = '#fff',
  guideLabelOutlineColor,
  guideLabelBorderWidth = 2,
  guideOpacity = 1,
  selectable,
  isOpenLoop = false,
}) => {
  // Context
  const { updateMeshRefs, cameraRef } = useContext(EditorContext)

  // Refs
  const ref = useRef<Mesh | null>(null)

  // Vars
  const planeOrigin = useMemo(
    () => (origin ? new Vector3(...origin) : new Vector3(...polygon.center!)),
    [origin, polygon.center],
  )

  // Hooks
  const { onPointerDown, onPointerUp, onPointerOver, onPointerOut } = useShape(polygon, ShapeKeyType.POLYGON, cameraRef)

  /**
   * Keep track of the mesh reference
   */
  useEffect(() => {
    if (polygon.shape_id) {
      updateMeshRefs({ [polygon.shape_id]: ref as MutableRefObject<Object3D> })
    }
  }, [updateMeshRefs, polygon.shape_id])

  const [geometry, setGeometry] = useState<BufferGeometry>(new BufferGeometry())

  useEffect(() => {
    if (!polygon.positions?.length || !polygon.vertices?.length || !polygon.center) return

    const buffer = new BufferGeometry()
    const positions = new Float32Array(polygon.positions.length * 3)
    polygon.positions.forEach((vertex, index) => {
      new Vector3(...vertex).toArray(positions, index * 3)
    })

    const indi = ShapeUtils.triangulateShape(
      polygon.vertices.map((v) => new Vector2(...v)),
      [],
    )

    buffer.setAttribute('position', new BufferAttribute(positions, 3))
    buffer.setIndex(indi.flat())
    buffer.translate(-polygon.center[0], -polygon.center[1], -polygon.center[2])
    buffer.computeVertexNormals()
    setGeometry(buffer)
  }, [polygon.positions, polygon.vertices, polygon.center])

  return (
    <group renderOrder={1}>
      {/* Plane mesh */}
      {polygon.positions.length > 2 && (
        <mesh
          ref={ref}
          onPointerOver={selectable ? onPointerOver : undefined}
          onPointerOut={selectable ? onPointerOut : undefined}
          onPointerDown={selectable ? onPointerDown : undefined}
          onPointerUp={selectable ? onPointerUp : undefined}
          position={planeOrigin}
          geometry={geometry}
        >
          <meshBasicMaterial color={color} side={DoubleSide} transparent={transparent} opacity={opacity} />
        </mesh>
      )}

      {/* Guidelines between placed points */}
      {showGuides &&
        polygon.positions.map((point, index) =>
          (isOpenLoop && index === polygon.positions.length - 1) || index < polygon.positions.length - 1 ? (
            <DistanceLabel
              key={`${polygon.shape_id}-guide-${point.join('-')}`}
              id={`${polygon.shape_id}-guide-${point.join('-')}`}
              hideAnchors
              points={[point, polygon.positions[(index + 1) % polygon.positions.length]]}
              label={
                showGuidesLabel
                  ? `${Math.round(
                      meterToMillimeter(
                        new Vector3(...point).distanceTo(
                          new Vector3(...polygon.positions[(index + 1) % polygon.positions.length]),
                        ),
                      ),
                    )}mm`
                  : undefined
              }
              lineColor={guideColor}
              lineStyle={guideStyle}
              lineThickness={guideThickness}
              labelTextColor={guideLabelTextColor}
              labelBgColor={guideLabelBgColor}
              labelOutlineColor={guideLabelOutlineColor}
              labelBorderWidth={guideLabelBorderWidth}
              opacity={guideOpacity}
            />
          ) : undefined,
        )}

      {/* Label */}
      {!!label && ref.current && (
        <Html
          position={polygon.center}
          style={{ transform: 'translateX(-50%) translateY(-50%)', pointerEvents: 'none' }}
          zIndexRange={[1, 9]}
        >
          <Box
            backgroundColor={labelBgColor}
            px={2}
            fontSize="80%"
            fontWeight="bold"
            color={labelTextColor}
            whiteSpace="nowrap"
          >
            {label}
          </Box>
        </Html>
      )}
    </group>
  )
}
