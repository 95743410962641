/* istanbul ignore file */
import { Fill, Style } from 'exceljs'

export const verticalMiddleStyle = Object.freeze({ alignment: { vertical: 'middle' } }) as Style

export const boldStyle = Object.freeze({
  font: { bold: true },
}) as Style

export const underlineStyle = Object.freeze({
  font: { color: { argb: '000000FF' }, underline: true },
}) as Style

export const nameStyleLeft = Object.freeze({
  ...verticalMiddleStyle,
  fill: {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'F3F3F3' },
  },
}) as Style // freeze to avoid accidental modification

export const nameStyleCenter = Object.freeze({
  ...nameStyleLeft,
  alignment: { horizontal: 'center', vertical: 'middle' },
}) as Style

export const valueStyleMili = Object.freeze({
  alignment: { horizontal: 'right', vertical: 'middle' },
  numFmt: '0',
}) as Style

export const valueStyleMeter = Object.freeze({
  alignment: { horizontal: 'right', vertical: 'middle' },
  numFmt: '0.0000',
}) as Style

export const plusMinusValueStyleMili = Object.freeze({
  ...valueStyleMili,
  numFmt: '±0',
}) as Style

export const plusMinusValueStyleMeter = Object.freeze({
  ...valueStyleMeter,
  numFmt: '±0.0000',
}) as Style
export const summaryTitleStyle = Object.freeze({
  ...boldStyle,
  ...verticalMiddleStyle,
}) as Style

export const planeHeaderFill = {
  type: 'pattern',
  pattern: 'solid',
  fgColor: { argb: 'CCCCCC' },
} as Fill
