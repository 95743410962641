import { useCallback, useContext, useEffect, useMemo } from 'react'

import { Text } from '@chakra-ui/react'
import { cloneDeep } from 'lodash'
import mixpanel from 'mixpanel-browser'
import { setAttentionText } from 'pages/projects/common/AttentionText/store/attentionText'
import { setHidePlaneLabel, setSelectedShapeIds } from 'pages/projects/editor/store/editor'
import { useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { RootState, useAppDispatch } from 'store/app'
import { Vector3 } from 'three'

import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import { EDITOR_COLLAPSE_TYPES, EDITOR_TOOLS, GRID_MINIMUM_INTERVAL } from 'config/constants'

import { MinimumAreaBoundary } from 'interfaces/diagram'
import { EditorConfig } from 'interfaces/editor'
import { Editor, InspectionItemGrid, ShapeKeyType } from 'interfaces/interfaces'
import { PlaneSide, Polygon } from 'interfaces/shape'

import { estimateGridDepth } from 'services/GridDepthEstimation'
import { alignMinimumAreaBoundary, minAreaRectangleOfPolygon } from 'services/MinimumRectangle'
import { generateGridPoints, meterToMillimeter } from 'services/Util'
import { getVolumeEstimationItem } from 'services/VolumeEstimation'

import {
  IntervalsConfig,
  reset,
  resetPerVolumeId,
  resetWorking as resetWorkingGrid,
  setGrids,
  setIsLoading,
  setSelectedVolumeId,
  updateInterval,
  updateWorkingGridPoints,
} from '../store'

/**
 * Editor hook for Grid tool
 */
const useEditor = ({
  selectedTool,
  shapes,
  inspectionItems,
  inspectionSheet,
  meshRefs,
  fetchInspectionItems,
  changeIsJobRunning,
  updateToggledCollapses,
}: Editor): EditorConfig => {
  // URL Params
  const { project_id } = useParams<{ project_id: string }>()
  const location = useLocation()
  const queries = new URLSearchParams(location.search)
  const inspection_area_id = queries.get('area')

  // Context
  const { getAccessToken } = useContext(UserContext)
  const { showErrorModal } = useContext(GlobalModalContext)

  // Store
  const dispatch = useAppDispatch()
  const isLoading = useSelector((state: RootState) => state.toolGrid.isLoading)
  const grids = useSelector((state: RootState) => state.toolGrid.grids)
  const intervals = useSelector((state: RootState) => state.toolGrid.intervals)
  const workingGridPoints = useSelector((state: RootState) => state.toolGrid.workingGridPoints)
  const selectedShapeIds = useSelector((state: RootState) => state.editor.selectedShapeIds)

  // Flags and vars
  const isToolSelected = useMemo(() => selectedTool === EDITOR_TOOLS.GRID, [selectedTool])
  const selectedPlanes = selectedShapeIds.filter((id) => shapes.polygons.find((plane) => plane.shape_id === id))
  const isPlaneSelected = selectedPlanes.length > 0
  const selectedVolumeItem = useMemo(
    () => (isPlaneSelected ? getVolumeEstimationItem(inspectionItems, selectedShapeIds[0]) : undefined),
    [inspectionItems, selectedShapeIds, isPlaneSelected],
  ) // any of the selected shape is fine

  /**
   * If any of the volumes was deleted, reset its working grid
   */
  useEffect(
    () => {
      const volumeIdsToDelete = Object.keys(intervals).filter((volumeId) =>
        inspectionItems.every((item) => item.inspection_item_id !== volumeId),
      )
      volumeIdsToDelete.forEach((volumeId) => {
        dispatch(resetPerVolumeId(volumeId))
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inspectionItems],
  )

  /**
   * Set grid data once inspection items are loaded
   */
  useEffect(() => {
    dispatch(
      setGrids(
        inspectionItems
          .filter((item) => item.grid)
          .map((item, itemIndex) => {
            const updatedItem = cloneDeep(item)
            if (updatedItem.grid) {
              updatedItem.grid.list_distances = updatedItem.grid.list_distances.map((distance, distanceIndex) => ({
                ...distance,
                name: `t${itemIndex + 1}-${distanceIndex + 1}`,
              }))
            }
            return updatedItem
          }),
      ),
    )
  }, [inspectionItems, dispatch])

  const checkIsLocked = useCallback(
    (grid: InspectionItemGrid | undefined, interval: IntervalsConfig, topPlaneId: string): boolean => {
      // If there's no existing grid, we're creating new one so no need to lock it.
      if (!grid) return false

      // Grid without intervals are based on old algo, always lock them.
      if (!grid.intervals) return true

      // Can't identify if they should or not without mesh so always lock if grid already exists
      if ((!meshRefs || !meshRefs[topPlaneId]) && grid) return true

      if (grid && meshRefs) {
        const meshRef = meshRefs[topPlaneId]
        if (meshRef.current) {
          const generatedGridPoints = generateGridPoints(interval).map((point) => point.map((v) => Math.round(v / 0.5)))
          const existingPositions = grid.list_distances
            // Convert world positions to local positions
            .map((distance) =>
              meshRef.current
                .worldToLocal(new Vector3(...distance.position_on_grid_point))
                .toArray()
                .map((v) => Math.round(meterToMillimeter(v) / 0.5)),
            )

          // For every single existing positions, check if there's an equivalent in generated points
          // If there's none, it means the grid has been modified, so lock it.
          return !existingPositions.every((position) =>
            generatedGridPoints.some((point) => point[0] === position[0] && point[1] === position[1]),
          )
        }
      }

      return false
    },
    [meshRefs],
  )

  const minimumRectangleBoundaries = useMemo(
    () =>
      (shapes.polygons || [])
        .filter((poly) => poly.plane_side === PlaneSide.UPPER)
        .map((poly) => ({
          polygon: poly,
          boundary: minAreaRectangleOfPolygon(poly.vertices, poly.positions),
        }))
        .filter((bundle) => bundle?.boundary) as { polygon: Polygon; boundary: MinimumAreaBoundary }[],
    [shapes],
  )

  /**
   * From the selected volume, get the top plane and calculate default interval for both axis (long and short)
   */
  useEffect(
    () => {
      if (!isToolSelected) return

      if (selectedVolumeItem?.inspection_item_id) {
        dispatch(setSelectedVolumeId(selectedVolumeItem.inspection_item_id))

        // If the config has been defined for this volume, skip.
        if (intervals[selectedVolumeItem.inspection_item_id]) return

        const grid = grids.find((row) => row.volume_id === selectedVolumeItem.inspection_item_id)
        let topShapeId = ''
        let bottomShapeId = ''
        let longAxisInt = 0
        let shortAxisInt = 0
        let longIntAxis: 1 | 2 = 1
        let angle = 0

        const topPolygon = shapes.polygons.find(
          (polygon) =>
            polygon.plane_side === PlaneSide.UPPER && selectedVolumeItem.shape_ids.polygons.includes(polygon.shape_id),
        )
        const bottomPolygon = shapes.polygons.find(
          (polygon) =>
            polygon.plane_side === PlaneSide.LOWER && selectedVolumeItem.shape_ids.polygons.includes(polygon.shape_id),
        )
        const topBoundary = minimumRectangleBoundaries.find(
          (bundle) => bundle.polygon.shape_id === topPolygon?.shape_id,
        )?.boundary

        if (topPolygon && bottomPolygon && topBoundary) {
          const alignedTopBoundary = alignMinimumAreaBoundary(topBoundary, topPolygon, bottomPolygon)
          topShapeId = topPolygon.shape_id || ''
          const [width, height] = topBoundary.extent
          longAxisInt = Math.max(width, height)
          shortAxisInt = Math.min(width, height)
          angle =
            alignedTopBoundary.boundaryAxisAlignment.x.angle *
            (alignedTopBoundary.mirrored &&
            (alignedTopBoundary.mirrored.horizontally || alignedTopBoundary.mirrored.vertically)
              ? 1
              : -1)
          longIntAxis = 1
        }

        bottomShapeId = bottomPolygon?.shape_id || ''

        if (topShapeId && bottomShapeId) {
          const interval: IntervalsConfig = {
            shapeKey: ShapeKeyType.POLYGON,
            locked: false,
            topPlaneId: topShapeId,
            bottomPlaneId: bottomShapeId,
            longAxis: {
              max: Math.max(Math.round(meterToMillimeter(longAxisInt / 2)), GRID_MINIMUM_INTERVAL),
              value:
                grid?.grid?.intervals?.long_axis ||
                Math.max(
                  Math.round((meterToMillimeter(longAxisInt / 2) + GRID_MINIMUM_INTERVAL) / 2),
                  GRID_MINIMUM_INTERVAL,
                ),
              // Polygon are origin at the center
              offset: Math.round(meterToMillimeter(longAxisInt / 2)),
            },
            shortAxis: {
              max: Math.max(Math.round(meterToMillimeter(shortAxisInt / 2)), GRID_MINIMUM_INTERVAL),
              value:
                grid?.grid?.intervals?.short_axis ||
                Math.max(
                  Math.round((meterToMillimeter(shortAxisInt / 2) + GRID_MINIMUM_INTERVAL) / 2),
                  GRID_MINIMUM_INTERVAL,
                ),
              offset: Math.round(meterToMillimeter(shortAxisInt / 2)),
            },
            angle,
            whichLongAxis: longIntAxis,
          }

          dispatch(
            updateInterval({
              id: selectedVolumeItem.inspection_item_id,
              interval: { ...interval, locked: checkIsLocked(grid?.grid, interval, topShapeId) },
            }),
          )
          dispatch(setHidePlaneLabel(true))
        }

        // For existing grid, set the working grid points on the grid points
        if (grid?.grid) {
          dispatch(
            updateWorkingGridPoints({
              id: selectedVolumeItem.inspection_item_id,
              points: grid.grid.list_distances.map((distance) => [
                distance.position_on_grid_point,
                distance.position_on_projected_point,
              ]),
            }),
          )
        }
      } else {
        dispatch(setSelectedVolumeId(''))
      }
    },
    // Ignore `intervals` since we don't want to run this effect when it changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isToolSelected, selectedVolumeItem, grids, shapes.polygons, checkIsLocked, dispatch],
  )

  /**
   * Initialize grid tool when this tool is selected
   */
  useEffect(() => {
    if (!isToolSelected) return

    updateToggledCollapses([EDITOR_COLLAPSE_TYPES.gridInterval, EDITOR_COLLAPSE_TYPES.workingGrid])
  }, [isToolSelected, updateToggledCollapses])

  /**
   * Show attention text
   */
  useEffect(() => {
    if (isToolSelected || Object.keys(intervals).length > 0) {
      dispatch(
        setAttentionText({
          message: isPlaneSelected
            ? [
                'グリッド間隔は右側のパネルから変更できます。',
                '各点はドラッグして移動することができます。',
                '選択が完了したら右下の測定ボタンをクリックしてください。',
              ].join('\n')
            : '平面をクリックして領域を選択してください。',
        }),
      )
    }
  }, [isToolSelected, intervals, isPlaneSelected, dispatch])

  /**
   * Reset store on unmount
   */
  useEffect(
    () => () => {
      dispatch(reset())
    },
    [dispatch],
  )

  /**
   * Run delete of grid depth data
   */
  const runEstimateGridDepth = useCallback(async () => {
    if (!Object.keys(intervals).length || !inspectionSheet || !project_id || !inspection_area_id) {
      return
    }

    const access_token = await getAccessToken()
    if (!access_token) {
      return
    }

    dispatch(setIsLoading(true))
    changeIsJobRunning(true)
    dispatch(
      setAttentionText({
        message: 'グリッド測定中...',
      }),
    )

    // Start estimating depth
    mixpanel.track('Create depth grid', {
      'Grid Count': Object.keys(intervals).length,
    })
    const results = await Promise.all(
      Object.keys(intervals)
        .filter((volumeId) => workingGridPoints[volumeId])
        .map((volumeId) =>
          estimateGridDepth(
            access_token,
            project_id,
            inspection_area_id,
            inspectionSheet.inspection_sheet_id,
            `測定したグリッド ${grids.length + 1}`,
            volumeId,
            intervals[volumeId],
            intervals[volumeId].shapeKey === ShapeKeyType.POLYGON
              ? [intervals[volumeId].topPlaneId, intervals[volumeId].bottomPlaneId]
              : undefined,
            workingGridPoints[volumeId].map((points) => points[0]),
            showErrorModal,
          ),
        ),
    )

    // Refresh inspection items to get the updated data
    if (results.some((result) => result)) {
      await fetchInspectionItems()
      dispatch(resetWorkingGrid())
    }

    dispatch(setIsLoading(false))
    dispatch(setAttentionText({ message: '' }))
    changeIsJobRunning(false)
    updateToggledCollapses([EDITOR_COLLAPSE_TYPES.grid])
  }, [
    intervals,
    project_id,
    inspection_area_id,
    inspectionSheet,
    grids,
    workingGridPoints,
    dispatch,
    getAccessToken,
    showErrorModal,
    changeIsJobRunning,
    updateToggledCollapses,
    fetchInspectionItems,
  ])

  const isEveryIntervalInRange = useMemo(
    () =>
      Object.keys(intervals).every(
        (volumeId) =>
          intervals[volumeId].longAxis.value >= GRID_MINIMUM_INTERVAL &&
          intervals[volumeId].longAxis.value <= intervals[volumeId].longAxis.max &&
          intervals[volumeId].shortAxis.value >= GRID_MINIMUM_INTERVAL &&
          intervals[volumeId].shortAxis.value <= intervals[volumeId].shortAxis.max,
      ),
    [intervals],
  )

  return {
    buttons: {
      submit: {
        key: 'estimate-depth-grid',
        label: `測定(${Object.keys(workingGridPoints).length}個のグリッド)`,
        loadingLabel: '測定中',
        tooltip: !isEveryIntervalInRange ? (
          <Text color="orange.400">グリッド間隔が正しく設定されていません</Text>
        ) : undefined,
        onClick: runEstimateGridDepth,
        isShown: useCallback(
          () => isToolSelected || isLoading || Object.keys(intervals).length > 0,
          [isLoading, isToolSelected, intervals],
        ),
        isLoading: useCallback(() => isLoading, [isLoading]),
        isDisabled: useCallback(
          () => !Object.keys(intervals).length || isLoading || !isEveryIntervalInRange,
          [intervals, isLoading, isEveryIntervalInRange],
        ),
      },
      reset: {
        onClick: useCallback(() => {
          dispatch(resetWorkingGrid())
          dispatch(setSelectedShapeIds([]))
        }, [dispatch]),
        isShown: useCallback(() => isToolSelected, [isToolSelected]),
        isDisabled: useCallback(() => !Object.keys(intervals).length || isLoading, [intervals, isLoading]),
      },
    },
  }
}

export default useEditor
