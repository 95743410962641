import { FC, useContext, useMemo } from 'react'

import { Flex, HStack, Text, VStack } from '@chakra-ui/react'
import CollapsePanel from 'pages/projects/editor/infoPanels/components/CollapsePanel'
import LayerItem from 'pages/projects/editor/infoPanels/components/LayerItem'
import { useSelector } from 'react-redux'
import { RootState } from 'store/app'

import { EditorContext } from 'contexts/Editor'

import { EDITOR_COLLAPSE_TYPES, EDITOR_TOOLS } from 'config/constants'
import { INFO_PANEL_PADDING } from 'config/styles'

import usePolylinePanel from '../../hooks/usePolylinePanel'

const WorkingPolylinePanel: FC = () => {
  const { workingPolylines, updatePolylineStatus, deletePolyline } = usePolylinePanel()

  // Context
  const { isLayerModifying, selectedTool } = useContext(EditorContext)

  // Store
  const permissionSet = useSelector((state: RootState) => state.editor.permissionSet)
  const userType = useSelector((state: RootState) => state.user.userType)

  // Derived
  const isAllowedToDetect = permissionSet.DETECT.includes(userType)
  const isToolSelected = useMemo(() => selectedTool === EDITOR_TOOLS.POLYLINE, [selectedTool])

  if (!isToolSelected && !workingPolylines.length) {
    return null
  }

  return (
    <Flex
      backgroundColor="gray.800"
      borderBottomLeftRadius="md"
      borderTopLeftRadius="md"
      w="100%"
      flex={1}
      data-testid="distance-panel"
    >
      <CollapsePanel title="検出中の延長" type={EDITOR_COLLAPSE_TYPES.polyline} onChange={() => null}>
        <VStack w="100%" spacing={0} pb={INFO_PANEL_PADDING - 1} overflowY="auto">
          {workingPolylines.map((polyline) => (
            <LayerItem
              disabled={isLayerModifying}
              selected={polyline.selected}
              deleteLayer={isAllowedToDetect ? () => deletePolyline(polyline) : undefined}
              invisible={polyline.invisible}
              key={polyline.inspection_item_id}
              label={
                <HStack fontSize={10} alignItems="baseline" spacing={1}>
                  <Text>{polyline.part_name}</Text>
                </HStack>
              }
              updateVisibility={(invisible) => updatePolylineStatus({ invisible }, polyline)}
              onClick={() => updatePolylineStatus({ selected: !polyline.selected, invisible: false }, polyline)}
            />
          ))}

          {/* Batch deletion */}
          {/* {selectedPolylines.length > 0 && (
            <Box fontWeight="bold" w="100%">
              <LayerItem
                onClick={deselectSelectedPolylines}
                invisible={!isSomeSelectedShapesVisible}
                label={`Selected Polyline(${selectedPolylines.length})`}
                updateVisibility={(invisible) => updateSelectedPolylines({ invisible })}
                deleteLayer={deleteSelectedPolylines}
              />
            </Box>
          )} */}
        </VStack>
      </CollapsePanel>
    </Flex>
  )
}

export default WorkingPolylinePanel
