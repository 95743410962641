import { FC, useCallback } from 'react'

import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import mixpanel from 'mixpanel-browser'

import { ExternalLink, HelpCircleIcon } from 'assets/icons'

import { HELP_CENTER_URL } from 'config/constants'

import { TutorialVideoConfig } from 'interfaces/tutorialVideoConfig'

import { hashCode } from 'services/Util'

interface TutorialVideoModalProps extends TutorialVideoConfig {
  useDisclosureProps: ReturnType<typeof useDisclosure>
}

const TutorialVideoModal: FC<TutorialVideoModalProps> = ({
  useDisclosureProps,
  toolType,
  variant,
  version,
  title,
  description,
  videoUrl,
  helpCenterUrl,
}) => {
  const { isOpen, onClose } = useDisclosureProps

  const onModalClose = useCallback(() => {
    // Tracking
    mixpanel.track('Close Help Modal', {
      'Selected Tool': toolType,
      Variant: variant,
      'Video URL': videoUrl,
      'External URL': helpCenterUrl,
      'Help Config Version': version,
    })

    // Close modal
    onClose()
  }, [onClose, toolType, variant, videoUrl, helpCenterUrl, version])

  const openHelpCenterUrl = useCallback(() => {
    // Tracking
    mixpanel.track('Open Help Center', {
      'Selected Tool': toolType,
      Variant: variant,
      'Video URL': videoUrl,
      'External URL': helpCenterUrl,
      'Help Config Version': version,
    })
    // Open help center url
    window.open(helpCenterUrl || HELP_CENTER_URL, '_blank')
  }, [toolType, variant, videoUrl, helpCenterUrl, version])

  return (
    <Modal isOpen={isOpen} onClose={onModalClose} size={videoUrl ? '4xl' : 'xl'}>
      {/* It should come out in front of the selection modal of the rebar diameter. */}
      <ModalOverlay zIndex={1401} />
      <ModalContent
        containerProps={{
          zIndex: '1401',
        }}
      >
        <ModalHeader>
          <Flex alignItems="center">
            <Box pr="2">
              <HelpCircleIcon size="40px" />
            </Box>
            {title}
          </Flex>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {description && description.map((line) => <Text key={hashCode(line)}>{line}</Text>)}
          {videoUrl && (
            <Box pt="16px">
              <iframe
                width="100%"
                height="450"
                src={videoUrl}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </Box>
          )}
        </ModalBody>
        <ModalFooter justifyContent="space-between">
          <Button onClick={onModalClose}>閉じる</Button>
          <Button onClick={openHelpCenterUrl} variant="outlineStatic" bgColor="primary.500">
            <Box pr="1">
              <ExternalLink size=".8rem" />
            </Box>
            ユーザーマニュアルを開く
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default TutorialVideoModal
