import { Matrix4, Mesh, Object3D, Object3DEventMap, Quaternion, Raycaster, Vector2, Vector3 } from 'three'

import { InspectionItem } from 'interfaces/inspection'
import { PlaneSide, Polygon, Shape, Shapes } from 'interfaces/shape'

import { fixVertexOnNormal } from './Points'
import { millimeterToMeter } from './Util'

const raycaster = new Raycaster()

/**
 * Function to find the normal of plane A pointing towards plane B
 */
export function findNormalTowardsB(pointA: Vector3, pointB: Vector3, normalA: Vector3): Vector3 {
  // Calculate the vector from A to B
  const vectorAB = new Vector3().subVectors(pointA, pointB).normalize()

  // Check if the normal of A is pointing towards B
  return normalA.dot(vectorAB) < 0 ? normalA : normalA.negate()
}

/**
 * Get normal vector of the top plane
 */
export const getNormals = (topShapePolygon?: Polygon, topMesh?: Mesh): Vector3 => {
  let normal: Vector3 = new Vector3()
  /* istanbul ignore else */
  if (topShapePolygon) {
    const vertex1 = new Vector3(...topShapePolygon.positions[0])
    const vertex2 = new Vector3(...topShapePolygon.positions[1])
    const vertex3 = new Vector3(...topShapePolygon.positions[2])

    // Calculate the normal vector of the triangle
    normal = new Vector3()
      .crossVectors(new Vector3().subVectors(vertex1, vertex2), new Vector3().subVectors(vertex3, vertex2))
      .normalize()
  } else if (topMesh) {
    normal = topMesh.getWorldDirection(new Vector3())
  }

  return normal
}

/**
 * Project points from top plane to bottom plane
 *
 * @param points Grid points to project
 * @param topMesh Top plane mesh
 * @param bottomMesh Bottom plane mesh
 */
/* istanbul ignore next */
export const projectFromTopToBottom = (
  points: Vector3[],
  topMesh: Object3D<Object3DEventMap>,
  bottomMesh: Object3D<Object3DEventMap>,
  direction: Vector3,
) =>
  points.map((point) => {
    const startPosition = new Vector3(
      millimeterToMeter(point.x),
      millimeterToMeter(point.y),
      millimeterToMeter(point.z),
    )
    topMesh.localToWorld(startPosition)

    raycaster.set(startPosition, direction)
    const results = raycaster.intersectObject(bottomMesh, true)

    if (results.length) {
      return [startPosition.toArray(), results[0].point.toArray()]
    }

    return [startPosition.toArray()]
  })

/**
 * Update a single shape while keeping the old shape's invisible status.
 *
 * @param newShape New shape to update
 * @param oldShape Old shape to pull invisible status from
 */
export function updateShape<T extends Shape>(newShape: T, oldShape?: T): T {
  if (!oldShape || newShape.invisible !== undefined) {
    return newShape
  }

  const updated = { ...newShape }
  updated.invisible = oldShape.invisible
  return updated
}

/**
 * Find the upper plane of the inspection item
 *
 * @param shapes
 * @param inspectionItem
 */
export const findUpperPlane = (shapes: Shapes, inspectionItem: InspectionItem) =>
  shapes.polygons?.find(
    (plane) => plane.plane_side === PlaneSide.UPPER && inspectionItem?.shape_ids.polygons?.includes(plane.shape_id),
  )

/**
 *  Find the lower plane of the inspection item
 * @param shapes
 * @param inspectionItem
 * @returns
 */
export const findLowerPlane = (shapes: Shapes, inspectionItem: InspectionItem) =>
  shapes.polygons?.find(
    (plane) => plane.plane_side === PlaneSide.LOWER && inspectionItem?.shape_ids.polygons?.includes(plane.shape_id),
  )

/**
 * Aligns a polyline's coordinates to the z-axis.
 *
 * @param points The polyline points.
 * @returns The aligned polyline points. If the polyline has less than 3 points, the original points are returned.
 */
export const alignPolylineToZAxis = (points: Vector3[]): Vector3[] => {
  if (points.length < 3) return points

  // Calculate two vectors from three points
  const vector1 = new Vector3().subVectors(points[1], points[0])
  const vector2 = new Vector3().subVectors(points[2], points[0])

  // Calculate the normal of the plane
  const normal = new Vector3().crossVectors(vector1, vector2).normalize()

  // Define the z-axis as the target normal
  const zAxis = new Vector3(0, 0, 1)

  // Create a quaternion that rotates the normal to align with the z-axis
  const quaternion = new Quaternion().setFromUnitVectors(normal, zAxis)

  // Rotate each point to align with the z-axis
  const alignedPoints = points.map((point) => point.clone().applyQuaternion(quaternion))

  return alignedPoints
}

/**
 * Creates a polygon object from 3 points.
 *
 * @param points - points of the polygon
 * @returns polygon object
 */
export const getPolygonFromPoints = (points: Vector3[]) => {
  const pointVecs = points.map((p) => new Vector3(p.x, p.y, p.z))
  const fixedPoints = fixVertexOnNormal(pointVecs.slice(0, 3))
  const transformMatrix = new Matrix4()
    .makeBasis(
      fixedPoints[1].clone().sub(fixedPoints[0]).normalize(),
      fixedPoints[2].clone().sub(fixedPoints[1]).normalize(),
      fixedPoints[1].clone().sub(fixedPoints[0]).cross(fixedPoints[2].clone().sub(fixedPoints[1])).normalize(),
    )
    .invert()
    .toArray()
  transformMatrix[3] = fixedPoints[0].x
  transformMatrix[7] = fixedPoints[0].y
  transformMatrix[11] = fixedPoints[0].z

  const transformation = {
    t_00: transformMatrix[0],
    t_01: transformMatrix[1],
    t_02: transformMatrix[2],
    t_03: transformMatrix[3],
    t_10: transformMatrix[4],
    t_11: transformMatrix[5],
    t_12: transformMatrix[6],
    t_13: transformMatrix[7],
    t_20: transformMatrix[8],
    t_21: transformMatrix[9],
    t_22: transformMatrix[10],
    t_23: transformMatrix[11],
    t_30: transformMatrix[12],
    t_31: transformMatrix[13],
    t_32: transformMatrix[14],
    t_33: transformMatrix[15],
  }
  const vertices = pointVecs
    .map((v) => new Vector3(v.x, v.y, v.z).applyMatrix4(new Matrix4().set(...transformMatrix).invert()))
    .map((v) => [v.x, v.y])

  return {
    transformation,
    vertices,
  }
}

/**
 * Sorts the coordinates of a 3D rectangular plane in a proper winding order.
 *
 * @param points - An array of Vector3 representing the coordinates of the rectangle.
 * @returns - An array of Vector3 sorted in a proper winding order.
 */
export const sortRectanglePoints3D = (points: Vector3[]): Vector3[] => {
  // Calculate the centroid of the rectangle
  const centroid = points.reduce((acc, point) => acc.add(point), new Vector3()).divideScalar(points.length)

  // Project the points onto the XY plane
  const points2D = points.map((point) => new Vector2(point.x, point.y))
  const centroid2D = new Vector2(centroid.x, centroid.y)

  // Sort the points based on their angle relative to the centroid in the 2D plane
  const sortedPoints2D = points2D.sort((a, b) => {
    const angleA = Math.atan2(a.y - centroid2D.y, a.x - centroid2D.x)
    const angleB = Math.atan2(b.y - centroid2D.y, b.x - centroid2D.x)
    return angleA - angleB
  })

  // Map the sorted 2D points back to the original 3D points
  const sortedPoints = sortedPoints2D.map((sortedPoint2D) =>
    points.find((point) => point.x === sortedPoint2D.x && point.y === sortedPoint2D.y),
  )

  return sortedPoints as Vector3[]
}

/**
 * Generates points for each face of a bounding box given its min and max coordinates.
 * @param min - The minimum corner of the bounding box.
 * @param max - The maximum corner of the bounding box.
 * @returns An object containing arrays of points for each face.
 */
export const getBoundingBoxFaces = (min: Vector3, max: Vector3) => {
  // Define the eight vertices of the bounding box
  const vertices = [
    new Vector3(min.x, min.y, min.z),
    new Vector3(max.x, min.y, min.z),
    new Vector3(max.x, max.y, min.z),
    new Vector3(min.x, max.y, min.z),
    new Vector3(min.x, min.y, max.z),
    new Vector3(max.x, min.y, max.z),
    new Vector3(max.x, max.y, max.z),
    new Vector3(min.x, max.y, max.z),
  ]

  // Define the six faces of the bounding box using the vertices
  const faces = [
    [vertices[0], vertices[1], vertices[2], vertices[3]], // Bottom face
    [vertices[4], vertices[5], vertices[6], vertices[7]], // Top face
    [vertices[0], vertices[1], vertices[5], vertices[4]], // Front face
    [vertices[2], vertices[3], vertices[7], vertices[6]], // Back face
    [vertices[0], vertices[3], vertices[7], vertices[4]], // Left face
    [vertices[1], vertices[2], vertices[6], vertices[5]], // Right face
  ]

  return faces
}
