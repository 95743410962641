/**
 * Wrapper for a single row of image selected for upload.
 */
import { FC } from 'react'

import { HStack, IconButton, Image, Input, Text, VStack } from '@chakra-ui/react'
import { startsWith } from 'lodash'
import { useAppDispatch } from 'store/app'

import { CheckCircleIcon, DeleteIcon } from 'assets/icons'

import { TEMP_IMAGE_ID } from 'config/constants'

import { CommentUploadedImage, IMAGE_UPLOAD_STATUS } from 'interfaces/interfaces'

import { removeFromFormImages, replaceFormImage } from '../store/comments'

// TODO: Not supposed to be here
const ICON_BUTTON_PROPS = {
  size: 'xs',
  variant: 'outline',
  fontSize: 'sm',
  borderWidth: '1px',
  borderRadius: '6px',
  padding: '4px',
}

export const CommentInputImage: FC<{
  /**
   * The image to be uploaded.
   */
  image: CommentUploadedImage

  /**
   * Disables input if true.
   */
  isDisabled: boolean
}> = ({ image, isDisabled }) => {
  // Store
  const dispatch = useAppDispatch()

  /**
   * Remove image. This is done differently depending on whether we are creating a new
   * comment/reply and editing them.
   * - During creation :  Simply remove the image object from comment/reply images array.
   * - During editing  :  Soft-deletion by marking `deleted` flag as true. Actual deletion is
   *                      done when the comment/reply is saved within CommentInput.
   */
  const onRemoveImage = () => {
    if (startsWith(image.image_id, TEMP_IMAGE_ID)) {
      dispatch(removeFromFormImages(image.image_id))
    } else {
      dispatch(
        replaceFormImage({ image_id: image.image_id, image: { ...image, status: IMAGE_UPLOAD_STATUS.DELETING } }),
      )
    }
  }

  /**
   * Updates image caption directly on image object within comment/reply in the store.
   *
   * @param caption Image caption
   */
  const updateImageCaption = (caption: string) => {
    dispatch(replaceFormImage({ image_id: image.image_id, image: { ...image, caption } }))
  }

  return (
    <HStack width="100%" py="3px">
      {image.compressed_size_url && <Image src={image.compressed_size_url} width="20%" />}
      {!image.compressed_size_url && <Image src={image.url} width="20%" />}
      <VStack flex={1} alignItems="flex-start">
        <Text fontSize="80%" mb="-6px" color={!isDisabled ? 'var(--chakra-colors-primary-600)' : 'inherit'}>
          {image.filename}
        </Text>
        <Input
          size="sm"
          fontSize="80%"
          placeholder="説明文を入力できます。"
          value={image.caption || ''}
          onChange={(e) => updateImageCaption(e.target.value)}
          maxLength={30}
          isDisabled={isDisabled}
          borderWidth="1px"
          borderRadius="6px"
          padding="4px"
          width="100%"
        />
      </VStack>
      <IconButton
        {...ICON_BUTTON_PROPS}
        aria-label="Delete Image"
        icon={image.status === IMAGE_UPLOAD_STATUS.DONE ? <CheckCircleIcon /> : <DeleteIcon />}
        color={image.status === IMAGE_UPLOAD_STATUS.DONE ? 'green' : ''}
        onClick={onRemoveImage}
        isDisabled={isDisabled || image.status === IMAGE_UPLOAD_STATUS.DONE}
        isLoading={image.status === IMAGE_UPLOAD_STATUS.LOADING}
      />
    </HStack>
  )
}
