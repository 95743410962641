import axios from 'axios'

import { API_GATEWAY_URL } from 'config/environments'

import { Blueprint } from 'interfaces/interfaces'

import { ERROR_PROCESS, processErrorHandler } from './ErrorHandler'

const PROJECTS_API_URL = `${API_GATEWAY_URL}/projects`

/**
 * Get all blueprints
 * @param {string} access_token token
 * @param {string} project_id project id
 * @return {Blueprint[]}} Blueprints
 */
export const getBlueprints = async (
  access_token: string,
  project_id: string,
  showErrorModal: (message: string) => void,
): Promise<Blueprint[] | null> => {
  const blueprints = await axios
    .get<{ results: Blueprint[] }>(`${PROJECTS_API_URL}/${project_id}/blueprints`, {
      responseType: 'json',
      headers: { 'X-Authorization': `Bearer ${access_token}` },
    })
    .then((response) => response.data.results)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.GET_BLUEPRINTS, showErrorModal)
      return null
    })

  return blueprints
}

/**
 * Get a blueprint's url
 * @param {string} access_token token
 * @param {string} project_id project id
 * @param {string} blueprint_id blueprint id
 * @return {string} url
 */
export const getBlueprintUrl = async (
  access_token: string,
  project_id: string,
  blueprint_id: string,
  showErrorModal: (message: string) => void,
): Promise<string | null> => {
  const url = await axios
    .get<Blueprint>(`${PROJECTS_API_URL}/${project_id}/blueprints/${blueprint_id}?original_file_url=true`, {
      responseType: 'json',
      headers: { 'X-Authorization': `Bearer ${access_token}` },
    })
    .then((response) => response.data.original_file_url || null)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.GET_BLUEPRINTS, showErrorModal)
      return null
    })

  return url
}

/**
 * Get signed url for upload blueprint
 * @param {string} access_token access token
 * @param {string} project_id project id
 * @param {string} filename filename
 * @param {function} showErrorModal show error modal function
 * @returns {Blueprint}
 */
export const getSignedUrlForUploadBlueprint = async (
  access_token: string,
  project_id: string,
  filename: string,
  showErrorModal: (message: string) => void,
): Promise<Blueprint | null> => {
  const signedFile = await axios
    .post<Blueprint>(
      `${PROJECTS_API_URL}/${project_id}/blueprint-files`,
      {
        filename,
      },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      },
    )
    .then((response) => response.data)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.ADD_BLUEPRINT, showErrorModal)
      return null
    })

  return signedFile
}

/**
 * Update or insert a blueprint metadata
 * @param {string} access_token access token
 * @param {string} project_id project id
 * @param {Blueprint} blueprint blueprint
 * @param {string} filename filename
 * @param {function} showErrorModal show error modal function
 * @returns {Blueprint}
 */
export const upsertBlueprintMetaData = async (
  access_token: string,
  project_id: string,
  blueprint: Blueprint,
  showErrorModal: (message: string) => void,
): Promise<Blueprint | null> =>
  axios
    .put<Blueprint>(
      `${PROJECTS_API_URL}/${project_id}/blueprints/${blueprint.blueprint_id}`,
      {
        s3_filename: blueprint.s3_filename,
        original_filename: blueprint.original_filename,
      },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      },
    )
    .then((response) => response.data)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.ADD_BLUEPRINT, showErrorModal)
      return null
    })

/**
 * Delete a blueprint
 * @param {string} access_token access token
 * @param {string} project_id project id
 * @param {string} blueprint_id blueprint id
 * @param {function} showErrorModal show error modal function
 * @returns {boolean}
 */
export const deleteBlueprint = async (
  access_token: string,
  project_id: string,
  blueprint_id: string,
  showErrorModal: (message: string) => void,
): Promise<boolean> => {
  const result = await axios
    .delete(`${PROJECTS_API_URL}/${project_id}/blueprints/${blueprint_id}`, {
      responseType: 'json',
      headers: { 'X-Authorization': `Bearer ${access_token}` },
    })
    .then(() => true)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.DELETE_BLUEPRINT, showErrorModal)
      return false
    })

  return result
}
