import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { TutorialVideoConfig } from 'interfaces/interfaces'

export interface VideoState {
  /**
   * Whether the video has been loaded.
   */
  videoLoaded: boolean

  /**
   * List of videos.
   */
  videos: TutorialVideoConfig[]

  /**
   * Selected video configuration.
   */
  selectedVideoConfig: TutorialVideoConfig | null
}

const initialState: VideoState = {
  videoLoaded: false,
  videos: [],
  selectedVideoConfig: null,
}

export const slice = createSlice({
  name: 'video',
  initialState,
  reducers: {
    setVideoLoaded: (state) => {
      state.videoLoaded = true
    },
    setVideos: (state, action: PayloadAction<TutorialVideoConfig[]>) => {
      state.videos = action.payload
    },
    setSelectedVideoConfig: (state, action: PayloadAction<TutorialVideoConfig>) => {
      state.selectedVideoConfig = action.payload
    },
  },
})

export const { setVideoLoaded, setVideos, setSelectedVideoConfig } = slice.actions

export default slice.reducer
