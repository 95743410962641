import { FC, useEffect, useState } from 'react'

import { Button, HStack, StackDivider } from '@chakra-ui/react'
import { isTablet as isMobile } from 'react-device-detect'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'

import { ToolbarRotateIcon, ToolbarScaleIcon, ToolbarTranslateIcon } from 'assets/icons'

import { EDITOR_SUB_TOOLS } from 'config/constants'

import { TransformTypes } from 'interfaces/canvas'

import { setCurrentTransformType, setShouldResetTransforms } from '../store/editor'
import SubToolbarButton from './components/SubToolbarButton'

const TransformControlsToolbar: FC = () => {
  // Store
  const dispatch = useAppDispatch()
  const message = useSelector((state: RootState) => state.attentionText.message)
  const allowedTransformControls = useSelector((state: RootState) => state.editor.allowedTransformControls)
  const currentTransformType = useSelector((state: RootState) => state.editor.currentTransformType)

  // States
  const [topOffset, setTopOffset] = useState<string | number>(1)

  // Vars
  const buttonSize = isMobile ? 12 : 8

  /**
   * Adjust top offset when message changes.
   */
  useEffect(() => {
    if (message) {
      setTimeout(() => {
        const ele = document.getElementById('attention-text')
        if (ele) {
          setTopOffset(`${ele.offsetHeight + ele.offsetTop + 10}px`)
        }
      })
    }

    setTopOffset(1)
  }, [message])

  if (!allowedTransformControls?.length) return null

  return (
    <HStack
      left={isMobile ? '600px' : '50%'}
      transform="translateX(-50%)"
      overflow="hidden"
      position="absolute"
      spacing={4}
      top={topOffset}
      h={isMobile ? 12 : 8}
    >
      <HStack
        backgroundColor="gray.800"
        borderRadius="md"
        overflow="hidden"
        divider={<StackDivider borderColor="whiteAlpha.200" />}
        spacing={0}
      >
        {!allowedTransformControls ||
          (allowedTransformControls?.includes(TransformTypes.TRANSLATE) && (
            <SubToolbarButton
              icon={<ToolbarTranslateIcon width="50%" height="50%" />}
              label="移動"
              toolType={EDITOR_SUB_TOOLS.CUBOID.TRANSLATE}
              buttonSize={buttonSize}
              selected={currentTransformType === TransformTypes.TRANSLATE}
              onClick={() => dispatch(setCurrentTransformType(TransformTypes.TRANSLATE))}
            />
          ))}
        {!allowedTransformControls ||
          (allowedTransformControls?.includes(TransformTypes.SCALE) && (
            <SubToolbarButton
              icon={<ToolbarScaleIcon width="50%" height="50%" />}
              label="サイズ"
              toolType={EDITOR_SUB_TOOLS.CUBOID.SCALE}
              buttonSize={buttonSize}
              selected={currentTransformType === TransformTypes.SCALE}
              onClick={() => dispatch(setCurrentTransformType(TransformTypes.SCALE))}
            />
          ))}
        {!allowedTransformControls ||
          (allowedTransformControls?.includes(TransformTypes.ROTATE) && (
            <SubToolbarButton
              icon={<ToolbarRotateIcon width="50%" height="50%" />}
              label="回転"
              toolType={EDITOR_SUB_TOOLS.CUBOID.ROTATE}
              buttonSize={buttonSize}
              selected={currentTransformType === TransformTypes.ROTATE}
              onClick={() => dispatch(setCurrentTransformType(TransformTypes.ROTATE))}
            />
          ))}
      </HStack>
      <Button variant="editorGhost" size="sm" onClick={() => dispatch(setShouldResetTransforms(true))}>
        リセット
      </Button>
    </HStack>
  )
}

export default TransformControlsToolbar
