import { FC, PropsWithChildren, useContext, useEffect } from 'react'

import { Box, Button, Collapse, Flex, useDisclosure } from '@chakra-ui/react'

import { BigChevronDownIcon as ChevronDownIcon, BigChevronUpIcon as ChevronUpIcon } from 'assets/icons'

import { EditorContext } from 'contexts/Editor'

const CollapsePanel: FC<PropsWithChildren & { title: string; type: string; onChange: (isOpen: boolean) => void }> = ({
  children,
  title,
  type,
  onChange,
}) => {
  const { toggledCollapses } = useContext(EditorContext)
  const { isOpen, onToggle, onClose, onOpen } = useDisclosure({ defaultIsOpen: true })

  useEffect(() => {
    if (!toggledCollapses.includes(type)) {
      onClose()
    } else {
      onOpen()
    }
  }, [onClose, onOpen, toggledCollapses, type])

  useEffect(() => {
    onChange(isOpen)
  }, [isOpen, onChange])

  return (
    <Flex w="100%" maxH="100%" flex={1} flexDirection="column">
      <Button
        onClick={onToggle}
        w="100%"
        variant="layer"
        rightIcon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
        paddingInlineStart={0}
      >
        {title}
      </Button>
      <Box flex={1} overflowX="hidden" overflowY="auto" className="collapse-content">
        <Collapse in={isOpen} animateOpacity data-testid="panel-contents">
          {children}
        </Collapse>
      </Box>
    </Flex>
  )
}

export default CollapsePanel
