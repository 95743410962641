import { useContext } from 'react'

import ToolbarButton from 'pages/projects/editor/toolbar/components/ToolbarButton'

import { ToolbarFocusButton } from 'assets/icons'

import { EditorContext } from 'contexts/Editor'

import { EDITOR_TOOLS, EDITOR_TOOLS_LABELS, EDITOR_TOOL_BUTTON_SIZE } from 'config/constants'

/**
 * Camera focus button.
 * This component is being imported by CameraProfile's CameraToolbar component.
 */
const CameraFocusButton = () => {
  // Context
  const { selectedTool, changeTool } = useContext(EditorContext)

  return (
    <ToolbarButton
      icon={<ToolbarFocusButton size="50%" />}
      label={EDITOR_TOOLS_LABELS.FOCUS}
      toolType={EDITOR_TOOLS.FOCUS}
      buttonSize={EDITOR_TOOL_BUTTON_SIZE}
      selectedTool={selectedTool}
      changeTool={changeTool}
      toolTipPlacement="top"
    />
  )
}

export default CameraFocusButton
