import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'

import { Text, ToastId, useToast } from '@chakra-ui/react'
import { cloneDeep } from 'lodash'
import mixpanel from 'mixpanel-browser'
import { setAttentionText } from 'pages/projects/common/AttentionText/store/attentionText'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store/app'

import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import { EDITOR_COLLAPSE_TYPES, EDITOR_SHAPE_KEYS, EDITOR_TOOLS } from 'config/constants'

import { Editor } from 'interfaces/canvas'
import { EditorConfig } from 'interfaces/editor'
import { ErrorResponse } from 'interfaces/errors'
import { INSPECTION_ITEM_TYPES, InspectionItem } from 'interfaces/inspection'
import { PlaneSide } from 'interfaces/shape'

import { DepthEstimationResultKey, estimateDepth, getDepthEstimationItems } from 'services/DepthEstimation'
import { ERROR_PROCESS, getErrorForToast } from 'services/ErrorHandler'
import { shapeIdArrayToShapeIds } from 'services/Util'
import { getVolumeEstimationItems } from 'services/VolumeEstimation'

import { INITIAL_DEPTH_ESTIMATION_TYPES, setDepthEstimationTypes } from '../store'

/**
 * Initialize the editor for the depth estimation tool.
 * Note that when a shape is selected, there is an additional mechanic to filter/add more shapes to the selections.
 * This mechanics is located in MainCanvas::changeSelectedShapeIds and addAdditionalSelectedShapeIds.
 *
 * @param props The editor context.
 */
export default function useEditor({
  selectedTool,
  inspectionItems,
  inspectionSheet,
  shapes,
  collidingShapeIds,
  prevSelectedTool,
  changeIsJobRunning,
  updateAllShapesStatus,
  updateToggledCollapses,
  fetchInspectionItems,
  changeCollidingShapeIds,
}: Editor): EditorConfig {
  // Toast
  const toast = useToast()
  const toastIdRef = useRef<ToastId>()

  // Context
  const { showErrorModal } = useContext(GlobalModalContext)
  const { getAccessToken } = useContext(UserContext)

  // Store
  const dispatch = useDispatch()
  const project = useSelector((state: RootState) => state.page.project)
  const inspectionArea = useSelector((state: RootState) => state.page.inspectionArea)
  const selectedShapeIds = useSelector((state: RootState) => state.editor.selectedShapeIds)

  // State
  const [isLoading, setIsLoading] = useState(false)

  // Vars
  const isSelectedTool = useMemo(() => selectedTool === EDITOR_TOOLS.DEPTH, [selectedTool])
  const shapesToSubmit = shapeIdArrayToShapeIds(shapes, selectedShapeIds)
  const isCylindersSelected = shapesToSubmit.cylinders.length > 0

  // ------------ Estimate depth ------------ //
  const runEstimateDepth = useCallback(async () => {
    if (!project || !inspectionArea || !inspectionSheet) {
      return false
    }

    const volumeItem = getVolumeEstimationItems(inspectionItems).find((item) =>
      [...(item.shape_ids.polygons || [])].some((id) => selectedShapeIds.includes(id)),
    )
    if (!volumeItem?.inspection_item_id) {
      return false
    }

    const token = await getAccessToken()
    if (!token) {
      changeIsJobRunning(false)
      return false
    }

    // Change page state to loading
    setIsLoading(true)
    changeIsJobRunning(true)
    dispatch(setAttentionText({ message: 'かぶり厚を測定中...' }))

    // clear colliding shape IDs for shapes used before running
    changeCollidingShapeIds(collidingShapeIds.filter((id) => !selectedShapeIds.includes(id)))

    // Call API and reload data once done
    const success = await estimateDepth(
      token,
      project.project_id,
      inspectionArea.inspection_area_id,
      inspectionSheet.inspection_sheet_id,
      volumeItem.inspection_item_id,
      shapesToSubmit,
      showErrorModal,
    )

    mixpanel.track('Estimate depth', {
      Tool: selectedTool, // a bit redundant, but we want to be consistent
      'Volume item': volumeItem.inspection_item_id,
      'Shape IDs': selectedShapeIds,
      'Shape amount': selectedShapeIds.length,
      Success: !!success,
    })

    let newCollidingShapeIds: string[] = []
    if (success) {
      // re-show all planes
      if (!success.plane_to_plane_distance.status_message) {
        updateAllShapesStatus({ invisible: false }, EDITOR_SHAPE_KEYS.POLYGONS)
      }

      await fetchInspectionItems()

      const statusIds = new Set<number>()
      ;(Object.keys(success) as DepthEstimationResultKey[]).forEach((row) => {
        // This specific type has encountered an error, get its status ID
        if (success[row].status_message) {
          const statusId = (JSON.parse(success[row].status_message!) as ErrorResponse)?.status_id
          if (statusId) {
            statusIds.add(statusId)

            const { colliding_shape_ids } = success[row]
            if (colliding_shape_ids) {
              newCollidingShapeIds = [...newCollidingShapeIds, ...colliding_shape_ids]
            }
          }
        }
      })
      changeCollidingShapeIds(newCollidingShapeIds)

      // Show toast for every unqiue status ID
      if (statusIds.size) {
        statusIds.forEach((statusId) => {
          toastIdRef.current = toast({
            ...getErrorForToast(ERROR_PROCESS.ESTIMATE_DEPTH, statusId),
            status: 'warning',
            duration: 10000,
            isClosable: true,
          })
        })
      }
    }

    // Reset page state
    setIsLoading(false)
    changeIsJobRunning(false)
    dispatch(setAttentionText({ message: '' }))
    updateToggledCollapses([EDITOR_COLLAPSE_TYPES.depth])

    return true
  }, [
    selectedTool,
    selectedShapeIds,
    project,
    inspectionArea,
    inspectionSheet,
    inspectionItems,
    collidingShapeIds,
    shapesToSubmit,
    showErrorModal,
    dispatch,
    getAccessToken,
    fetchInspectionItems,
    changeIsJobRunning,
    updateAllShapesStatus,
    changeCollidingShapeIds,
    updateToggledCollapses,
    toast,
  ])

  /**
   * Set depth estimate data after loading inspection items
   */
  useEffect(() => {
    const items = getDepthEstimationItems(inspectionItems)
    const depthTypes = cloneDeep(INITIAL_DEPTH_ESTIMATION_TYPES)

    // Old items have no plane_side so treat the first plane to cylinder as Type 1
    depthTypes[0].inspectionItems = items
      // The reduce is to make sure we only get 1 plane to cylinder distance per volume
      .reduce<InspectionItem[]>((arr, item) => {
        if (item.plane_side) {
          return arr
        }
        if (arr.some((it) => it.volume_id === item.volume_id)) {
          return arr
        }
        if (
          item.item_type === INSPECTION_ITEM_TYPES.PLANE_TO_CYLINDERS_DISTANCE &&
          !!item?.plane_to_cylinders_distance?.estimated_value
        ) {
          return [...arr, item]
        }

        return arr
      }, [])

    // If there's another plane to cylinder different from found on Type 1, then that shall be Type 2
    depthTypes[1].inspectionItems = items.filter(
      (item) =>
        !item.plane_side &&
        item.item_type === INSPECTION_ITEM_TYPES.PLANE_TO_CYLINDERS_DISTANCE &&
        !depthTypes[0].inspectionItems?.some((it) => it.inspection_item_id === item.inspection_item_id) &&
        !!item?.plane_to_cylinders_distance?.estimated_value,
    )

    // Top side
    depthTypes[2].inspectionItems = items.filter((item) => item.plane_side === PlaneSide.UPPER)

    // Bottom side
    depthTypes[3].inspectionItems = items.filter((item) => item.plane_side === PlaneSide.LOWER)

    dispatch(setDepthEstimationTypes(depthTypes))
  }, [inspectionItems, shapes, dispatch])

  /**
   * Toggle info panels when tool is selected
   */
  useEffect(() => {
    if (!isSelectedTool) return

    dispatch(
      setAttentionText({
        message:
          '平面を選択すると自動で領域内の鉄筋が選択されます。' +
          '鉄筋は手動で除外/追加することもできます。\n' +
          '選択が完了したら右下の測定ボタンをクリックしてください。',
      }),
    )

    updateToggledCollapses([EDITOR_COLLAPSE_TYPES.detected, EDITOR_COLLAPSE_TYPES.depth])
  }, [isSelectedTool, prevSelectedTool, updateToggledCollapses, dispatch])

  return {
    buttons: {
      submit: {
        key: 'depth-estimate-submit',
        label: `かぶり厚を測定`,
        loadingLabel: '測定中',
        tooltip: !isCylindersSelected ? (
          <Text color="orange.400">平面の領域と１つ以上の鉄筋を選択してください</Text>
        ) : undefined,
        onClick: runEstimateDepth,
        isShown: useCallback(() => isSelectedTool || isLoading, [isSelectedTool, isLoading]),
        isLoading: useCallback(() => isLoading, [isLoading]),
        isDisabled: useCallback(
          () => isLoading || !selectedShapeIds.length || !isCylindersSelected,
          [isLoading, isCylindersSelected, selectedShapeIds.length],
        ),
      },
    },
  }
}
