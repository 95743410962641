import { FC } from 'react'

import { Button, Container, Heading, Image, Link, VStack } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { RootState } from 'store/app'

import BackgroundImage from 'assets/imgs/maintenance-page.png'

import { CONTAINER_MAX_WIDTH } from 'config/styles'

const MaintenancePage: FC = () => {
  // Store
  const serviceStatus = useSelector((state: RootState) => state.system.serviceStatus)

  const message =
    serviceStatus?.isDown.message || serviceStatus?.isDown.urlText ? (
      <>
        {serviceStatus?.isDown.message} &nbsp;
        {serviceStatus.isDown.url && serviceStatus.isDown.urlText && (
          <Link href={serviceStatus?.isDown.url} variant="underline" target="_blank">
            {serviceStatus?.isDown.urlText}
          </Link>
        )}
      </>
    ) : (
      'メンテナンス終了までしばらくお待ちください。'
    )

  return (
    <Container flex="1" maxW={CONTAINER_MAX_WIDTH} textAlign="center">
      <VStack>
        <Image src={BackgroundImage} py={20} />
        <Heading textAlign="center" size="xl">
          ただいま、メンテナンス中です。
        </Heading>
        <Heading textAlign="center" size="md">
          {message}
        </Heading>
        <Button as={Link} href="https://hatsuly.datalabs.jp/" colorScheme="primary" target="_blank">
          Hatsulyについて
        </Button>
      </VStack>
    </Container>
  )
}

export default MaintenancePage
