import { FC } from 'react'

import { Box, Tooltip } from '@chakra-ui/react'
import { Html } from '@react-three/drei'

import { LabelProps } from 'interfaces/canvas'

/**
 * Label for warnings, complete with Tooltip!
 */
const WarningLabel: FC<LabelProps> = ({
  point,
  label,
  labelPrefix = '⚠️ ',
  message,
  backgroundColor = 'yellow',
  color = 'black',
  opacity,
  offset = [0, 0],
  padding = [0, 4],
}) => {
  let boxPadding = {}
  if (typeof padding === 'number') {
    boxPadding = { padding }
  } else if (typeof padding === 'object' && padding.length === 2) {
    boxPadding = { px: padding[0], py: padding[1] }
  } else if (typeof padding === 'object' && padding.length === 4) {
    boxPadding = {
      paddingTop: padding[0],
      paddingRight: padding[1],
      paddingBottom: padding[2],
      paddingLeft: padding[3],
    }
  }

  return (
    <group>
      <Html
        position={point}
        style={{ transform: `translateX(calc(-50% + ${offset[0]}px)) translateY(calc(-50% + ${offset[1]}px))` }}
        zIndexRange={[1, 9]}
      >
        <Tooltip hasArrow placement="top" label={message} p="8px" fontWeight="normal" className="canvas-tooltip">
          <Box
            {...boxPadding}
            backgroundColor={backgroundColor}
            opacity={opacity}
            fontSize="80%"
            fontWeight="bold"
            color={color}
            whiteSpace="nowrap"
          >
            {labelPrefix}
            {label}
          </Box>
        </Tooltip>
      </Html>
    </group>
  )
}

export default WarningLabel
