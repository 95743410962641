/* istanbul ignore file */
import { FC, useEffect, useMemo, useRef } from 'react'

import Konva from 'konva'
import { uniqueId } from 'lodash'
import { Layer, Line, Stage, Text } from 'react-konva'
import { useAppDispatch } from 'store/app'
import { setDiagram } from 'store/diagram'
import { Matrix4, Matrix4Tuple, Vector2, Vector3 } from 'three'

import { PointArray } from 'interfaces/attribute'
import { DiagramOperation, MinimumAreaBoundary } from 'interfaces/diagram'
import { CameraProfile, CameraViewOrientation, InspectionItem } from 'interfaces/inspection'
import { Polygon } from 'interfaces/shape'

import { minAreaRectangleOfPolygon } from 'services/MinimumRectangle'
import { bestFitRectangle, getProjectedVertices, orientPositionsBasedOnProjectedDiagram } from 'services/PlaneDiagram'
import { alignPolylineToZAxis, getPolygonFromPoints } from 'services/Shape'
import { meterToMillimeter } from 'services/Util'

interface PolylineDiagram {
  polyline: Vector2[]
  labels?: {
    text: string
    point: Vector2
  }[]
  projected: Partial<ReturnType<typeof getProjectedVertices>>
  polygon: Polygon
  minimumRectangleBoundary: MinimumAreaBoundary
  scale?: number
}

const PolylineDiagram: FC<{
  /**
   * ID to be used for the diagram.
   * This is used to store the diagram in the store.
   */
  imageId: string

  /**
   * Canvas width.
   */
  width: number

  /**
   * Canvas height.
   */
  height: number

  /**
   * Inspection item of the Polyline.
   */
  inspectionItem: InspectionItem

  /**
   * Camera profile to be used for the diagram.
   */
  cameraProfile: CameraProfile | undefined
}> = ({ imageId, width, height, inspectionItem, cameraProfile }) => {
  // Store
  const dispatch = useAppDispatch()

  // Refs
  const stageRef = useRef<Konva.Stage>(null)

  const pointsInVector3 = useMemo(
    () =>
      inspectionItem.polyline_length?.positions_for_distance?.map((point) => new Vector3(point[0], point[1], point[2])),
    [inspectionItem],
  )
  /**
   * Generate a polygon reference of the polyline.
   * This will be used as a reference so we can use the existing codes for polygon diagram.
   */
  const diagramGuessProjection: PolylineDiagram | null = useMemo(() => {
    if (!pointsInVector3) return null

    // When there's no camera profile, we need to align the polyline to the z-axis to get the top-view.
    const alignedPoints = alignPolylineToZAxis(pointsInVector3)

    // To create a plane of the polyline, we'll take 2 points; first and the furthest point from the first.
    const planePoints: Vector3[] = [
      alignedPoints[0],
      new Vector3(0, 0, 0), // dummy, will be replaced later
      alignedPoints.reduce(
        (furthest, point) => {
          const distance = point.distanceTo(alignedPoints[0])
          if (distance > furthest.distance) {
            return { distance, point }
          }
          return furthest
        },
        { distance: 0, point: alignedPoints[0] },
      ).point,
    ]

    // The 3rd point will be a point on the plane that is perpendicular to the line between the first and the furthest point
    // at 0.5 distance from the first point.
    planePoints[1] = alignedPoints[0].clone().add(alignedPoints[1]).sub(alignedPoints[0]).multiplyScalar(0.5)
    planePoints.push(planePoints[2])

    // Make transformation and 2d vertices of the plane points
    const { transformation, vertices } = getPolygonFromPoints(planePoints)

    // get the minimum rectangle boundary of the polygon
    const planePointsArray = planePoints.map((point) => [point.x, point.y, point.z] as PointArray)
    const minimumRectangleBoundary = minAreaRectangleOfPolygon(vertices, planePointsArray)
    if (!minimumRectangleBoundary) return null

    const polygon = {
      transformation: Object.values(transformation) as Matrix4Tuple,
      vertices: vertices as [number, number][],
      shape_id: imageId,
      positions: planePointsArray,
      min_rect_boundaries: minimumRectangleBoundary,
      center: new Vector3().addVectors(planePoints[0], planePoints[1]).multiplyScalar(0.5).toArray() as PointArray,
    } as Polygon

    return {
      polyline: alignedPoints.map((point) => new Vector2(meterToMillimeter(point.x), meterToMillimeter(point.y))),
      projected: {
        boundary: polygon.vertices.map((v) => new Vector2(meterToMillimeter(v[0]), meterToMillimeter(v[1]))),
      },
      polygon,
      minimumRectangleBoundary,
    }
  }, [imageId, pointsInVector3])

  /**
   * Generate a polygon reference of the camera profile, if it was defined.
   * This will be used as a reference so we can use the existing codes for polygon diagram.
   */
  const diagramCameraProjection: PolylineDiagram | null = useMemo(() => {
    if (!cameraProfile || !pointsInVector3) return null
    const { positions, state, target } = cameraProfile

    const cameraPosition = new Vector3(
      positions[CameraViewOrientation.FRONT][0],
      positions[CameraViewOrientation.FRONT][1],
      positions[CameraViewOrientation.FRONT][2],
    )
    const cameraTarget = new Vector3().fromArray(target)
    const upVector = new Vector3(
      state.arcballState.cameraUp.x,
      state.arcballState.cameraUp.y,
      state.arcballState.cameraUp.z,
    )
    const leftVector = new Vector3().crossVectors(cameraPosition.clone().sub(cameraTarget), upVector).normalize()

    // Generate a poylgon based on the camera profile
    const planePoints = [
      cameraPosition.clone().add(upVector.clone().multiplyScalar(0.5)), // top
      cameraPosition.clone().add(leftVector.clone().multiplyScalar(-0.5)), // right
      cameraPosition.clone().add(upVector.clone().multiplyScalar(-0.5)), // bottom
      cameraPosition.clone().add(leftVector.clone().multiplyScalar(0.5)), // left
    ]

    // Since the ref polygon was made based on the camera profile, we can use the camera's transformation.
    const transformation = new Matrix4().fromArray(state.arcballState.cameraMatrix.elements)
    const invertTransform = transformation.clone().invert()
    const vertices = planePoints
      .map((p) => p.clone().applyMatrix4(invertTransform))
      .map((p) => [p.x, p.y] as [number, number])

    // get the minimum rectangle boundary of the polygon
    const planePointsArray = planePoints.map((point) => [point.x, point.y, point.z] as PointArray)
    const minimumRectangleBoundary = minAreaRectangleOfPolygon(vertices, planePointsArray)
    if (!minimumRectangleBoundary) return null

    const polygon = {
      shape_id: 'camera-profile-boundary',
      vertices,
      positions: planePoints.map((v) => v.toArray() as PointArray),
      transformation: Object.values(transformation) as Matrix4Tuple,
      center: new Vector3().addVectors(planePoints[0], planePoints[2]).multiplyScalar(0.5).toArray() as PointArray,
      min_rect_boundaries: minimumRectangleBoundary,
    } as Polygon

    const projected = getProjectedVertices(cameraProfile, minimumRectangleBoundary, width, height, polygon, 1, false) // we will scale later
    if (!projected) return null

    const oriented = orientPositionsBasedOnProjectedDiagram(projected.operations, pointsInVector3, 1, invertTransform)

    return {
      polyline: oriented,
      projected,
      polygon,
      minimumRectangleBoundary,
    }
  }, [cameraProfile, pointsInVector3, width, height])

  /**
   * Diagram to be used.
   * Default to the diagram based on the camera profile.
   * Also calculates the length label of each line section.
   */
  const fullSizedDiagram: PolylineDiagram | null = useMemo(() => {
    const diag = diagramCameraProjection || diagramGuessProjection
    if (!diag) return null

    const vertices = diag.polyline
    return {
      ...diag,
      labels: vertices.reduce<PolylineDiagram['labels']>((acc, point, index) => {
        if (!vertices[index + 1]) return acc

        const distance = point.distanceTo(vertices[index + 1])
        const center = new Vector2().addVectors(point, vertices[index + 1]).multiplyScalar(0.5)

        return [
          ...(acc || []),
          {
            text: distance.toFixed(0),
            point: center,
          },
        ]
      }, []),
    }
  }, [diagramGuessProjection, diagramCameraProjection])

  /**
   * Align diagram to canvas, includes scaling appropriately.
   */
  const diagram: PolylineDiagram | null = useMemo(() => {
    if (!fullSizedDiagram || !pointsInVector3) return null

    // Calculate the boundary based on the diagram's vertices.
    const diagramBBox = fullSizedDiagram.polyline.reduce(
      (acc, point) => {
        if (point.x < acc[0].x) acc[0].x = point.x
        if (point.x > acc[1].x) acc[1].x = point.x
        if (point.y < acc[0].y) acc[0].y = point.y
        if (point.y > acc[1].y) acc[1].y = point.y
        return acc
      },
      [
        { x: Infinity, y: Infinity },
        { x: -Infinity, y: -Infinity },
      ],
    )

    // Get the width and height of the boundary.
    const boundaryWidth = diagramBBox[1].x - diagramBBox[0].x
    const boundaryHeight = diagramBBox[1].y - diagramBBox[0].y
    let newBoundary = [
      new Vector2(diagramBBox[0].x, diagramBBox[0].y),
      new Vector2(diagramBBox[0].x + boundaryWidth, diagramBBox[0].y),
      new Vector2(diagramBBox[0].x + boundaryWidth, diagramBBox[0].y + boundaryHeight),
      new Vector2(diagramBBox[0].x, diagramBBox[0].y + boundaryHeight),
    ]

    // Move the boundary to the top left corner.
    const operations: DiagramOperation[] = []
    operations.push({
      translate: {
        fixed: new Vector2(-diagramBBox[0].x, -diagramBBox[0].y),
      },
    })

    // Resize to fit the canvas.
    const { scale } = bestFitRectangle(width - 120, height - 100, boundaryWidth, boundaryHeight)
    operations.push({
      scale,
    })

    // add some padding, also acts for centering it as well.
    operations.push({
      translate: {
        fixed: new Vector2((width - boundaryWidth * scale) / 2, 50),
      },
    })

    // Apply the operations to the diagram and new boundary.
    let updatedVertices = [...fullSizedDiagram.polyline]
    let updatedLabels = [...(fullSizedDiagram.labels || [])]
    operations.forEach((operation) => {
      if (operation.translate?.fixed) {
        updatedVertices = updatedVertices.map((vertex) => vertex.clone().add(operation.translate!.fixed as Vector2))
        newBoundary = newBoundary.map((vertex) => vertex.clone().add(operation.translate!.fixed as Vector2))
      }

      if (operation.scale) {
        updatedVertices = updatedVertices.map((vertex) => vertex.clone().multiplyScalar(operation.scale as number))
        newBoundary = newBoundary.map((vertex) => vertex.clone().multiplyScalar(operation.scale as number))
        updatedLabels = updatedLabels.map((label) => ({
          ...label,
          point: label.point.clone().multiplyScalar(operation.scale as number),
        }))
      }
    })

    return {
      ...fullSizedDiagram,
      polyline: updatedVertices,
      labels: updatedVertices.reduce<PolylineDiagram['labels']>((acc, point, index) => {
        if (!updatedVertices[index + 1]) return acc

        const distance = meterToMillimeter(pointsInVector3[index].distanceTo(pointsInVector3[index + 1]))
        const center = new Vector2().addVectors(point, updatedVertices[index + 1]).multiplyScalar(0.5)

        return [
          ...(acc || []),
          {
            text: distance.toFixed(0),
            point: center,
          },
        ]
      }, []),
      projected: {
        ...fullSizedDiagram.projected,
        boundary: newBoundary,
      },
      scale,
    }
  }, [fullSizedDiagram, pointsInVector3, width, height])

  // Mainly used for debugs. Should alwasy be 0.
  const debugXOffset = 0
  const debugYOffset = 0

  // Fixed canvas width but height is diagram + padding
  const canvasWidth = width
  const canvasHeight = diagram?.projected?.boundary ? Math.round(diagram.projected.boundary[2].y + 50) : height

  /**
   * Export the diagram into an image to be used in file exports.
   */
  useEffect(() => {
    if (stageRef.current === null) return

    dispatch(
      setDiagram({
        imageId,
        diagram: {
          image: stageRef.current?.toDataURL() || '',
          width: canvasWidth,
          height: canvasHeight,
        },
      }),
    )
  }, [imageId, canvasWidth, canvasHeight, dispatch])

  if (!diagram) return null

  return (
    <Stage width={canvasWidth} height={canvasHeight} ref={stageRef}>
      {/* Debug Layer */}
      <Layer y={debugYOffset} x={debugXOffset}>
        {/* diagram boundary */}
        {/* <Group>
          <Circle x={diagram.projected.boundary[0].x} y={diagram.projected.boundary[0].y} radius={10} fill="orange" />
          <Arrow
            points={[
              diagram.projected.boundary[0].x,
              diagram.projected.boundary[0].y,
              diagram.projected.boundary[1].x,
              diagram.projected.boundary[1].y,
            ]}
            stroke="red"
            fill="red"
            strokeWidth={1}
          />
          <Arrow
            points={[
              diagram.projected.boundary[1].x,
              diagram.projected.boundary[1].y,
              diagram.projected.boundary[2].x,
              diagram.projected.boundary[2].y,
            ]}
            stroke="red"
            fill="red"
            strokeWidth={1}
          />
          <Arrow
            points={[
              diagram.projected.boundary[2].x,
              diagram.projected.boundary[2].y,
              diagram.projected.boundary[3].x,
              diagram.projected.boundary[3].y,
            ]}
            stroke="red"
            fill="red"
            strokeWidth={1}
          />
          <Arrow
            points={[
              diagram.projected.boundary[3].x,
              diagram.projected.boundary[3].y,
              diagram.projected.boundary[0].x,
              diagram.projected.boundary[0].y,
            ]}
            stroke="red"
            fill="red"
            strokeWidth={1}
          />
        </Group> */}

        {/* diagram ref polygon */}
        {/* <Group>
          <Circle
            x={meterToMillimeter(diagram.polygon.vertices[0][0])}
            y={meterToMillimeter(diagram.polygon.vertices[0][1])}
            radius={10}
            fill="orange"
          />

          {diagram.projected?.result2d?.up && diagram.projected.result2d.right && (
            <Group>
              <Arrow
                points={[
                  diagram.projected.result2d.up.edge[0].x,
                  diagram.projected.result2d.up.edge[0].y,
                  diagram.projected.result2d.up.edge[1].x,
                  diagram.projected.result2d.up.edge[1].y,
                ]}
                fill="lightgreen"
                stroke="lightgreen"
              />
              {diagram.projected.result2d.up.intersectionPoint && (
                <Arrow
                  points={[
                    diagram.projected.result2d.up.centroid.x,
                    diagram.projected.result2d.up.centroid.y,
                    diagram.projected.result2d.up.intersectionPoint.x,
                    diagram.projected.result2d.up.intersectionPoint.y,
                  ]}
                  fill="pink"
                  stroke="pink"
                />
              )}
              {diagram.projected.result2d.right.intersectionPoint && (
                <Arrow
                  points={[
                    diagram.projected.result2d.right.centroid.x,
                    diagram.projected.result2d.right.centroid.y,
                    diagram.projected.result2d.right.intersectionPoint.x,
                    diagram.projected.result2d.right.intersectionPoint.y,
                  ]}
                  fill="orange"
                  stroke="orange"
                />
              )}
            </Group>
          )}

          {diagram.polygon.vertices.map((vertex, index) => (
            <Group>
              <Arrow
                stroke="#000"
                strokeWidth={1}
                points={[
                  meterToMillimeter(vertex[0]),
                  meterToMillimeter(vertex[1]),
                  meterToMillimeter(diagram.polygon.vertices[(index + 1) % diagram.polygon.vertices.length][0]),
                  meterToMillimeter(diagram.polygon.vertices[(index + 1) % diagram.polygon.vertices.length][1]),
                ]}
              />
            </Group>
          ))}
        </Group> */}
      </Layer>

      <Layer y={debugYOffset} x={debugXOffset}>
        {diagram.polyline &&
          diagram.polyline.map(
            (point, index) =>
              diagram.polyline[index + 1] && (
                <Line
                  key={uniqueId()}
                  points={[point.x, point.y, diagram.polyline[index + 1].x, diagram.polyline[index + 1].y]}
                  stroke="green"
                  strokeWidth={1}
                />
              ),
          )}

        {diagram.labels &&
          diagram.labels.map((label) => (
            <Text
              text={label.text}
              x={label.point.x - 25}
              y={label.point.y - 13}
              width={50}
              height={26}
              align="center"
              verticalAlign="middle"
              stroke="white"
              strokeWidth={5}
              fontSize={15}
              fontStyle="bold"
              fillAfterStrokeEnabled
            />
          ))}
      </Layer>
    </Stage>
  )
}

export default PolylineDiagram
