/**
 * Wrapper for CommentPopup to position it in 3D space.
 * It also wraps CommentPopup in redux Provider since it does not get forwarded automatically
 */
import { FC } from 'react'

import { ChakraProvider } from '@chakra-ui/react'
import { Html, useContextBridge } from '@react-three/drei'
import { useThree } from '@react-three/fiber'
import CommentPopup from 'pages/projects/common/Comments/CommentPopup'
import { CommentPopupProps } from 'pages/projects/common/Comments/hooks/commentPopup'
import { Provider } from 'react-redux'
import { store } from 'store/app'
import { Vector3 } from 'three'

import { GlobalModalContext } from 'contexts/GlobalModal'
import { RouterContext } from 'contexts/Router'
import { UserContext } from 'contexts/Users'

import theme from 'config/theme'

export const CommentPopup3d: FC<CommentPopupProps> = (props) => {
  const { comment } = props
  const { gl } = useThree()

  // Forward contexts to CommentPopup. This has to be done again due to
  // another Html wrapping causing lose of context
  const ContextBridge = useContextBridge(UserContext, GlobalModalContext, RouterContext)

  return (
    <Html
      position={
        new Vector3(
          comment.cartesian_position?.x || 0,
          comment.cartesian_position?.y || 0,
          comment.cartesian_position?.z || 0,
        )
      }
      portal={{ current: document.getElementById('canvasBox') || gl.domElement }}
      zIndexRange={[1000, 1000]}
    >
      <ContextBridge>
        <Provider store={store}>
          <ChakraProvider theme={theme}>
            <CommentPopup {...props} />
          </ChakraProvider>
        </Provider>
      </ContextBridge>
    </Html>
  )
}
