import { useContext, useState } from 'react'

import mixpanel from 'mixpanel-browser'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { RootState, useAppDispatch } from 'store/app'

import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import { Blueprint } from 'interfaces/blueprint'

import { uploadFile } from 'services/AWS'
import { deleteBlueprint, getSignedUrlForUploadBlueprint, upsertBlueprintMetaData } from 'services/Blueprint'

import { fetchBlueprintsByProject, setSelectedBlueprint } from '../store/blueprint'

export const useDocumentSwitcher = () => {
  // Get IDs from the URL
  const { project_id } = useParams<{ project_id: string }>()

  // Store
  const dispatch = useAppDispatch()
  const blueprints = useSelector((state: RootState) => state.blueprint.blueprints)
  const isAllowedModify = useSelector((state: RootState) => state.blueprint.isAllowedModify)

  // Contexts
  const { getAccessToken } = useContext(UserContext)
  const { showModal, showErrorModal } = useContext(GlobalModalContext)

  // Local state
  const [isLoading, setIsLoading] = useState(false)

  /**
   * Wrap async function with proper handling of loading state
   *
   * @param fn Function to run
   */
  const withLoading = async (fn: () => Promise<boolean>): Promise<boolean> => {
    setIsLoading(true)
    return fn().finally(() => setIsLoading(false))
  }

  /**
   * Handle file change
   *
   * @param e HTML event
   */
  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    if (!e || !e.target || !e.target.files || !e.target.files.length) return
    const file: File = e.target.files[0]

    await onBlueprintAdd(file)

    // clear the files list
    e.target.value = ''
  }

  /**
   * Add a blueprint file (PDF)
   *
   * @param file File to be added
   */
  const onBlueprintAdd = async (file: File) =>
    withLoading(async (): Promise<boolean> => {
      if (!project_id || !isAllowedModify) {
        return false
      }

      const token = await getAccessToken()
      if (!token) {
        return false
      }

      const signedFile = await getSignedUrlForUploadBlueprint(token, project_id, file.name, showErrorModal)

      if (
        !signedFile?.url ||
        !(await uploadFile(signedFile.url, signedFile.content_type || file.type, file, null, showErrorModal))
      ) {
        return false
      }

      const newBlueprint = await upsertBlueprintMetaData(token, project_id, signedFile, showErrorModal)
      if (!newBlueprint) {
        return false
      }

      // track event to mixpanel
      mixpanel.track('Import blueprint', {
        'Blueprint ID': newBlueprint.blueprint_id,
        'Number of blueprints (after importing a new blueprint)': blueprints.length + 1,
      })

      // Reload and set the new blueprint as active
      dispatch(setSelectedBlueprint(newBlueprint))
      await reloadBlueprints(false)

      return true
    })

  /**
   *
   * @param blueprintId Blueprint ID to be deleted
   */
  const onBlueprintDelete = (blueprintId: string) => {
    if (!isAllowedModify) {
      return
    }
    showModal({
      body: 'この図面を削除しますか？関連するコメントの図面上の位置情報も削除されます。',
      confirmText: 'はい',
      onConfirm: () => {
        void doDeleteBlueprint(blueprintId)
        return true
      },
    })
  }

  /**
   * Make the API call to delete a blueprint
   *
   * @param blueprintId Blueprint ID to be deleted
   * @returns
   */
  const doDeleteBlueprint = (blueprintId: string) =>
    withLoading(async (): Promise<boolean> => {
      if (!project_id) {
        return false
      }

      const token = await getAccessToken()
      if (!token) {
        return false
      }

      if (!(await deleteBlueprint(token, project_id, blueprintId, showErrorModal))) {
        return false
      }

      // track event to mixpanel
      mixpanel.track('Delete blueprint', {
        'Blueprint ID': blueprintId,
        'Number of blueprints (after deleting one)': blueprints.length - 1,
      })

      dispatch(setSelectedBlueprint(null))
      await reloadBlueprints()

      return true
    })

  /**
   * Called when a file is added or deleted
   * @param {boolean} autoSelect Whether to auto select the first blueprint
   */
  const reloadBlueprints = async (autoSelect = true) => {
    if (!project_id) {
      return
    }

    const access_token = await getAccessToken()
    if (!access_token) {
      return
    }

    const result = await dispatch(fetchBlueprintsByProject({ access_token, project_id, showErrorModal }))

    if (autoSelect) dispatch(setSelectedBlueprint((result.payload as Blueprint[])[0]))
  }

  return {
    isLoading,
    onFileChange,
    onBlueprintAdd,
    onBlueprintDelete,
  }
}
