import { createContext, useContext } from 'react'

import { Router as RouterInterface } from 'interfaces/system'

const initialState: RouterInterface = {
  router: null,
}

export const RouterContext = createContext(initialState)
export const useRouterContext = () => useContext(RouterContext)
