/**
 * Comment is the area selection box that is drawn on the blueprint image. Clicking the box will
 * open the comment popup (CommentPopup.tsx).
 */
import { FC } from 'react'

import { Box, Center, IconButton, Tooltip } from '@chakra-ui/react'

import {
  AlertFillIcon,
  CircleFillIcon,
  CommentDisplayIcon,
  CommentMoveIcon,
  InputEditorCancelIcon,
  InputEditorConfirmIcon,
} from 'assets/icons'

import { BLUEPRINT_COMMENT_AREA_MIN_SIZE } from 'config/constants'

import { Comment } from 'interfaces/interfaces'

import { MODE, RESIZE_CORNER, useCommentFrame } from '../hooks/commentFrame'
import { CommentPopup2d } from './CommentPopup2d'
import TooltipBody from './TooltipBody'

const ICON_BUTTON_PROPS = {
  size: 'xs',
  variant: 'outline',
  fontSize: 'sm',
  borderWidth: '1px',
  borderRadius: '50%',
  borderColor: 'gray.500',
  padding: '4px',
  backgroundColor: 'white',
  boxShadow: '0 0 3px white',
}

const CommentFrame: FC<{
  /**
   * The comment object of this frame.
   * This can be a temporary comment object when it uses TEMP_COMMENT_ID constant as ID.
   */
  comment: Comment
}> = ({ comment }) => {
  const {
    frameSize,
    unplaced,
    isHoldingPlace,
    isViewComment,
    isDisabled,
    blueprintScale,
    frameId,
    mode,
    isEditing,
    isTooltipOpen,
    setIsTooltipOpen,
    onCancelMove,
    onConfirmMove,
    onClick,
    onCloseCommentPopup,
    onStartModify,
    handleBoxStartDrag,
    handleBoxStartResize,
    containerRef,
    frameRef,
    position,
    scale,
    inspectionAreaName,
    selectedComment,
  } = useCommentFrame(comment)

  if (!position) return null

  return (
    <>
      {/* COMMENT FRAME */}
      {/* Tooltip is manually controlled as it has issues re-appearing after closing comment popup 
      and can only be closed by re-hovering into the comment frame */}
      <Tooltip
        hasArrow
        placement="top"
        label={
          inspectionAreaName || unplaced ? (
            <TooltipBody
              title={inspectionAreaName}
              body={unplaced && !isEditing ? '\n設計図面が指定されていません。' : ''}
            />
          ) : (
            ''
          )
        }
        p={2}
        fontSize="xs"
        fontWeight="normal"
        isOpen={isTooltipOpen}
      >
        <Box
          ref={containerRef}
          position="absolute"
          top={`${((position.y || 0) * blueprintScale).toFixed(3)}px`}
          left={`${((position.x || 0) * blueprintScale).toFixed(3)}px`}
          padding={unplaced && !isEditing ? 1 : 0}
          zIndex={isEditing || isViewComment ? 3 : 2}
          pointerEvents={isHoldingPlace ? 'none' : 'auto'}
          visibility={isHoldingPlace ? 'hidden' : 'visible'}
          id={frameId || 'temp-comment'}
          onMouseEnter={() => !selectedComment && setIsTooltipOpen(true)}
          onMouseLeave={() => setIsTooltipOpen(false)}
        >
          <Center
            ref={frameRef}
            w={`${frameSize.width * blueprintScale}px`}
            h={`${frameSize.height * blueprintScale}px`}
            borderWidth={Math.min(3, Math.ceil(3 * scale))}
            borderColor={isEditing ? 'yellow.500' : 'primary.500'}
            backgroundColor="blackAlpha.100"
            cursor={isDisabled ? 'inherit' : 'pointer'}
            onClick={onClick}
            onMouseDown={(event) => {
              event.stopPropagation()
              handleBoxStartDrag({ x: event.clientX, y: event.clientY })
            }}
            onTouchStart={(event) => {
              event.stopPropagation()
              handleBoxStartDrag({ x: event.touches[0].clientX, y: event.touches[0].clientY })
            }}
            opacity={isDisabled ? '0.6' : '1'}
          >
            {!isEditing && (
              <Center
                transform={`scale(${scale / 100})`}
                minW={BLUEPRINT_COMMENT_AREA_MIN_SIZE - 2}
                minH={BLUEPRINT_COMMENT_AREA_MIN_SIZE - 2}
              >
                <CommentDisplayIcon data-testid="commentDisplayIcon" />
                {unplaced && (
                  <Center position="absolute" color="white" marginBottom={1} zIndex={1} top="6px" left="8px">
                    <AlertFillIcon data-testid="unplacedCommentDisplayIcon" />
                  </Center>
                )}
              </Center>
            )}
            {mode === MODE.MODIFY && (
              <>
                <CommentMoveIcon />
                <IconButton
                  {...ICON_BUTTON_PROPS}
                  borderColor="red.500"
                  color="red.500"
                  aria-label="Cancel Move"
                  icon={<InputEditorCancelIcon />}
                  isDisabled={isDisabled}
                  onClick={onCancelMove}
                  position="absolute"
                  bottom={-10}
                  left={0}
                />
                <IconButton
                  {...ICON_BUTTON_PROPS}
                  borderColor="primary.500"
                  color="primary.500"
                  aria-label="Confirm Move"
                  icon={<InputEditorConfirmIcon />}
                  isDisabled={isDisabled}
                  onClick={onConfirmMove}
                  position="absolute"
                  bottom={-10}
                  left={8}
                />
                <IconButton
                  {...ICON_BUTTON_PROPS}
                  borderColor="yellow.500"
                  fontSize="xs"
                  aria-label="Move Top Left"
                  icon={<CircleFillIcon />}
                  isDisabled={isDisabled}
                  onMouseDown={(e) => handleBoxStartResize({ x: e.clientX, y: e.clientY }, RESIZE_CORNER.TOP_LEFT)}
                  onTouchStart={(e) =>
                    handleBoxStartResize({ x: e.touches[0].clientX, y: e.touches[0].clientY }, RESIZE_CORNER.TOP_LEFT)
                  }
                  onClick={(e) => e.stopPropagation()}
                  position="absolute"
                  top={-3}
                  left={-3}
                />
                <IconButton
                  {...ICON_BUTTON_PROPS}
                  borderColor="yellow.500"
                  fontSize="xs"
                  aria-label="Move Bottom Right"
                  icon={<CircleFillIcon />}
                  isDisabled={isDisabled}
                  onMouseDown={(e) => handleBoxStartResize({ x: e.clientX, y: e.clientY }, RESIZE_CORNER.BOTTOM_RIGHT)}
                  onTouchStart={(e) =>
                    handleBoxStartResize(
                      { x: e.touches[0].clientX, y: e.touches[0].clientY },
                      RESIZE_CORNER.BOTTOM_RIGHT,
                    )
                  }
                  onClick={(e) => e.stopPropagation()}
                  position="absolute"
                  bottom={-3}
                  right={-3}
                />
              </>
            )}
          </Center>

          {/* COMMENT POPUP */}
          {isViewComment && !isEditing && (
            <CommentPopup2d comment={comment} onClose={onCloseCommentPopup} onMove={onStartModify} />
          )}
        </Box>
      </Tooltip>
    </>
  )
}

export default CommentFrame
