import { Matrix4, Vector2, Vector3 } from 'three'

import {
  AlignedMinimumAreaBoundary,
  AlignedMinimumAreaBoundaryMirrored,
  AxisAlignment,
  IntersectionResult3D,
  MinimumAreaBoundary,
} from 'interfaces/diagram'
import { Polygon } from 'interfaces/shape'

import { findIntersection, findPointAtDuration, getOrientation, isFlipped } from './PlaneDiagram'
import { findCenter } from './Points'
import { meterToMillimeter } from './Util'

/**
 * Align a polygon to the x- and y-axis.
 *
 * @param vertices Vertices of the polygon
 * @param chosenEdgeIndex Index of the edge to align to the axis
 */
export const alignToAxis = (vertices: Vector2[], chosenEdgeIndex: number): AxisAlignment => {
  const chosenEdgeStart = vertices[chosenEdgeIndex]
  const chosenEdgeEnd = vertices[(chosenEdgeIndex + 1) % vertices.length] // Next point in cyclic manner
  if (!chosenEdgeStart || !chosenEdgeEnd) {
    return {
      x: { angle: 0 },
      y: { angle: 0 },
      verticeIndex: chosenEdgeIndex,
      translation: [0, 0],
      vertices,
    }
  }

  // Calculate angle of rotation
  const deltaY = chosenEdgeEnd.y - chosenEdgeStart.y
  const deltaX = chosenEdgeEnd.x - chosenEdgeStart.x
  const rotationAngleToX = Math.atan2(deltaY, deltaX)
  const rotationAngleToY = Math.atan2(deltaX, deltaY)

  // Move the polygon to the positive quadrant
  const anchorVertice = vertices[chosenEdgeIndex]
  const nextVertice = vertices[(chosenEdgeIndex + 1) % vertices.length]
  const prevVertice = vertices[chosenEdgeIndex === 0 ? vertices.length - 1 : chosenEdgeIndex - 1]
  const xOffset = -Math.min(anchorVertice.x, nextVertice.x, prevVertice.x)
  const yOffset = -Math.min(anchorVertice.y, nextVertice.y, prevVertice.y)

  return {
    x: { angle: rotationAngleToX },
    y: { angle: rotationAngleToY },
    verticeIndex: chosenEdgeIndex,
    translation: [xOffset, yOffset],
    vertices: vertices.map((v) => new Vector2(v.x + xOffset, v.y + yOffset)),
  }
}

/**
 * Calculate alignment of the boundary to the x-axis.
 */
export const alignBoundary = (
  vertices: Vector2[],
  mirrored: AlignedMinimumAreaBoundaryMirrored | null,
  edgeIndex: number,
  centroid: Vector2,
): AxisAlignment => {
  const allX = vertices.map((v) => v.x)
  const allY = vertices.map((v) => v.y)
  let xOffset = 0
  let yOffset = 0

  // 1. The max length of x should be more than y
  // 2. there should be more than 1 vertice on x - axis
  const isAlreadyAligned =
    Math.abs(Math.max(...allX) - Math.min(...allX)) > Math.abs(Math.max(...allY) - Math.min(...allY)) &&
    vertices.filter((v) => v.y === 0).length > 1

  // 3. All vertices should be on the positive quadrant
  let allOnPositiveQuadrant = vertices.every((v) => v.x >= 0 && v.y >= 0)

  if ((isAlreadyAligned && allOnPositiveQuadrant) || edgeIndex < 0)
    return {
      x: { angle: 0 },
      y: { angle: 0 },
      translation: [xOffset, yOffset],
      verticeIndex: edgeIndex,
      vertices,
    }

  let updatedVerts = [...vertices]

  // invert it if necessary
  if (mirrored && (mirrored.horizontally || mirrored.vertically)) {
    updatedVerts = updatedVerts.map((point) => new Vector2(2 * centroid.x - point.x, point.y))
  }

  // Rotate the boundary to align it to the x-axis
  let angle = 0
  if (!isAlreadyAligned) {
    const alignedVerts = alignToAxis(
      updatedVerts.map((v) => new Vector2(v.x, v.y)),
      edgeIndex,
    )
    angle = -alignedVerts.x.angle

    updatedVerts = updatedVerts.map((v) => v.rotateAround(centroid, angle))

    // check again where the boundary are
    allOnPositiveQuadrant = updatedVerts.every((v) => v.x >= 0 && v.y >= 0)
  }

  // // Move the boundary to the positive quadrant
  if (!allOnPositiveQuadrant) {
    const anchorVertice = updatedVerts[edgeIndex]
    const nextVertice = updatedVerts[(edgeIndex + 1) % updatedVerts.length]
    const prevVertice = updatedVerts[edgeIndex === 0 ? updatedVerts.length - 1 : edgeIndex - 1]
    xOffset = -Math.min(anchorVertice.x, nextVertice.x, prevVertice.x)
    yOffset = -Math.min(anchorVertice.y, nextVertice.y, prevVertice.y)
  }

  return {
    x: { angle },
    y: { angle: 0 }, // only aligned to x-axis
    verticeIndex: edgeIndex,
    translation: [xOffset, yOffset],
    vertices: updatedVerts.map((v) => new Vector2(v.x + xOffset, v.y + yOffset)),
  }
}

/**
 * Calculate minimum area of boundary for a polygon.
 * This will generate a boundary based the most optimum edge to create a minuium area of boundary.
 *
 * @param vertices Vertices of polygon. It should be the original local `vertices` from tekkin-detector.
 * @param worldVertices Vertices of polygon in world positions.
 * @returns Rectangle with minimum area of boundary, or null if it's not possible to calculate
 */
export function minAreaRectangleOfPolygon(vertices: number[][], worldVertices: number[][]): MinimumAreaBoundary | null {
  let minArea = 0
  let result: MinimumAreaBoundary | null = null
  vertices.forEach((vert, index) => {
    // Skip the last vertice since it's about line between vertices, not vertices itself
    if (index === vertices.length - 1) return

    const rectangle = calculateMinAreaRectangleOfPolygon(vertices, worldVertices, index)
    if (rectangle && (minArea === 0 || rectangle.area < minArea)) {
      minArea = rectangle.area
      result = rectangle
    }
  })

  return result
}

function findAnchorVerticesIndex(verts: [number, number][]): number {
  let zeroVertice = verts.findIndex((v) => v[0] === 0 && v[1] === 0)

  // if (zeroVertice < 0) zeroVertice = verts.findIndex((v) => v[0] === 0 || v[1] === 0)
  // If there are no exact zero vertices, find the closest one
  if (zeroVertice < 0) {
    const distances = verts.map((v) => new Vector2(0, 0).distanceTo(new Vector2(v[0], v[1])))
    zeroVertice = distances.indexOf(Math.min(...distances))
  }

  if (zeroVertice < 0) return -1

  return zeroVertice
}

export const getWindingOrder = (points: number[][]): 'clockwise' | 'counterclockwise' | 'collinear' => {
  let sum = 0
  const numPoints = points.length

  for (let i = 0; i < numPoints; i += 1) {
    const currPoint = points[i]
    const nextPoint = points[(i + 1) % numPoints]

    sum += (nextPoint[0] - currPoint[0]) * (nextPoint[1] + currPoint[1])
  }

  if (sum < 0) {
    return 'counterclockwise'
  }

  if (sum > 0) {
    return 'clockwise'
  }

  return 'collinear'
}

/**
 * Determine the long edge of a polygon tp align to the x-axis.
 * If there is no vertice at (0,0), then we'll pick the first long edge.
 *
 * @param vertices Vertices of polygon
 * @param width Width of the plane
 */
function getLongEdgeIndex(vertices: [number, number][]): number {
  if (!vertices.length) return -1

  const zeroVertice = findAnchorVerticesIndex(vertices)

  // If there is no vertice at (0,0), then we'll pick the first long edge
  if (zeroVertice < 0) {
    const nextLength = new Vector2().fromArray(vertices[0]).distanceTo(new Vector2().fromArray(vertices[1]))
    const prevLength = new Vector2()
      .fromArray(vertices[vertices.length - 1])
      .distanceTo(new Vector2().fromArray(vertices[0]))

    return nextLength > prevLength ? 0 : vertices.length - 1
  }

  const nextVertice = vertices[(zeroVertice + 1) % vertices.length]
  const prevVertice = vertices[zeroVertice === 0 ? vertices.length - 1 : zeroVertice - 1]

  const nextLength = new Vector2().fromArray(vertices[zeroVertice]).distanceTo(new Vector2().fromArray(nextVertice))
  const prevLength = new Vector2().fromArray(vertices[zeroVertice]).distanceTo(new Vector2().fromArray(prevVertice))

  if (nextLength > prevLength) return zeroVertice

  return zeroVertice === 0 ? vertices.length - 1 : zeroVertice - 1
}

/**
 * Checks if two polygons are mirrored on an axis. There will always be some differences
 * due to floating point precision, so a threshold is used to determine similarity.
 *
 * @param vertices2d Polygon 1 vertices
 * @param vertices3d Polygon 2 vertices
 * @param threshold Similarity threshold for comparison
 */
export const checkMirroredOnAxis = (
  upperPolygon: Polygon,
  lowerPolygon: Polygon,
): AlignedMinimumAreaBoundaryMirrored | null => {
  const vertices2d = upperPolygon.vertices.map((v) => new Vector2(v[0], v[1]))
  const vertices3d = upperPolygon.positions.map((v) => new Vector3(v[0], v[1], v[2]))

  let upVector: Vector3 = new Vector3()
  let rightVector: Vector3 = new Vector3()
  let resultUp3d: IntersectionResult3D | null = null
  let resultRight3d: IntersectionResult3D | null = null
  const polygonCenter = findCenter(vertices3d)

  if (!lowerPolygon.center) {
    const lowPolyCenter = findCenter(lowerPolygon.positions.map((v) => new Vector3(...v)))
    lowerPolygon.center = lowPolyCenter ? lowPolyCenter.toArray() : undefined
  }

  if (!upperPolygon.center) {
    const upPolyCenter = findCenter(upperPolygon.positions.map((v) => new Vector3(...v)))
    upperPolygon.center = upPolyCenter ? upPolyCenter.toArray() : undefined
  }

  if (!polygonCenter || !lowerPolygon.center || !upperPolygon.center) {
    return null
  }

  // find the upward intersection point
  const intersectionUp = findIntersection(vertices2d, 'up')
  if (intersectionUp?.edgeIndices && intersectionUp.duration) {
    // prep data from polygon
    const intersectedUpPoint = findPointAtDuration(
      vertices3d[intersectionUp.edgeIndices[0]],
      vertices3d[intersectionUp.edgeIndices[1]],
      intersectionUp.duration,
    )
    upVector = new Vector3().subVectors(intersectedUpPoint, new Vector3(...polygonCenter))
    resultUp3d = {
      intersectionPoint: intersectedUpPoint,
      edge: [vertices3d[intersectionUp.edgeIndices[0]], vertices3d[intersectionUp.edgeIndices[1]]],
      edgeIndices: intersectionUp.edgeIndices,
      duration: intersectionUp.duration,
      centroid: new Vector3(...polygonCenter),
      vectorDiff: 0,
      isOpposite: false,
    }
  }

  // find the horizontal intersection point
  const intersectionHorizontal = findIntersection(vertices2d, 'right')
  if (intersectionHorizontal?.edgeIndices && intersectionHorizontal.duration) {
    // prep data from polygon
    const intersectedPoint = findPointAtDuration(
      vertices3d[intersectionHorizontal.edgeIndices[0]],
      vertices3d[intersectionHorizontal.edgeIndices[1]],
      intersectionHorizontal.duration,
    )

    // Unlike 2d, what we got are horizontal vectors, unknown if it's left or right
    const horizontalVector = new Vector3().subVectors(intersectedPoint, new Vector3(...polygonCenter))

    // generate forward vector from upper plane to lower plane
    const forwardVector = new Vector3().subVectors(
      new Vector3(...lowerPolygon.center),
      new Vector3(...upperPolygon.center),
    )

    // cross it to get left vector
    const leftVector = new Vector3().crossVectors(forwardVector, upVector).normalize()

    // decide on the right vector
    rightVector = leftVector.dot(horizontalVector) > 0 ? horizontalVector : horizontalVector.negate()

    resultRight3d = {
      intersectionPoint: intersectedPoint,
      edge: [vertices3d[intersectionHorizontal.edgeIndices[0]], vertices3d[intersectionHorizontal.edgeIndices[1]]],
      edgeIndices: intersectionHorizontal.edgeIndices,
      duration: intersectionHorizontal.duration,
      centroid: new Vector3(...polygonCenter),
      vectorDiff: 0,
      isOpposite: false,
    }
  }

  const result = isFlipped(vertices2d, vertices3d, upVector, rightVector)

  return {
    ...result,
    intersectionUp2d: intersectionUp,
    intersectionRight2d: intersectionHorizontal,
    intersectionUp3d: resultUp3d,
    intersectionRight3d: resultRight3d,
  }
}

/**
 * Helper function to get the edges of a polygon
 *
 * @param points Vertices of the polygon
 */
function getEdges(points: Vector3[]) {
  const edges = []
  for (let i = 0; i < points.length; i += 1) {
    const nextIndex = (i + 1) % points.length
    edges.push(new Vector3().subVectors(points[nextIndex], points[i]))
  }
  return edges
}

/**
 * Calculate minimum area of boundary for a polygon.
 * This will generate a boundary based on a specific edge between 2 vertices.
 * To find the most optimum area of boundary, call this with all possible edges between vertices.
 *
 * @param vertices Vertices of polygon. It should be the original local `vertices` from tekkin-detector.
 * @param verticeIndex Index of vertex for the start point of the edge. The end will always be the next vertex.
 * @param worldVertices Vertices of polygon in world positions.
 * @returns Rectangle with minimum area of boundary, or null if it's not possible to calculate
 */
function calculateMinAreaRectangleOfPolygon(
  vertices: number[][],
  worldVertices: number[][],
  verticeIndex: number,
): MinimumAreaBoundary | null {
  if (vertices.length < 2 || !vertices[verticeIndex + 1]) return null

  const points = vertices.map((p) => new Vector3(p[0], p[1], p[2] || 0))

  // Get edges of the polygon
  const edges = getEdges(points)

  // Initialize variables for the minimum area and corresponding box
  let minArea = Infinity
  let minBox = null

  // Loop through each edge as the base of the bounding box
  for (let i = 0; i < edges.length; i += 1) {
    const edge = edges[i]
    const angle = -Math.atan2(edge.y, edge.x)

    // Rotate all points to align the current edge with the x-axis
    const rotatedPoints = points.map((p) => {
      const rotatedX = p.x * Math.cos(angle) - p.y * Math.sin(angle)
      const rotatedY = p.x * Math.sin(angle) + p.y * Math.cos(angle)
      return new Vector3(rotatedX, rotatedY, p.z)
    })

    // Get the bounding box of the rotated points
    const minX = Math.min(...rotatedPoints.map((p) => p.x))
    const maxX = Math.max(...rotatedPoints.map((p) => p.x))
    const minY = Math.min(...rotatedPoints.map((p) => p.y))
    const maxY = Math.max(...rotatedPoints.map((p) => p.y))

    // Calculate the area of the bounding box
    const width = maxX - minX
    const height = maxY - minY
    const area = width * height

    // Update minimum area and box if the current one is smaller
    if (area < minArea) {
      minArea = area
      minBox = {
        area,
        angle,
        minX,
        maxX,
        minY,
        maxY,
      }
    }
  }

  if (!minBox) return null

  // return the 2 edge points, and the 2 points that are projected by max width
  // and sort coordinates in clockwise
  const maxWidth = minBox.maxX - minBox.minX
  const maxLength = minBox.maxY - minBox.minY

  // Calculate the vertices of the bounding box in the original coordinate system
  const { angle, area } = minBox
  const cos = Math.cos(-angle)
  const sin = Math.sin(-angle)

  const boundaryVertices = [
    new Vector3(minBox.minX, minBox.minY, 0),
    new Vector3(minBox.maxX, minBox.minY, 0),
    new Vector3(minBox.maxX, minBox.maxY, 0),
    new Vector3(minBox.minX, minBox.maxY, 0),
  ]

  const rect = boundaryVertices.map((v) => {
    const x = v.x * cos - v.y * sin
    const y = v.x * sin + v.y * cos
    return new Vector2(x, y)
  })
  const rectArr = rect.map((v) => [v.x, v.y] as [number, number])

  const windingOrder = getWindingOrder(rectArr)
  if (windingOrder === 'counterclockwise') {
    rectArr.reverse()
    rect.reverse()
  }

  return {
    center: new Vector2().addVectors(rect[0], rect[2]).multiplyScalar(0.5).toArray(),
    extent: [maxWidth, maxLength],
    area,
    vertices: rectArr,
    selectedVerticeIndex: verticeIndex,
  }
}

export const alignMinimumAreaBoundary = (
  boundary: MinimumAreaBoundary,
  upperPolygon: Polygon,
  lowerPolygon?: Polygon,
): AlignedMinimumAreaBoundary => {
  const { positions: worldVertices } = upperPolygon
  const { vertices: rectArr } = boundary

  // Convert world positions to a localized position based on the center of the polygon.
  const center = findCenter(worldVertices.map((point) => new Vector3(...point))) || new Vector3()
  const localizedWorldVertices = worldVertices.map((pos) =>
    new Vector3(...pos)
      .applyMatrix4(
        new Matrix4().fromArray([1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, center.x, center.y, center.z, 1]).invert(),
      )
      .toArray(),
  )

  const polygonAxisAlignment = alignToAxis(
    localizedWorldVertices.map((v) => new Vector2(v[0], v[1])),
    boundary.selectedVerticeIndex,
  )

  // Check if the polygon is mirrored on 3D vs 2D
  const mirrored = lowerPolygon ? checkMirroredOnAxis(upperPolygon, lowerPolygon) : null

  // Align the boundary to the axis and find the orientation
  const longEdgeIndex = getLongEdgeIndex(rectArr)
  const rectArrV2 = rectArr.map((v) => new Vector2(meterToMillimeter(v[0]), meterToMillimeter(v[1])))
  const centroid = new Vector2(meterToMillimeter(boundary.center[0]), meterToMillimeter(boundary.center[1]))
  const boundaryAxisAlignment = alignBoundary(rectArrV2, mirrored, longEdgeIndex, centroid)

  return {
    ...boundary,
    orientation: getOrientation(rectArr),
    mirrored,
    polygonAxisAlignment,
    boundaryAxisAlignment,
  }
}
