import { useContext, useMemo } from 'react'

import {
  modifyComment,
  setEditingComment,
  setFormImages,
  setSelectedComment,
} from 'pages/projects/common/Comments/store/comments'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'

import { EditorContext } from 'contexts/Editor'

import { EDITOR_TOOLS, TEMP_COMMENT_ID } from 'config/constants'

import { CanvasConfig } from 'interfaces/editor'

export default function useMainCanvas(): CanvasConfig {
  // Context
  const { selectedTool } = useContext(EditorContext)

  // Store
  const dispatch = useAppDispatch()
  const inspectionArea = useSelector((state: RootState) => state.page.inspectionArea)
  const user = useSelector((state: RootState) => state.user.user)
  const editingCommentId = useSelector((state: RootState) => state.comments.editingCommentId)
  const selectedComment = useSelector((state: RootState) => state.comments.selectedComment)

  // Vars
  const isSelectedTool = useMemo(() => selectedTool === EDITOR_TOOLS.COMMENT, [selectedTool])

  return {
    events: {
      onMouseUp: (ev, points, { isDragged }) => {
        if (!isSelectedTool || isDragged || !points || !inspectionArea) return

        const newPosition = { x: points[0], y: points[1], z: points[2] }
        // Update the editing comment's position
        if (editingCommentId && editingCommentId !== TEMP_COMMENT_ID) {
          dispatch(
            modifyComment({
              thread_id: editingCommentId,
              cartesian_position: newPosition,
            }),
          )
        }
        // Add new comment
        else if (!selectedComment) {
          const dummyComment = {
            thread_id: TEMP_COMMENT_ID,
            author_name: user?.nickname || '',
            thread_body: '',
            cartesian_position: newPosition,
            inspection_area_id: inspectionArea.inspection_area_id,
          }
          dispatch(setEditingComment(dummyComment))
          dispatch(setSelectedComment(dummyComment))
          dispatch(setFormImages([]))
        }
      },
    },
  }
}
