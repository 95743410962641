import { useEffect } from 'react'

const defaultTitle = document.title

export const useDocumentTitle = (title: string) => {
  useEffect(() => {
    document.title = title

    return () => {
      document.title = defaultTitle
    }
  }, [title])
}
