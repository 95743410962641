import { useEffect, useState } from 'react'

import mixpanel from 'mixpanel-browser'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'

import { BLUEPRINT_MAX_SCALE, BLUEPRINT_MIN_SCALE } from 'config/constants'

import { setCurrentPage, setScale } from '../store/blueprint'

export const useActionsPanel = (totalPages: number) => {
  // Store
  const dispatch = useAppDispatch()
  const scale = useSelector((state: RootState) => state.blueprint.scale)
  const currentPage = useSelector((state: RootState) => state.blueprint.currentPage)
  const visiblePages = useSelector((state: RootState) => state.blueprintVisiblePages.collection)

  // States
  const [localScale, setLocalScale] = useState(scale)
  const [localPage, setLocalPage] = useState(currentPage)

  /**
   * Scroll page into view
   *
   * @param page Page number
   */
  const scrollToPage = (page: number) => {
    if (totalPages && page > 0 && page <= totalPages) {
      const pageElement = document.querySelector(`.react-pdf__Page[data-page-number="${page}"]`)
      pageElement?.scrollIntoView()

      mixpanel.track('Blueprint - Change Page', {
        Before: currentPage,
        After: page,
      })

      dispatch(setCurrentPage(page))
    }
  }

  /**
   * Set page number when visible pages change
   */
  useEffect(() => {
    if (!visiblePages.length) return

    // Use temp variable as we need to sort (sort mutates the array)
    // Slicing out first and last element as when there are more than 2 visible pages,
    // the first and last element are cut-off by the viewport so no need to consider those
    let tempVisiblePages = [...visiblePages]
    if (tempVisiblePages.length > 2) {
      tempVisiblePages = tempVisiblePages.sort().slice(1, tempVisiblePages.length - 1)
    }

    dispatch(setCurrentPage(Math.min(...tempVisiblePages)))
  }, [visiblePages, dispatch])

  /**
   * Update local value when actuaL value changes
   */
  useEffect(() => setLocalScale(scale), [scale])
  useEffect(() => setLocalPage(currentPage), [currentPage])

  /**
   * Finalize scale value
   *
   * @param value
   */
  const updateScale = (value: number) => {
    const newScale = Math.min(Math.max(value, BLUEPRINT_MIN_SCALE), BLUEPRINT_MAX_SCALE)
    setLocalScale(newScale)
    dispatch(setScale(newScale))
  }

  /**
   * Callback for changes of scale by input field
   */
  const handleInputScaleChange = (newScale: number) => {
    mixpanel.track('Blueprint - Change Scale', {
      Before: scale,
      After: newScale,
      Method: 'input',
    })

    updateScale(newScale)
  }

  /**
   * Callback for changes of scale by +- buttons
   *
   * @param amount
   */
  const handleButtonScaleChange = (amount: number) => {
    const newScale = localScale + amount

    mixpanel.track('Blueprint - Change Scale', {
      Before: scale,
      After: newScale,
      Method: 'button',
    })

    setLocalScale(newScale)
    updateScale(newScale)
  }

  /**
   * Kepp track of input value to be used later.
   * Also prevent non-numeric values.
   *
   * @param value
   */
  const onInputScaleChange = (value: string) => {
    const newValue = value.replace('%', '')
    if (/^\d+$/.test(newValue)) {
      setLocalScale(Number(newValue))
    }
  }

  return {
    localScale,
    localPage,
    setLocalPage,
    scrollToPage,
    handleInputScaleChange,
    handleButtonScaleChange,
    onInputScaleChange,
  }
}
