import { FC, useCallback, useContext, useState } from 'react'

import { Box, Collapse, Flex, Text, VStack } from '@chakra-ui/react'
import { cloneDeep } from 'lodash'
import mixpanel from 'mixpanel-browser'
import { setAttentionText } from 'pages/projects/common/AttentionText/store/attentionText'
import CollapsePanel from 'pages/projects/editor/infoPanels/components/CollapsePanel'
import LayerItem from 'pages/projects/editor/infoPanels/components/LayerItem'
import LayerItemDivider from 'pages/projects/editor/infoPanels/components/LayerItemDivider'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'

import { EditorContext } from 'contexts/Editor'
import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import { EDITOR_COLLAPSE_TYPES, MODAL_TYPES } from 'config/constants'
import { INFO_PANEL_PADDING } from 'config/styles'

import { InspectionItem } from 'interfaces/inspection'

import { deleteInspectionItem } from 'services/InspectionSheet'
import { zeroPad } from 'services/Util'

import { resetPerVolumeId, setGrids, updateGrid } from '../../store'

const GridInfoPanel: FC = () => {
  // Store
  const dispatch = useAppDispatch()
  const permissionSet = useSelector((state: RootState) => state.editor.permissionSet)
  const project = useSelector((state: RootState) => state.page.project)
  const inspectionArea = useSelector((state: RootState) => state.page.inspectionArea)
  const grids = useSelector((state: RootState) => state.toolGrid.grids)
  const userType = useSelector((state: RootState) => state.user.userType)

  // Context
  const { getAccessToken } = useContext(UserContext)
  const { isLayerModifying, inspectionItems, inspectionSheet, fetchInspectionItems } = useContext(EditorContext)
  const { showModal, showErrorModal } = useContext(GlobalModalContext)

  // State
  const [isCollapseOpen, setIsCollapseOpen] = useState(true)
  const [gridListCollapsed, setGridListCollapsed] = useState<Record<string, boolean>>({})

  /**
   * Handle deletion
   */
  const handleDelete = useCallback(
    (item: InspectionItem) => {
      if (!project || !inspectionArea) {
        return
      }

      showModal({
        title: 'グリッド',
        body: <Text>一度削除してしまうと、元に戻せません。</Text>,
        confirmText: '削除',
        modalType: MODAL_TYPES.CONFIRMATION_CRITICAL,
        onConfirmPromised: async () => {
          if (!inspectionSheet) {
            return false
          }

          const token = await getAccessToken()
          if (!token) {
            return false
          }

          dispatch(setAttentionText({ message: 'データを更新中...' }))

          const result = await deleteInspectionItem(
            token,
            project.project_id,
            inspectionArea.inspection_area_id,
            inspectionSheet.inspection_sheet_id,
            item.inspection_item_id!,
            showErrorModal,
          )

          dispatch(setAttentionText({ message: '' }))

          if (!result) {
            return false
          }

          mixpanel.track('Deleted grid', {
            'Number of grid': inspectionItems.filter((inspectionItem) => inspectionItem.grid).length - 1,
          })

          await fetchInspectionItems()
          dispatch(resetPerVolumeId(item.volume_id!))
          return true
        },
      })
    },
    [
      project,
      inspectionArea,
      inspectionSheet,
      inspectionItems,
      getAccessToken,
      dispatch,
      showModal,
      showErrorModal,
      fetchInspectionItems,
    ],
  )

  /**
   * Update visibility of all grid's visibility
   * @param {DepthType} depthType The object should contain the new value of visibility
   */
  const updateAllLayerVisibility = useCallback(
    (visibility: boolean) => {
      const newGrids = grids.map((item) => {
        const newItem = cloneDeep(item)
        newItem.grid!.list_distances = newItem.grid!.list_distances.map((distance) => ({
          ...distance,
          invisible: visibility,
        }))
        return newItem
      })

      dispatch(setGrids(newGrids))
    },
    [grids, dispatch],
  )

  /**
   * Update specfic entire grid visibility
   * @param {boolean} visibility New visibility
   * @param {number} index Array index of the mode
   */
  const updateGridVisibility = useCallback(
    (visibility: boolean, item: InspectionItem) => {
      const newGrid = cloneDeep(item)
      newGrid.grid!.list_distances =
        newGrid.grid?.list_distances.map((distance) => ({ ...distance, invisible: visibility })) || []
      dispatch(updateGrid(newGrid))
    },
    [dispatch],
  )

  /**
   * Update specfic grid points' visibility
   * @param {boolean} visibility New visibility
   * @param {number} index Array index of the mode
   */
  const updateGridDistanceVisibility = useCallback(
    (visibility: boolean, item: InspectionItem, index: number) => {
      const newGrid = cloneDeep(item)
      newGrid.grid!.list_distances[index] = { ...newGrid.grid!.list_distances[index], invisible: visibility }
      dispatch(updateGrid(newGrid))
    },
    [dispatch],
  )

  const updateGridDistanceHighlight = useCallback(
    (highlight: boolean, item: InspectionItem, index: number) => {
      const newGrid = cloneDeep(item)
      newGrid.grid!.list_distances[index] = { ...newGrid.grid!.list_distances[index], highlighted: highlight }
      dispatch(updateGrid(newGrid))
    },
    [dispatch],
  )

  /**
   * Update grid list collapsed state
   */
  const toggleGridListCollapse = useCallback(
    (id: string) => {
      setGridListCollapsed({
        ...gridListCollapsed,
        [id]: !gridListCollapsed[id],
      })
    },
    [gridListCollapsed],
  )

  // Permission check
  const isAllowedToModify = permissionSet.MODIFY.includes(userType)

  // Items to display
  if (!grids.length) {
    return null
  }

  return (
    <Flex
      backgroundColor="gray.800"
      borderBottomLeftRadius="md"
      borderTopLeftRadius="md"
      w="100%"
      flex={1}
      minH={isCollapseOpen ? 20 : 10}
      data-testid="grid-panel"
    >
      <CollapsePanel title="グリッド" type={EDITOR_COLLAPSE_TYPES.grid} onChange={setIsCollapseOpen}>
        <VStack w="100%" spacing={0} pb={INFO_PANEL_PADDING - 1} divider={<LayerItemDivider />} overflowY="auto">
          <LayerItem
            disabled={isLayerModifying}
            invisible={grids.every((item) => item.grid?.list_distances.every((distance) => distance.invisible))}
            label="全て表示"
            updateVisibility={(invisible) => updateAllLayerVisibility(invisible)}
          />
          {grids.map((item, itemIndex) => (
            <VStack w="100%" spacing={0} key={`grid-parent-${item.inspection_item_id!}`}>
              <LayerItem
                disabled={isLayerModifying}
                selected={false}
                invisible={item.grid?.list_distances.every((distance) => distance.invisible)}
                key={`grid-listitem-${item.inspection_item_id!}`}
                label={`グリッド${zeroPad(itemIndex + 1, 2)}`}
                updateVisibility={(invisible) => updateGridVisibility(invisible, item)}
                deleteLayer={isAllowedToModify ? () => handleDelete(item) : undefined}
                collapsible
                collapsed={!gridListCollapsed[item.inspection_item_id!]}
                updateExpansion={() => toggleGridListCollapse(item.inspection_item_id!)}
              />
              <Box width="100%">
                <Collapse in={gridListCollapsed[item.inspection_item_id!]} animateOpacity>
                  {item.grid?.list_distances.map((distance, distanceIndex) => (
                    <LayerItem
                      disabled={isLayerModifying}
                      selected={false}
                      invisible={distance.invisible}
                      key={`grid-listitem-${item.inspection_item_id!}-${zeroPad(distanceIndex, 2)}`}
                      label={distance.name}
                      updateVisibility={(invisible) => updateGridDistanceVisibility(invisible, item, distanceIndex)}
                      onMouseOver={() => updateGridDistanceHighlight(true, item, distanceIndex)}
                      onMouseOut={() => updateGridDistanceHighlight(false, item, distanceIndex)}
                      isChild
                    />
                  ))}
                </Collapse>
              </Box>
            </VStack>
          ))}
        </VStack>
      </CollapsePanel>
    </Flex>
  )
}

export default GridInfoPanel
