import './styles/blueprint.css'
import 'react-pdf/dist/Page/AnnotationLayer.css'
import 'react-pdf/dist/Page/TextLayer.css'

import { FC } from 'react'

import { Box, VStack } from '@chakra-ui/react'
import LoadingPage from 'components/LoadingPage'
import PageErrorHandler from 'components/PageErrorHandler'
import { Document, pdfjs } from 'react-pdf'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'

import { useDocumentTitle } from 'hooks/useDocumentTitle'

import {
  EDITOR_NAVBAR_HEIGHT,
  EDITOR_NAVBAR_HEIGHT_TABLET,
  EDITOR_NAVBAR_PADDING,
  TEMP_COMMENT_ID,
} from 'config/constants'

import { useComments } from '../common/Comments/hooks/comments'
import Navbar from '../common/Navbar'
import ActionsPanel from './components/ActionsPanel'
import BlueprintTools from './components/BlueprintTools'
import CommentFrame from './components/CommentFrame'
import { DocumentPage } from './components/DocumentPage'
import DocumentSwitcher from './components/DocumentSwitcher'
import { useBlueprint } from './hooks/blueprint'
import { useDocumentViewer } from './hooks/documentViewer'
import { setCurrentPage, setTotalPages } from './store/blueprint'

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.mjs`

const Blueprint: FC = () => {
  // Hooks
  const { selectedBlueprint, comments, isLoading } = useBlueprint()
  const { isLoading: isCommentsLoading } = useComments()
  const { blueprintUrl, onClick, isLoading: isDocumentLoading } = useDocumentViewer()

  // Store
  const dispatch = useAppDispatch()
  const editingCommentId = useSelector((state: RootState) => state.comments.editingCommentId)
  const isPageLoading = useSelector((state: RootState) => state.page.isLoading)
  const editingComment = useSelector((state: RootState) => state.comments.editingComment)
  const totalPages = useSelector((state: RootState) => state.blueprint.totalPages)
  const project = useSelector((state: RootState) => state.page.project)
  const inspectionArea = useSelector((state: RootState) => state.page.inspectionArea)

  // page title
  useDocumentTitle(
    [
      !inspectionArea && !project ? 'Hatsuly' : null,
      inspectionArea ? inspectionArea.inspection_area_name : null,
      project ? project.project_name : null,
    ]
      .filter(Boolean)
      .join(' · '),
  )

  // Vars
  const isAllLoading = isLoading || isPageLoading || isDocumentLoading || isCommentsLoading

  return (
    <PageErrorHandler>
      <VStack m={0} w="100svw" h="100svh" backgroundColor="black" className="blueprint" userSelect="none" gap={0}>
        <Navbar />

        <Box
          zIndex={100}
          position="fixed"
          left={2}
          top={{
            base: EDITOR_NAVBAR_HEIGHT_TABLET + EDITOR_NAVBAR_PADDING * 2 + 2,
            xl: EDITOR_NAVBAR_HEIGHT + EDITOR_NAVBAR_PADDING * 2 + 2,
          }} // extra 2 for margin between navbar and tools
        >
          <BlueprintTools />
        </Box>

        {isAllLoading ? (
          <Box position="relative" overflow="hidden" flex={1} w="100%">
            <LoadingPage text="読み込み中…" />
          </Box>
        ) : (
          <Box
            className={`blueprint-viewer ${editingCommentId ? 'disabled-scroll' : ''}`}
            top={{
              base: EDITOR_NAVBAR_HEIGHT_TABLET + 2 * EDITOR_NAVBAR_PADDING + 2,
              xl: EDITOR_NAVBAR_HEIGHT + 2 * EDITOR_NAVBAR_PADDING + 2,
            }}
          >
            {!isDocumentLoading && (
              <Document
                loading="読み込み中…"
                file={blueprintUrl}
                onLoadSuccess={(args) => {
                  dispatch(setTotalPages(args.numPages))
                  dispatch(setCurrentPage(1))
                }}
                onClick={onClick}
              >
                {Array.from(Array(totalPages).keys()).map((index) => (
                  <DocumentPage
                    key={`blueprint-page-${selectedBlueprint?.blueprint_id || ''}-${index}`}
                    pageNumber={index}
                  />
                ))}

                {/* Existing comments */}
                {comments.map((comment) => (
                  <CommentFrame key={comment.thread_id} comment={comment} />
                ))}

                {/* Temporary frame for adding new comment */}
                {editingComment?.thread_id === TEMP_COMMENT_ID && (
                  <CommentFrame key={TEMP_COMMENT_ID} comment={editingComment} />
                )}
              </Document>
            )}

            <VStack
              position="fixed"
              top={{
                base: EDITOR_NAVBAR_HEIGHT_TABLET + EDITOR_NAVBAR_PADDING * 2 + 2,
                xl: EDITOR_NAVBAR_HEIGHT + EDITOR_NAVBAR_PADDING * 2 + 2,
              }} // extra 2 for margin between navbar and tools
              right={2}
              zIndex={100}
              w={{
                base: '285px',
                xl: '235px',
              }}
              gap={1}
            >
              <DocumentSwitcher />
              <ActionsPanel />
            </VStack>
          </Box>
        )}
      </VStack>
    </PageErrorHandler>
  )
}

export default Blueprint
