import { FC } from 'react'

import { Group, Line, Text } from 'react-konva'

interface LengthIndicatorProps {
  /**
   * Length of the line
   */
  length: number

  /**
   * Width of the anchor
   */
  anchorWidth?: number

  /**
   * X coordinate of the line.
   */
  x: number

  /**
   * Y coordinate of the line.
   */
  y: number

  /**
   * Rotation of the line.
   * If not provided, the line will be vertical.
   */
  rotation?: number

  /**
   * Label of the line.
   */
  label?: string

  /**
   * If true, the position of the label will be inverted.
   * By default, the label is positioned to the left of the line (or bottom if flipped 270).
   * Inverting will place at the opposite side of the line.
   */
  invertLabelPosition?: boolean

  /**
   * Style of the line's dash.
   */
  lineDash?: number[]
}

/**
 * The origin is at the beginning of the line not offsetted by the anchor width.
 *           |                   |
 *   here -> |-------------------|
 *           |                   |
 */
const LengthIndicator: FC<LengthIndicatorProps> = ({
  length,
  anchorWidth = 15,
  x,
  y,
  rotation = 0,
  label,
  invertLabelPosition,
  lineDash = [10, 5],
}) => {
  let labelXOffset = invertLabelPosition ? 15 : -5
  if (!rotation) {
    labelXOffset *= -1
  }

  return (
    <Group x={x} y={y} rotation={rotation}>
      {/* body */}
      <Line stroke="#000" strokeWidth={1} points={[0, 0, 0, length]} dash={lineDash} />

      {/* anchor 1 */}
      <Line stroke="#000" strokeWidth={1} points={[-(anchorWidth / 2), 0, anchorWidth / 2, 0]} x={0} y={0} />

      {/* anchor 2 */}
      <Line stroke="#000" strokeWidth={1} points={[-(anchorWidth / 2), length, anchorWidth / 2, length]} x={0} y={0} />

      {/* label */}
      <Group>
        <Text
          text={label}
          y={rotation ? 0 : length}
          x={labelXOffset}
          rotation={rotation ? -rotation : -90}
          width={length}
          align="center"
          verticalAlign="middle"
          fontSize={16}
          fontStyle="bold"
        />
      </Group>
    </Group>
  )
}

export default LengthIndicator
